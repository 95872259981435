import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";

const QueueDetailComponent = ({ queue }) => {
  return (
    <>
      <MeaDetailCard>
        <MeaDetailField field="Nom de la liste" value={queue?.name} />
        <MeaDetailField
          field="Présentation"
          value={queue?.presentation || "-"}
        />
      </MeaDetailCard>
    </>
  );
};

export default QueueDetailComponent;
