import { useNavigate, useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import LevelDetailComponent from "./components/LevelDetailComponent";
import levelsAPI from "../../../services/levels";
import MeaPageTitle from "../../../components/MeaPageTitle";
import { useRef, useState } from "react";
import ClassesListComponent from "../classes/components/ClassesListComponent";
import CoursesListComponent from "../courses/components/CoursesListComponent";
import { TabMenu } from "primereact/tabmenu";
import MeaLoadingContent from "../../../components/MeaLoadingContent";
import { Panel } from "primereact/panel";
import UpdateLevelModal from "./components/UpdateLevelModal";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const LevelDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const updateModalRef = useRef();
  const toast = useRef();
  const navigate = useNavigate();
  const levelId = params.levelId;
  //
  const levelQuery = levelsAPI.GetOne(
    schoolId,
    trainingId,
    accessToken,
    levelId
  );
  const levelObj = levelQuery?.data?.data;
  //
  // states
  const [activeTab, setActiveTab] = useState(0);
  const listRef = useRef();
  // params
  const tabs = [
    { label: "Classes", icon: "pi pi-th-large" },
    { label: "Cours", icon: "pi pi-file-edit" },
  ];

  //
  return (
    <>
      <MeaPage
        title={<MeaPageTitle prefix={"Niveau:"} title={levelObj?.name} />}
        actions={[
          {
            label: "Modifier",
            icon: "pi pi-file-edit",
            command: () => {
              if (updateModalRef.current) {
                updateModalRef.current.show(() => {
                  levelQuery.refetch();
                });
              }
            },
          },
          {
            separator: true,
          },
          {
            label: "Supprimer",
            icon: "pi pi-trash",
            command: () => {
              confirmDialog({
                message: "Voulez-vous vraiment supprimer ce niveau?",
                header: "Confirmation",
                icon: "pi pi-info-circle",
                defaultFocus: "reject",
                acceptClassName: "p-button-danger",
                accept: () => {
                  levelsAPI
                    .DeleteOne(schoolId, trainingId, accessToken, levelId)
                    .then((data) => {
                      toast.current.show({
                        severity: "success",
                        summary: "Succès",
                        detail: levelObj?.name + " Supprimé!",
                        life: 3000,
                      });
                      navigate(
                        "/c/t/" + schoolId + "/" + trainingId + "/levels"
                      );
                    })
                    .catch((error) => {
                      toast.current.show({
                        severity: "error",
                        summary: "Erreur",
                        detail: "Erreur de suppression!",
                      });
                    });
                },
              });
            },
          },
        ]}
      >
        <LevelDetailComponent level={levelObj} />

        <UpdateLevelModal ref={updateModalRef} level={levelObj} />
        <ConfirmDialog />
        <Toast ref={toast} />

        <div className="mt-5">
          <TabMenu
            model={tabs}
            activeIndex={activeTab}
            onTabChange={(e) => {
              setActiveTab(e.index);
            }}
          />
          {activeTab === 0 && (
            <MeaLoadingContent objectQuery={levelQuery}>
              <ClassesListComponent
                ref={listRef}
                filter={{ level_id: levelObj?.id }}
                showAddButton={true}
                showFilterButton={false}
                showToolbar={true}
                selectedFields={{
                  level: false,
                  maxCapacity: true,
                }}
              />
            </MeaLoadingContent>
          )}
          {activeTab === 1 && (
            <MeaLoadingContent objectQuery={levelQuery}>
              <CoursesListComponent
                ref={listRef}
                filter={{ level_id: levelObj?.id }}
                showAddButton={true}
                showFilterButton={false}
                showToolbar={true}
                selectedFields={{
                  level: false,
                }}
              />
            </MeaLoadingContent>
          )}
        </div>
      </MeaPage>
    </>
  );
};
export default LevelDetailPage;
