import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import attendancesAPI from "../../../../services/attendances";

const UpdateAttendanceModal = forwardRef((props, ref) => {
  //
  const [status, setStatus] = useState("");
  const [delay, setDelay] = useState("");
  const [comment, setComment] = useState("");
  //
  useEffect(() => {
    setStatus(props?.attendance?.name);
    setDelay(props?.attendance?.delay);
    setComment(props?.attendance?.comment);
  }, [props]);
  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer la présence"
        serviceAPI={attendancesAPI}
        id={props?.attendance?.id}
        data={{
          status: status,
          delay: delay,
          comment: comment,
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Situation
        </label>
        <InputText
          id="name"
          type="text"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label htmlFor="delay" className="block text-900 font-medium mb-2">
          Retard
        </label>
        <InputTextarea
          id="delay"
          type="text"
          value={delay}
          onChange={(e) => setDelay(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
        <label htmlFor="comment" className="block text-900 font-medium mb-2">
          Commentaire
        </label>
        <InputText
          id="comment"
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="w-full mb-3"
          disabled={true}
        />
      </MeaUpdateModal>
    </>
  );
});

export default UpdateAttendanceModal;
