import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import useAuthContext from "../hooks/useAuthContext";

const MeaUpdateModal = forwardRef((props, ref) => {
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [active, setActive] = useState(false);
  const [callbackFn, setCallbackFn] = useState(() => { });
  //
  const [loading, setLoading] = useState(false);
  //
  const toast = useRef(null);
  //
  const handleUpdateSubmit = async (e) => {
    // Handle update action
    setLoading(true);
    props.serviceAPI
      .UpdateOne(schoolId, trainingId, accessToken, props.id, props.data)
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Modification effectuée avec succès!",
        });
        setActive(false);
        callbackFn();
        //
        if (props.onSubmitted) {
          props.onSubmitted();
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur de modification!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //
  useImperativeHandle(ref, () => ({
    show: (callback) => {
      setCallbackFn(() => callback);
      setActive(true);
    },
  }));
  //

  const footerContent = (
    <div>
      <Button
        label="Annuler"
        icon="pi pi-times"
        disabled={loading}
        onClick={() => setActive(false)}
        className="p-button-text"
      />
      <Button
        type="submit"
        loading={loading}
        label="Enregister"
        icon="pi pi-save"
        onClick={() => handleUpdateSubmit()}
      />
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={props.title}
        visible={active}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setActive(false)}
        footer={footerContent}
      >
        <div className="w-full">
          <div className="flex flex-column gap-2">{props.children}</div>
        </div>
      </Dialog>
    </>
  );
});

export default MeaUpdateModal;
