import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";

const ClassroomDetailComponent = ({ classroom }) => {
  return (
    <MeaDetailCard>
      <MeaDetailField field="Nom de la salle" value={classroom?.name} />
      <MeaDetailField
        field="Présentation"
        value={classroom?.presentation || " "}
      />
      <MeaDetailField field="Capacité" value={classroom?.capacity} />
    </MeaDetailCard>
  );
};

export default ClassroomDetailComponent;
