import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import StudentsListComponent from "./components/StudentsListComponent";

const StudentsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des élèves inscrits"
        subtitle="Gérer l'ensemble des élèves avec inscription validée de cette formation"
      >
        <StudentsListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default StudentsListPage;
