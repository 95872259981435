import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import { InputText } from "primereact/inputtext";
import CreateSystemSchoolModal from "./CreateSystemSchoolModal";
import systemSchoolsAPI from "../../../../services/system_schools";

const SystemSchoolsListComponent = forwardRef((props, ref) => {
  const { selectedFields, showAddButton, showFilterButton } = props;
  const { auth } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = "";
  const trainingId = "";
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  let selectedData = {
    name: selectedFields?.name ?? true,
    createdAt: selectedFields?.createdAt ?? true,
    edit: selectedFields?.edit ?? true,
  };
  //
  //
  const queryList = systemSchoolsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Créer une école "
          icon="pi pi-plus"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouvelle école"
        />
      )}
    </div>
  );
  //
  return (
    <>
      <CreateSystemSchoolModal ref={createModalRef} />
      <MeaDataTable
        query={queryList}
        serviceAPI={systemSchoolsAPI}
        names={{ pagination: "school", singular: "école", plural: "écoles" }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        {selectedData.name && (
          <Column
            field="name"
            header="Ecole"
            editor={(options) => (
              <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
              />
            )}
            body={(item) => (
              <MeaLink to={"/c/s/" + item.id}>{item.name}</MeaLink>
            )}
          ></Column>
        )}
        {selectedData.createdAt && (
          <Column field="created_at" header="Crée le"></Column>
        )}

        {selectedData.edit && (
          <Column
            body={(item) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  text
                  aria-label="Filter"
                  onClick={() => {
                    //setSchool(item);
                    if (updateModalRef.current) {
                      updateModalRef.current.show(() => {
                        queryList.refetch();
                      });
                    }
                  }}
                />
              </>
            )}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        )}
      </MeaDataTable>
      {/*<UpdateSystemSchoolModal ref={updateModalRef} school={school} />*/}
    </>
  );
});

export default SystemSchoolsListComponent;
