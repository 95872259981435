import { Dialog } from "primereact/dialog";
import React, { useState, forwardRef, useEffect, useImperativeHandle } from "react";
import useAuthContext from "../../../../hooks/useAuthContext";
import { Button } from "primereact/button";
import tasksAPI from "../../../../services/tasks";
import { Message } from "primereact/message";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ImportLevelsClassesFromExcelModal = forwardRef((props, ref) => {

    const { auth, currentMembership, currentTraining } = useAuthContext();
    const schoolId = currentMembership?.school_id;

    const [file, setFile] = useState(null);
    const [active, setActive] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [jobId, setJobId] = useState(null);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (file) {
            console.log('Uploading file...');
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append("name", file.name);

            fetch(BACKEND_URL + "/api/v1/schools/" + schoolId + "/trainings/" + currentTraining?.id + "/tasks/import-levelsclasses-from-excel-file", {
                method: "POST",
                body: formData,
                credentials: "include",
                headers: {
                    Authorization: "Bearer " + auth?.access_token,
                },
            })
                .then((response) => {
                    if (response.status >= 299) {
                        throw new Error(
                            "Erreur de chargement du fichier!"
                        );
                    }
                    return response.json();
                })
                .then((json) => {
                    setJobId(json?.data?.id);
                })
                .catch(function (error) {
                    console.log("error " + error);
                });
        }
    };

    useEffect(() => {
        const fetchTaskStatus = async () => {
            tasksAPI.GetTask(schoolId, currentTraining?.id, jobId, auth?.access_token)
                .then((data) => {
                    if (data?.data?.status === "COMPLETED") {
                        {
                            setSuccessMessage("Fichier importé avec succès!");
                            setErrorMessage(null);
                            setUploading(false);
                            setJobId(null);
                        }
                    } else if (data?.data?.status === "FAILED") {
                        {
                            setSuccessMessage();
                            setErrorMessage(data?.data?.message);
                            setUploading(false);
                            setJobId(null);
                        }
                    }
                    else {
                        setTimeout(fetchTaskStatus, 1000); // Retry after 1 seconds
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                    setSuccessMessage(null);
                    setErrorMessage("Erreur de chargement du fichier!");
                    setJobId(null);
                    setUploading(false);
                });
        };

        if (uploading && jobId) {
            fetchTaskStatus();
        }

    }, [uploading, jobId]);

    //
    useImperativeHandle(ref, () => ({
        show: (callback) => {
            //setCallbackFn(() => callback);
            setActive(true);
        },
    }));

    return (
        <>
            <Dialog
                header="Importer des niveaux/classes depuis un fichier Excel"
                visible={active}
                onHide={() => { setActive(false); setUploading(false); setFile(null); setErrorMessage(null); setSuccessMessage(null) }}
            >
                <div className="w-full">

                    {!uploading && !successMessage && !errorMessage && <div className="flex flex-column gap-2">
                        <input className="" id="file" type="file" onChange={handleFileChange} />
                    </div>}
                    {file && (
                        <section>
                            File details:
                            <ul>
                                <li>Nom de fichier: {file.name}</li>
                                <li>Taille: {(file.size / (1024 * 1024)).toFixed(2)} MB</li>
                            </ul>
                        </section>
                    )}
                    {uploading && <div><i className="pi pi-spin pi-cog" style={{ fontSize: '2rem' }}></i><span className="ml-3">Merci d'attendre...</span></div>}

                    {file && !uploading && !successMessage && !errorMessage && (
                        <Button
                            disabled={file === null}
                            onClick={handleUpload}
                            className="submit"
                        >Importer</Button>
                    )}
                    {errorMessage && (
                        <><Message severity="error" text={errorMessage} /><br /><br /><Button outlined label="Fermer" onClick={() => { setActive(false); setUploading(false); setFile(null); setErrorMessage(null); setSuccessMessage(null) }} /></>
                    )}
                    {successMessage && (<><Message severity="success" text={successMessage} /><br /><br /><Button outlined label="Fermer" onClick={() => { setActive(false); setUploading(false); setFile(null); setErrorMessage(null); setSuccessMessage(null) }} /></>)}
                </div>
            </Dialog>
        </>
    );
}
);

export default ImportLevelsClassesFromExcelModal;