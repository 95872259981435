import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30
) => {
  return useQuery(
    ["teachers", schoolId, trainingId, accessToken, page, pageSize],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/teachers?page=${page}&pageSize=${pageSize}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch teachers!");
        }
        if (response.status >= 299) {
          throw new Error(
            "Erreur de récupération de la liste des enseignants!"
          );
        }
        const result = await response.json();
        return result;
      } catch (error) {
        // Handle the error
        console.error("Error fetching teacher:", error);
        throw error;
      }
    }
  );
};

const GetOne = (schoolId, trainingId, accessToken, teacherId) => {
  return useQuery(
    ["slot", schoolId, trainingId, accessToken, teacherId],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/teachers/${teacherId}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erreur de récupération de l'enseignant!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    }
  );
};

const AddList = async (schoolId, trainingId, accessToken, data) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/teachers",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de l'ajout des enseignants!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, trainingId, accessToken, teacherId) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/teachers/" +
        teacherId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression de l'enseignant!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const teachersAPI = {
  useGetList,
  GetOne,
  AddList,
  //CreateTeacher,
  //UpdateTeacher,
  DeleteOne,
};

export default teachersAPI;
