import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";
import { ToFormatedDateForEvents } from "../../../../utils/dates";

const EventDetailExtraComponent = ({ event }) => {
  const startSate = new Date(event?.start_date);
  const formattedStartDate = ToFormatedDateForEvents(startSate, event?.all_day);
  const endSate = new Date(event?.end_date);
  const formattedEndDate = ToFormatedDateForEvents(endSate, event?.all_day);
  return (
    <MeaDetailCard>
      <MeaDetailField field="Type d'évènement" value={event?.event_type} />
      <MeaDetailField field="Date de début" value={formattedStartDate} />
      <MeaDetailField field="Date de fin" value={formattedEndDate} />
    </MeaDetailCard>
  );
};

export default EventDetailExtraComponent;
