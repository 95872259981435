import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import SystemSchoolsListComponent from "./components/SystemSchoolsListComponent";

const SystemSchoolsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des écoles"
        subtitle="Gérer l'ensemble des écoles sur la plateforme"
      >
        <SystemSchoolsListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={false}
        />
      </MeaPage>
    </>
  );
};
export default SystemSchoolsListPage;
