import useAuthContext from "../../../hooks/useAuthContext";
import MeaPage from "../../../components/MeaPage";
import { useCallback, useRef, useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import ScheduleListComponent from "../schedule/components/ScheduleListComponent";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import tasksAPI from "../../../services/tasks";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
const GenerateSessionsPage = () => {
  const { authContext, auth, currentMembership, currentTraining } =
    useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //
  const toast = useRef();
  //
  const [startDate, setStartDate] = useState(
    authContext?.currentTraining?.start_courses_date?.split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    authContext?.currentTraining?.end_courses_date?.split("T")[0]
  );
  const [avoidHolidays, setAvoidHolidays] = useState(true);
  const handleAvoidHolidaysChange = useCallback(
    (newChecked) => setAvoidHolidays(newChecked),
    []
  );
  const [avoidVacations, setAvoidVacations] = useState(true);
  const handleAvoidVacationsChange = useCallback(
    (newChecked) => setAvoidVacations(newChecked),
    []
  );
  //
  const scheduleRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Génération des séances de cours"
        subtitle="Générer des séances de cours pour cette formation"
      >
        <ConfirmDialog />
        <Toast ref={toast} position="bottom-center" />
        <Fieldset legend="Interval de temps" className="m-2">
          <div className="card flex flex-column md:flex-row gap-3">
            <div className="p-inputgroup flex flex-column gap-2">
              <label
                htmlFor="startdate"
                className="block text-900 font-medium mb-2"
              >
                Date de début
              </label>
              <InputText
                id="startdate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full mb-3"
              />
            </div>
            <div className="p-inputgroup flex flex-column gap-2">
              <label
                htmlFor="endDate"
                className="block text-900 font-medium mb-2"
              >
                Date de début
              </label>
              <InputText
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full mb-3"
              />
            </div>
          </div>
        </Fieldset>
        <Fieldset legend="A éviter" className="m-2">
          <div className="card flex flex-column gap-3">
            <div className="flex align-items-center">
              <Checkbox
                inputId="avoidHolidays"
                name="avoidHolidays"
                checked={avoidHolidays}
                onChange={(e) => handleAvoidHolidaysChange(e.checked)}
              />
              <label htmlFor="avoidHolidays" className="ml-2">
                Eviter les jours fériés
              </label>
            </div>
            <div className="flex align-items-center">
              <Checkbox
                inputId="avoidVacations"
                name="avoidVacations"
                checked={avoidVacations}
                onChange={(e) => handleAvoidVacationsChange(e.checked)}
              />
              <label htmlFor="avoidVacations" className="ml-2">
                Eviter les jours de vacances
              </label>
            </div>
          </div>
        </Fieldset>
        <Fieldset
          legend="Pour quels créneaux ?"
          className="m-2"
        >
          <ScheduleListComponent
            ref={scheduleRef}
            showToolbar={false}
            showTotalSelectedElements={true}
          />
        </Fieldset>
        <Button
          label="Générer les séances de cours"
          className="m-2"
          loading={loading}
          onClick={(e) => {
            e.stopPropagation();
            setLoading(true);
            let elements = scheduleRef.current.getSelectedElements();
            const data = {
              start_date: new Date(startDate).toISOString(),
              end_date: new Date(endDate).toISOString(),
              schedule_items_ids: elements?.map((element) => {
                return element.id;
              }),
              avoid_holidays: avoidHolidays,
              avoid_vacations: avoidVacations,
            };
            confirmDialog({
              message:
                "Voulez-vous procéder à la génération des séances de cours?",
              header: "Confirmation",
              icon: "pi pi-info-circle",
              acceptLabel: "Oui",
              rejectLabel: "Non",
              defaultFocus: "reject",
              reject: () => {
                setLoading(false);
              },
              accept: () => {
                toast.current.show({
                  severity: "info",
                  summary: "Info",
                  sticky: true,
                  detail: "Génération des séances de cours...",
                });
                tasksAPI
                  .GenerateTrainingSessions(
                    schoolId,
                    trainingId,
                    accessToken,
                    data
                  )
                  .then((data) => {
                    toast.current.clear();
                    toast.current.show({
                      severity: "success",
                      summary: "Succès",
                      detail: "Séances de cours générés avec succès.",
                      life: 3000,
                    });
                    navigate(
                      "/c/t/" + schoolId + "/" + trainingId + "/sessions"
                    );
                  })
                  .catch((error) => {
                    toast.current.clear();
                    toast.current.show({
                      severity: "error",
                      summary: "Erreur",
                      detail: "Erreur de génération!",
                    });
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              },
            });
          }}
        />
      </MeaPage>
    </>
  );
};
export default GenerateSessionsPage;
