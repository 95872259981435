import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import EventsListComponent from "./components/EventsListComponent";

const EventsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des évenements"
        subtitle="Gérer l'ensemble des évenements dans cette formation"
      >
        <EventsListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default EventsListPage;
