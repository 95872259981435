import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import TrainingsTree from "../components/TrainingsTree";
import { useNavigate } from "react-router-dom";
import accountsAPI from "../../../../services/accounts";
import useAuthContext from "../../../../hooks/useAuthContext";

const SwitchTrainingModal = forwardRef((props, ref) => {

  const { authContext, currentMembership } = useAuthContext();

  const [active, setActive] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState(null);

  useEffect(() => {
    setSelectedMembership(currentMembership);
  }, [currentMembership]);

  const navigate = useNavigate();

  const membershipsList = accountsAPI.useMemberships(
    authContext?.auth?.access_token,
    "1",
    "1000000"
  );
  const memberships = membershipsList?.data?.data || [];

  useImperativeHandle(ref, () => ({
    open: () => {
      setActive(true);
    },
    close: () => {
      setActive(false);
    },
  }));

  return (
    <>
      <Dialog
        header="Changement de formation"
        visible={active}
        onHide={() => setActive(false)}
        footer={
          <>
            <Button
              label="Gérer les formations"
              icon="pi pi-cog"
              disabled={!selectedMembership}
              onClick={() => { setActive(false); navigate("/c/s/" + selectedMembership?.school?.id + "/trainings") }}
              className="p-button-text"
            />
            <Button
              label="Annuler"
              icon="pi pi-times"
              onClick={() => setActive(false)}
              className="p-button-text"
            />
          </>
        }
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="flex flex-column gap-2">
          <Dropdown style={{ fontWeight: "bold" }} value={selectedMembership} onChange={(e) => setSelectedMembership(e.value)} options={memberships} optionLabel="school.name"
            placeholder="" />
          <TrainingsTree schoolId={selectedMembership?.school?.id} callback={() => setActive(false)} />
        </div>
      </Dialog>
    </>
  );
});

export default SwitchTrainingModal;
