import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import queueLevelsAPI from "../../../../services/queue_levels";
import { useParams } from "react-router-dom";
import CreateQueueLevelModal from "./CreateQueueLevelModal";
import UpdateQueueLevelModal from "./UpdateQueueLevelModal";

const QueueLevelsListComponent = forwardRef((props, ref) => {
  const { showAddButton } = props;
  const { auth, currentMembership } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();
  //
  const params = useParams();
  const queueId = params.queueId;
  //
  const schoolId = currentMembership?.school_id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  const [level, setLevel] = useState(null);
  //
  //
  const queryList = queueLevelsAPI.useGetList(
    schoolId,
    queueId,
    accessToken,
    page,
    pageSize
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Créer un niveau"
          icon="pi pi-plus"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouveau niveau"
        />
      )}
    </div>
  );
  //
  return (
    <>
      <CreateQueueLevelModal ref={createModalRef} queueId={queueId} />
      <UpdateQueueLevelModal ref={updateModalRef} level={level} />
      <MeaDataTable
        queueId={queueId}
        query={queryList}
        serviceAPI={queueLevelsAPI}
        names={{
          pagination: "queueLevels",
          singular: "niveau",
          plural: "niveaux",
        }}
        startToolbarTemplate={startToolbarTemplate}
        //showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column field="name" header="Niveau"></Column>
        <Column field="presentation" header="Presentation"></Column>
        <Column
          body={(item) => (
            <>
              <Button
                icon="pi pi-pencil"
                rounded
                text
                aria-label="Filter"
                onClick={() => {
                  setLevel(item);
                  if (updateModalRef.current) {
                    updateModalRef.current.show(() => {
                      queryList.refetch();
                    });
                  }
                }}
              />
            </>
          )}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </MeaDataTable>
    </>
  );
});

export default QueueLevelsListComponent;
