import { useNavigate, useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import CourseDetailComponent from "./components/CourseDetailComponent";
import coursesAPI from "../../../services/courses";
import MeaPageTitle from "../../../components/MeaPageTitle";
import MeaLoadingContent from "../../../components/MeaLoadingContent";
import { useRef, useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import ScheduleListComponent from "../schedule/components/ScheduleListComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import UpdateCourseModal from "./components/UpdateCourseModal";

const CourseDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const updateModalRef = useRef();
  const toast = useRef();
  const navigate = useNavigate();
  const courseId = params.courseId;
  //
  const courseQuery = coursesAPI.GetOne(
    schoolId,
    trainingId,
    accessToken,
    courseId
  );
  const courseObj = courseQuery?.data?.data;
  //
  //
  // states
  const [activeItem, setActiveItem] = useState(0);
  const listRef = useRef();
  // params
  const items = [{ label: "Programme de la semaine", icon: "pi pi-clock" }];
  //
  return (
    <>
      <MeaPage
        title={<MeaPageTitle prefix={"Cours:"} title={courseObj?.name} />}
        actions={[
          {
            label: "Modifier",
            icon: "pi pi-file-edit",
            command: () => {
              if (updateModalRef.current) {
                updateModalRef.current.show(() => {
                  courseQuery.refetch();
                });
              }
            },
          },
          {
            separator: true,
          },
          {
            label: "Supprimer",
            icon: "pi pi-trash",
            command: () => {
              confirmDialog({
                message: "Voulez-vous vraiment supprimer ce cours?",
                header: "Confirmation",
                icon: "pi pi-info-circle",
                defaultFocus: "reject",
                acceptClassName: "p-button-danger",
                acceptLabel: "Oui",
                rejectLabel: "Non",
                accept: () => {
                  coursesAPI
                    .DeleteOne(schoolId, trainingId, accessToken, courseId)
                    .then((data) => {
                      toast.current.show({
                        severity: "success",
                        summary: "Succès",
                        detail: courseObj?.name + " Supprimé!",
                        life: 3000,
                      });
                      navigate(
                        "/c/t/" + schoolId + "/" + trainingId + "/courses"
                      );
                    })
                    .catch((error) => {
                      toast.current.show({
                        severity: "error",
                        summary: "Erreur",
                        detail: "Erreur de suppression!",
                      });
                    });
                },
              });
            },
          },
        ]}
      >
        <CourseDetailComponent course={courseObj} />
        <UpdateCourseModal ref={updateModalRef} course={courseObj} />
        <ConfirmDialog />
        <Toast ref={toast} />

        <div className="mt-5">
          <TabMenu
            model={items}
            activeIndex={activeItem}
            onTabChange={(e) => {
              setActiveItem(e.index);
            }}
          />
          {activeItem === 0 && (
            <MeaLoadingContent objectQuery={courseQuery}>
              <ScheduleListComponent
                ref={listRef}
                filter={{ course_id: courseObj?.id }}
                showAddButton={false}
                showFilterButton={false}
                selectedFields={{
                  course: false,
                }}
              />
            </MeaLoadingContent>
          )}
        </div>
      </MeaPage>
    </>
  );
};
export default CourseDetailPage;
