import { Message } from "primereact/message";
import { Skeleton } from "primereact/skeleton";

const MeaDataTableLoadingQuery = ({ query }) => {
  if (query?.isError) {
    return (
      <Message
        severity="error"
        text="Erreur de récuperation des données!"
        className="block col-12 xl:col-6 mt-3 mb-3"
      />
    );
  } else {
    if (query?.isLoading) {
      return <Skeleton />;
    } else {
      return "Pas d'éléments";
    }
  }
};

export default MeaDataTableLoadingQuery;
