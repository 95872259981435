import MeaPage from "../../../components/MeaPage";
import React, { useRef } from "react";
import RegistrationsListComponent from "./components/RegistrationsListComponent";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";

const RegistrationsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<BreadCrumb model={[{ label: 'Les inscriptions' }]} home={{ icon: 'pi pi-home', url: '/' }} />}
        title="Gestion des inscriptions"
        subtitle="Gérer l'ensemble des insctipions des élèves de cette formation"
      >

        <RegistrationsListComponent ref={listRef} showAddButton={false} />
      </MeaPage>
    </>
  );
};
export default RegistrationsListPage;
