import { classNames } from "primereact/utils";
import useAuthContext from "../../../../hooks/useAuthContext";
import accountsAPI from "../../../../services/accounts";
import { Tag } from "primereact/tag";
import { DataView } from "primereact/dataview";
import MeaLink from "../../../../components/MeaLink";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UserSchoolsListComponent = () => {
  const { authContext } = useAuthContext();

  const membershipsList = accountsAPI.useMemberships(
    authContext?.auth?.access_token,
    "1",
    "1000000"
  );
  const memberships = membershipsList?.data?.data || [];

  const getSeverity = (status) => {
    switch (status) {
      case "is_owner":
        return "success";

      case "is_admin":
        return "warning";

      case "is_staff":
        return "danger";

      case "is_teacher":
        return "danger";

      case "is_parent":
        return "danger";

      case "is_student":
        return "danger";

      default:
        return null;
    }
  };
  const itemTemplate = (membership, index) => {
    return (
      <div className="col-12" key={membership.id}>
        <div
          className={classNames(
            "flex flex-column xl:flex-row align-items-start p-4 gap-4",
            { "border-top-1 surface-border": index !== 0 }
          )}
        >
          <MeaLink to={`/c/s/${membership.school_id}`}>
            <img
              className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block border-round"
              src={
                membership.school?.avatar
                  ? BACKEND_URL + "/api/v1/medias/" + membership.school?.avatar
                  : "/School.jpeg"
              }
              alt={membership.name}
            />
          </MeaLink>
          <div className="flex flex-column sm:flex-row justify-content-between align-items-start flex-1 gap-4">
            <div className="flex flex-column align-items-start gap-3">
              <div className="text-2xl font-bold text-900">
                <MeaLink to={`/c/s/${membership.school_id}`}>
                  {membership.school.name}
                </MeaLink>
              </div>
              <div className="text-2xl font-normal text-900">
                {membership.school.presentation}
              </div>
              <div className="flex align-items-center gap-3">
                <span className="flex align-items-start gap-2">
                  <i className="pi pi-tag"></i>
                  <span className="font-semibold">
                    {membership.school.status}
                  </span>
                </span>
                {membership.is_owner && (
                  <Tag
                    value="Propriétaire"
                    severity={getSeverity("is_owner")}
                  ></Tag>
                )}
                {membership.is_admin && (
                  <Tag
                    value="Administration"
                    severity={getSeverity("is_admin")}
                  ></Tag>
                )}
                {membership.is_staff && (
                  <Tag
                    value="Personnel"
                    severity={getSeverity("is_staff")}
                  ></Tag>
                )}
                {membership.is_teacher && (
                  <Tag
                    value="Enseignant"
                    severity={getSeverity("is_teacher")}
                  ></Tag>
                )}
                {membership.is_parent && (
                  <Tag value="Parent" severity={getSeverity("is_parent")}></Tag>
                )}
                {membership.is_student && (
                  <Tag
                    value="Etudiant"
                    severity={getSeverity("is_student")}
                  ></Tag>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const listTemplate = (items) => {
    if (!items || items.length === 0)
      return <span>Vous n'appartenez à aucune école.</span>;

    let list = items.map((membership, index) => {
      return itemTemplate(membership, index);
    });

    return <div className="grid grid-nogutter">{list}</div>;
  };

  return (
    <DataView
      value={memberships}
      listTemplate={listTemplate}
      emptyMessage="Vous n'appartenez à aucune école."
      layout={"list"}
    />
  );
};

export default UserSchoolsListComponent;
