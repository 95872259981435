import useUrlState from "@ahooksjs/use-url-state";
import { forwardRef, useImperativeHandle, useState } from "react";
import MeaDataTable from "../../../../components/MeaDataTable";
import { Column } from "primereact/column";
import MeaStudentRegistrationAvatar from "../../../../components/MeaStudentRegistrationAvatar";
import useAuthContext from "../../../../hooks/useAuthContext";
import memberRegistrationsAPI from "../../../../services/member_registrations";
import MeaLink from "../../../../components/MeaLink";
import MeaStudentStatus2 from "../../../../components/MeaStudentStatus2";

const MemberRegistrationsListComponent = forwardRef((props, ref) => {
    const { member } = props;
    const { auth, currentMembership } = useAuthContext();
    const schoolId = currentMembership?.school_id;
    const accessToken = auth?.access_token;

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(30);

    const [state, setState] = useUrlState({
        registrationsPage: "1",
        registrationsPageSize: "30",
        //studentsFilter: JSON.stringify({ father_id: filter?.member_id || "" }),
    });
    /*
    const setFilter = (field, value) => {
        let currentFilter = state?.studentsFilter;
        let currentFilterObj = JSON.parse(currentFilter);
        currentFilterObj[field] = value;
        setState((s) => ({
            studentsPage: 1,
            studentsFilter: JSON.stringify(currentFilterObj),
        }));
    };*/
    //
    const queryList = memberRegistrationsAPI.useGetList(
        schoolId,
        "",
        accessToken,
        member?.id,
        page,
        pageSize,
        //JSON.parse(state.studentsFilter)
    );


    //
    useImperativeHandle(ref, () => ({
        refresh: () => {
            queryList?.refetch();
        },
    }));
    return (
        <MeaDataTable query={queryList}
            serviceAPI={memberRegistrationsAPI}
            names={{ pagination: "registrations", singular: "inscription", plural: "inscriptions" }}
            //startToolbarTemplate={startToolbarTemplate}
            //showFilterButton={showFilterButton}
            //showToolbar={showToolbar}
            setPage={setPage}
            setPageSize={setPageSize}>

            <Column header="Inscrit" body={(student) => <MeaStudentRegistrationAvatar student={student} />}></Column>
            <Column header="Formation" body={(student) => {
                return student?.training?.name;
            }}></Column>
            <Column
                field="level"
                header="Niveau"
                body={(student) => (
                    <MeaLink
                        to={
                            "/c/t/" +
                            student?.school_id +
                            "/" +
                            student?.training_id +
                            "/levels/" +
                            student?.level_id
                        }
                    >
                        {student?.level?.name}
                    </MeaLink>
                )}

            ></Column>
            <Column
                field="classe"
                header="Classe"
                body={(student) => (
                    <MeaLink
                        to={
                            "/c/t/" +
                            student?.school_id +
                            "/" +
                            student?.training_id +
                            "/classes/" +
                            student?.class_id
                        }
                    >
                        {student?.level?.name}
                    </MeaLink>
                )}
            ></Column>
            <Column
                field="status"
                header="Status"
                body={(student) => {
                    return <MeaStudentStatus2 student={student} />;
                }}
            ></Column>
            <Column header="Contrat & paiements" />

        </MeaDataTable>);
});

export default MemberRegistrationsListComponent;