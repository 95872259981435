import React, { useState, forwardRef, useEffect } from "react";

import membersAPI from "../../../../services/members";
import MeaUpdateAvatarModal from "../../../../components/MeaUpdateAvatarModal";

const UpdateMemberAvatarModal = forwardRef((props, ref) => {

  return (
    <>
      <MeaUpdateAvatarModal
        ref={ref}
        title="Editer le profil"
        serviceAPI={membersAPI}
        id={props?.member?.id}
      >
      </MeaUpdateAvatarModal>
    </>
  );
});

export default UpdateMemberAvatarModal;
