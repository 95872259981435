import { DataTable } from "primereact/datatable";
import MeaDataTableLoadingQuery from "./MeaDataTableLoadingQuery";
import { Toolbar } from "primereact/toolbar";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import useUrlState from "@ahooksjs/use-url-state";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import useAuthContext from "../hooks/useAuthContext";
import { Checkbox } from "primereact/checkbox";

const MeaDataTable = forwardRef((props, ref) => {
  let {
    children,
    query,
    serviceAPI,
    names,
    showToolbar = true,
    startToolbarTemplate,
    endToolbarTemplate,
    showFilterButton = true,
    showTotalSelectedElements = false,
    setPage,
    setPageSize,
  } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = props.queueId || currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  useEffect(() => {
    // cleanup selected elements if refetching
    setSelectedElements([])
  }, [query.isRefetching])
  //
  const [showFilter, setShowFilter] = useState(props.showFilter);
  //
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const toast = useRef(null);
  const jsonString =
    '{"' +
    names.pagination +
    'Page": "1", "' +
    names.pagination +
    'PageSize": "30"}';

  const [state, setState] = useUrlState(JSON.parse(jsonString));
  useEffect(() => {
    setPage(state[names.pagination + "Page"]);
    setPageSize(state[names.pagination + "PageSize"]);
  }, [state]);

  const [selectedElements, setSelectedElements] = useState(null);
  //
  useImperativeHandle(ref, () => ({
    getSelectedElements: () => {
      return selectedElements;
    },
  }));
  //
  const onPageChange = (e) => {
    let newPage = e.page + 1;
    let newPageSize = Number(e.rows);
    if (e.rows !== parseInt(state[names.pagination + "PageSize"])) {
      newPage = 1;
    }
    const jsonString =
      '{"' +
      names.pagination +
      'Page": "' +
      newPage +
      '", "' +
      names.pagination +
      'PageSize": "' +
      newPageSize +
      '"}';
    setState((s) => JSON.parse(jsonString));
    setPage(newPage);
    setPageSize(newPageSize);
    setSelectedElements([]);
  };

  const _startToolbarTemplate = (
    <div className="flex justify-content-between align-items-center">
      {startToolbarTemplate}
      {selectedElements?.length > 0 && (
        <Button
          label="Supprimer"
          icon="pi pi-trash"
          severity="danger"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            setConfirmDialogVisible(true);
          }}
          data-pr-tooltip="Create new car"
        />

      )}
    </div>
  );

  const _endToolbarTemplate = (
    <div className="flex justify-content-between align-items-center">
      {showFilterButton && (
        <>
          <label htmlFor="chkFilterDisplay" className="font-semibold mr-2">
            Afficher les filtres
          </label>
          <InputSwitch
            inputId="chkFilterDisplay"
            className="mr-2"
            checked={showFilter}
            aria-label="Switch filter display between menu and row"
            tooltipOptions={{ position: "top" }}
            onChange={(e) => {
              setShowFilter(!showFilter);
            }}
          />
        </>
      )}
      {endToolbarTemplate}
    </div>
  );

  const handleUpdateElement = async (e) => {
    let { newData, index } = e;

    try {
      await serviceAPI.UpdateOne(
        schoolId,
        trainingId,
        accessToken,
        newData?.id,
        newData
      );
      query.refetch();
      toast.current.clear();
      toast.current.show({
        severity: "success",
        summary: "Succès",
        detail: "Eléments mis à jour!",
        life: 3000,
      });
    } catch (err) {
      toast.current.clear();
      toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail: "Erreur de mise à jour de l'élément sélectionné!",
        life: 3000,
      });
    }
  };

  const handleDeleteSelected = async (e) => {
    toast.current.show({
      severity: "info",
      summary: "Info",
      sticky: true,
      detail: "Supression des éléments sélectionnés...",
      life: 3000,
    });

    try {
      await Promise.all(
        selectedElements.map((element) => {
          serviceAPI.DeleteOne(schoolId, trainingId, accessToken, element?.id);
        })
      );
      query.refetch();
      toast.current.clear();
      toast.current.show({
        severity: "success",
        summary: "Succès",
        detail: "Eléments supprimés!",
        life: 3000,
      });
      setSelectedElements([]);
    } catch (err) {
      toast.current.clear();
      toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail: "Erreur de supression des éléments sélectionnés!",
        life: 3000,
      });
    }
  };

  return (
    <>
      {(showToolbar === true && (
        <Toolbar
          className="bg-white"
          start={_startToolbarTemplate}
          end={_endToolbarTemplate}
        ></Toolbar>
      )) || <></>}
      <DataTable
        className="bg-white"
        emptyMessage={<MeaDataTableLoadingQuery query={query} />}
        value={query?.data?.data || []}
        lazy
        dataKey="id"
        // pagination
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={
          "{first} de {last} sur {totalRecords} " + names.plural
        }
        rowsPerPageOptions={[10, 30, 50, 100]}
        totalRecords={query?.data?.meta?.total || 0}
        onPage={onPageChange}
        first={
          (parseInt(state[names.pagination + "Page"]) - 1) *
          parseInt(state[names.pagination + "PageSize"])
        }
        rows={parseInt(state[names.pagination + "PageSize"])}
        // selection
        selectionMode={true}
        selection={selectedElements}
        onSelectionChange={(e) => {
          console.log("selected", e);
          setSelectedElements(e.value)
        }}
        tableStyle={{ minWidth: "50rem" }}
        size="small"
        filterDisplay={showFilter ? "row" : ""}
        //globalFilterFields={["weekday"]}
        // edit
        editMode="row"
        onRowEditComplete={handleUpdateElement}
      >
        {children}
      </DataTable>
      {showTotalSelectedElements && selectedElements?.length > 0 && (
        <div className="flex align-items-center mt-3">
          <Checkbox
            inputId="ingredient1"
            name="pizza"
            value="Cheese"
            onChange={() => {
              setSelectedElements([]);
            }}
            checked={selectedElements?.length > 0}
          />
          <label htmlFor="ingredient1" className="ml-2">
            <span>Eléments sélectionnés : </span>
            <b>{selectedElements?.length}</b>
          </label>
        </div>
      )}
      <Toast ref={toast} position="bottom-center" />
      <ConfirmDialog
        group="declarative"
        visible={confirmDialogVisible}
        onHide={() => setConfirmDialogVisible(false)}
        message="Voulez-vous vraiment supprimer les éléments séléctionnés?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        acceptLabel="Oui"
        rejectLabel="Non"
        accept={handleDeleteSelected}
        reject={() => { }}
      />
    </>
  );
});

export default MeaDataTable;
