import { useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import studentsAPI from "../../../services/students";
import { useRef, useState } from "react";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import StudentDetailHeader from "./components/StudentDetailHeader";
import MeaStudentActions from "../../../components/MeaStudentActions";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const StudentDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const studentId = params.studentId;
  //
  const studentQuery = studentsAPI.GetOne(
    schoolId,
    trainingId,
    accessToken,
    studentId
  );
  const studentObj = studentQuery?.data?.data;
  // states
  const [activeItem, setActiveItem] = useState(0);
  const listRef = useRef();
  // params
  const items = [
    { label: "Emplois du temps", icon: "pi pi-clock" },
    { label: "Séances de cours", icon: "pi pi-calendar" },
    { label: "Dossier d'inscription", icon: "pi pi-book" },
    { label: "Famille", icon: "pi pi-users" },
    { label: "Evaluations", icon: "pi pi-align-center" },
    { label: "Remarques", icon: "pi pi-flag-fill" },
    { label: "Historiques", icon: "pi pi-history" },
  ];

  return (
    <>
      <MeaPage

        header={<StudentDetailHeader student={studentObj} />}

      >
        <div className="grid p-0 m-0 ">
          <div className="col-12 md:col-6 lg:col-3">
            <div className="flex align-items-start p-3 border-bottom-1  surface-border">
              <i className="text-2xl pi pi-id-card text-900 mr-3"></i>
              <div className="flex flex-column">
                <span className="text-900 font-medium text-xl mb-1 line-height-1">Demande</span>
              </div>
            </div>
            <div className="flex-grow-1">
              <ul className="list-none p-0 m-0">
                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                  <div className="text-500 w-full md:w-5 font-medium">Origine de la demande</div>
                  <div className="text-900 w-full md:w-5">DEBBABI</div>
                </li><li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                  <div className="text-500 w-full md:w-5 font-medium">Détail de la demande</div>
                  <div className="text-900 w-full md:w-5">Frère d'un elève déjà inscrits dans l'école depuis deux ans déjà.</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 border-top-2 border-blue-500 surface-card shadow-2 border-round">

            <div className="flex align-items-start p-3 border-bottom-1  surface-border">
              <i className="text-2xl pi pi-th-large text-primary-500 mr-3"></i>
              <div className="flex flex-column">
                <span className="text-primary-500 font-medium text-xl mb-1 line-height-1">Pré-inscription</span>
              </div>
            </div>
            <div className="flex-grow-1">
              <ul className="list-none p-0 m-0">
                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                  <div className="text-500 w-full md:w-5 font-medium">Pré-inscription confirmé ?</div>
                  <div className="text-900 w-full md:w-5">-</div>
                </li>
                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                  <div className="text-500 w-full md:w-5 font-medium">Signature du père</div>
                  <div className="text-900 w-full md:w-5">-</div>
                </li>
                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                  <div className="text-500 w-full md:w-5 font-medium">Signature de la mère</div>
                  <div className="text-900 w-full md:w-5">-</div>
                </li>
                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                  <div className="text-500 w-full md:w-5 font-medium">Demande de radiation ?</div>
                  <div className="text-900 w-full md:w-5">-</div>
                </li>
              </ul>
            </div>
            <div className="flex-grow-1">
              <MeaStudentActions
                student={studentObj}
                callback={() => {
                  console.log("changed..");
                  //callback?.();
                }}
              />
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 opacity-40">
            <div className="flex align-items-start p-3 border-bottom-1 surface-border">
              <i className="text-2xl pi pi-credit-card text-900 mr-3"></i>
              <div className="flex flex-column">
                <span className="text-900 font-medium text-xl mb-1 line-height-1">Payment</span>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 opacity-40">
            <div className="flex align-items-start p-3 border-bottom-1 surface-border">
              <i className="text-2xl pi pi-check-circle text-900 mr-3"></i>
              <div className="flex flex-column">
                <span className="text-900 font-medium text-xl mb-1 line-height-1">Inscription confirmée</span>
              </div>
            </div>
          </div>
        </div>

        <Panel className="mt-5">
          <TabView>
            <TabPanel header="Dossier d'inscription" leftIcon="pi pi-book mr-2">
              <div className="m-0">

              </div>
            </TabPanel>

          </TabView>
          {/* 
         <TabMenu
            model={items}
            activeIndex={activeItem}
            onTabChange={(e) => {
              setActiveItem(e.index);
            }}
          />
          {activeItem === 0 && (
            <MeaLoadingContent objectQuery={studentQuery}>
              <ScheduleListComponent
                ref={listRef}
                filter={{ classe_id: studentObj?.class_id }}
                showAddButton={false}
                showFilterButton={true}
                selectedFields={{
                  classe: false,
                }}
              />
            </MeaLoadingContent>
          )}
          {activeItem === 1 && (
            <MeaLoadingContent objectQuery={studentQuery}>
              <SessionsListComponent
                ref={listRef}
                filter={{ classe_id: studentObj?.class_id }}
                showAddButton={false}
                showFilterButton={true}
                selectedFields={{
                  classe: false,
                }}
              />
            </MeaLoadingContent>
          )}
         */}
        </Panel>
      </MeaPage>
    </>
  );
};
export default StudentDetailPage;
