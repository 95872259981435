const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UpdateUserProfile = async (accessToken, firstname, lastname) => {
  try {
    const response = await fetch(BACKEND_URL + "/api/v1/account/profile", {
      method: "PATCH",
      body: JSON.stringify({
        firstname: firstname,
        lastname: lastname,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur de modification du profile utilisateur!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateUserPassword = async (
  accessToken,
  oldPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const response = await fetch(BACKEND_URL + "/api/v1/account/password", {
      method: "POST",
      body: JSON.stringify({
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur de modification du mot de passe!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateUserAvatar = async (accessToken, newAvatar) => {
  let formData = new FormData();
  formData.append("file", newAvatar);
  try {
    const response = await fetch(BACKEND_URL + "/api/v1/account/avatar", {
      method: "POST",
      body: formData,
      credentials: "include",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur de modification de la photo de profil!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const userAPI = {
  UpdateUserProfile,
  UpdateUserPassword,
  UpdateUserAvatar,
};

export default userAPI;
