import MeaPage from "../../../components/MeaPage";
import { useState } from "react";
import MonthlyCalendar from "./components/MonthlyCalendar";
import CustomAnnualCalendar from "./components/CustomAnnualCalendar";

const CourseDetailPage = () => {

  // states
  const [isYearView, setIsYearView] = useState(true);
  // params
  //
  return (
    <>
      <MeaPage
        title="Calendrier annuel"
        subtitle="Aperçu du calendrier annuel de la formation"
        actions={[
          {
            label: "Vue Annuel",
            icon: "pi pi-file-edit",
            command: () => {
              setIsYearView(true)
            },
          },
          {
            label: "Vue Mensuel",
            icon: "pi pi-file-edit",
            command: () => {
              setIsYearView(false)
            },
          }
        ]}
      >
        {!isYearView && <MonthlyCalendar />}
        { isYearView && <CustomAnnualCalendar /> }
      </MeaPage>
    </>
  );
};
export default CourseDetailPage;
