import { Avatar } from "primereact/avatar";
import MeaLink from "./MeaLink";
import useAuthContext from "../hooks/useAuthContext";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const MeaStudentRegistrationAvatar = ({ student }) => {
  return (
    <MeaLink
      to={"/c/t/" + student?.school_id + "/" + student?.training_id + "/registrations/" + student?.id}
    >
      <Avatar
        className="mr-2 p-overlay-badge"
        icon="pi pi-user"
        shape="circle"
        image={
          student?.member?.avatar
            ? BACKEND_URL + "/api/v1/medias/" + student?.member?.avatar
            : ""
        }
        label={
          student?.member?.lastname
            ? Array.from(student?.member?.lastname)[0] +
            (student?.member?.firstname
              ? Array.from(student?.member?.firstname)[0]
              : "")
            : student?.member?.name
              ? Array.from(student?.member?.name)[0]
              : ""
        }
        size="normal"
      ></Avatar>
      <span>
        {
          (student?.lastname || student?.member?.lastname || "") +
          " " +
          (student?.firstname || student?.member?.firstname || "")}
      </span>
    </MeaLink>
  );
};
export default MeaStudentRegistrationAvatar;
