import React, { useState, forwardRef } from "react";

import { InputText } from "primereact/inputtext";
import MeaCreateModal from "../../../../components/MeaCreateModal";
import systemSchoolsAPI from "../../../../services/system_schools";
import { InputTextarea } from "primereact/inputtextarea";

const CreateSystemSchoolModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerLastname, setOwnerLastname] = useState("");
  const [ownerFirstname, setOwnerFirstname] = useState("");
  //
  return (
    <>
      <MeaCreateModal
        ref={ref}
        title="Créer une école"
        serviceAPI={systemSchoolsAPI}
        data={{
          name: name,
          owner_email: ownerEmail,
          owner_lastname: ownerLastname,
          owner_firstname: ownerFirstname,
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom de l'école
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          E-mail du responsable
        </label>
        <InputText
          id="name"
          type="text"
          value={ownerEmail}
          onChange={(e) => setOwnerEmail(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex-1 flex-column gap-2">
            <label htmlFor="name" className="block text-900 font-medium mb-2">
              Nom du responsable
            </label>
            <InputText
              id="name"
              type="text"
              value={ownerLastname}
              onChange={(e) => setOwnerLastname(e.target.value)}
              className="w-full mb-3"
              autoFocus
            />
          </div>
          <div className="p-inputgroup flex-1 flex-column gap-2">
            <label htmlFor="name" className="block text-900 font-medium mb-2">
              Prénom du responsable
            </label>
            <InputText
              id="name"
              type="text"
              value={ownerFirstname}
              onChange={(e) => setOwnerFirstname(e.target.value)}
              className="w-full mb-3"
              autoFocus
            />
          </div>
        </div>
      </MeaCreateModal>
    </>
  );
});

export default CreateSystemSchoolModal;
