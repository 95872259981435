import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import classesAPI from "../../../../services/classes";
import { InputNumber } from "primereact/inputnumber";

const UpdateClasseModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");
  const [levelName, setLevelName] = useState("");
  const [maxCapacity, setMaxCapacity] = useState("");

  //
  useEffect(() => {
    setName(props?.classe?.name);
    setPresentation(props?.classe?.presentation);
    setLevelName(props?.classe?.level?.name)
    setMaxCapacity(props?.classe?.max_capacity)
  }, [props]);
//
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer la classe"
        serviceAPI={classesAPI}
        id={props?.classe?.id}
        data={{
          name: name,
          presentation: presentation,
          max_capacity: maxCapacity
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom de la classe
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Niveau de la classe
        </label>
        <InputText
          id="name"
          type="text"
          value={levelName}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          disabled={true}
        />

        <label htmlFor="maxCapacity" className="block text-900 font-medium mb-2">
          Capacité maximale
        </label>
        <InputNumber
          id="maxCapacity"
          value={maxCapacity}
          onValueChange={(e) => setMaxCapacity(e.value)}
        />
      </MeaUpdateModal>
    </>
  );
});

export default UpdateClasseModal;
