import React, { useState, forwardRef } from "react";

import { InputText } from "primereact/inputtext";
import MeaCreateModal from "../../../../components/MeaCreateModal";
import { InputTextarea } from "primereact/inputtextarea";
import trainingsAPI from "../../../../services/trainings";

const CreateTrainingModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");
  const [trainingStartDate, setTrainingStartDate] = useState("");
  const [trainingEndDate, setTrainingEndDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");
  //
  return (
    <>
      <MeaCreateModal
        ref={ref}
        title="Créer une nouvelle formation"
        serviceAPI={trainingsAPI}
        data={{
          name: name,
          presentation: presentation,
          start_courses_date:
            trainingStartDate !== ""
              ? new Date(trainingStartDate).toISOString()
              : null,
          end_courses_date:
            trainingEndDate !== ""
              ? new Date(trainingEndDate).toISOString()
              : null,
          start_registration_date:
            registrationStartDate !== ""
              ? new Date(registrationStartDate).toISOString()
              : null,
          end_registration_date:
            registrationEndDate !== ""
              ? new Date(registrationEndDate).toISOString()
              : null,
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom du évènement
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="startdate"
              className="block text-900 font-medium mb-2"
            >
              Date de début d'inscriptions
            </label>
            <InputText
              id="startdate"
              type="date"
              value={registrationStartDate}
              onChange={(e) => setRegistrationStartDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="enddate"
              className="block text-900 font-medium mb-2"
            >
              Date de fin d'inscriptions
            </label>
            <InputText
              id="enddate"
              type="date"
              value={registrationEndDate}
              onChange={(e) => setRegistrationEndDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
        </div>
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="startdate"
              className="block text-900 font-medium mb-2"
            >
              Date de début des cours
            </label>
            <InputText
              id="startdate"
              type="date"
              value={trainingStartDate}
              onChange={(e) => setTrainingStartDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="enddate"
              className="block text-900 font-medium mb-2"
            >
              Date de fin des cours
            </label>
            <InputText
              id="enddate"
              type="date"
              value={trainingEndDate}
              onChange={(e) => setTrainingEndDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
        </div>

        {/* <p>
          La formation aura un status{" "}
          <Tag severity="warning" value="EN PREPARATION"></Tag> par défault.
          Vous devriez compléter son contenue avant de l'activer et donc le
          rendre disponible aux différents utilisateurs concernés.
        </p> */}
      </MeaCreateModal>
    </>
  );
});

export default CreateTrainingModal;
