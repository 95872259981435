import useAuthContext from "../hooks/useAuthContext";

import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import studentsAPI from "../services/students";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";

// deprecated
const MeaStudentStatus = ({ student }) => {
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const toast = useRef(null);

  const accept = () => {
    studentsAPI
      .UpdateOne(schoolId, trainingId, accessToken, student?.id, {
        status: newStatus,
      })
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Succès",
          life: 3000,
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur de modificatio du dossier d'élève!",
        });
      });
  };

  const reject = () => {};

  const doCommand = (message, newStatus) => {
    setMessage(message);
    setNewStatus(newStatus);
    setVisible(true);
  };
  return (
    <>
      <Toast ref={toast} position="bottom-center" />
      <ConfirmDialog
        group="declarative"
        visible={visible}
        onHide={() => setVisible(false)}
        message={message}
        acceptLabel="Oui"
        rejectLabel="Non"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
      {student?.status === "PREREGISTRED" && (
        <>
          <SplitButton
            text
            label="PRÉ-INSCRIT"
            size="small"
            severity="info"
            model={[
              {
                label: "Valider l'inscription",
                command: () => {
                  doCommand(
                    "Confirmez-vous vraiment valider l'inscription de cet élève?",
                    "ENROLLED"
                  );
                },
              },
              {
                label: "Exclure",
                command: () => {
                  doCommand(
                    "Voulez-vous vraiment exclure cet élève?",
                    "EXPELLED"
                  );
                },
              },
              {
                label: "Abondon",
                command: () => {
                  doCommand(
                    "Confirmez-vous que cet élève a abondonné la formation?",
                    "DROPPEDOUT"
                  );
                },
              },
            ]}
          />
        </>
      )}
      {student?.status === "ENROLLED" && (
        <>
          <SplitButton
            text
            label="INSCRIT"
            size="small"
            model={[
              {
                label: "Exclure",
                command: () => {
                  doCommand(
                    "Voulez-vous vraiment exclure cet élève?",
                    "EXPELLED"
                  );
                },
              },
              {
                label: "Abondon",
                command: () => {
                  doCommand(
                    "Confirmez-vous que cet élève a abondonné la formation?",
                    "DROPPEDOUT"
                  );
                },
              },
            ]}
          />
        </>
      )}
      {student?.status === "RETAINED" && (
        <>
          <SplitButton
            text
            label="REDOUBLE"
            size="small"
            severity="warning"
            model={[
              {
                label: "Inscrit",
                command: () => {
                  doCommand(
                    "Voulez-vous vraiment supprimer le status REDOUBLE de cet élève?",
                    "ENROLLED"
                  );
                },
              },
            ]}
          />
        </>
      )}
      {student?.status === "PASSED" && (
        <>
          <SplitButton
            text
            label="REUSSI"
            size="small"
            severity="success"
            model={[
              {
                label: "Inscrit",
                command: () => {
                  doCommand(
                    "Voulez-vous vraiment supprimer le status REUSSI de cet élève?",
                    "ENROLLED"
                  );
                },
              },
            ]}
          />
        </>
      )}
      {student?.status === "DROPPEDOUT" && (
        <>
          <SplitButton
            text
            label="ABONDON"
            size="small"
            severity="danger"
            model={[
              {
                label: "Ré-inscrire",
                command: () => {
                  doCommand(
                    "Voulez-vous vraiment ré-inscrire cet élève dans la formation en cours?",
                    "ENROLLED"
                  );
                },
              },
            ]}
          />
        </>
      )}
      {student?.status === "EXPELLED" && (
        <>
          <SplitButton
            text
            label="EXCLU"
            size="small"
            className="bg-black"
            severity="contrast"
            model={[
              {
                label: "Ré-inscrire",
                command: () => {
                  doCommand(
                    "Voulez-vous vraiment ré-inscrire cet élève?",
                    "ENROLLED"
                  );
                },
              },
            ]}
          />
        </>
      )}
    </>
  );
};
export default MeaStudentStatus;
