import { CalculateAge } from "../utils/dates";
import { useCallback } from "react";

const MeaStudentAge = ({ student, showBirthdate = true }) => {
  const getBirthday = useCallback(() => {
    let birthday = student?.birthday;
    if (birthday !== undefined) {
      return (
        CalculateAge(birthday) +
        " ans " +
        (showBirthdate
          ? " (" + new Date(birthday).toLocaleDateString("fr-FR") + ")"
          : "")
      );
    } else {
      return "-";
    }
  }, []);

  return getBirthday();
};
export default MeaStudentAge;
