import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import CreateCourseModal from "./AddMemberModal";
import membersAPI from "../../../../services/members";
import MeaMemberAvatar from "../../../../components/MeaMemberAvatar";
import { Tag } from "primereact/tag";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";

const MembersListComponent = forwardRef((props, ref) => {
  const { showAddButton } = props;
  const { auth, currentMembership } = useAuthContext();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const schoolId = currentMembership?.school_id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  //
  const [selectedTab, setSelectedTab] = useState(0);
  const [state, setState] = useUrlState({
    memberType: "all",
    memberName: "",
  });
  const setMemberType = useCallback(
    (mType) => {
      setState((s) => ({
        membersPage: 1,
        memberType: mType,
      }));
    },
    [setState]
  );

  const setSearchName = (name) => {
    setState((s) => ({
      membersPage: 1,
      memberName: name,
    }));
  };
  //
  useEffect(() => {
    switch (state.memberType) {
      case "all":
        setSelectedTab(0);
        break;
      case "admin,owner":
        setSelectedTab(1);
        break;
      case "staff":
        setSelectedTab(2);
        break;
      case "teacher":
        setSelectedTab(3);
        break;
      case "student":
        setSelectedTab(4);
        break;
      case "parent":
        setSelectedTab(5);
        break;
      case "owner":
        setSelectedTab(6);
        break;
      default:
        setSelectedTab(0);
        break;
    }
  }, [state.memberType]);
  //
  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      var selectedType = "";
      switch (selectedTabIndex) {
        case 0:
          selectedType = "all";
          break;
        case 1:
          selectedType = "admin,owner";
          break;
        case 2:
          selectedType = "staff";
          break;
        case 3:
          selectedType = "teacher";
          break;
        case 4:
          selectedType = "student";
          break;
        case 5:
          selectedType = "parent";
          break;
        case 6:
          selectedType = "owner";
          break;
        default:
          selectedType = "all";
          break;
      }
      setMemberType(selectedType);
    },
    [setMemberType]
  );
  //
  const queryList = membersAPI.useGetList(
    schoolId,
    accessToken,
    page,
    pageSize,
    state.memberType,
    state.memberName
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Ajouter un membre"
          icon="pi pi-plus"
          size="normal"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouveau membre"
        />
      )}
      <span className="p-input-icon-left mr-2 ">
        <i className="pi pi-search" />
        <InputText
          className="p-inputtext-sm"
          value={state.memberName}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder="Chercher un membre"
        />
      </span>
    </div>
  );
  //
  return (
    <>
      <CreateCourseModal ref={createModalRef} />
      <TabView
        activeIndex={selectedTab}
        onTabChange={(e) => handleTabChange(e.index)}
      >
        <TabPanel header="Tous"></TabPanel>
        <TabPanel header="Administration"></TabPanel>
        <TabPanel header="Personnels de l'école"></TabPanel>
        <TabPanel header="Enseignants"></TabPanel>
        <TabPanel header="Elèves"></TabPanel>
        <TabPanel header="Parents d'élèves"></TabPanel>
      </TabView>
      <MeaDataTable
        query={queryList}
        serviceAPI={membersAPI}
        names={{
          pagination: "members",
          singular: "membre",
          plural: "membres",
        }}
        startToolbarTemplate={startToolbarTemplate}
        //showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column
          header="Membre"
          body={(member) => <MeaMemberAvatar member={member} />}
        ></Column>
        <Column
          header="E-mail"
          field="email"
        ></Column>
        <Column
          header="Roles"
          body={(member) => {
            return (
              <div className=" flex flex-wrap justify-content-start gap-1">
                {member.is_owner && (
                  <Tag value="Propriétaire" severity="info" rounded></Tag>
                )}
                {member.is_admin && (
                  <Tag value="Administration" severity="info" rounded></Tag>
                )}
                {member.is_staff && (
                  <Tag value="Secrétariat" severity="info" rounded></Tag>
                )}
                {member.is_teacher && (
                  <Tag value="Enseignant(e)" severity="info" rounded></Tag>
                )}
                {member.is_parent && (
                  <Tag value="Parent" severity="info" rounded></Tag>
                )}
                {member.is_student && (
                  <Tag value="Elève" severity="info" rounded></Tag>
                )}
              </div>
            );
          }}
        ></Column>
        <Column
          header="Dernière activité"
          body={(member) => {
            return (
              (member.last_activity_at && (
                <span>
                  {new Date(member.last_activity_at).toLocaleString("fr-FR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              )) || <span>Jamais connecté!</span>
            );
          }}
        ></Column>
        <Column
          rowEditor={true}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </MeaDataTable>
    </>
  );
});

export default MembersListComponent;
