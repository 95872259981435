import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import ScheduleListComponent from "./components/ScheduleListComponent";

const SessionsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Programme de la semaine"
        subtitle="Gérer le programme d'une semaine, pour toute l'année"
      >
        <ScheduleListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default SessionsListPage;
