import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";
import { CalculateAge } from "../../../../utils/dates";

const MemberDetailComponent = ({ member }) => {
  return (
    <MeaDetailCard>
      <MeaDetailField field="Nom" value={member?.lastname || "-"} />
      <MeaDetailField field="Prénom" value={member?.firstname || "-"} />
      <MeaDetailField field="Nom d'usage" value={member?.name || "-"} />
      <MeaDetailField
        field="Genre"
        value={
          member?.gender !== undefined && member?.gender === "G"
            ? "Féminin"
            : member?.gender === "B"
              ? "Masculin"
              : "-"
        }
      />
      <MeaDetailField
        field="Date de naissance"
        value={
          member?.birthday !== undefined && member?.birthday !== null && member?.birthday !== ""
            ? new Date(member?.birthday).toLocaleDateString("fr-FR") +
            " (" +
            CalculateAge(member?.birthday) +
            " ans)"
            : "-"
        }
      />
    </MeaDetailCard>
  );
};

export default MemberDetailComponent;
