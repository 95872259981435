import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

//import useToastContext from "../../../hooks/useToastContext";
import useAuthContext from "../../../../hooks/useAuthContext";
import slotsAPI from "../../../../services/slots";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

const CreateSlotModal = forwardRef((props, ref) => {
  //const { callback } = props;
  //const toast = useToastContext();
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [slotName, setSlotName] = useState("");
  const [slotWeekday, setSlotWeekday] = useState("1");
  const [slotStartHour, setSlotStartHour] = useState("");
  const [slotEndHour, setSlotEndHour] = useState("");
  //
  const [active, setActive] = useState(false);
  const [callbackFn, setCallbackFn] = useState(() => {});
  //
  const [loading, setLoading] = useState(false);
  //
  const toast = useRef(null);
  //
  const handleCreateSubmit = async (e) => {
    // Handle create slot submit logic here
    setLoading(true);
    slotsAPI
      .CreateOne(schoolId, trainingId, accessToken, {
        name: slotName,
        weekday: parseInt(slotWeekday),
        start_hour: slotStartHour,
        end_hour: slotEndHour,
      })
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Créneau " + data?.data?.name + " crée!",
        });
        setActive(false);
        callbackFn();
        //
        setSlotName("");
        setSlotWeekday("1");
        setSlotStartHour("");
        setSlotEndHour("");
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur de création du créneau!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDialog = (callback) => {
    setCallbackFn(() => callback);
    setActive(true);
  };

  //
  useImperativeHandle(ref, () => ({
    show: (callback) => {
      setCallbackFn(() => callback);
      setActive(true);
    },
  }));
  //

  const footerContent = (
    <div>
      <Button
        label="Annuler"
        icon="pi pi-times"
        disabled={loading}
        onClick={() => setActive(false)}
        className="p-button-text"
      />
      <Button
        type="submit"
        loading={loading}
        label="Créer"
        icon="pi pi-check"
        onClick={() => handleCreateSubmit()}
      />
    </div>
  );

  return (
    <>
      <Toast ref={toast} />

      <Dialog
        header="Créer un nouveau créneau"
        visible={active}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setActive(false)}
        footer={footerContent}
      >
        <div className="w-full">
          <div className="flex flex-column gap-2">
            <label htmlFor="name" className="block text-900 font-medium mb-2">
              Nom du créneau
            </label>
            <InputText
              id="name"
              type="text"
              value={slotName}
              onChange={(e) => setSlotName(e.target.value)}
              className="w-full mb-3"
              autoFocus
            />
            <label htmlFor="day" className="block text-900 font-medium mb-2">
              Jour de la semaine
            </label>
            <Dropdown
              value={slotWeekday}
              onChange={(e) => setSlotWeekday(e.value)}
              options={[
                { label: "Lundi", value: "1" },
                { label: "Mardi", value: "2" },
                { label: "Mercredi", value: "3" },
                { label: "Jeudi", value: "4" },
                { label: "Vendredi", value: "5" },
                { label: "Samedi", value: "6" },
                { label: "Dimanche", value: "0" },
              ]}
              optionLabel="label"
              className="w-full mb-3"
            />
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="p-inputgroup flex flex-column gap-2">
                <label
                  htmlFor="starthour"
                  className="block text-900 font-medium mb-2"
                >
                  Heure de début
                </label>
                <InputText
                  id="starthour"
                  type="time"
                  value={slotStartHour}
                  onChange={(e) => setSlotStartHour(e.target.value)}
                  className="w-full mb-3"
                />
              </div>
              <div className="p-inputgroup flex flex-column gap-2">
                <label
                  htmlFor="endhour"
                  className="block text-900 font-medium mb-2"
                >
                  Heure de fin
                </label>
                <InputText
                  id="endhour"
                  type="time"
                  value={slotEndHour}
                  onChange={(e) => setSlotEndHour(e.target.value)}
                  className="w-full mb-3"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
});

export default CreateSlotModal;
