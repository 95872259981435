import { useState, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Toast } from "primereact/toast";
import MeaLink from "../../components/MeaLink";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const RESET_PASS_URL = BACKEND_URL + "/api/v1/auth/doresetpass";

const DoPasswordReset = () => {
    const navigate = useNavigate();
    //
    const toast = useRef(null);
    const captchaRef = useRef(null);
    // params
    const params = useParams();
    const token = params.token;
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    //
    var canReset =
        password === "" || confirmPassword === "" || password !== confirmPassword;
    //
    const handleSubmit = async (e) => {
        e.preventDefault();
        const reCaptchatoken = captchaRef.current.getValue();
        setLoading(true);
        fetch(RESET_PASS_URL, {
            method: "POST",
            body: JSON.stringify({
                email: email,
                token: token,
                password: password,
                "g-recaptcha-response": reCaptchatoken,
            }),
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status >= 299) {
                    throw new Error("");
                }
                return response.json();
            })
            .then((response) => {
                setErrMsg("");
                toast.current.show({
                    severity: "success",
                    summary: "Félécitations!",
                    detail:
                        "Votre mot de passe a été modifié! Vous pouvez maintenant vous connecter.",
                    sticky: true,
                    content: (props) => (
                        <div
                            className="flex flex-column align-items-left"
                            style={{ flex: "1" }}
                        >
                            <div className="flex align-items-center gap-2">
                                <span className="font-bold text-900">
                                    {props.message.summary}
                                </span>
                            </div>
                            <div className="font-medium text-lg my-3 text-900">
                                {props.message.detail}
                            </div>
                            <Button
                                className="p-button-sm flex"
                                label="S'identifier"
                                severity="success"
                                onClick={clear}
                            ></Button>
                        </div>
                    ),
                });
            })
            .catch(function (error) {
                setErrMsg(
                    "Problème de réinitialisation de mot de passe! " + error.message
                );
            })
            .finally(() => {
                //
                setLoading(false);
                setPassword("");
                setConfirmPassword("");
            });
    };

    const clear = () => {
        toast.current.clear();
        navigate("/d/login");
    };

    return (
        <>
            <Toast ref={toast} position="center" onRemove={clear} />
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }} className="flex justify-content-center flex-wrap">
                <div style={{ maxWidth: "400px" }} className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                    <div className="text-center mb-5">
                        <div className="flex align-items-start">
                            <Button
                                icon="pi pi-arrow-left"
                                text
                                style={{ minHeight: "50px", maxWidth: "50px" }}
                                onClick={() => navigate("/")}
                            ></Button>
                            <div>
                                <MeaLink to="/d/welcome"><img src="/mea-logo.png" alt="hyper" height={50} className="mb-3 pl-3" /></MeaLink>
                            </div>
                        </div>
                        <div className="text-900 text-3xl font-medium mb-3">Modifier votre mot de passe</div>
                    </div>

                    <div>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="email" className="block text-900 font-medium mb-2">Votre adresse email</label>
                            <InputText id="email" type="text" placeholder="" className="w-full mb-3" value={email}
                                disabled />
                            <label htmlFor="password" className="block text-900 font-medium mb-2">Nouveau mot de passe</label>
                            <InputText id="password" type="password" placeholder="" className="w-full mb-3" value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoFocus />
                            <label htmlFor="confirmPassword" className="block text-900 font-medium mb-2">Confirmer le nouveau mot de passe</label>
                            <InputText id="confirmPassword" type="password" placeholder="" className="w-full mb-3" value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <div className="flex align-items-center justify-content-between mb-4">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    ref={captchaRef}
                                ></ReCAPTCHA>
                            </div>
                            {errMsg && (
                                <Message
                                    severity="error"
                                    text={errMsg}
                                    className="block w-full"
                                />
                            )}
                            <Button disabled={canReset} label="Réinitialiser le mot de passe" icon="pi pi-user" className="w-full" loading={loading} />
                        </form>
                    </div>
                </div>
            </div>

        </>
    );

};
export default DoPasswordReset;