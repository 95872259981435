import { SplitButton } from "primereact/splitbutton";
import studentsAPI from "../../../../services/students";
import useAuthContext from "../../../../hooks/useAuthContext";
import { Button } from "primereact/button";
import MeaLink from "../../../../components/MeaLink";
import { useRef, } from "react";
import UpdateRegistrationDetailsModal from "../modals/UpdateRegistrationDetailsModal";
import MeaStudentStatus2 from "../../../../components/MeaStudentStatus2";
import MeaStudentResult from "../../../../components/MeaStudentResult";
import { Toast } from "primereact/toast";
import MeaStudentRegistrationOrigin from "../../../../components/MeaStudentRegistrationOrigin";

const RegistrationStepNewComponent = ({ currentStudent, setCurrentStudent, callback }) => {

    const { auth, currentMembership, currentTraining } = useAuthContext();
    const schoolId = currentMembership?.school_id;
    const trainingId = currentTraining?.id;
    const memberId = currentMembership?.id;
    const accessToken = auth?.access_token;

    const toast = useRef(null);

    const updateRegistrationDetailsModalRef = useRef(null);

    const updateStatus = async (newStatus, _callback) => {
        studentsAPI
            .UpdateStatus(schoolId, trainingId, accessToken, currentStudent?.id, newStatus)
            .then((data) => {
                _callback?.();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const updatePreviousTrainingInfos = async () => {
        studentsAPI.UpdatePreviousTrainingInfos(schoolId, trainingId, accessToken, currentStudent?.id)
            .then((data) => {
                console.log("done.");
                toast.current.clear();
                toast.current.show({
                    severity: "success",
                    summary: "Succès",
                    detail: "Informations de la dernière inscription mis à jour!",
                    life: 3000,
                });
                callback?.();
            }
            )
            .catch((error) => {
                console.log("error", error);
            }
            );
    };

    return (
        <>
            <Toast ref={toast} position="bottom-center" />
            <div className={currentStudent?.status === "NEW" ? "col-12 md:col-6 lg:col-4 border-top-2 border-primary-500 surface-card shadow-2 border-round" : "col-12 md:col-6 lg:col-4 surface-border border-right-1"}>
                {currentStudent?.status === "NEW" &&
                    <div className="flex justify-content-between flex-wrap align-items-center  p-3 border-bottom-1  surface-border">
                        <div className="w-11">
                            <div className="flex align-items-start">
                                <i className="text-2xl pi pi-id-card text-primary-500 mr-3"></i>
                                <div className="flex flex-column">
                                    <span className="text-primary-500 font-medium text-xl mb-1 line-height-1">Candidature</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-1"><Button icon="pi pi-refresh" className="mr-2" size="small" text onClick={updatePreviousTrainingInfos} /></div>
                    </div>

                    ||

                    <div className="flex justify-content-between flex-wrap align-items-center  p-3 border-bottom-1  surface-border">
                        <div className="w-11">
                            <div className="flex align-items-start">
                                <i className="text-2xl pi pi-id-card text-500 mr-3"></i>
                                <div className="flex flex-column">
                                    <span className="text-00 font-medium text-xl mb-1 line-height-1">Candidature</span>
                                </div>
                            </div>
                        </div>
                        <Button icon="pi pi-refresh" size="small" text onClick={updatePreviousTrainingInfos} />
                    </div>
                }
                <div className="flex-grow-1">
                    <ul className="list-none p-0 m-0">
                        <li className="flex align-items-center py-2 px-2 flex-wrap ">
                            <div className="text-500 w-full md:w-5 font-medium">Dernier résultat</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-full">{<MeaStudentRegistrationOrigin student={currentStudent} />}</div>
                                </div>
                            </div>
                        </li>
                        <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-5 font-medium">Immatriculation</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-full">{currentStudent?.member_id || "-"}</div>
                                </div>
                            </div>
                        </li>
                        <li className="flex align-items-center py-2 px-2 flex-wrap ">
                            <div className="text-500 w-full md:w-5 font-medium">Dernière inscription</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-full">{currentStudent?.previous_training && <MeaLink to={"/c/t/" + currentStudent?.previous_training?.school_id + "/" + currentStudent?.previous_training?.id}>{currentStudent?.previous_training?.name}</MeaLink> || <span>-</span>}</div>
                                </div>
                            </div>
                        </li>
                        <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-5 font-medium">Dernier niveau</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-full">{currentStudent?.previous_training_level_name || "-"}</div>
                                </div>
                            </div>
                        </li>
                        <li className="flex align-items-center py-2 px-2 flex-wrap ">
                            <div className="text-500 w-full md:w-5 font-medium">Dernière classe</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-full">{currentStudent?.previous_training_class_name || "-"}</div>
                                </div>
                            </div>
                        </li>
                        <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-5 font-medium">Dernier status</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-full"><MeaStudentStatus2 student={currentStudent} previousStatus={true} old={true} /></div>

                                </div>
                            </div>
                        </li>
                        <li className="flex align-items-center py-2 px-2 flex-wrap ">
                            <div className="text-500 w-full md:w-5 font-medium">Dernier résultat</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-full"><MeaStudentResult student={currentStudent} previousResult={true} /></div>

                                </div>
                            </div>
                        </li>

                        <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-5 font-medium">Confirmation de poursuite</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-full">{currentStudent?.previous_training_continuation_intent || "-"}</div>
                                </div>
                            </div>
                        </li>
                        <li className="flex align-items-center py-2 px-2 flex-wrap ">
                            <div className="text-500 w-full md:w-5 font-medium">Remarques</div>
                            <div className="text-900 w-full pl-3 md:w-7">
                                <div className="flex justify-content-between flex-wrap align-items-center">
                                    <div className="w-10">{currentStudent?.registration_details || "-"}</div>
                                    <div className="w-2"><Button icon="pi pi-pencil" className="mr-2" size="small" text onClick={() => {
                                        if (updateRegistrationDetailsModalRef.current) {
                                            updateRegistrationDetailsModalRef.current.show(() => {
                                                callback?.();
                                            });
                                        }
                                    }} /></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <UpdateRegistrationDetailsModal ref={updateRegistrationDetailsModalRef} student={currentStudent} />
                </div>
                {currentStudent?.status === "NEW" && <div className="flex-grow-1">
                    <SplitButton
                        label="Pré-inscrire dans cette formation"
                        onClick={() => {
                            updateStatus("PREREGISTRED", () => { callback?.() });
                        }}
                        className="w-full mt-3"

                        model={[
                            {
                                label: "Mettre en liste d'attente",
                                icon: "pi pi-hourglass",
                                command: () => {
                                    updateStatus("WAITING_LIST", () => { callback?.() });
                                },
                            },
                            {
                                label: "Supprimer la demande",
                                icon: "pi pi-trash",
                                disabled: true,
                            },
                        ]}
                    />
                </div>}
            </div>
        </>
    );
}

export default RegistrationStepNewComponent;