import { Button } from "primereact/button";
import useAuthContext from "../../../../hooks/useAuthContext";
import studentsAPI from "../../../../services/students";
import { SplitButton } from "primereact/splitbutton";

const RegistrationStepWaitingListComponent = ({ currentStudent, setCurrentStudent, callback }) => {

    const { auth, currentMembership, currentTraining } = useAuthContext();
    const schoolId = currentMembership?.school_id;
    const trainingId = currentTraining?.id;
    const memberId = currentMembership?.id;
    const accessToken = auth?.access_token;

    const updateStatus = async (newStatus, _callback) => {
        studentsAPI
            .UpdateStatus(schoolId, trainingId, accessToken, currentStudent?.id, newStatus)
            .then((data) => {
                _callback?.();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };



    const confirmRegistration = (memberId, _callback) => {
        studentsAPI
            .UpdateStatus(schoolId, trainingId, accessToken, currentStudent?.id, "ENROLLED", memberId)
            .then((data) => {
                _callback?.();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    return (
        <div className={(currentStudent?.status === "WAITING_LIST") ? "flex flex-column  justify-content-between flex-wrap col-12 md:col-6 lg:col-4 border-top-2 border-yellow-500 surface-card shadow-2 border-round" : "col-12 md:col-6 lg:col-4"}>
            <>
                {currentStudent?.status === "NEW" && <>
                    <div className="flex align-items-start p-3 border-bottom-1 surface-border opacity-40">
                        <i className="text-2xl pi pi-check-circle text-900 mr-3"></i>
                        <div className="flex flex-column">
                            <span className="text-900 font-medium text-xl mb-1 line-height-1">En liste d'attente</span>
                        </div>
                    </div>
                </> ||
                    currentStudent?.status === "WAITING_LIST" &&
                    <div className="flex align-items-start p-3 border-bottom-1  surface-border">
                        <i className="text-2xl pi pi-check-circle text-yellow-500 mr-3"></i>
                        <div className="flex flex-column">
                            <span className="text-yellow-500 font-medium text-xl mb-1 line-height-1">En liste d'attente</span>
                        </div>
                    </div> ||
                    <div className="flex align-items-start p-3 border-bottom-1  surface-border">
                        <i className="text-2xl pi pi-check-circle text-900 mr-3"></i>
                        <div className="flex flex-column">
                            <span className="text-900 font-medium text-xl mb-1 line-height-1">En liste d'attente</span>
                        </div>
                    </div>}
                <div className="flex-grow-1">
                    <ul className="list-none p-0 m-0">
                        <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground ">
                            <div className="text-500 w-full md:w-5 font-medium">Depuis</div>
                            <div className="text-900 w-full pl-3 md:w-5">
                                <div className="w-full">{currentStudent?.on_waiting_list_since_date ? "-" : "-"}</div>
                            </div>
                        </li>
                        <li className="flex align-items-center py-2 px-2 flex-wrap ">
                            <div className="text-500 w-full md:w-5 font-medium">Plus d'informations</div>
                            <div className="text-900 w-full pl-3 md:w-5">
                                <div className="w-full">{currentStudent?.on_waiting_list_details ? "-" : "-"}</div>
                            </div>
                        </li>


                    </ul>
                </div>
                <div className="flex-none">
                    <SplitButton
                        label="Pré-inscrire dans cette formation"
                        onClick={() => {
                            updateStatus("PREREGISTRED", () => { callback?.() });
                        }}
                        className="w-full mt-3"
                        outlined
                        severity="warning"
                        model={[

                            {
                                label: "Supprimer la demande",
                                icon: "pi pi-trash",
                                disabled: true,
                            },
                        ]}
                    />
                </div>
            </>
        </div>
    );
}

export default RegistrationStepWaitingListComponent;