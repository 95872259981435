import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import TeachersListComponent from "./components/TeachersListComponent";

const TeachersListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des enseignants"
        subtitle="Gérer l'ensemble des enseignants de cette formation"
      >
        <TeachersListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default TeachersListPage;
