import useAuthContext from "../../../hooks/useAuthContext";
import MeaPage from "../../../components/MeaPage";
import TrainingHeader from "./components/TrainingHeader";
import TrainingDetailComponent from "./components/TrainingDetailComponent";
import { Button } from "primereact/button";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import UpdateTrainingModal from "./components/UpdateTrainingModal";
import trainingsAPI from "../../../services/trainings";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const TrainingInfo = () => {
  const { auth, currentTraining, reloadCurrentTraining } = useAuthContext();
  const accessToken = auth?.access_token;
  //
  const updateModalRef = useRef();
  const toast = useRef();
  const navigate = useNavigate();
  //
  const trainingQuery = trainingsAPI.GetOne(
    currentTraining?.school_id,
    currentTraining?.id,
    accessToken
  );
  const trainingObj = trainingQuery?.data?.data;
  //
  return (
    <>
      <MeaPage header={<TrainingHeader training={trainingObj} />}>
        <div className="mb-5">
          <Button
            label="Modifier"
            icon="pi pi-pencil"
            size="small"
            className="p-button mr-2 action"
            onClick={() => {
              updateModalRef.current.show(() => {
                trainingQuery.refetch();
                reloadCurrentTraining();
              });
            }}
            data-pr-tooltip="Nouveau niveau"
          />
          {/* <Button
            label="Changer le statut"
            icon="pi pi-sync"
            size="small"
            className="p-button mr-2 action"
            severity="info"
            onClick={() => {
              // createModalRef.current.show(() => {
              //   queryList.refetch();
              // });
            }}
            data-pr-tooltip="Nouveau niveau"
          /> */}

        </div>
        <TrainingDetailComponent training={trainingObj} />
        <ConfirmDialog />
        <Toast ref={toast} />

        <UpdateTrainingModal ref={updateModalRef} training={trainingObj} />
      </MeaPage>
    </>
  );
};
export default TrainingInfo;
