import React, { forwardRef, useMemo } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Card } from "primereact/card";
import CustomMonthCalendar from "./CustomMonthCalendar";
import useAuthContext from "../../../../hooks/useAuthContext";
import eventsAPI from "../../../../services/events";
import useUrlState from "@ahooksjs/use-url-state";
import { Tag } from "primereact/tag";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";

const CustomAnnualCalendar = forwardRef((props, ref) => {
  const { filter } = props;
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;

  const [state, setState] = useUrlState({
    eventsFilter: {
      eventType: filter?.eventType || "",
      isAggretate: filter?.isAggretate || true,
    },
  });

  const queryList = eventsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000000, // Fetch all events at once
    state.eventsFilter
  );

  const events = useMemo(() => {
    if (queryList?.data?.data) {
      return queryList.data.data.map(
        ({ id, name, start_date, end_date, event_type }) => ({
          id,
          title: name,
          startDate: moment(start_date),
          endDate: moment(end_date),
          event_type,
        })
      );
    }
    return [];
  }, [queryList?.data?.data]);

  const startDate = useMemo(
    () => moment(authContext?.currentTraining?.start_date, moment.ISO_8601),
    [authContext?.currentTraining?.start_date]
  );
  const endDate = useMemo(
    () => moment(authContext?.currentTraining?.end_date, moment.ISO_8601),
    [authContext?.currentTraining?.end_date]
  );

  const firstDayOfMonth = useMemo(
    () => startDate.clone().startOf("month"),
    [startDate]
  );
  const lastDayOfMonth = useMemo(
    () => endDate.clone().endOf("month"),
    [endDate]
  );

  const dates = useMemo(() => {
    const datesArr = [];
    let currentDate = firstDayOfMonth.clone();
    while (currentDate.isSameOrBefore(lastDayOfMonth, "day")) {
      datesArr.push(currentDate.clone());
      currentDate.add(1, "day");
    }
    return datesArr;
  }, [firstDayOfMonth, lastDayOfMonth]);

  const eventsByDate = useMemo(() => {
    const groupedEvents = {};
    events.forEach((event) => {
      const eventStartDate = event.startDate;
      const eventEndDate = event.endDate;
      let currentDate = firstDayOfMonth.clone();
      while (currentDate.isSameOrBefore(lastDayOfMonth, "day")) {
        const isEventOnDate = currentDate.isBetween(
          eventStartDate,
          eventEndDate,
          "day",
          "[]"
        );
        if (isEventOnDate) {
          const dateKey = currentDate.format("YYYY-MM-DD");
          if (!groupedEvents[dateKey]) {
            groupedEvents[dateKey] = [];
          }
          groupedEvents[dateKey].push(event);
        }
        currentDate.add(1, "day");
      }
    });
    return groupedEvents;
  }, [events, firstDayOfMonth, lastDayOfMonth]);

  const months = useMemo(
    () => [...new Set(dates.map((date) => date.format("MMMM YYYY")))],
    [dates]
  );

  const monthData = useMemo(
    () =>
      months.map((month) => ({
        month,
        dates: dates.filter((date) => date.format("MMMM YYYY") === month),
      })),
    [months, dates]
  );

  const skeletonItems = Array.from({ length: 5 }, (v, i) => i);

  return (
    <>
      {queryList.isLoading && (
        <DataTable value={skeletonItems} className="p-datatable-striped">
          <Column style={{ width: "25%" }} body={<Skeleton />}></Column>
          <Column style={{ width: "25%" }} body={<Skeleton />}></Column>
          <Column style={{ width: "25%" }} body={<Skeleton />}></Column>
          <Column style={{ width: "25%" }} body={<Skeleton />}></Column>
        </DataTable>
      )}
      {!queryList.isLoading && queryList.error && (
        <div className="p-message p-message-error">
          <div className="p-message-content">
            <i className="pi pi-error"></i>
            <span>Erreur de récupération de la liste des événements.</span>
          </div>
        </div>
      )}
      {!queryList.isLoading && !queryList.error && (
        <div className="year-planner">
          <div className="grid">
            {monthData.map(({ month, dates }, index) => (
              <CustomMonthCalendar
                key={index}
                month={month}
                dates={dates}
                events={eventsByDate}
              />
            ))}
          </div>
        </div>
      )}
      <Card className="legend-card" rounded="md">
        <h2 className="p-card-title">Légende</h2>
        <div className="legend-box">
          <Tag
            className="mr-2"
            style={{ backgroundColor: "#e8e8e8", color: "#8c6161" }}
          >
            Séance de cours terminé
          </Tag>
          <Tag
            className="mr-2"
            style={{ backgroundColor: "#0ea5e9", color: "white" }}
          >
            Séance de cours en cours
          </Tag>
          <Tag
            className="mr-2"
            style={{ backgroundColor: "#f97316", color: "white" }}
          >
            Séance de cours à venir
          </Tag>
          <Tag
            className="mr-2"
            style={{ backgroundColor: "#22c55e", color: "white" }}
          >
            Réunion
          </Tag>
          <Tag
            className="mr-2"
            style={{ backgroundColor: "#ef4444", color: "white" }}
          >
            Vacances / Jours fériés
          </Tag>
          <Tag>Autre</Tag>
        </div>
      </Card>
    </>
  );
});

export default CustomAnnualCalendar;
