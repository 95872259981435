import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useEffect, useRef, useState } from "react";
import levelsAPI from "../../../../services/levels";
import classesAPI from "../../../../services/classes";
import useAuthContext from "../../../../hooks/useAuthContext";
import { Dropdown } from "primereact/dropdown";
import studentsAPI from "../../../../services/students";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import MeaMemberAvatar from "../../../../components/MeaMemberAvatar";
import { ToFormatedBackendDateFromShortISODateString, ToFormatedShortISODateFromDateString } from "../../../../utils/dates";

const StudentRegistrationFormComponent = ({ student, callback }) => {
  const navigate = useNavigate();
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const [editMode1, setEditMode1] = useState(false);
  const [editMode2, setEditMode2] = useState(false);
  const [editMode3, setEditMode3] = useState(false);
  const [editMode4, setEditMode4] = useState(false);
  const [editMode5, setEditMode5] = useState(false);
  //
  const toast = useRef();
  //
  const [currentStudent, setCurrentStudent] = useState();
  const [gender, setGender] = useState("-1");
  //
  useEffect(() => {
    if (student) {
      setCurrentStudent(student);
      setGender(student?.gender || "-1")
    }
  }, [student]);

  //
  /*
  const levelsQuery = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const classesQuery = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000,
    {
      level_id: currentStudent?.level_id,
    }
  );
  */
  //
  const updateStudentField = (field, value) => {
    let updated = {};
    updated[field] = value;
    setCurrentStudent((s) => ({
      ...s,
      ...updated,
    }));
  };
  //
  const getGender = () => {
    let gender = currentStudent?.gender;
    if (gender !== undefined) {
      if (gender === "G") {
        return "Fille";
      } else if (gender === "B") {
        return "Garçon";
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };
  //
  const saveCurrentStudent = () => {
    studentsAPI.UpdateOne(
      schoolId,
      trainingId,
      accessToken,
      currentStudent.id,
      currentStudent
    ).then((data) => {
      toast.current.show({
        severity: "success",
        summary: "Mise à jour réussie",
        detail: "Les informations de l'élève ont été mises à jour avec succès",
        life: 3000,
      });
      callback();
    }).catch((error) => {
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Erreur de mise à jour",
        detail: "Une erreur s'est produite lors de la mise à jour des informations de l'élève",
        life: 3000,
      });
    }).finally(() => {
      setEditMode1(false);
      setEditMode2(false);
      setEditMode3(false);
      setEditMode4(false);
      setEditMode5(false);
    });;
  };

  //
  return (
    <>
      <Toast ref={toast} />
      <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div className="text-500 w-6 md:w-2 font-medium">Elève</div>
          <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Nom de famille</div>
                {(!editMode1 && <div className="text-900 w-full md:w-9">{student?.lastname || "-"}</div>) ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.lastname} onInput={(e) => {
                    updateStudentField("lastname", e.target.value?.toUpperCase())
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Prénom</div>
                {(!editMode1 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.firstname || "-"}</div>) ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.firstname} onInput={(e) => {
                    updateStudentField("firstname", e.target.value?.toUpperCase())
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Genre</div>
                {(!editMode1 && <div className="text-900 w-full md:w-9">{getGender()}</div>) ||
                  <Dropdown
                    className="p-inputtext-sm w-full md:w-9"
                    value={currentStudent?.gender}
                    checkmark={true}
                    onChange={(e) => { updateStudentField("gender", e.value) }}
                    options={[
                      { label: "", code: "" },
                      { label: "Fille", code: "G" },
                      { label: "Garçon", code: "B" },
                    ]}
                    optionLabel="label"
                    optionValue="code"
                    placeholder="Genre"
                  />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Date de naissance</div>
                {!editMode1 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.birthday}</div> ||

                  <InputText type="date" className="p-inputtext-sm w-full md:w-9" value={ToFormatedShortISODateFromDateString(currentStudent?.birthday)} onChange={(e) => {
                    console.log(e.target.value)
                    updateStudentField("birthday", ToFormatedBackendDateFromShortISODateString(e.target.value))
                  }} />

                }
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Age</div>
                {!editMode1 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.age}</div> ||
                  <InputText type="number" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.age} onChange={(e) => {
                    console.log(e.target.value)
                    updateStudentField("age", parseInt(e.target.value))
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">E-mail de contact</div>
                {!editMode1 && <div className="text-900 w-full md:w-9">{currentStudent?.email || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.email} onInput={(e) => {
                    updateStudentField("email", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Téléphone</div>
                {!editMode1 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.phone || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.phone} onInput={(e) => {
                    updateStudentField("phone", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Adresse</div>
                {!editMode1 && <div className="text-900 w-full md:w-9">{currentStudent?.address || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.address} onInput={(e) => {
                    updateStudentField("address", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Code postale</div>
                {!editMode1 && <div className="text-900 w-full md:w-9">{currentStudent?.postal_code || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.postal_code} onInput={(e) => {
                    updateStudentField("postal_code", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Ville</div>
                {!editMode1 && <div className="text-900 w-full md:w-9">{currentStudent?.city || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.city} onInput={(e) => {
                    updateStudentField("city", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Autorise l'école à m'envoyer des informations</div>
                <div className="text-900 w-full md:w-9 line-height-3"><Checkbox disabled={!editMode1} onChange={e => updateStudentField("accept_contacted", e.checked)} checked={currentStudent?.accept_contacted}></Checkbox></div>
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Compte utilisateur</div>
                <div className="text-900 w-full md:w-9"><MeaMemberAvatar member={currentStudent?.member} /></div>
              </li>
            </ul>
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap">

                {!editMode1 && <Button label="Modifier" icon="pi pi-pencil" className="p-button-text" onClick={() => {
                  setEditMode1(true);
                }} /> ||
                  <><Button label="Enregistrer" icon="pi pi-save" className="p-button" onClick={saveCurrentStudent} /><Button className="p-button-text ml-2" label="Annuler" onClick={() => {
                    callback();
                    setEditMode1(false);
                  }} /></>}
              </li>
            </ul>
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div className="text-500 w-6 md:w-2 font-medium">Responsables légaux</div>
          <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Nom du père</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.father_lastname || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.father_lastname} onInput={(e) => {
                    updateStudentField("father_lastname", e.target.value?.toUpperCase())
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Prénom du père</div>
                {!editMode2 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.father_firstname || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.father_firstname} onInput={(e) => {
                    updateStudentField("father_firstname", e.target.value?.toUpperCase())
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Téléphone du père</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.father_phone || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.father_phone} onInput={(e) => {
                    updateStudentField("father_phone", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">E-mail du père</div>
                {!editMode2 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.father_email || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.father_email} onInput={(e) => {
                    updateStudentField("father_email", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap ">
                <div className="text-500 w-full md:w-3 font-medium">Compte utilisateur</div>
                <div className="text-900 w-full md:w-9"><MeaMemberAvatar member={currentStudent?.father} /></div>
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Adresse postale du père</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.father_address || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.father_address} onInput={(e) => {
                    updateStudentField("father_address", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Code postale du père</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.father_postal_code || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.father_postal_code} onInput={(e) => {
                    updateStudentField("father_postal_code", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Ville du père</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.father_city || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.father_city} onInput={(e) => {
                    updateStudentField("father_city", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Autorise l'école à m'envoyer des informations</div>
                <div className="text-900 w-full md:w-9 line-height-3"><Checkbox disabled={!editMode2} onChange={e => updateStudentField("father_accept_contacted", e.checked)} checked={currentStudent?.father_accept_contacted}></Checkbox></div>
              </li>

            </ul>
            <ul className="list-none p-0 m-0 border-top-1 surface-border mt-3">
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Nom de la mère</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.mother_lastname || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.mother_lastname} onInput={(e) => {
                    updateStudentField("mother_lastname", e.target.value?.toUpperCase())
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Prénom de la mère</div>
                {!editMode2 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.mother_firstname || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.mother_firstname} onInput={(e) => {
                    updateStudentField("mother_firstname", e.target.value?.toUpperCase())
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Téléphone de la mère</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.mother_phone || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.mother_phone} onInput={(e) => {
                    updateStudentField("mother_phone", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">E-mail de la mère</div>
                {!editMode2 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.mother_email || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.mother_email} onInput={(e) => {
                    updateStudentField("mother_email", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap ">
                <div className="text-500 w-full md:w-3 font-medium">Compte utilisateur</div>
                <div className="text-900 w-full md:w-9"><MeaMemberAvatar member={currentStudent?.mother} /></div>
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Adresse postale de la mère</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.mother_address || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.mother_address} onInput={(e) => {
                    updateStudentField("mother_address", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Code postale de la mère</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.mother_postal_code || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.mother_postal_code} onInput={(e) => {
                    updateStudentField("mother_postal_code", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Ville de la mère</div>
                {!editMode2 && <div className="text-900 w-full md:w-9">{currentStudent?.mother_city || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.mother_city} onInput={(e) => {
                    updateStudentField("mother_city", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Autorise l'école à m'envoyer des informations</div>
                <div className="text-900 w-full md:w-9 line-height-3"><Checkbox disabled={!editMode2} onChange={e => updateStudentField("mother_accept_contacted", e.checked)} checked={currentStudent?.mother_accept_contacted}></Checkbox></div>
              </li>

            </ul>
            <ul className="list-none p-0 m-0 border-top-1 surface-border mt-3">
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Situation familiale</div>
                <div className="text-900 w-full md:w-9">
                  <div className="card flex flex-column md:flex-row gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode2}
                        checked={currentStudent?.parents_divorced === false}
                        onChange={(e) =>
                          updateStudentField("parents_divorced", false)
                        }
                      />
                      <label className="ml-2">Marié(e)</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode2}
                        checked={currentStudent?.parents_divorced === true}
                        onChange={(e) => updateStudentField("parents_divorced", true)}
                      />
                      <label className="ml-2">Divorcé(e)</label>
                    </div>
                  </div>

                </div>
              </li>
            </ul>
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap">

                {!editMode2 && <Button label="Modifier" icon="pi pi-pencil" className="p-button-text" onClick={() => {
                  setEditMode2(true);
                }} /> ||
                  <><Button label="Enregistrer" icon="pi pi-save" className="p-button" onClick={saveCurrentStudent} /><Button className="p-button-text ml-2" label="Annuler" onClick={() => {
                    callback();
                    setEditMode2(false);
                  }} /></>}
              </li>
            </ul>
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div className="text-500 w-6 md:w-2 font-medium">Personnes à contacter</div>
          <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Nom & prénom - Personne 1</div>
                {!editMode3 && <div className="text-900 w-full md:w-9">{currentStudent?.contact_person_1 || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.contact_person_1} onInput={(e) => {
                    updateStudentField("contact_person_1", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Téléphone - Personne 1</div>
                {!editMode3 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.contact_person_1_phone || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.contact_person_1_phone} onInput={(e) => {
                    updateStudentField("contact_person_1_phone", e.target.value)
                  }} />}
              </li>
            </ul>
            <ul className="list-none p-0 m-0 border-top-1 surface-border mt-3">
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">Nom & prénom - Personne 2</div>
                {!editMode3 && <div className="text-900 w-full md:w-9">{currentStudent?.contact_person_2 || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.contact_person_2} onInput={(e) => {
                    updateStudentField("contact_person_2", e.target.value)
                  }} />}
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Téléphone - Personne 2</div>
                {!editMode3 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.contact_person_2_phone || "-"}</div> ||
                  <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.contact_person_2_phone} onInput={(e) => {
                    updateStudentField("contact_person_2_phone", e.target.value)
                  }} />}
              </li>
            </ul>
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap">

                {!editMode3 && <Button label="Modifier" icon="pi pi-pencil" className="p-button-text" onClick={() => {
                  setEditMode3(true);
                }} /> ||
                  <><Button label="Enregistrer" icon="pi pi-save" className="p-button" onClick={saveCurrentStudent} /><Button className="p-button-text ml-2" label="Annuler" onClick={() => {
                    callback();
                    setEditMode3(false);
                  }} /></>}
              </li>
            </ul>
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div className="text-500 w-6 md:w-2 font-medium">Autorisations</div>
          <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">J'autorise mon enfant à partir seul après l'école ?</div>
                <div className="text-900 w-full md:w-9">
                  <div className="card flex flex-column md:flex-row gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode4}
                        checked={currentStudent?.authorized_back_alone === true}
                        onChange={(e) =>
                          updateStudentField("authorized_back_alone", true)
                        }
                      />
                      <label className="ml-2">Oui</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode4}
                        checked={currentStudent?.authorized_back_alone === false}
                        onChange={(e) =>
                          updateStudentField("authorized_back_alone", false)
                        }
                      />
                      <label className="ml-2">Non</label>
                    </div>
                  </div>
                </div>
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">Personnes autorisées à récupérer l'enfant après les cours ?</div>
                <div className="text-900 w-full md:w-9 line-height-3">
                  {!editMode4 && <div className="text-900 w-full md:w-9 line-height-3">{currentStudent?.authorized_back_with || "-"}</div> ||
                    <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.authorized_back_with} onInput={(e) => {
                      updateStudentField("authorized_back_with", e.target.value)
                    }} />}
                </div>
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">J’autorise l’école à utiliser sur ses différents supports et outils de communication, les éventuelles photographies ou vidéos de mon enfant réalisées dans le cadre de l’école ?</div>
                <div className="text-900 w-full md:w-9">
                  <div className="card flex flex-column md:flex-row gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode4}
                        checked={currentStudent?.authorized_take_photo === true}
                        onChange={(e) =>
                          updateStudentField("authorized_take_photo", true)
                        }
                      />
                      <label className="ml-2">Oui</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode4}
                        checked={currentStudent?.authorized_take_photo === false}
                        onChange={(e) =>
                          updateStudentField("authorized_take_photo", false)
                        }
                      />
                      <label className="ml-2">Non</label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap">

                {!editMode4 && <Button label="Modifier" icon="pi pi-pencil" className="p-button-text" onClick={() => {
                  setEditMode4(true);
                }} /> ||
                  <><Button label="Enregistrer" icon="pi pi-save" className="p-button" onClick={saveCurrentStudent} /><Button className="p-button-text ml-2" label="Annuler" onClick={() => {
                    callback();
                    setEditMode4(false);
                  }} /></>}
              </li>
            </ul>
          </div>

        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
          <div className="text-500 w-6 md:w-2 font-medium">Renseignements médicaux</div>
          <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">L'enfant a-il des problèmes de santé ?</div>
                <div className="text-900 w-full md:w-9">
                  <div className="card flex flex-column md:flex-row gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode5}
                        checked={currentStudent?.health_issues === true}
                        onChange={(e) => updateStudentField("health_issues", true)}
                      />
                      <label className="ml-2">Oui</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode5}
                        checked={currentStudent?.health_issues === false}
                        onChange={(e) => updateStudentField("health_issues", false)}
                      />
                      <label className="ml-2">Non</label>
                    </div>
                  </div>
                  <div className="card flex flex-column md:flex-row gap-3 mt-3">
                    <div className="p-inputgroup flex-1">
                      <span className="p-float-label">
                        {currentStudent?.health_issues === true && <><InputTextarea readOnly={!editMode5} type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.health_issues_details} onInput={(e) => {
                          updateStudentField("health_issues_details", e.target.value)
                        }} />
                          <label>
                            Préciser les précautions à prendre et la conduite à tenir
                          </label></>}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">L'enfant suit-il un traitement médical ?</div>
                <div className="text-900 w-full md:w-9">
                  <div className="card flex flex-column md:flex-row gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode5}
                        checked={currentStudent?.medical_traitement === true}
                        onChange={(e) => updateStudentField("medical_traitement", true)}
                      />
                      <label className="ml-2">Oui</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode5}
                        checked={currentStudent?.medical_traitement === false}
                        onChange={(e) => updateStudentField("medical_traitement", false)}
                      />
                      <label className="ml-2">Non</label>
                    </div>
                  </div>
                  <div className="card flex flex-column md:flex-row gap-3 mt-3">
                    <div className="p-inputgroup flex-1">
                      <span className="p-float-label">
                        {currentStudent?.medical_traitement === true && <><InputTextarea readOnly={!editMode5} type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.medical_traitement_details} onInput={(e) => {
                          updateStudentField("medical_traitement_details", e.target.value)
                        }} />
                          <label>
                            Préciser la conduite à tenir
                          </label></>}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-3 font-medium">L'enfant a-il des allergies ?</div>
                <div className="text-900 w-full md:w-9">
                  <div className="card flex flex-column md:flex-row gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode5}
                        checked={currentStudent?.has_allergies === true}
                        onChange={(e) => updateStudentField("has_allergies", true)}
                      />
                      <label className="ml-2">Oui</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode5}
                        checked={currentStudent?.has_allergies === false}
                        onChange={(e) => updateStudentField("has_allergies", false)}
                      />
                      <label className="ml-2">Non</label>
                    </div>
                  </div>
                  <div className="card flex flex-column md:flex-row gap-3 mt-3">
                    <div className="p-inputgroup flex-1">
                      <span className="p-float-label">
                        {currentStudent?.has_allergies === true && <><InputTextarea readOnly={!editMode5} type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.has_allergies_details} onInput={(e) => {
                          updateStudentField("has_allergies_details", e.target.value)
                        }} />
                          <label>
                            Préciser lesquelles
                          </label></>}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="flex align-items-center py-2 px-2 flex-wrap">
                <div className="text-500 w-full md:w-3 font-medium">L'enfant porte-il des équipements médicales (des lunettes, des prothèses auditives, des prothèses dentaires, etc)?</div>
                <div className="text-900 w-full md:w-9">
                  <div className="card flex flex-column md:flex-row gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode5}
                        checked={currentStudent?.wears_medical_devices === true}
                        onChange={(e) => updateStudentField("wears_medical_devices", true)}
                      />
                      <label className="ml-2">Oui</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled={!editMode5}
                        checked={currentStudent?.wears_medical_devices === false}
                        onChange={(e) => updateStudentField("wears_medical_devices", false)}
                      />
                      <label className="ml-2">Non</label>
                    </div>
                  </div>
                  <div className="card flex flex-column md:flex-row gap-3 mt-3">
                    <div className="p-inputgroup flex-1">
                      <span className="p-float-label">
                        {currentStudent?.wears_medical_devices === true && <><InputTextarea readOnly={!editMode5} type="text" className="p-inputtext-sm w-full md:w-9" value={currentStudent?.wears_medical_devices_details} onInput={(e) => {
                          updateStudentField("wears_medical_devices_details", e.target.value)
                        }} />
                          <label>
                            Préciser lesquelles
                          </label></>}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-2 px-2 flex-wrap">

                {!editMode5 && <Button label="Modifier" icon="pi pi-pencil" className="p-button-text" onClick={() => {
                  setEditMode5(true);
                }} /> ||
                  <><Button label="Enregistrer" icon="pi pi-save" className="p-button" onClick={saveCurrentStudent} /><Button className="p-button-text ml-2" label="Annuler" onClick={() => {
                    callback();
                    setEditMode5(false);
                  }} /></>}
              </li>
            </ul>
          </div>

        </li>
      </ul >
    </>
  );
};

export default StudentRegistrationFormComponent;
