import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import ClassesListComponent from "./components/ClassesListComponent";

const ClassesListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des classes"
        subtitle="Gérer l'ensemble des classes de cette formation"
      >
        <ClassesListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default ClassesListPage;
