import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MeaDataTable from "../../../../components/MeaDataTable";
import systemAccountsAPI from "../../../../services/system_accounts";

const SystemAccountsListComponent = forwardRef((props, ref) => {
  const { showAddButton, showFilterButton } = props;
  const { auth } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();
  const [email, setEmail] = useState();

  const schoolId = "";
  const trainingId = "";
  const accessToken = auth?.access_token;
  //
  //const createModalRef = useRef();
  //const updateModalRef = useRef();
  //
  //
  const queryList = systemAccountsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    email,
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //
  return (
    <>
      <MeaDataTable
        query={queryList}
        serviceAPI={systemAccountsAPI}
        names={{
          pagination: "account",
          singular: "Compte utilisateur",
          plural: "Comptes utilisateurs",
        }}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column field="lastname" header="Nom"></Column>
        <Column field="firstname" header="Prénom"></Column>
        <Column field="email" header="E-mail"></Column>
        <Column field="status" header="Status"></Column>
        <Column field="created_at" header="Crée le"></Column>
      </MeaDataTable>
      {/*<UpdateSystemSchoolModal ref={updateModalRef} school={school} />*/}
    </>
  );
});

export default SystemAccountsListComponent;
