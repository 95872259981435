import { Skeleton } from "primereact/skeleton";
const MeaLoadingContent = ({ objectQuery, children }) => {
  return (
    <>
      {(objectQuery?.isSuccess && children) || (
        <Skeleton width="10rem" className="mb-2" />
      )}
    </>
  );
};
export default MeaLoadingContent;
