import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useRef } from "react";

const MeaPage = ({
  breadcrumb,
  title,
  subtitle,
  actions,
  header,
  children,
}) => {
  const actionsMenu = useRef(null);
  return (
    <>
      {header && <>{header}</>}
      {breadcrumb}
      <div className="p-3 mb-3">
        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
          {(title || subtitle || actions) && (
            <>
              <div className="flex align-items-start">
                <Button
                  icon="pi pi-arrow-left"
                  text
                  style={{ minHeight: "50px", maxWidth: "50px" }}
                  onClick={() => window.history.back()}
                ></Button>
                <div>
                  <div className="font-medium text-3xl text-900">{title}</div>
                  <div className="flex align-items-center text-700 flex-wrap  mt-1">
                    {subtitle}
                  </div>
                </div>
              </div>
              {actions && (
                <div className="mt-3 lg:mt-0">
                  <Menu
                    model={actions || []}
                    popup
                    ref={actionsMenu}
                    id="popup_actions_menu"
                  />
                  <Button
                    label="Actions"
                    text
                    raised
                    icon="pi pi-align-right"
                    className="mr-2"
                    onClick={(event) => actionsMenu.current.toggle(event)}
                    aria-controls="popup_actions_menu"
                    aria-haspopup
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="mt-2">{children}</div>
      </div>
    </>
  );
};

export default MeaPage;
