import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import SystemAccountsListComponent from "./components/SystemAccountsListComponent";
const SystemAccountsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des comptes utilisateurs"
        subtitle="Gérer l'ensemble des comptes utilisateurs sur la plateforme"
      >
        <SystemAccountsListComponent
          ref={listRef}
          showAddButton={false}
          showFilterButton={false}
        />
      </MeaPage>
    </>
  );
};
export default SystemAccountsListPage;
