import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

//import useToastContext from "../../../hooks/useToastContext";
import useAuthContext from "../../../../hooks/useAuthContext";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import membersAPI from "../../../../services/members";
import { Button } from "primereact/button";


const UpdateMemberRolesModal = forwardRef((props, ref) => {
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isStudent, setIsStudent] = useState(false);

  //
  useEffect(() => {
    setIsAdmin(props?.member?.is_admin);
    setIsStaff(props?.member?.is_staff);
    setIsTeacher(props?.member?.is_teacher);
    setIsStudent(props?.member?.is_student);
  }, [props]);

  //
  const [active, setActive] = useState(false);
  const [callbackFn, setCallbackFn] = useState(() => { });
  //
  const [loading, setLoading] = useState(false);
  //
  const toast = useRef(null);
  //
  const handleUpdateSubmit = async () => {
    setLoading(true);
    membersAPI
      .UpdateRoles(
        schoolId,
        accessToken,
        props?.member?.id,
        isAdmin,
        isStaff,
        isTeacher,
        isStudent
      )
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Roles modifiés!",
        });
        setActive(false);
        callbackFn();
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur lors de l'ajout!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //
  useImperativeHandle(ref, () => ({
    show: (callback) => {
      setCallbackFn(() => callback);
      setActive(true);
    },
  }));
  //
  const footerContent = (
    <div>
      <Button
        label="Annuler"
        icon="pi pi-times"
        disabled={loading}
        onClick={() => setActive(false)}
        className="p-button-text"
      />
      <Button
        type="submit"
        loading={loading}
        label="Ajouter"
        icon="pi pi-check"
        onClick={() => handleUpdateSubmit()}
      />
    </div>
  );

  return (
    <>
      <Toast ref={toast} />

      <Dialog
        header="Modifier les roles du membres"
        visible={active}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setActive(false)}
        footer={footerContent}
      >
        <div className="text-700 w-full mb-5">Veuillez sélectionner un ou plusieurs rôle pour ce membre.</div>
        <div className="flex flex-column gap-3">
          <div>
            <Checkbox inputId="Administration" onChange={(e) => setIsAdmin(e.checked)} checked={isAdmin} />
            <label htmlFor="Administration" className="ml-2"> Administration  </label>
          </div>

          <div>
            <Checkbox inputId="Secretariat" onChange={(e) => setIsStaff(e.checked)} checked={isStaff} />
            <label htmlFor="Secretariat" className="ml-2"> Secretariat  </label>
          </div>

          <div>
            <Checkbox inputId="Enseignant(e)" onChange={(e) => setIsTeacher(e.checked)} checked={isTeacher} />
            <label htmlFor="Enseignant(e)" className="ml-2"> Enseignant(e)  </label>
          </div>

          <div>
            <Checkbox inputId="Élève" onChange={(e) => setIsStudent(e.checked)} checked={isStudent} />
            <label htmlFor="Élève" className="ml-2"> Élève  </label>
          </div>
        </div>

      </Dialog>
    </>
  );
});

export default UpdateMemberRolesModal;
