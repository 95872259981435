// e.g. mer 27/12/2023
export function ToFormatedDateWithWeekday(date) {
  if (date) {
    const formattedDate = date.toLocaleDateString("fr-FR", {
      timeZone: "Europe/Paris",
      weekday: "short",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  }
  return "";
}

// e.g. 17:23
export function ToFormatedHour(date) {
  if (date) {
    const formattedHour = date.toLocaleTimeString("fr-FR", {
      timeZone: "Europe/Paris",
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedHour;
  }
  return "";
}

// e.g. 2023-12-27
export function ToFormatedShortISODate(date) {
  if (date) {
    const _year = date.getFullYear();
    const _month = String(date.getMonth() + 1).padStart(2, "0");
    const _day = String(date.getDate()).padStart(2, "0");
    return `${_year}-${_month}-${_day}`;
  }
  return "";
}

// e.g. input 2/12/2023 output 2023-12-02
export function ToFormatedShortISODateFromDateString(dateStr) {
  if (dateStr) {
    let parts = dateStr.split('/');

    // Extract the day, month, and year
    let day = parts[0].padStart(2, '0'); // Ensure day is two digits
    let month = parts[1].padStart(2, '0'); // Ensure month is two digits
    let year = parts[2];

    // Construct the new date string in the desired format
    let newDateStr = `${year}-${month}-${day}`;
    return newDateStr
  }
  return "";
}

// e.g. input 2023-12-02 output 2/12/2023
export function ToFormatedBackendDateFromShortISODateString(dateStr) {
  if (dateStr) {
    // Split the input date string by '-'
    let parts = dateStr.split('-');

    // Extract the year, month, and day
    let year = parts[0];
    let month = parts[1];
    let day = parts[2];

    // Construct the new date string in the desired format
    let newDateStr = `${day}/${month}/${year}`;

    return newDateStr;
  }
  return "";
}

// e.g. 27-12-2023
export function ToFormatedShortDate(date) {
  if (date) {
    const _year = date.getFullYear();
    const _month = String(date.getMonth() + 1).padStart(2, "0");
    const _day = String(date.getDate()).padStart(2, "0");
    return `${_day}-${_month}-${_year}`;
  }
  return "";
}

// e.g. 27/12/2023 17:23
export function ToFormatedDateForEvents(date, allday) {
  return (
    (allday &&
      date.toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })) ||
    date.toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
  );
}

// day = "2023-01-19";
// hour = "17:27";
// tz = "Europe/Paris"; // Replace with your specific timezone
export function ToFormatedISOTime(day, hour, tz) {
  const moment = require("moment-timezone"); // Import the moment-timezone library
  const combinedDateTimeString = `${day}T${hour}:00.000`;
  // Use moment-timezone to create a moment with the specified timezone
  if (!tz) {
    tz = "Europe/Paris";
  }
  const momentWithTz = moment.tz(combinedDateTimeString, tz);
  // Get the ISO date string
  const isoDate = momentWithTz.toISOString();
  return isoDate;
}

export const GetWeekDay = (day) => {
  switch (day) {
    case 0: {
      return "Dimanche";
    }
    case 1: {
      return "Lundi";
    }
    case 2: {
      return "Mardi";
    }
    case 3: {
      return "Mercredi";
    }
    case 4: {
      return "Jeudi";
    }
    case 5: {
      return "Vendredi";
    }
    case 6: {
      return "Samedi";
    }
    default: {
      return "-";
    }
  }
};

// retrive time HH:MM from datetime
export function GetTimeFromDate(dateString) {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}


export const CalculateAge = (birthday) => {
  var today = new Date();
  var birthDate = new Date(birthday);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
