import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import TrainingsListComponent from "./components/TrainingsListComponent";

const TrainingsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
      //breadcrumb={<SlotsBreadCrumb />}
      //title="Liste des formations de l'école"
      //subtitle="Gérer l'ensemble des formation de l'école"
      >
        <TrainingsListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default TrainingsListPage;
