import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import MeaLink from "../../components/MeaLink";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const signup_URL = BACKEND_URL + "/api/v1/signup";

const Signup = () => {
    const navigate = useNavigate();
    //
    const toast = useRef(null);
    const captchaRef = useRef(null);
    //
    const [lastname, setLastname] = useState("");
    const [firstname, setFirstname] = useState("");
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [ageCondition, setAgeCondition] = useState(false);
    const [dataCondition, setDataCondition] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    //
    var canRegister =
        email === "" ||
        confirmEmail === "" ||
        email !== confirmEmail ||
        lastname === "" ||
        firstname === "" ||
        ageCondition === false ||
        dataCondition === false;

    const handleSubmit = async (e) => {
        if (
            firstname.trim() === "" ||
            lastname.trim() === "" ||
            email.trim() === ""
        ) {
            setErrMsg("Veuillez remplir tous les champs");
            return;
        }
        e.preventDefault();
        const token = captchaRef.current.getValue();
        setLoading(true);
        fetch(signup_URL, {
            method: "POST",
            body: JSON.stringify({ lastname, firstname, email, "g-recaptcha-response": token }),
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status >= 299) {
                    const data = response.json();
                    data.then((data) => { setErrMsg("Problème lors de la creation du compte: " + data.error); });
                    throw new Error(response.message);
                }
                return response.json();
            })
            .then((response) => {
                setErrMsg("");
                toast.current.show({
                    severity: "success",
                    summary: "Félécitations!",
                    detail:
                        "Votre compte a était créer avec succés. Un email a été envoyé à " + email + " pour définir votre mot de passe.",
                    sticky: true,
                    content: (props) => (
                        <div
                            className="flex flex-column align-items-left"
                            style={{ flex: "1" }}
                        >
                            <div className="flex align-items-center gap-2">
                                <span className="font-bold text-900">
                                    {props.message.summary}
                                </span>
                            </div>
                            <div className="font-medium text-lg my-3 text-900">
                                {props.message.detail}
                            </div>
                            <Button
                                className="p-button-sm flex"
                                label="S'identifier"
                                severity="success"
                                onClick={clear}
                            ></Button>
                        </div>
                    ),
                });
            })
            .catch(function (error) {
                console.log("Problème lors de la creation du compte: " + error.message);
            })
            .finally(() => {
                //
                setLoading(false);
                captchaRef.current.reset();
            });
    };

    const clear = () => {
        toast.current.clear();
        navigate("/d/login");
    };

    return (
        <>
            <Toast ref={toast} position="center" onRemove={clear} />
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }} className="flex justify-content-center flex-wrap">
                <div style={{ maxWidth: "400px" }} className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                    <div className="text-center mb-5">
                        <div className="flex align-items-start">
                            <Button
                                icon="pi pi-arrow-left"
                                text
                                style={{ minHeight: "50px", maxWidth: "50px" }}
                                onClick={() => window.history.back()}
                            ></Button>
                            <div>
                                <MeaLink to="/d/welcome"><img src="/mea-logo.png" alt="hyper" height={50} className="mb-3 pl-3" /></MeaLink>
                            </div>
                        </div>
                        <div className="text-900 text-3xl font-medium mb-3">Nouveau compte</div>
                    </div>

                    <div>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="email" className="block text-900 font-medium mb-2">Adresse email</label>
                            <InputText id="email" type="text" placeholder="" className="w-full mb-3" value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                label="Votre Courriel (email)"
                                autoFocus />

                            <label htmlFor="confirmEmail" className="block text-900 font-medium mb-2">Confirmer l'adresse email</label>
                            <InputText id="confirmEmail" type="text" placeholder="" className="w-full mb-3" value={confirmEmail}
                                onChange={(e) => setConfirmEmail(e.target.value)}
                                label="Confirmer le courriel (email)"
                            />

                            <label htmlFor="lastname" className="block text-900 font-medium mb-2">Nom de famille</label>
                            <InputText id="lastname" type="text" placeholder="" className="w-full mb-3" value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                label="Nom de famille"
                            />

                            <label htmlFor="firstname" className="block text-900 font-medium mb-2">Prénom</label>
                            <InputText id="firstname" type="text" placeholder="" className="w-full mb-3" value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                label="Prénom"
                            />
                            <div className="block  mb-2">

                                <Checkbox inputId="ageCondition" onChange={(e) =>
                                    setAgeCondition(e.checked)
                                } checked={ageCondition} />
                                <label htmlFor="ageCondition" className="text-sm text-600 ml-2">Je déclare avoir plus de 15 ans. En cas de fausse déclaration avérée, le compte sera supprimé.</label>
                            </div>
                            <div className="block  mb-2">
                                <Checkbox inputId="dataCondition" onChange={(e) =>
                                    setDataCondition(e.checked)
                                } checked={dataCondition} />
                                <label htmlFor="dataCondition" className="text-sm text-600 ml-2">J'accepte que mes données personnelles soient utilisées dans le cadre du traitement d'accès aux services de la plateforme Mon-Ecole-Arabe.</label>
                            </div>
                            <div className="flex align-items-center justify-content-between mb-4">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    ref={captchaRef}
                                ></ReCAPTCHA>
                            </div>
                            {errMsg && (
                                <Message
                                    severity="error"
                                    text={errMsg}
                                    className="block w-full mb-2"
                                />
                            )}
                            <Button disabled={canRegister} label="S'enregistrer" icon="pi pi-user" className="w-full" loading={loading} />

                        </form>
                    </div>
                </div>
            </div>

        </>
    );

};
export default Signup;