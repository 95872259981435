import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PrimeReactProvider } from "primereact/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContextProvider } from "./context/AuthContext";
import { locale, addLocale } from "primereact/api";
import fr from "primelocale/fr.json";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
//import "primereact/resources/themes/mdc-light-deeppurple/theme.css";

addLocale("fr", fr);
locale("fr");

const configs = {
  locale: "fr",
  cssTransition: false,
};
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PrimeReactProvider value={configs}>
        <AuthContextProvider>
          <Routes>
            <Route
              path="/*"
              element={
                <QueryClientProvider client={queryClient}>
                  <div className="surface-100" style={{ height: "100vh" }}>
                    <App />
                  </div>
                  {/*<ReactQueryDevtools initialIsOpen={true} />*/}
                </QueryClientProvider>
              }
            />
          </Routes>
        </AuthContextProvider>
      </PrimeReactProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
