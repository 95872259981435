import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (schoolId, accessToken, page, pageSize, status) => {
  return useQuery(
    ["queues", schoolId, accessToken, page, pageSize, status],
    async () => {
      if (!schoolId) {
        return null;
      }
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/queues?page=${page}&pageSize=${pageSize}&status=${status}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch queues!");
        }
        if (response.status >= 299) {
          throw new Error(
            "Erreur de récupération de la liste des liste d'attentes!"
          );
        }
        const result = await response.json();
        return result;
      } catch (error) {
        // Handle the error
        console.error("Error fetching queues:", error);
        throw error;
      }
    }
  );
};

const GetOne = (schoolId, queueId, accessToken) => {
  return useQuery(["slot", schoolId, queueId, accessToken], async () => {
    try {
      const response = await fetch(
        BACKEND_URL + `/api/v1/schools/${schoolId}/queues/${queueId}`,
        {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch queue!");
      }
      if (response.status >= 299) {
        throw new Error("Erreur de récupération de la liste d'attente!");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  });
};

const CreateOne = async (schoolId, dummyQueueId, accessToken, data) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/schools/" + schoolId + "/queues",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de création de la liste d'attente!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (
  schoolId,
  queueId,
  accessToken,
  dummyQueueId,
  data
) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/schools/" + schoolId + "/queues/" + dummyQueueId,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification de la liste d'attente!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateQueueAvatar = async (schoolId, queueId, accessToken, newAvatar) => {
  try {
    let formData = new FormData();
    formData.append("file", newAvatar);
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/queues/" +
        queueId +
        "/avatar",
      {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification de la liste d'attente!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, accessToken, queueId) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/schools/" + schoolId + "/queues/" + queueId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression de la liste d'attente!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const queuesAPI = {
  useGetList,
  GetOne,
  CreateOne,
  UpdateOne,
  UpdateQueueAvatar,
  DeleteOne,
};

export default queuesAPI;
