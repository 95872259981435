import { useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import TeacherDetailComponent from "./components/TeacherDetailComponent";
import teachersAPI from "../../../services/teachers";
import MeaPageTitle from "../../../components/MeaPageTitle";
import TeacherDetailAvatarComponent from "./components/TeacherDetailAvatarComponent";
import MeaPageHeader from "../../../components/MeaPageHeader";
import { TabMenu } from "primereact/tabmenu";
import { useRef, useState } from "react";
import ScheduleListComponent from "../schedule/components/ScheduleListComponent";
import SessionsListComponent from "../sessions/components/SessionsListComponent";
import MeaLoadingContent from "../../../components/MeaLoadingContent";

const TeacherDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const teacherId = params.teacherId;
  //
  const teacherQuery = teachersAPI.GetOne(
    schoolId,
    trainingId,
    accessToken,
    teacherId
  );
  const teacherObj = teacherQuery?.data?.data;
  // states
  const [activeItem, setActiveItem] = useState(0);
  const listRef = useRef();
  // params
  const items = [
    { label: "Programme de la semaine", icon: "pi pi-clock" },
    { label: "Séances de cours", icon: "pi pi-calendar" },
  ];

  return (
    <>
      <MeaPage
        title={
          <MeaPageTitle
            prefix={"Enseignant(e):"}
            title={
              teacherObj?.member?.name ||
              teacherObj?.member?.lastname + " " + teacherObj?.member?.firstname
            }
          />
        }
      >
        <MeaPageHeader>
          <TeacherDetailComponent teacher={teacherObj} />
          <TeacherDetailAvatarComponent teacher={teacherObj} />
        </MeaPageHeader>

        <div className="mt-5">
          <TabMenu
            model={items}
            activeIndex={activeItem}
            onTabChange={(e) => {
              setActiveItem(e.index);
            }}
          />
          {activeItem === 0 && (
            <MeaLoadingContent objectQuery={teacherQuery}>
              <ScheduleListComponent
                ref={listRef}
                filter={{ teacher_id: teacherObj?.id }}
                showAddButton={false}
                showFilterButton={true}
                selectedFields={{
                  teacher: false,
                }}
              />
            </MeaLoadingContent>
          )}
          {activeItem === 1 && (
            <MeaLoadingContent objectQuery={teacherQuery}>
              <SessionsListComponent
                ref={listRef}
                filter={{ teacher_id: teacherObj?.id }}
                showAddButton={false}
                showFilterButton={true}
                selectedFields={{
                  teacher: false,
                }}
              />
            </MeaLoadingContent>
          )}
        </div>
      </MeaPage>
    </>
  );
};
export default TeacherDetailPage;
