import React from "react";
import MeaPage from "../../../components/MeaPage";
import UserSchoolsListComponent from "./components/UserSchoolsListComponent";

const UserSchoolsDetailPage = () => {
  return (
    <>
      <MeaPage title="Mon écoles">
        <UserSchoolsListComponent />
      </MeaPage>
    </>
  );
};
export default UserSchoolsDetailPage;
