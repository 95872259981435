import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
) => {
  return useQuery(["system-schools", accessToken, page, pageSize], async () => {
    try {
      const response = await fetch(
        BACKEND_URL +
          `/api/v1/system/schools?page=${page}&pageSize=${pageSize}`,
        {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Erreur de récupération de la liste des écoles!");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  });
};

const GetOne = (schoolId, trainingId, accessToken, id) => {
  return useQuery(
    ["systemSchool", schoolId, trainingId, accessToken, id],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL + `/api/v1/system/schools/${id}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          },
        );

        if (!response.ok) {
          throw new Error("Erreur de récupération de l'école!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    },
  );
};

const CreateOne = async (schoolId, trainingId, accessToken, data) => {
  try {
    const response = await fetch(BACKEND_URL + "/api/v1/system/schools", {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur de création de l'école!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (schoolId, trainingId, accessToken, id, data) => {
  try {
    const response = await fetch(BACKEND_URL + "/api/v1/system/schools/" + id, {
      method: "PATCH",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur de modification de l'école!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, trainingId, accessToken, id) => {
  try {
    const response = await fetch(BACKEND_URL + "/api/v1/system/schools/" + id, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur de suppression de l'école!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const systemSchoolsAPI = {
  useGetList,
  GetOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
};

export default systemSchoolsAPI;
