import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import studentsAPI from "../../../../services/students";
import MeaStudentRegistrationAvatar from "../../../../components/MeaStudentRegistrationAvatar";
import MeaStudentAge from "../../../../components/MeaStudentAge";
import MeaLink from "../../../../components/MeaLink";
import { Dropdown } from "primereact/dropdown";
import levelsAPI from "../../../../services/levels";
import classesAPI from "../../../../services/classes";
import MeaStudentStatus2 from "../../../../components/MeaStudentStatus2";
import statisticsAPI from "../../../../services/statistics";
import { Badge } from "primereact/badge";
import { Toolbar } from "primereact/toolbar";
import { useNavigate } from "react-router-dom";
import ImportRegistrationsFromExcelModal from "../modals/ImportRegistrationsFromExcelModal";
import MeaStudentRegistrationOrigin from "../../../../components/MeaStudentRegistrationOrigin";

const RegistrationsListComponent = forwardRef((props, ref) => {
  const { showAddButton, filter, showFilterButton } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const navigate = useNavigate();
  //
  const importRegistrationsFromExcelModalRef = useRef();
  //
  const [selectedTab, setSelectedTab] = useState(0);
  const [state, setState] = useUrlState({
    studentsPage: "1",
    studentsPageSize: "30",
    studentsFilter: JSON.stringify({
      level_id: filter?.level_id || "",
      classe_id: filter?.classe_id || "",
      name: filter?.name || "",
      status: filter?.status || "",
    }),
  });
  const setFilter = (field, value) => {
    let currentFilter = state?.studentsFilter;
    let currentFilterObj = JSON.parse(currentFilter);
    currentFilterObj[field] = value;
    setState((s) => ({
      studentsPage: 1,
      studentsFilter: JSON.stringify(currentFilterObj),
    }));
  };
  //
  const [selectedStudent, setSelectedStudent] = useState(null);
  //
  const statsQuery = statisticsAPI.useGetStudentsRegistrationsStats(
    schoolId,
    trainingId,
    accessToken
  );

  //
  const queryList = studentsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    JSON.parse(state.studentsFilter)
  );
  const levelsQuery = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const classesQuery = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000,
    {
      level_id: JSON.parse(state.studentsFilter)?.level_id,
    }
  );
  //
  const setStudentStatus = useCallback(
    (status) => {
      setFilter("status", status || "");
    },
    [setState]
  );
  //
  const filterCombobox = (options, query, field) => {
    return (
      <Dropdown
        value={query?.data?.data?.find(
          (obj) => obj.id === JSON.parse(state.studentsFilter)[field]
        )}
        options={query?.data?.data}
        optionLabel="name"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
          setFilter(field, e.value?.id || "");
        }}
        //itemTemplate={statusItemTemplate}
        placeholder="Séléctionner"
        className="p-column-filter"
        showClear
      />
    );
  };
  //
  const setSearchName = (name) => {
    setFilter("name", name || "");
  };
  //
  useEffect(() => {
    switch (JSON.parse(state.studentsFilter)?.status) {
      case "":
        setSelectedTab(0);
        break;
      case "NEW":
        setSelectedTab(1);
        break;
      case "PREREGISTRED":
        setSelectedTab(2);
        break;
      case "ENROLLED":
        setSelectedTab(3);
        break;
      case "WAITING_LIST":
        setSelectedTab(4);
        break;
      case "DROPPEDOUT":
        setSelectedTab(5);
        break;
      default:
        setSelectedTab(0);
        break;
    }
  }, [state.studentsFilter]);
  //
  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      var selectedStatus = "";
      switch (selectedTabIndex) {
        case 0:
          selectedStatus = "";
          break;
        case 1:
          selectedStatus = "NEW";
          break;
        case 2:
          selectedStatus = "PREREGISTRED";
          break;
        case 3:
          selectedStatus = "ENROLLED";
          break;
        case 4:
          selectedStatus = "WAITING_LIST";
          break;
        case 5:
          selectedStatus = "DROPPEDOUT";
          break;
        default:
          selectedStatus = "";
          break;
      }
      setStudentStatus(selectedStatus);
    },
    [setStudentStatus]
  );

  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Ajouter un membre"
          icon="pi pi-plus"
          size="normal"
          className="p-button mr-2 action"
          onClick={() => {
            navigate("/c/t/" + schoolId + "/" + trainingId + "/new-registration");
          }}
          data-pr-tooltip="Nouveau membre"
        />
      )}
      <span className="p-input-icon-left mr-2 ">
        <i className="pi pi-search" />
        <InputText
          className="p-inputtext-sm"
          value={state.studentName}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder="Chercher un membre"
        />
      </span>

      <Button label="Pré-inscrire" size="small"
        className="p-button mr-2 action" />


    </div>
  );
  //
  const tabTemplate = (options, title, badgeValue, severity) => {
    return (
      <div
        className="flex align-items-center gap-2 p-3"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        <span className="white-space-nowrap">{title}</span>
        {(severity && <Badge value={badgeValue} severity={severity} />) || (
          <Badge value={badgeValue} className="bg-gray-500" />
        )}
      </div>
    );
  };
  //
  return (
    <>
      <Toolbar start={<>
        <React.Fragment>
          <Button icon="pi pi-user-plus" label="Nouveau dossier d'inscription" className="mr-2" onClick={() => {
            navigate("/c/t/" + schoolId + "/" + trainingId + "/new-registration");
          }} />
          <Button icon="pi pi-cloud-upload" label="Importer" className="mr-2" outlined onClick={() => {
            if (importRegistrationsFromExcelModalRef.current) {
              importRegistrationsFromExcelModalRef.current.show(() => {
                //callback?.();
              });
            }
          }} />
        </React.Fragment>
      </>} />
      <TabView
        activeIndex={selectedTab}
        onTabChange={(e) => {
          console.log("selected tab", e.index);
          handleTabChange(e.index);
        }}
      >
        <TabPanel
          headerTemplate={(options) =>
            tabTemplate(
              options,
              "Tous",
              statsQuery?.data?.data?.all_students_count,
              null
            )
          }
        ></TabPanel>
        <TabPanel
          headerTemplate={(options) =>
            tabTemplate(
              options,
              "Candidatures",
              statsQuery?.data?.data?.new_students_count,
              "contrast"
            )
          }
        ></TabPanel>
        <TabPanel
          headerTemplate={(options) =>
            tabTemplate(
              options,
              "Pré-inscriptions",
              statsQuery?.data?.data?.preregistred_students_count,
              "info"
            )
          }
        ></TabPanel>
        <TabPanel
          headerTemplate={(options) =>
            tabTemplate(
              options,
              "Inscriptions confirmée",
              statsQuery?.data?.data?.enrolled_students_count,
              "success"
            )
          }
        ></TabPanel>
        <TabPanel
          headerTemplate={(options) =>
            tabTemplate(
              options,
              "En liste d'attente",
              statsQuery?.data?.data?.waitinglist_students_count,
              "warning"
            )
          }
        ></TabPanel>
        <TabPanel
          headerTemplate={(options) =>
            tabTemplate(
              options,
              "Abondonnés",
              statsQuery?.data?.data?.droppedout_students_count,
              "danger"
            )
          }
        ></TabPanel>
      </TabView>
      <MeaDataTable
        query={queryList}
        serviceAPI={studentsAPI}
        names={{
          pagination: "students",
          singular: "élève",
          plural: "élèves",
        }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>

        <Column
          header="Elève"
          body={(student) => <MeaStudentRegistrationAvatar student={student} />}
        ></Column>

        <Column
          header="Age"
          field="age"
        ></Column>

        <Column
          field="level"
          header="Niveau"
          body={(item) => (
            <MeaLink
              to={
                "/c/t/" +
                schoolId +
                "/" +
                trainingId +
                "/levels/" +
                item?.level?.id
              }
            >
              {item?.level?.name}
            </MeaLink>
          )}
          filter
          showFilterMenu={false}
          showClearButton={false}
          filterElement={(options) =>
            filterCombobox(options, levelsQuery, "level_id")
          }
        ></Column>

        <Column
          field="classe"
          header="Classe"
          body={(item) => (
            <MeaLink
              to={
                "/c/t/" +
                schoolId +
                "/" +
                trainingId +
                "/classes/" +
                item?.classe?.id
              }
            >
              {item?.classe?.name}
            </MeaLink>
          )}
          filter
          showFilterMenu={false}
          showClearButton={false}
          filterElement={(options) =>
            filterCombobox(options, classesQuery, "classe_id")
          }
        ></Column>
        <Column
          field="status"
          header="Status"
          body={(item) => {
            return <MeaStudentStatus2 student={item} />;
          }}
        ></Column>
        <Column
          field="registration_origin"
          header="Dernier résultat"
          body={(item) => {
            return <MeaStudentRegistrationOrigin student={item} />;
          }}
        ></Column>
        <Column
          field="previous_training_result"
          header="Dernier niveau"
        > </Column>
        {/* 
        <Column
          body={(item) => (
            <>
              <Button
                icon="pi pi-pencil"
                rounded
                text
                aria-label="Filter"
                onClick={() => {
                  setSelectedStudent(item);
                  //if (updateModalRef.current) {
                  //  updateModalRef.current.show(() => {
                  //    queryList.refetch();
                  //  });
                  //}
                }}
              />
            </>
          )}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>*/}
      </MeaDataTable>
      <ImportRegistrationsFromExcelModal ref={importRegistrationsFromExcelModalRef} />
    </>
  );
});

export default RegistrationsListComponent;
