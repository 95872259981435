import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";
import { Tag } from "primereact/tag";

const MemberDetailExtraComponent = ({ member }) => {
  return (
    <MeaDetailCard>
      <MeaDetailField field="Identifiant" value={member?.id || "-"} />
      <MeaDetailField field="Email" value={member?.email || "-"} />
      <MeaDetailField field="Téléphone" value={member?.phone || "-"} />
      <MeaDetailField field="Adresse" value={member?.address || "-"} />
      <MeaDetailField
        field="Role"
        value={
          <div className="flex flex-wrap justify-content-start gap-1">
            {member?.is_owner && (
              <Tag value="Propriétaire" severity="info" rounded></Tag>
            )}
            {member?.is_admin && (
              <Tag value="Administration" severity="info" rounded></Tag>
            )}
            {member?.is_staff && (
              <Tag value="Secrétariat" severity="info" rounded></Tag>
            )}
            {member?.is_teacher && (
              <Tag value="Enseignant(e)" severity="info" rounded></Tag>
            )}
            {member?.is_parent && (
              <Tag value="Parent" severity="info" rounded></Tag>
            )}
            {member?.is_student && (
              <Tag value="Elève" severity="info" rounded></Tag>
            )}
          </div>
        }
      />
    </MeaDetailCard>
  );
};

export default MemberDetailExtraComponent;
