import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import slotsAPI from "../../../../services/slots";
import { Dropdown } from "primereact/dropdown";

const UpdateSlotModal = forwardRef((props, ref) => {
  //
  const [slotName, setSlotName] = useState("");
  const [slotWeekday, setSlotWeekday] = useState("1");
  const [slotStartHour, setSlotStartHour] = useState("");
  const [slotEndHour, setSlotEndHour] = useState("");

  //
  useEffect(() => {
    setSlotName(props?.slot?.name);
    setSlotWeekday(props?.slot?.weekday?.toString());
    setSlotStartHour(props?.slot?.start_hour);
    setSlotEndHour(props?.slot?.end_hour);
  }, [props]);
  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer le créneau"
        serviceAPI={slotsAPI}
        id={props?.slot?.id}
        data={{
          name: slotName,
          weekday: parseInt(slotWeekday),
          start_hour: slotStartHour,
          end_hour: slotEndHour,
        }}
      >
        <div className="w-full">
          <div className="flex flex-column gap-2">
            <label htmlFor="name" className="block text-900 font-medium mb-2">
              Nom du créneau
            </label>
            <InputText
              id="name"
              type="text"
              value={slotName}
              onChange={(e) => setSlotName(e.target.value)}
              className="w-full mb-3"
              autoFocus
            />
            <label htmlFor="day" className="block text-900 font-medium mb-2">
              Jour de la semaine
            </label>
            <Dropdown
              value={slotWeekday}
              onChange={(e) => setSlotWeekday(e.value)}
              options={[
                { label: "Lundi", value: "1" },
                { label: "Mardi", value: "2" },
                { label: "Mercredi", value: "3" },
                { label: "Jeudi", value: "4" },
                { label: "Vendredi", value: "5" },
                { label: "Samedi", value: "6" },
                { label: "Dimanche", value: "0" },
              ]}
              optionLabel="label"
              className="w-full mb-3"
            />
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="p-inputgroup flex flex-column gap-2">
                <label
                  htmlFor="starthour"
                  className="block text-900 font-medium mb-2"
                >
                  Heure de début
                </label>
                <InputText
                  id="starthour"
                  type="time"
                  value={slotStartHour}
                  onChange={(e) => setSlotStartHour(e.target.value)}
                  className="w-full mb-3"
                />
              </div>
              <div className="p-inputgroup flex flex-column gap-2">
                <label
                  htmlFor="endhour"
                  className="block text-900 font-medium mb-2"
                >
                  Heure de fin
                </label>
                <InputText
                  id="endhour"
                  type="time"
                  value={slotEndHour}
                  onChange={(e) => setSlotEndHour(e.target.value)}
                  className="w-full mb-3"
                />
              </div>
            </div>
          </div>
        </div>
      </MeaUpdateModal>
    </>
  );
});

export default UpdateSlotModal;
