import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";
import MeaStudentAge from "../../../../components/MeaStudentAge";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import MeaStudentStatus2 from "../../../../components/MeaStudentStatus2";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { BreadCrumb } from "primereact/breadcrumb";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const StudentDetailHeader = ({ student }) => {

    const menu11_1 = useRef(null);

    const getGender = () => {
        let gender = student?.gender;
        if (gender !== undefined) {
            if (gender === "G") {
                return "Fille";
            } else if (gender === "B") {
                return "Garçon";
            } else {
                return "-";
            }
        } else {
            return "-";
        }
    };

    return (
        <>
            <div className="surface-section px-4 py-5 md:px-3 lg:px-3">
                <div className="flex align-items-start flex-column lg:flex-row lg:justify-content-between">
                    <div className="flex align-items-start flex-column md:flex-row">
                        <div className="flex align-items-start mr-3">
                            <Button
                                icon="pi pi-arrow-left"
                                text
                                style={{ minHeight: "50px", maxWidth: "50px" }}
                                onClick={() => window.history.back()}
                            ></Button>
                            <img src={
                                student?.member?.avatar
                                    ? BACKEND_URL + "/api/v1/medias/" + student?.member?.avatar
                                    : "/user.png"
                            } className="mr-3 ml-3 mb-3 lg:mb-0" style={{ width: '90px', height: '90px', borderRadius: '50%' }} alt="Eleve" />
                        </div>
                        <div>
                            <span className="text-900 font-medium text-3xl uppercase">{
                                student?.member?.name ||
                                (student?.lastname || student?.member?.lastname || "") +
                                " " +
                                (student?.firstname || student?.member?.firstname || "")
                            }</span>
                            <span className="text-2xl ml-3 text-yellow-500"><MeaStudentStatus2 student={student} /></span>
                            <div className="flex align-items-center flex-wrap text-sm">
                                <div className="mr-5 mt-3">
                                    <span className="font-medium text-500">Nom</span>
                                    <div className="text-700 mt-2 uppercase">{student?.lastname || student?.member?.lastname || "-"}</div>
                                </div>
                                <div className="mr-5 mt-3">
                                    <span className="font-medium text-500">Prénom</span>
                                    <div className="text-700 mt-2 uppercase">{student?.firstname || student?.member?.firstname || "-"}</div>
                                </div>
                                <div className="mr-5 mt-3">
                                    <span className="font-medium text-500">Genre</span>
                                    <div className="text-700 mt-2">{getGender()}</div>
                                </div>
                                <div className="mr-5 mt-3">
                                    <span className="font-medium text-500">Age</span>
                                    <div className="text-700 mt-2">{student?.age || "-"}</div>
                                </div>
                                <div className="mr-5 mt-3">
                                    <span className="font-medium text-500">Origine de l'inscription</span>
                                    <div className="text-700 mt-2">{student?.registration_origin || "-"}</div>
                                </div>
                                <div className="mt-3">
                                    <span className="font-medium text-500">Immatriculation</span>
                                    <div className="text-700 mt-2">{student?.member?.id || "-"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 lg:mt-0">
                        <Button type="button" icon="pi pi-ellipsis-v" className="p-button-text p-button-rounded" onClick={(event) => menu11_1.current.toggle(event)} />
                        <Menu ref={menu11_1} popup model={[
                            { label: "Javascript" },
                            { label: "CSS" },
                            { label: "Testing" },
                        ]} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentDetailHeader;
