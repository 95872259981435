import { useNavigate, useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import MeaPageTitle from "../../../components/MeaPageTitle";
import { useRef, useState } from "react";
import SessionDetailComponent from "./components/SessionDetailComponent";
import sessionsAPI from "../../../services/sessions";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToFormatedDateWithWeekday } from "../../../utils/dates";
import UpdateSessionModal from "./components/UpdateSessionModal";
import { Toast } from "primereact/toast";
import { Panel } from "primereact/panel";
import { TabMenu } from "primereact/tabmenu";
import MeaLoadingContent from "../../../components/MeaLoadingContent";
import AttendancesListComponent from "../../acedemiclife/attendances/components/AttendancesListComponent";

const SessionDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const updateModalRef = useRef();
  const toast = useRef();
  const navigate = useNavigate();
  const sessionId = params.sessionId;
  //
  const sessionQuery = sessionsAPI.GetOne(
    schoolId,
    trainingId,
    accessToken,
    sessionId
  );
  const sessionObj = sessionQuery?.data?.data;
  //
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [{ label: "Feuille de présence", icon: "pi pi-th-large" }];
  //
  return (
    <>
      <MeaPage
        title={
          <MeaPageTitle
            prefix={"Séance de cours: "}
            title={ToFormatedDateWithWeekday(
              new Date(sessionObj?.event?.start_date)
            )}
          />
        }
        actions={[
          {
            label: "Modifier",
            icon: "pi pi-file-edit",
            command: () => {
              if (updateModalRef.current) {
                updateModalRef.current.show(() => {
                  sessionQuery.refetch();
                });
              }
            },
          },
          {
            separator: true,
          },
          {
            label: "Supprimer",
            icon: "pi pi-trash",
            command: () => {
              confirmDialog({
                message: "Voulez-vous vraiment supprimer cette session?",
                header: "Confirmation",
                icon: "pi pi-info-circle",
                defaultFocus: "reject",
                acceptClassName: "p-button-danger",
                accept: () => {
                  sessionsAPI
                    .DeleteOne(schoolId, trainingId, accessToken, sessionId)
                    .then((data) => {
                      toast.current.show({
                        severity: "success",
                        summary: "Succès",
                        detail: sessionObj?.name + " Supprimé!",
                        life: 3000,
                      });
                      navigate(
                        "/c/t/" + schoolId + "/" + trainingId + "/sessions"
                      );
                    })
                    .catch((error) => {
                      toast.current.show({
                        severity: "error",
                        summary: "Erreur",
                        detail: "Erreur de suppression!",
                      });
                    });
                },
              });
            },
          },
        ]}
      >
        <SessionDetailComponent session={sessionObj} />

        <UpdateSessionModal ref={updateModalRef} session={sessionObj} />
        <ConfirmDialog />
        <Toast ref={toast} />

        <Panel className="mt-5">
          <TabMenu
            model={tabs}
            activeIndex={activeTab}
            onTabChange={(e) => {
              setActiveTab(e.index);
            }}
          />
          {activeTab === 0 && (
            <MeaLoadingContent objectQuery={sessionQuery}>
              <AttendancesListComponent
                showAddButton={true}
                showFilterButton={false}
                showToolbar={true}
                session={sessionObj}
              />
            </MeaLoadingContent>
          )}
        </Panel>
      </MeaPage>
    </>
  );
};
export default SessionDetailPage;
