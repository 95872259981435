import { useNavigate, useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import MeaPageTitle from "../../../components/MeaPageTitle";
import MeaPageHeader from "../../../components/MeaPageHeader";
import membersAPI from "../../../services/members";
import MemberDetailComponent from "./components/MemberDetailComponent";
import MemberDetailAvatarComponent from "./components/MemberDetailAvatarComponent";
import MemberDetailExtraComponent from "./components/MemberDetailExtraComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import UpdateMemberModal from "./components/UpdateMemberModal";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import UpdateMemberAvatarModal from "./components/UpdateMemberAvatarModal";
import UpdateMemberRolesModal from "./components/UpdateMemberRolesModal";
import { TabMenu } from "primereact/tabmenu";
import MeaLoadingContent from "../../../components/MeaLoadingContent";
import MemberRegistrationsListComponent from "./components/MemberRegistrationsListComponent";
const MemberDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const updateModalRef = useRef();
  const updateRolesModalRef = useRef()
  const updateAvatarModalRef = useRef();
  const toast = useRef();
  const navigate = useNavigate();
  const memberId = params.memberId;
  //
  const memberQuery = membersAPI.GetOne(schoolId, accessToken, memberId);
  const memberObj = memberQuery?.data?.data;
  //
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { label: "Inscriptions", icon: "pi pi-user" },
  ];
  //
  return (
    <>
      <MeaPage
        title={
          <MeaPageTitle
            prefix={"Membre:"}
            title={
              memberObj?.name ||
              memberObj?.lastname + " " + memberObj?.firstname
            }
          />
        }
        actions={[
          {
            label: "Modifier le profile",
            icon: "pi pi-file-edit",
            command: () => {
              if (updateModalRef.current) {
                updateModalRef.current.show(() => {
                  memberQuery.refetch();
                });
              }
            },
          },
          {
            label: "Modifier les roles",
            icon: "pi pi-sitemap",
            command: () => {
              if (updateRolesModalRef.current) {
                updateRolesModalRef.current.show(() => {
                  memberQuery.refetch();
                });
              }
            },
          },
          {
            separator: true,
          },
          {
            label: "Changer l'image",
            icon: "pi pi-image",
            command: () => {
              if (updateAvatarModalRef.current) {
                updateAvatarModalRef.current.show(() => {
                  memberQuery.refetch();
                });
              }
            },
          },
          {
            label: "Supprimer l'image",
            icon: "pi pi-times",
            command: () => {
              confirmDialog({
                message: "Voulez-vous vraiment supprimer l'image de ce membre?",
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                acceptLabel: "Oui",
                rejectLabel: "Non",
                accept: () => {
                  membersAPI.DeleteAvatar(schoolId, accessToken, memberId)
                    .then(data => {
                      toast.current.show({ severity: 'success', summary: 'Succès', detail: memberObj?.name + " Retiré!", life: 3000 });
                      memberQuery.refetch();
                    })
                    .catch(error => {
                      toast.current.show({
                        severity: "error",
                        summary: "Erreur",
                        detail: "Erreur de suppression!",
                      });
                    });
                },
              })
            },
          },
          {
            separator: true,
          },
          {
            label: "Retirer de l'école",
            icon: "pi pi-trash",
            command: () => {
              confirmDialog({
                message: "Voulez-vous vraiment retirer ce membre de l'école?",
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                acceptLabel: "Oui",
                rejectLabel: "Non",
                accept: () => {
                  membersAPI.DeleteOne(schoolId, accessToken, memberId)
                    .then(data => {
                      toast.current.show({ severity: 'success', summary: 'Succès', detail: memberObj?.name + " Retiré!", life: 3000 });
                      navigate("/c/s/" + schoolId + "/members");
                    })
                    .catch(error => {
                      toast.current.show({
                        severity: "error",
                        summary: "Erreur",
                        detail: "Erreur de suppression!",
                      });
                    });
                },
              })
            }
          },
        ]}
      >

        <UpdateMemberModal ref={updateModalRef} member={memberObj} />
        <UpdateMemberRolesModal ref={updateRolesModalRef} member={memberObj} />
        <UpdateMemberAvatarModal ref={updateAvatarModalRef} member={memberObj} />
        <ConfirmDialog />
        <Toast ref={toast} />

        <MeaPageHeader>
          <MemberDetailComponent member={memberObj} />
          <MemberDetailExtraComponent member={memberObj} />
          <MemberDetailAvatarComponent member={memberObj} />
        </MeaPageHeader>
        <div className="mt-5">
          <TabMenu
            model={tabs}
            activeIndex={activeTab}
            onTabChange={(e) => setActiveTab(e.index)} />
          {activeTab === 0 && (<>
            <MeaLoadingContent objectQuery={memberQuery}>
              <MemberRegistrationsListComponent member={memberObj} />
            </MeaLoadingContent>
          </>)}
        </div>
      </MeaPage>
    </>
  );
};
export default MemberDetailPage;
