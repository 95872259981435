import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
  schoolId,
  accessToken,
  page,
  pageSize,
  memberType = "",
  name = ""
) => {
  if (memberType === "all") {
    memberType = "";
  }
  return useQuery(
    ["teachers", schoolId, accessToken, page, pageSize, memberType, name],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
          `/api/v1/schools/${schoolId}/members?page=${page}&pageSize=${pageSize}&type=${memberType}&name=${name}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch members!");
        }
        if (response.status >= 299) {
          throw new Error("Erreur de récupération de la liste des membres!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        // Handle the error
        console.error("Error fetching member:", error);
        throw error;
      }
    }
  );
};

const GetOne = (schoolId, accessToken, memberId) => {
  return useQuery(["slot", schoolId, accessToken, memberId], async () => {
    try {
      const response = await fetch(
        BACKEND_URL + `/api/v1/schools/${schoolId}/members/${memberId}`,
        {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Erreur de récupération du membre!");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  });
};

const CreateOne = async (schoolId, dummyTrainingId, accessToken, data) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/schools/" + schoolId + "/members",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur d'ajout' du membre!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (schoolId, trainingId, accessToken, memberId, data) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/schools/" + schoolId + "/members/" + memberId,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification du membre!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateRoles = async (
  schoolId,
  accessToken,
  memberId,
  isAdmin,
  isStaff,
  isTeacher,
  isStudent
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/members/" +
      memberId +
      "/roles",
      {
        method: "PATCH",
        body: JSON.stringify({
          is_admin: isAdmin,
          is_staff: isStaff,
          is_teacher: isTeacher,
          is_student: isStudent,
        }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification du membre!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateAvatar = async (schoolId, accessToken, memberId, newAvatar) => {
  try {
    let formData = new FormData();
    formData.append("file", newAvatar);
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/members/" +
      memberId +
      "/avatar",
      {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification de l'image du membre!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteAvatar = async (schoolId, accessToken, memberId) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/members/" +
      memberId +
      "/avatar",
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression de l'image du membre!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, accessToken, memberId) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/schools/" + schoolId + "/members/" + memberId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression du membre!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const useSearchStudentMembers = (schoolId, accessToken, page = 0, pageSize = 10, filter) => {
  return useQuery(
    ["search-student-members", schoolId, accessToken, page, pageSize, filter],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL + `/api/v1/schools/${schoolId}/search-student-members?member_id=${filter?.id || ""}&lastname=${filter?.lastname || ""}&firstname=${filter?.firstname || ""}&email=${filter?.email || ""}&birthday=${filter?.birthday || ""}&page=${page}&pageSize=${pageSize}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Erreur de chercher des members!`);
        }
        let actualData = await response.json();
        return actualData;
      } catch (err) {
        throw err;
      }
    }
  );
};

const GetMemberTrainingsRegistrations = async (schoolId, accessToken, memberId) => {
  try {
    const response = await fetch(
      BACKEND_URL + `/api/v1/schools/${schoolId}/members/${memberId}/trainings-registrations`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de chercher des inscriptions!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};


const membersAPI = {
  useGetList,
  GetOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
  UpdateAvatar,
  DeleteAvatar,
  UpdateRoles,
  useSearchStudentMembers,
  GetMemberTrainingsRegistrations,
};

export default membersAPI;
