import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import { InputText } from "primereact/inputtext";
import CreateClassroomModal from "./CreateClassroomModal";
import classroomsAPI from "../../../../services/classrooms";
import UpdateClassroomModal from "./UpdateClassroomModal";

const ClassroomsListComponent = forwardRef((props, ref) => {
  const { selectedFields, showAddButton } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  let selectedData = {
    name: selectedFields?.name ?? true,
    presentation: selectedFields?.age ?? true,
    capacity: selectedFields?.level ?? true,
    edit: selectedFields?.edit ?? true,
  };
  //
  const [classroom, setClassroom] = useState(null);
  //
  //
  const queryList = classroomsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Créer une salle"
          icon="pi pi-plus"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouvelle salle"
        />
      )}
    </div>
  );
  //
  return (
    <>
      <CreateClassroomModal ref={createModalRef} />
      <MeaDataTable
        query={queryList}
        serviceAPI={classroomsAPI}
        names={{ pagination: "classroom", singular: "salle", plural: "salles" }}
        startToolbarTemplate={startToolbarTemplate}
        //showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        {selectedData.name && (
          <Column
            field="name"
            header="Salle"
            editor={(options) => (
              <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
              />
            )}
            body={(item) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/classrooms/" +
                  item.id
                }
              >
                {item.name}
              </MeaLink>
            )}
          ></Column>
        )}
        {selectedData.presentation && (
          <Column field="presentation" header="Presentation"></Column>
        )}
        {selectedData.capacity && (
          <Column
            field="capacity"
            header="Capacité"
            style={{ width: "20%" }}
            editor={(options) => (
              <InputText
                type="number"
                value={options.value}
                onChange={(e) => options.editorCallback(Number(e.target.value))}
              />
            )}
          ></Column>
        )}
        {selectedData.edit && (
          <Column
            body={(item) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  text
                  aria-label="Filter"
                  onClick={() => {
                    setClassroom(item);
                    if (updateModalRef.current) {
                      updateModalRef.current.show(() => {
                        queryList.refetch();
                      });
                    }
                  }}
                />
              </>
            )}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        )}
      </MeaDataTable>
      <UpdateClassroomModal ref={updateModalRef} classroom={classroom} />
    </>
  );
});

export default ClassroomsListComponent;
