import React, { useState, forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ToFormatedISOTime } from "../../../../utils/dates";
import teachersAPI from "../../../../services/teachers";
import coursesAPI from "../../../../services/courses";
import classesAPI from "../../../../services/classes";
import useAuthContext from "../../../../hooks/useAuthContext";
import classroomsAPI from "../../../../services/classrooms";
import MeaCreateModal from "../../../../components/MeaCreateModal";
import sessionsAPI from "../../../../services/sessions";

const CreateSessionModal = forwardRef((props, ref) => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  const timezone = authContext?.currentMembership?.school?.timezone;
  //
  const [classe, setClasse] = useState("-1");
  const [course, setCours] = useState("-1");
  const [teacher, setTeacher] = useState("-1");
  const [classroom, setClassroom] = useState("-1");
  const [day, setDay] = useState("");
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");

  // Classes
  const classesList = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueClasses = classesList.data?.data || [];
  uniqueClasses.sort((a, b) => a.name);
  const classes = uniqueClasses.map((classe, index) => ({
    key: classe.id,
    label: classe.name,
    value: classe.id,
  }));
  classes.unshift({
    key: "empty-class-key",
    label: "",
    value: "",
  });
  // Courses
  const coursesList = coursesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueCourses = coursesList.data?.data || [];
  uniqueCourses.sort((a, b) => a.name);
  const courses = uniqueCourses.map((course, index) => ({
    key: course.id,
    label: course.name,
    value: course.id,
  }));
  courses.unshift({
    key: "empty-course-key",
    label: "",
    value: "",
  });

  // Teachers
  const teachersList = teachersAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueTeachers = teachersList.data?.data || [];
  uniqueTeachers.sort((a, b) => a.member.lastname - b.member.lastname);
  const teachers = uniqueTeachers.map((teacher, index) => ({
    key: teacher.id,
    label: teacher.member.lastname + " " + teacher.member.firstname,
    value: teacher.id,
  }));
  teachers.unshift({
    key: "empty-teacher-key",
    label: "",
    value: "",
  });
  // Classroom
  const classroomsList = classroomsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueClassrooms = classroomsList.data?.data || [];
  uniqueClassrooms.sort((a, b) => a.name - b.name);
  const classrooms = uniqueClassrooms.map((classroom, index) => ({
    key: classroom.id,
    label: classroom.name,
    value: classroom.id,
  }));
  classrooms.unshift({
    key: "empty-classroom-key",
    label: "",
    value: "",
  });
  //
  return (
    <>
      <MeaCreateModal
        ref={ref}
        title="Créer une session"
        serviceAPI={sessionsAPI}
        data={{
          course_id: course === "-1" ? "" : course,
          class_id: classe === "-1" ? "" : classe,
          teacher_id: teacher === "-1" ? "" : teacher,
          classroom_id: classroom === "-1" ? "" : classroom,
          start_date: ToFormatedISOTime(day, startHour, timezone),
          end_date: ToFormatedISOTime(day, endHour, timezone),
        }}
      >
        <label htmlFor="jour" className="block text-900 font-medium mb-2">
          Jour
        </label>
        <InputText
          id="jour"
          type="date"
          value={day}
          onChange={(e) => setDay(e.target.value)}
          className="w-full mb-3"
        />

        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="heureDebut"
              className="block text-900 font-medium mb-2"
            >
              Heure de début
            </label>
            <InputText
              id="heureDebut"
              type="time"
              value={startHour}
              onChange={(e) => setStartHour(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="heureFin"
              className="block text-900 font-medium mb-2"
            >
              Heure de fin
            </label>
            <InputText
              id="heureFin"
              type="time"
              value={endHour}
              onChange={(e) => setEndHour(e.target.value)}
              className="w-full mb-3"
            />
          </div>
        </div>
        <small>
          Les heures doivent correspondre au fuseau horaire de l'école (
          {timezone})
        </small>

        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label htmlFor="classe" className="block text-900 font-medium mb-2">
              Classe
            </label>
            <Dropdown
              id="classe"
              options={classes}
              value={classe || "-1"}
              onChange={(e) => setClasse(e.value)}
              optionLabel="label"
              placeholder="Select a Classe"
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label htmlFor="cours" className="block text-900 font-medium mb-2">
              Cours
            </label>
            <Dropdown
              id="cours"
              options={courses}
              value={course || "-1"}
              onChange={(e) => setCours(e.value)}
              optionLabel="label"
              placeholder="Select a Course"
              className="w-full mb-3"
            />
          </div>
        </div>

        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="enseignant"
              className="block text-900 font-medium mb-2"
            >
              Enseignant(e)
            </label>
            <Dropdown
              id="enseignant"
              options={teachers}
              value={teacher || "-1"}
              onChange={(e) => setTeacher(e.value)}
              optionLabel="label"
              placeholder="Select a Teacher"
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label htmlFor="salle" className="block text-900 font-medium mb-2">
              Salle
            </label>
            <Dropdown
              id="salle"
              options={classrooms}
              value={classroom || "-1"}
              onChange={(e) => setClassroom(e.value)}
              optionLabel="label"
              placeholder="Select a Classroom"
              className="w-full mb-3"
            />
          </div>
        </div>
      </MeaCreateModal>
    </>
  );
});

export default CreateSessionModal;
