import { Skeleton } from "primereact/skeleton";
const MeaPageTitle = ({ prefix, title, subTitle, children }) => {
  return (
    <div className="flex align-items-center justify-content-start">
      <span className="inline-block mr-2">{prefix}</span>
      {title ? (
        <span className="inline-block">{title}</span>
      ) : (
        <Skeleton width="10rem" className="inline-block mb-2"></Skeleton>
      )}
    </div>
  );
};
export default MeaPageTitle;
