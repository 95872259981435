import { Badge } from "primereact/badge";
import { Tag } from "primereact/tag";

const MeaStudentResult = ({ student, previousResult = false }) => {
  let sResult = previousResult
    ? student?.previous_training_result
    : student?.result;
  return (
    <>
      {sResult === "ONGOING" && (
        <Tag value="En cours" severity="info"></Tag>
      )}
      {sResult === "PASSED" && (
        <Tag value="Réussi" severity="success"></Tag>
      )}
      {sResult === "FAILED" && (
        <Tag value="Échoué" severity="danger"></Tag>
      )}
    </>
  );
};
export default MeaStudentResult;
