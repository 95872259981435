import React, { useState, useEffect, useCallback } from "react";
import useAuthContext from "../hooks/useAuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Badge } from "primereact/badge";
import { Ripple } from "primereact/ripple";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default function MeaSidebar({
  showUserMenu = false,
  showSchoolMenu = false,
  showTrainingMenu = false,
  showRegistrationMenu = false,
  showAcademicLifeMenu = false,
  showSuperAdminMenu = false,
  expandUserMenu = false,
  expandSchoolMenu = false,
  expandTrainingMenu = false,
  expandRegistrationMenu = false,
  expandAcademicLifeMenu = false,
}) {
  const { currentAccount, currentMembership } = useAuthContext();
  const [menuModel, setMenuModel] = useState([]);
  const navigate = useNavigate();
  //
  const params = useParams();
  const schoolId = params.schoolId;
  const trainingId = params.trainingId;
  //

  const itemRenderer = useCallback(
    (item, options) => {
      return (
        <a
          className="flex align-items-center px-3 py-2 cursor-pointer"
          onClick={() => {
            navigate(item.url);
          }}
        >
          <span className={`${item.icon} text-primary`} />
          <span className={`mx-2 ${item.items && "font-semibold"}`}>
            {item.label}
          </span>
          {item.badge && <Badge className="ml-auto" value={item.badge} />}
          {item.shortcut && (
            <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
              {item.shortcut}
            </span>
          )}
        </a>
      );
    },
    [navigate],
  );

  const UserMenuItems = useCallback(
    () => ({
      id: "i0",
      label: "UTILISATEUR",
      icon: "pi pi-user",
      expanded: expandUserMenu,
      items: [
        {
          id: "i0_1",
          label: "Mon profil utilisateur",
          url: "/c/user/profile",
          icon: "pi pi-user",
          template: itemRenderer,
        },
        {
          id: "i0_2",
          label: "",
          url: "/c/user/schools",
          icon: "pi pi-home",
          template: itemRenderer,
        },
      ],
    }),
    [expandUserMenu, itemRenderer],
  );

  const SchoolMenuItems = useCallback(
    (schoolId) => ({
      id: "i1",
      label: "L'ECOLE",
      icon: "pi pi-home",
      expanded: expandSchoolMenu,
      items: [
        {
          id: "i1.1",
          label: "L'école",
          url: "/c/s/" + schoolId,
          icon: "pi pi-home",
          template: itemRenderer,
        },
        {
          id: "i1.2",
          label: "Formations",
          url: "/c/s/" + schoolId + "/trainings",
          icon: "pi pi-book",
          template: itemRenderer,
        },
        // {
        //   id: "i1.2",
        //   label: "Listes d'attente",
        //   url: "/c/s/" + schoolId + "/queues",
        //   icon: "pi pi-clock",
        //   template: itemRenderer,
        // },
        {
          id: "i1.3",
          label: "Comptes utilisateurs",
          url: "/c/s/" + schoolId + "/members",
          icon: "pi pi-users",
          template: itemRenderer,
        },
      ],
    }),
    [expandSchoolMenu, itemRenderer],
  );

  const TrainingMenuItems = useCallback(
    (schoolId, trainingId) => ({
      id: "i2",
      label: "GESTION DE LA FORMATION",
      expanded: expandTrainingMenu,
      icon: "pi pi-book",
      items: [
        {
          id: "i2_333",
          label: "La formation",
          url: "/c/t/" + schoolId + "/" + trainingId + "/details",
          icon: "pi pi-book",
          template: itemRenderer,
        },

        {
          id: "i2_3",
          label: "Niveaux",
          url: "/c/t/" + schoolId + "/" + trainingId + "/levels",
          icon: "pi pi-sliders-v",
          template: itemRenderer,
        },
        {
          id: "i2_6",
          label: "Classes",
          url: "/c/t/" + schoolId + "/" + trainingId + "/classes",
          icon: "pi pi-th-large",
          template: itemRenderer,
        },
        {
          id: "i2_1",
          label: "Créneaux",
          url: "/c/t/" + schoolId + "/" + trainingId + "/slots",
          icon: "pi pi-bolt",
          template: itemRenderer,
        },
        {
          id: "i2_2",
          label: "Salles",
          url: "/c/t/" + schoolId + "/" + trainingId + "/classrooms",
          icon: "pi pi-map",
          template: itemRenderer,
        },
        {
          id: "i2_4",
          label: "Cours",
          url: "/c/t/" + schoolId + "/" + trainingId + "/courses",
          icon: "pi pi-file-edit",
          template: itemRenderer,
        },
        {
          id: "i2_5",
          label: "Enseignants",
          url: "/c/t/" + schoolId + "/" + trainingId + "/teachers",
          icon: "pi pi-user-edit",
          template: itemRenderer,
        },

        {
          id: "i2_10",
          label: "Programme de la semaine",
          icon: "pi pi-clock",
          url: "/c/t/" + schoolId + "/" + trainingId + "/schedule",
          template: itemRenderer,
        },
        {
          id: "i2_9",
          label: "Evènements",
          icon: "pi pi-calendar-plus",
          url: "/c/t/" + schoolId + "/" + trainingId + "/events",
          template: itemRenderer,
        },
        {
          id: "i2_11",
          label: "Emplois du temps",
          icon: "pi pi-calendar",
          url: "/c/t/" + schoolId + "/" + trainingId + "/sessions",
          template: itemRenderer,
        },
        {
          id: "i2_9",
          label: "Calendrier",
          icon: "pi pi-calendar",
          url: "/c/t/" + schoolId + "/" + trainingId + "/planning",
          template: itemRenderer,
        },
      ],
    }),
    [expandTrainingMenu, itemRenderer],
  );
  //
  const RegistrationMenuItems = useCallback(
    (schoolId, trainingId) => ({
      id: "i5",
      label: "INSCRIPTIONS",
      expanded: expandRegistrationMenu,
      icon: "pi pi-book",
      items: [
        {
          id: "i5_7",
          label: "Inscriptions",
          url: "/c/t/" + schoolId + "/" + trainingId + "/registrations",
          icon: "pi pi-users",
          template: itemRenderer,
        },
        {
          id: "i5_7",
          label: "Cotisations",
          url: "/c/t/" + schoolId + "/" + trainingId + "/registrations",
          icon: "pi pi-users",
          template: itemRenderer,
        },
      ],
    }),
    [expandTrainingMenu, itemRenderer],
  );
  //
  const AcademicLifeMenuItems = useCallback(
    (schoolId, trainingId) => ({
      id: "i3",
      label: "VIE SCOLAIRE",
      expanded: expandAcademicLifeMenu,
      icon: "pi pi-book",
      items: [
        {
          id: "i2_7",
          label: "Elèves",
          url: "/c/a/" + schoolId + "/" + trainingId + "/students",
          icon: "pi pi-users",
          template: itemRenderer,
        },
        {
          id: "i2_7",
          label: "Parents",
          url: "/c/a/" + schoolId + "/" + trainingId + "/parents",
          icon: "pi pi-users",
          template: itemRenderer,
        },
        {
          id: "i3_1",
          label: "Les feuilles de présence",
          url: "/c/a/" + schoolId + "/" + trainingId + "/attendances",
          icon: "pi pi-book",
          template: itemRenderer,
        },
      ],
    }),
    [expandTrainingMenu, itemRenderer],
  );

  const SuperAdminMenuItems = useCallback(
    (schoolId, trainingId) => ({
      id: "i6",
      label: "SUPERADMIN",
      expanded: true,
      icon: "pi pi-book",
      items: [
        {
          id: "i6_1",
          label: "Ecoles",
          url: "/c/sys/schools",
          icon: "pi pi-home",
          template: itemRenderer,
        },
        {
          id: "i6_2",
          label: "Comptes utilisateurs",
          url: "/c/sys/accounts",
          icon: "pi pi-users",
          template: itemRenderer,
        },
        {
          id: "i6_3",
          label: "Superadmins",
          url: "/c/sys/superadmins",
          icon: "pi pi-users",
          template: itemRenderer,
        },
        {
          id: "i6_4",
          label: "Fonctionalités",
          url: "/c/sys/features",
          icon: "pi pi-cog",
          template: itemRenderer,
        },
      ],
    }),
    [expandTrainingMenu, itemRenderer],
  );
  //
  useEffect(() => {
    let menus = [];
    if (showTrainingMenu === true && trainingId !== undefined) {
      menus.push(TrainingMenuItems(schoolId, trainingId));
    }
    if (showRegistrationMenu === true && trainingId !== undefined) {
      menus.push(RegistrationMenuItems(schoolId, trainingId));
    }
    if (showAcademicLifeMenu === true && trainingId !== undefined) {
      menus.push(AcademicLifeMenuItems(schoolId, trainingId));
    }
    if (showSchoolMenu === true && schoolId !== undefined) {
      menus.push(SchoolMenuItems(schoolId));
    }
    if (showUserMenu === true) {
      menus.push(UserMenuItems());
    }
    if (showSuperAdminMenu === true && currentAccount?.superadmin) {
      menus.push(SuperAdminMenuItems());
    }
    setMenuModel(menus);
  }, [
    showSchoolMenu,
    showTrainingMenu,
    showAcademicLifeMenu,
    schoolId,
    trainingId,
    SchoolMenuItems,
    TrainingMenuItems,
  ]);

  const _logo = currentMembership?.school?.avatar
    ? BACKEND_URL + "/api/v1/medias/" + currentMembership?.school.avatar
    : "/mea-logo.png";
  const start = (
    <div className="flex align-items-stretch">
      <div className="flex align-items-center justify-content-center">
        <img alt="logo" src={_logo} height="40" className="mr-2"></img>
      </div>
      <div className="flex align-items-center justify-content-center font-bold">
        {currentMembership?.school?.name}
      </div>
    </div>
  );

  return (
    <div
      id="app-sidebar-2"
      className="shadow-2 surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none"
      style={{ width: "250px" }}
    >
      <div className="flex flex-column h-full">
        <div
          className="flex align-items-center px-4 flex-shrink-0"
          style={{ height: "60px" }}
        >
          <img src={_logo} alt="MEA" height={40} />
        </div>
        <div className="overflow-y-auto">
          {menuModel.map((item, index) => (
            <ul
              key={index}
              className="list-none p-2 m-0 border-top-1 surface-border"
            >
              <li>
                <div className="p-ripple p-3 pl-2  flex align-items-center justify-content-between text-600 cursor-pointer">
                  <span className="font-medium text-sm">{item.label}</span>
                  {/*<i className="pi pi-chevron-down"></i>*/}
                </div>
                {item.items && (
                  <ul className="list-none p-0 m-0 overflow-hidden">
                    {item.items.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={subItem.url}
                          className="no-underline hover:no-underline text-color hover:underline"
                        >
                          <span className="p-ripple flex align-items-center cursor-pointer p-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                            <i className={`pi pi-${subItem.icon} mr-2`}></i>
                            <span className="font-medium">{subItem.label}</span>
                            <Ripple />
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          ))}
        </div>
        <div className="mt-auto mx-3">
          <hr className="mb-3 border-top-1 surface-border" />
          <a className="p-ripple my-3 flex align-items-center cursor-pointer p-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
            <span className="text-xs">v1.0.0</span>
            <Ripple />
          </a>
        </div>
      </div>
    </div>
  );
}
