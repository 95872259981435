import { useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import studentsAPI from "../../../services/students";
import { useRef, useState } from "react";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import StudentDetailHeader from "../students/components/StudentDetailHeader";
import StudentRegistrationFormComponent from "./components/StudentRegistrationFormComponent";
import RegistrationStepsComponent from "./components/RegistrationStepsComponent";

const RegistrationDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const studentId = params.studentId;
  //
  const studentQuery = studentsAPI.GetOne(
    schoolId,
    trainingId,
    accessToken,
    studentId
  );
  const studentObj = studentQuery?.data?.data;
  // states
  const [activeItem, setActiveItem] = useState(0);
  const listRef = useRef();
  // params
  const items = [
    { label: "Emplois du temps", icon: "pi pi-clock" },
    { label: "Séances de cours", icon: "pi pi-calendar" },
    { label: "Dossier d'inscription", icon: "pi pi-book" },
    { label: "Famille", icon: "pi pi-users" },
    { label: "Evaluations", icon: "pi pi-align-center" },
    { label: "Remarques", icon: "pi pi-flag-fill" },
    { label: "Historiques", icon: "pi pi-history" },
  ];

  return (
    <>
      <MeaPage
        header={<StudentDetailHeader student={studentObj} />}
      >
        <RegistrationStepsComponent student={studentObj}
          callback={() => {
            studentQuery?.refetch()
          }} />

        <div className="mt-5">
          <TabView>
            <TabPanel header="Dossier d'inscription" leftIcon="pi pi-book mr-2">
              <div className="m-0">
                <StudentRegistrationFormComponent
                  student={studentObj}
                  callback={() => {
                    studentQuery?.refetch()
                  }}
                />
              </div>
            </TabPanel>

          </TabView>
          {/* 
         <TabMenu
            model={items}
            activeIndex={activeItem}
            onTabChange={(e) => {
              setActiveItem(e.index);
            }}
          />
          {activeItem === 0 && (
            <MeaLoadingContent objectQuery={studentQuery}>
              <ScheduleListComponent
                ref={listRef}
                filter={{ classe_id: studentObj?.class_id }}
                showAddButton={false}
                showFilterButton={true}
                selectedFields={{
                  classe: false,
                }}
              />
            </MeaLoadingContent>
          )}
          {activeItem === 1 && (
            <MeaLoadingContent objectQuery={studentQuery}>
              <SessionsListComponent
                ref={listRef}
                filter={{ classe_id: studentObj?.class_id }}
                showAddButton={false}
                showFilterButton={true}
                selectedFields={{
                  classe: false,
                }}
              />
            </MeaLoadingContent>
          )}
         */}
        </div>
      </MeaPage>
    </>
  );
};
export default RegistrationDetailPage;
