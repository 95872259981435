import React, { useRef, useState } from "react";
import moment from "moment";
//import DayCoursesModal from "./DayCoursesModal";
import useAuthContext from "../../../../hooks/useAuthContext";
import MeaEvenTypeBadge from "../../../../components/MeaEvenTypeBadge";
import DayCoursesModal from "./DayCoursesModal";

const CustomMonthCalendar = ({ month, dates, events }) => {
  // Function to generate a unique key for each date
  const generateUniqueKey = (date, index) => `${date.valueOf()}_${index}`;
  const listSessionModalRef = useRef();
  const [event, setEvent] = useState(null)

  const { authContext } = useAuthContext();
  const trainingId = authContext?.currentTraining?.id;

  const css = `
    .year-planner {
        text-align: left;
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 20px 0;
    }

    .month {
        padding: 5px;
        width: 100%;
        /* Updated to full width for mobile and larger screens */
    }

    .month table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
    }

    .month th {
        padding: 5px;
        text-align: center;
        background-color: #673ab7;
        color: #FFFFFE
    }
    
    .month td {
        padding: 5px;
        border: 1px solid rgba(235,235,235,1);
        background: #fafafa;
        color: rgba(97,97,97,1);
        font-size: var(--p-font-size-300);
        padding: var(--pc-index-table-heading-padding-y) var(--pc-index-table-heading-padding-x);
        
    }

    .day {
        width: 1px;
        text-align: center;
        white-space: nowrap;
    }

    .first-letter {
        width: 1px;
        white-space: nowrap;
    }

    .event {
        width: 200px;
    }

    .has-event {}

    .event-line-course {
        margin-bottom: 2px;
        background-color: yellow;
    }

    .event-line-holiday {
        margin-bottom: 2px;
        background-color: #496BAB;
        color: white;
    }

    /* Media Queries for Mobile and Larger Screens */

    /* Mobile */
    @media (min-width: 600px) {
        .grid {
            justify-content: left;
        }

        .month {
            width: calc(50% - 20px);
            /* Two months per row for larger screens */
        }
    }

    /* Larger Screens */
    @media (min-width: 1200px) {
        .grid {
            justify-content: left;
        }

        .month {
            width: calc(25%);
            /* Three months per row for even larger screens */
        }
    }

    /* Larger Screens */
    @media print {
        .grid {
            justify-content: left;
        }

        .month {
            width: calc(33.33%);
            /* Three months per row for even larger screens */
        }
    }
  `;


  return (
    <div className="month">
      <style>{css}</style>
      <table>
        <thead>
          <tr>
            <th colSpan="3">
              <span as="p" fontWeight="semibold">
                {month}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {dates.map((date, index) => {
            const firstLetter = moment(date).format("ddd")[0].toUpperCase();
            const dateKey = moment(date).format("YYYY-MM-DD");
            const eventsOnDate = events[dateKey] || [];

            return (
              <tr key={generateUniqueKey(date, index)}>
                <td className="day">
                  <span variant="bodySm" as="p">
                    {moment(date).format("D")}
                  </span>
                </td>
                <td className="first-letter">
                  <span variant="bodySm" as="p">
                    {firstLetter}
                  </span>
                </td>
                <td className="event">
                  {eventsOnDate.map((event, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if(event.event_type === "COURSE_SESSION") {
                            setEvent(event)
                            if (listSessionModalRef.current) {
                              listSessionModalRef.current.show(() => {
                              });
                            }
                        } else {
                          window.open("/c/t/" + event?.school_id + "/" + trainingId + "/events/" + event?.id, '_blank');
                        }
                      }}
                      style={{ cursor: "pointer" }} // Add this style
                    >
                      <MeaEvenTypeBadge
                        key={index}
                        event={event}
                        current_date={date}
                      />
                    </div>
                  ))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <DayCoursesModal ref={listSessionModalRef} filter={{start_date: event?.startDate?.toDate().toISOString().split("T")[0], end_date: event?.endDate?.toDate().toISOString().split("T")[0]}}  />
    </div>
  );
};

export default CustomMonthCalendar;
