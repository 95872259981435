import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import CreateCourseModal from "./AddTeacherModal";
import teachersAPI from "../../../../services/teachers";
import MeaTeacherAvatar from "../../../../components/MeaTeacherAvatar";

const TeachersListComponent = forwardRef((props, ref) => {
  const { showAddButton } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  //
  //
  //
  const queryList = teachersAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Ajouter un(e) enseignant(e)"
          icon="pi pi-plus"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouveau niveau"
        />
      )}
    </div>
  );
  //
  return (
    <>
      <CreateCourseModal ref={createModalRef} />
      <MeaDataTable
        query={queryList}
        serviceAPI={teachersAPI}
        names={{
          pagination: "teachers",
          singular: "enseignant(e)",
          plural: "enseignant(e)s",
        }}
        startToolbarTemplate={startToolbarTemplate}
        //showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column
          header="Enseignant(e)"
          body={(teacher) => <MeaTeacherAvatar teacher={teacher} />}
        ></Column>
        <Column
          header="Nombre d'affectations"
          body={(teacher) => {
            return teacher?.schedule_items?.length;
          }}
        ></Column>
        <Column
          rowEditor={false}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </MeaDataTable>
    </>
  );
});

export default TeachersListComponent;
