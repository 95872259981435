import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";

const LevelDetailComponent = ({ level }) => {
  return (
    <>
      <MeaDetailCard>
        <MeaDetailField field="Nom du niveau" value={level?.name} />
        <MeaDetailField
          field="Présentation"
          value={level?.presentation || "-"}
        />
      </MeaDetailCard>
    </>
  );
};

export default LevelDetailComponent;
