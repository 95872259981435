import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (schoolId, accessToken, page, pageSize, status) => {
  return useQuery(
    ["trainings", schoolId, accessToken, page, pageSize, status],
    async () => {
      if (!schoolId) {
        return null;
      }
      try {
        const response = await fetch(
          BACKEND_URL +
          `/api/v1/schools/${schoolId}/trainings?page=${page}&pageSize=${pageSize}&status=${status}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch trainings!");
        }
        if (response.status >= 299) {
          throw new Error("Erreur de récupération de la liste des formations!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        // Handle the error
        console.error("Error fetching trainings:", error);
        throw error;
      }
    }
  );
};

const GetOne = (schoolId, trainingId, accessToken) => {
  return useQuery(["training", schoolId, trainingId, accessToken], async () => {
    if (!schoolId || !trainingId) {
      return null;
    }
    try {
      const response = await fetch(
        BACKEND_URL + `/api/v1/schools/${schoolId}/trainings/${trainingId}`,
        {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch training!");
      }
      if (response.status >= 299) {
        throw new Error("Erreur de récupération de la formation!");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  });
};

const GetTrainingObj = async (
  schoolId,
  trainingId,
  accessToken,
  updatePreference,
  memberId
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      `/api/v1/schools/${schoolId}/trainings/${trainingId}?update_preference=${updatePreference}&member_id=${memberId}`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch training!");
    }
    if (response.status >= 299) {
      throw new Error("Erreur de récupération de la formation!");
    }
    const trainingObj = await response.json();
    return trainingObj?.data;
  } catch (error) {
    console.error(
      `There was an error in fetching the training object: ${error.message}`
    );
    return null;
  }
};

const CreateOne = async (schoolId, dummyTrainingId, accessToken, data) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/schools/" + schoolId + "/trainings",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de création de la formation!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (
  schoolId,
  trainingId,
  accessToken,
  dummyTrainingId,
  data
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      dummyTrainingId,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification de la formation!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateTrainingAvatar = async (
  schoolId,
  trainingId,
  accessToken,
  newAvatar
) => {
  try {
    let formData = new FormData();
    formData.append("file", newAvatar);
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/avatar",
      {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification de la salle!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, accessToken, trainingId) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/schools/" + schoolId + "/trainings/" + trainingId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression de la formation!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const GetStatistics = (schoolId, trainingId, accessToken) => {
  return useQuery(
    ["statistics", schoolId, trainingId, accessToken],
    async () => {
      if (!schoolId || !trainingId) {
        return null;
      }
      try {
        const response = await fetch(
          BACKEND_URL +
          `/api/v1/schools/${schoolId}/trainings/${trainingId}/statistics`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch training!");
        }
        if (response.status >= 299) {
          throw new Error("Erreur de récupération de la formation!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    }
  );
};

const trainingsAPI = {
  useGetList,
  GetOne,
  GetTrainingObj,
  CreateOne,
  UpdateOne,
  UpdateTrainingAvatar,
  DeleteOne,
  GetStatistics,
};

export default trainingsAPI;
