import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import { InputText } from "primereact/inputtext";
import CreateLevelModal from "./CreateLevelModal";
import levelsAPI from "../../../../services/levels";
import UpdateLevelModal from "./UpdateLevelModal";
import ImportLevelsClassesFromExcelModal from "../modals/ImportLevelsClassesFromExcelModal";

const LevelsListComponent = forwardRef((props, ref) => {
  const { selectedFields, showAddButton, showFilterButton } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  const importLevelsClassesFromExcelModalRef = useRef();
  //
  let selectedData = {
    name: selectedFields?.name ?? true,
    presentation: selectedFields?.presentation ?? true,
    totalCourses: selectedFields?.totalCourses ?? true,
    totalClasses: selectedFields?.totalClasses ?? true,
    edit: selectedFields?.edit ?? true,
  };
  //
  const [level, setLevel] = useState(null);
  //
  //
  const queryList = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <>
          <Button
            label="Créer un niveau"
            icon="pi pi-plus"
            size="small"
            className="p-button mr-2 action"
            onClick={() => {
              createModalRef.current.show(() => {
                queryList.refetch();
              });
            }}
            data-pr-tooltip="Nouveau niveau"
          />
          <Button icon="pi pi-cloud-upload" label="Importer" size="small" className="p-button mr-2" outlined onClick={() => {
            if (importLevelsClassesFromExcelModalRef.current) {
              importLevelsClassesFromExcelModalRef.current.show(() => {
                //callback?.();
              });
            }
          }} />
        </>
      )}
    </div>
  );
  //
  return (
    <>
      <CreateLevelModal ref={createModalRef} />
      <MeaDataTable
        query={queryList}
        serviceAPI={levelsAPI}
        names={{ pagination: "level", singular: "niveau", plural: "niveaux" }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        {selectedData.name && (
          <Column
            field="name"
            header="Niveau"
            editor={(options) => (
              <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
              />
            )}
            body={(item) => (
              <MeaLink
                to={
                  "/c/t/" + schoolId + "/" + trainingId + "/levels/" + item.id
                }
              >
                {item.name}
              </MeaLink>
            )}
          ></Column>
        )}
        {selectedData.presentation && (
          <Column
            field="presentation"
            header="Presentation"
            editor={(options) => (
              <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
              />
            )}
          ></Column>
        )}
        {selectedData.totalClasses && (
          <Column field="total_classes" header="Nombre de classes"></Column>
        )}
        {selectedData.totalCourses && (
          <Column field="total_courses" header="Nombre de courses"></Column>
        )}
        {selectedData.edit && (
          <Column
            body={(item) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  text
                  aria-label="Filter"
                  onClick={() => {
                    setLevel(item);
                    if (updateModalRef.current) {
                      updateModalRef.current.show(() => {
                        queryList.refetch();
                      });
                    }
                  }}
                />
              </>
            )}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        )}
      </MeaDataTable>
      <UpdateLevelModal ref={updateModalRef} level={level} />
      <ImportLevelsClassesFromExcelModal ref={importLevelsClassesFromExcelModalRef} />
    </>
  );
});

export default LevelsListComponent;
