import React, { useEffect, useRef, useState } from "react";
import MeaPage from "../../../components/MeaPage";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useAuthContext from "../../../hooks/useAuthContext";
import { Image } from "primereact/image";
import userAPI from "../../../services/user";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { FileUpload } from "primereact/fileupload";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UserProfileDetailPage = () => {
  const { auth, currentAccount, setCurrentAccount } = useAuthContext();
  const [firstname, setFirstname] = useState(currentAccount?.firstname);
  const [lastname, setLastname] = useState(currentAccount?.lastname);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useRef(null);
  const [refresh, setRefresh] = useState();
  const [editPassword, setEditPassword] = useState(false);

  const [avatar, setAvatar] = useState(
    currentAccount?.avatar
      ? BACKEND_URL + "/api/v1/medias/" + currentAccount?.avatar
      : "/user.png"
  );
  useEffect(() => {
    setFirstname(currentAccount?.firstname);
    setLastname(currentAccount?.lastname);
    setAvatar(
      currentAccount?.avatar
        ? BACKEND_URL + "/api/v1/medias/" + currentAccount?.avatar
        : "/user.png"
    );
  }, [currentAccount, refresh]);
  //
  const handleUpdateProfileSubmit = async (e) => {
    e.preventDefault();
    userAPI
      .UpdateUserProfile(auth?.access_token, firstname, lastname)
      .then((data) => {
        setCurrentAccount(data?.data);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Profil mis à jour!",
          life: 3000,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur de mise à jour de votre profil utilisateur!",
          life: 3000,
        });
        setRefresh(new Date());
      });
  };
  //

  const handleUpdatePasswordSubmit = async (e) => {
    e.preventDefault();
    userAPI
      .UpdateUserPassword(
        auth?.access_token,
        oldPassword,
        newPassword,
        confirmPassword
      )
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Mot de passe mis à jour!",
          life: 3000,
        });
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setEditPassword(false);
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur de mise à jour de votre mot de passe!",
          life: 3000,
        });
        setRefresh(new Date());
      });
  };
  //
  return (
    <>
      <Toast ref={toast} position="bottom-center" />
      <MeaPage title="Mon profil utilisateur">
        <div className="grid mb-5 ml-5">
          <div className="col-12 lg:col-3">
            <div className="text-900 font-medium text-xl mb-3">
              Photo de profil
            </div>
            <p className="m-0 p-0 text-400 line-height-2 text-sm mr-3">
              C'est votre photo portrait qui sera visible aux autres membre de
              la plateform.
            </p>
          </div>
          <div className="col-12 lg:col-9">
            <div className="w-full lg:w-6 ">
              <div className="card flex flex-wrap gap-3 p-fluid">
                <Image
                  src={avatar}
                  zoomSrc={avatar}
                  alt="Image"
                  width="60"
                  height="60"
                  preview
                />
              </div>
              <div className="card flex flex-wrap gap-3 p-fluid mt-3">
                <div className="">
                  <FileUpload
                    mode="basic"
                    name="file"
                    customUpload
                    uploadHandler={async (event) => {
                      await userAPI
                        .UpdateUserAvatar(auth?.access_token, event.files[0])
                        .then((data) => {
                          setCurrentAccount(data?.data);
                          toast.current.show({
                            severity: "info",
                            summary: "Succès",
                            detail: "Votre photo de profil a été modifiée!",
                            life: 3000,
                          });
                        })
                        .catch((err) => {
                          toast.current.show({
                            severity: "error",
                            summary: "Erreur",
                            detail:
                              "Erreur de modification de votre photo de profil!",
                            life: 3000,
                          });
                          setRefresh(new Date());
                        });
                    }}
                    accept="image/*"
                    maxFileSize={1000000}
                    auto
                    chooseLabel="Modifier"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid mb-5 mt-3 ml-5">
          <div className="col-12 lg:col-3">
            <div className="text-900 font-medium text-xl mb-3">
              Mes informations de base
            </div>
            <p className="m-0 p-0 text-400 line-height-2 text-sm mr-3">
              Nous avons besoin de vos informations de base pour que les autres
              membres de la plateforme vous connaissent.
            </p>
          </div>
          <div className="col-12 lg:col-9">
            <div className="w-full lg:w-6 ">
              <div className="card flex flex-wrap gap-3 p-fluid">
                <div className="flex-auto">
                  <label htmlFor="minmax" className="font-bold block mb-2">
                    Nom de famille
                  </label>
                  <InputText
                    className="flex-1 w-full"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div>
              </div>
              <div className="card flex flex-wrap gap-3 p-fluid mt-3">
                <div className="flex-auto">
                  <label htmlFor="minmax" className="font-bold block mb-2">
                    Prénom
                  </label>
                  <InputText
                    className="flex-1 w-full"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>
              </div>
              <div className="card flex mt-3">
                <Button
                  outlined
                  disabled={
                    currentAccount.firstname === firstname &&
                    currentAccount.lastname === lastname
                  }
                  onClick={handleUpdateProfileSubmit}
                >
                  Enregistrer
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="grid mb-5 mt-3 ml-5">
          <div className="col-12 lg:col-3">
            <div className="text-900 font-medium text-xl mb-3">
              Mes informations de connexion
            </div>
            <p className="m-0 p-0 text-400 line-height-2 text-sm mr-3">
              Vous pouvez ici changer votre mot de passe ou activer d'autre
              méthode de connexion à la plateforme.
            </p>
          </div>
          <div className="col-12 lg:col-9">
            <div className="w-full lg:w-6 ">
              <div className="card flex flex-wrap gap-3 p-fluid">
                <div className="flex-auto">
                  <label htmlFor="minmax" className="font-bold block mb-2">
                    Email
                  </label>
                  <InputText
                    className="flex-1 w-full"
                    value={currentAccount.email}
                    disabled
                    //onValueChange={(e) => setValue4(e.value)}
                  />
                  <small id="username-help">
                    C'est aussi votre identifiant de connexion, vous ne pouvez
                    pas le changer!
                  </small>
                </div>
              </div>
              {!editPassword && (
                <div className="card flex flex-wrap gap-3 p-fluid mt-5">
                  <div className="">
                    <Button outlined onClick={() => setEditPassword(true)}>
                      Changer le mot de passe
                    </Button>
                  </div>
                </div>
              )}
              {editPassword && (
                <div className="w-full lg:w-6 mt-5">
                  <div className="card flex flex-wrap gap-3 p-fluid">
                    <div className="flex-auto">
                      <label htmlFor="minmax" className="font-bold block mb-2">
                        Ancien mot de passe
                      </label>
                      <Password
                        className="flex-1 w-full"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        feedback={false}
                        toggleMask
                      />
                    </div>
                  </div>
                  <div className="card flex flex-wrap gap-3 p-fluid mt-3">
                    <div className="flex-auto">
                      <label htmlFor="minmax" className="font-bold block mb-2">
                        Nouveau mot de passe
                      </label>
                      <Password
                        className="flex-1 w-full"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        feedback={false}
                        toggleMask
                      />
                    </div>
                  </div>
                  <div className="card flex flex-wrap gap-3 p-fluid mt-3">
                    <div className="flex-auto">
                      <label htmlFor="minmax" className="font-bold block mb-2">
                        Re-tapez le nouveau mot de passe
                      </label>
                      <Password
                        className="flex-1 w-full"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        feedback={false}
                        toggleMask
                      />
                    </div>
                  </div>
                  <div className="card flex mt-3">
                    <Button
                      disabled={
                        newPassword &&
                        confirmPassword &&
                        newPassword === confirmPassword
                          ? false
                          : true
                      }
                      onClick={handleUpdatePasswordSubmit}
                    >
                      Enregistrer
                    </Button>
                    <Button
                      className="ml-2"
                      outlined
                      onClick={() => {
                        setOldPassword("");
                        setNewPassword("");
                        setConfirmPassword("");
                        setEditPassword(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </MeaPage>
    </>
  );
};
export default UserProfileDetailPage;
