import { Skeleton } from "primereact/skeleton";
const MeaDetailField = ({ field, value, children }) => {
  return (
    <li className="flex align-items-center py-2 px-2 flex-wrap">
      <div
        className="text-500 w-full md:w-5 lg:w-4 font-medium"
        style={{ maxWidth: "150px" }}
      >
        {field}
      </div>
      <div className="text-900 w-full md:w-5 lg:w-6">
        {value || <Skeleton width="10rem" className="mb-2"></Skeleton>}
      </div>
    </li>
  );
};
export default MeaDetailField;
