import { createContext, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import trainingsAPI from "../services/trainings";
import accountsAPI from "../services/accounts";
import { useCookies } from "react-cookie";

const AuthContext = createContext({});

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const SESSION_COOKIE_DOMAIN = process.env.REACT_APP_SESSION_COOKIE_DOMAIN;
const CURRENT_ACCOUNT_URL = BACKEND_URL + "/api/v1/account/profile";
const GET_TRAINING_URL = BACKEND_URL + "/api/v1/schools/";

export const AuthContextProvider = ({ children }) => {
  const [cookies] = useCookies(["MEA_SESSION"]);
  const [currentAccount, setCurrentAccount] = useLocalStorage(
    "mea.currentAccount",
    {},
  );
  const [currentMembership, setCurrentMembership] = useLocalStorage(
    "mea.currentMembership",
    {},
  );
  const [currentTraining, setCurrentTraining] = useLocalStorage(
    "mea.currentTraining",
    {},
  );
  const auth = undefined;
  // intercept all requests to check/renew JWT access_tokens
  useEffect(() => {
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
      let [resource, config] = args;
      // request interceptor starts
      //console.log("REQUEST INTERCEPTED");
      //console.log(auth?.access_token);
      //if (Date.now() >= parseJwt(auth?.access_token)?.exp * 1000) {
      //  console.log("TOKEN EXPIRED");
      //  navigate("/d/login");
      //}
      // TODO
      // check if JWT is not expired, otherwise, refresh it before sending the actual request
      // request interceptor ends
      const response = await originalFetch(resource, config);
      if (response.status === 401) {
        //navigate("/d/login");
        if (window.location.pathname !== "/d/login") {
          window.location.replace("/d/login");
        }
        //throw new Error("Unaythorized_");
      }
      //if (response.status === 403) {
      //navigate("/d/login");
      //  window.location.replace("/d/unauthorized");
      //throw new Error("Unaythorized_");
      //}
      // TODO
      // response interceptor here
      return response;
    };
  }, []);

  // return Member object
  const switchSchool = (schoolId, account, accessToken) => {
    if (!schoolId) {
      updateCurrentMembership(null, account);
      return null;
    }
    if (!account) {
      account = currentAccount;
    }

    accountsAPI.GetMemberships(accessToken, 1, 100000).then((memberships) => {
      console.log(memberships)
      if (memberships?.data) {
        const currentMembership = memberships?.data?.find((element, index, array) => {
          return element.school_id === schoolId;
        });
        if (currentMembership) {
          updateCurrentMembership(currentMembership, account);
          return currentMembership;
        } else {
          updateCurrentMembership(null, account);
          return null;
        }
      }
      return null;
    });
    return null;
  };

  const updateCurrentMembership = (membership, account) => {
    setCurrentMembership(membership);
    // todo set as last visited school
    accountsAPI.UpdateCurrentAccountPreferences(
      auth?.access_token,
      membership?.school_id,
    );
  };

  const switchTraining = async (trainingId, schoolId, accessToken) => {
    return new Promise(async (resolve, reject) => {
      if (
        trainingId !== undefined &&
        trainingId !== null &&
        trainingId !== "null"
      ) {
        if (schoolId === undefined) {
          schoolId = currentMembership?.school_id;
        }
        if (accessToken === undefined) {
          accessToken = auth?.access_token;
        }
        //
        const training = await trainingsAPI.GetTrainingObj(
          schoolId,
          trainingId,
          accessToken,
          true,
          currentMembership?.id,
        );
        if (training !== null) {
          setCurrentTraining(training);

          resolve(training);
        } else {
          setCurrentTraining(null);
          reject("Error switching to non existing training!");
        }
      } else {
        setCurrentTraining(null);
      }
    });
    //
  };

  const isConnected = () => {
    /*if (!connected) {
      return false;
    }
    if (!auth) {
      return false;
    }
    if (auth?.access_token === undefined) {
      return false;
    }
    const storedDate = new Date(auth?.expires_in);
    const currentDate = new Date();
    if (storedDate.getTime() < currentDate.getTime()) {
      return false;
    }
    return true;*/
    const _isConnected = !!cookies.MEA_SESSION;
    return _isConnected;
  };

  const logoutCurrentAccount = () => {
    document.cookie =
      "MEA_SESSION=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" +
      SESSION_COOKIE_DOMAIN +
      ";";
  };

  const currentAccountRole = {
    isOwner: () => {
      return currentMembership?.is_owner;
    },
    isAdmin: () => {
      return currentMembership?.is_admin || currentMembership?.is_owner;
    },
    isStuff: () => {
      return currentMembership?.is_stuff;
    },
    isTeacher: () => {
      return currentMembership?.is_teacher;
    },
    isStudent: () => {
      return currentMembership?.is_student;
    },
  };

  const currentTrainingStatus = {
    isOpen: () => {
      return currentTraining?.status === "OPEN";
    },
    isDraft: () => {
      return currentTraining?.status === "DRAFT";
    },
    isSuspended: () => {
      return currentTraining?.status === "SUSPENDED";
    },
    isArchived: () => {
      return currentTraining?.status === "ARCHIVED";
    },
  };

  const reloadCurrentTraining = () => {
    if (currentTraining !== undefined) {
      // update memberships list from backend
      const schoolId = currentMembership?.school_id;
      fetch(GET_TRAINING_URL + schoolId + "/trainings/" + currentTraining.id, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: "Bearer " + auth?.access_token,
        },
      })
        .then((response) => {
          if (response.status >= 300) {
            throw new Error("Formation non trouvée!");
          }
          return response.json();
        })
        .then((json) => {
          const training = json?.data;
        })
        .catch(function (error) {
          //
          console.log(error);
        });
    }
  };

  const reloadAuthContext = async () => {
    console.error("reloadAuthContext no longer supported! will be removed");
  };

  const authContext = {
    auth: auth,
    currentMembership: currentMembership,
    currentAccountRole: currentAccountRole,
    currentTrainingStatus: currentTrainingStatus,
    currentTraining: currentTraining,
  };

  return (
    <AuthContext.Provider
      value={{
        authContext,
        switchSchool,
        switchTraining,
        reloadAuthContext,
        reloadCurrentTraining,
        isConnected,
        currentAccount,
        currentAccountRole,
        currentTrainingStatus,
        setCurrentAccount,
        setCurrentTraining,
        updateCurrentMembership,
        currentMembership,
        currentTraining,
        logoutCurrentAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
