
import MeaPage from "../../../components/MeaPage";
import StudentRegistrationComponent from "./components/StudentRegistrationComponent";

const StudentsRegistrationPage = () => {
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Pré-inscription d'un élève"
        subtitle="Ajouter un nouveau élève à cette formation"
      >
        <StudentRegistrationComponent />
      </MeaPage>
    </>
  );
};
export default StudentsRegistrationPage;
