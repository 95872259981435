import { useNavigate, useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import eventsAPI from "../../../services/events";
import MeaPageTitle from "../../../components/MeaPageTitle";
import EventDetailComponent from "./components/EventDetailComponent";
import MeaPageHeader from "../../../components/MeaPageHeader";
import EventDetailExtraComponent from "./components/EventDetailExtraComponent";
import { useRef } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import UpdateEventModal from "./components/UpdateEventModal";

const EventDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const updateModalRef = useRef();
  const toast = useRef();
  const navigate = useNavigate();
  const eventId = params.eventId;
  //
  const eventQuery = eventsAPI.GetOne(schoolId, trainingId, accessToken, eventId);
  const eventObj = eventQuery?.data?.data;
  //
  //
  return (
    <>
      <MeaPage
        title={<MeaPageTitle prefix={"Évènement:"} title={eventObj?.name} />}
        actions={[
          {
            label: "Modifier",
            icon: "pi pi-file-edit",
            command: () => {
              if (updateModalRef.current) {
                updateModalRef.current.show(() => {
                  eventQuery.refetch();
                });
              }
            },
          },
          {
            separator: true,
          },
          {
            label: "Supprimer",
            icon: "pi pi-trash",
            command: () => {
              confirmDialog({
                message: 'Voulez-vous vraiment supprimer cette évenement?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                acceptLabel: "Oui",
                rejectLabel: "Non",
                accept:() => {
                  eventsAPI.DeleteOne(schoolId, trainingId, accessToken, eventId)
                  .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Succès', detail: eventObj?.name + " Supprimé!", life: 3000 });
                    navigate("/c/t/" + schoolId + "/" + trainingId + "/events");
                  })
                  .catch(error => {
                    toast.current.show({
                      severity: "error",
                      summary: "Erreur",
                      detail: "Erreur de suppression!",
                    });
                  });
                },
              })
            }
          },
        ]}
      >
        <MeaPageHeader>
          <EventDetailComponent event={eventObj} />
          <EventDetailExtraComponent event={eventObj} />
        </MeaPageHeader>
      
        <UpdateEventModal ref={updateModalRef} event={eventObj} />
        <ConfirmDialog />
        <Toast ref={toast} />
      </MeaPage>
    </>
  );
};
export default EventDetailPage;
