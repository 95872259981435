import { Tree } from "primereact/tree";
import useAuthContext from "../../../../hooks/useAuthContext";
import trainingsAPI from "../../../../services/trainings";
import { useNavigate } from "react-router-dom";
import { forwardRef, useCallback } from "react";

const TrainingsTree = forwardRef((props, ref) => {
  const { callback, schoolId } = props;
  const navigate = useNavigate();
  const { auth, currentMembership } = useAuthContext();
  const school_id = schoolId ? schoolId : currentMembership?.school_id;
  const accessToken = auth?.access_token;

  const trainingsList = trainingsAPI.useGetList(
    school_id,
    accessToken,
    "1",
    "1000000",
    ""
  );
  const trainings = trainingsList?.data?.data || [];
  const trainingsMarkup = trainings.map((element, index) => {
    return {
      label: element.name,
      data: element,
      code: element.id,
      expandedIcon: "pi pi-book",
      collapsedIcon: "pi pi-book",
    };
  });

  const switchTrainingTrigger = useCallback(
    (school_id, trainingId) => {
      callback && callback();
      navigate("/c/t/" + school_id + "/" + trainingId);
    },
    [navigate, callback]
  );

  return (
    <>
      <Tree
        value={trainingsMarkup}
        filter
        selectionMode="single"
        filterMode="lenient"
        filterPlaceholder="Chercher.."
        nodeTemplate={(node) => {
          return (
            <a href=""
              className="flex align-items-center"
              onClick={() => {
                switchTrainingTrigger(node.data.school_id, node.code);
              }}
            >
              <span className="ml-2">{node.label}</span>
            </a>
          );
        }}
      />
    </>
  );
});

export default TrainingsTree;
