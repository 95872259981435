import React, { useState } from "react";
import useAuthContext from "../../hooks/useAuthContext";
import MeaPage from "../../components/MeaPage";
import { useNavigate } from "react-router-dom";
import { TabMenu } from "primereact/tabmenu";
import { Button } from "primereact/button";
import TrainingsListPage from "./trainings/TrainingsListPage";
import MembersListPage from "./members/MembersListPage";
const SchoolHome = () => {
  const { currentMembership } = useAuthContext();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const items = [
    { label: 'Formations', icon: 'pi pi-home' },
    { label: 'Membres', icon: 'pi pi-chart-line' },
  ];

  return (
    <>
      <MeaPage
        header={
          <div>
            <div
              style={{
                height: "200px",
                background:
                  'url("https://blocks.primereact.org/demo/images/blocks/pageheading/cover.png") no-repeat',
                backgroundSize: "cover",
              }}
            ></div>
            <div className="px-4 py-0 md:px-6 lg:px-8 surface-section">
              <div
                className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
                style={{
                  marginTop: "-2rem",
                  top: "-70px",
                  marginBottom: "-70px",
                }}
              >
                <div>
                  <div
                    style={{
                      width: "140px",
                      height: "140px",
                      borderRadius: "10px",
                    }}
                    className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
                  >
                    <img
                      src="https://blocks.primereact.org/demo/images/blocks/logos/hyper.svg"
                      alt="Image"
                      width="70"
                      height="70"
                    />
                  </div>
                  <div className="text-900 text-3xl font-medium mb-3">
                    {currentMembership?.school?.name}
                  </div>
                  <p className="mt-0 mb-3 text-700 text-xl">
                    {currentMembership?.school?.presentation}
                  </p>
                  <div className="text-600 font-medium">
                    <span>{currentMembership?.school?.address}</span>
                  </div>
                </div>
                <div className="mt-3 lg:mt-0">
                  {/* 
                  <Button
                    label="Follow"
                    icon="pi pi-plus-circle"
                    className="p-button-outlined mr-2"
                  />
                  <Button label="Website" icon="pi pi-link" className="mr-2" />
                  */}
                  <Button
                    icon="pi pi-ellipsis-v"
                    className="p-button-outlined p-button-rounded"
                  />
                </div>
              </div>
              <TabMenu className="pt-3" model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
            </div>
          </div>
        }
      >
        {activeIndex === 0 && (
          <TrainingsListPage />
        )}
        {activeIndex === 1 && (
          <MembersListPage />
        )}
      </MeaPage>
    </>
  );
};
export default SchoolHome;
