import { Outlet, useParams } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import { useEffect } from "react";
import MeaLayout from "../components/MeaLayout";
import MeaSidebar from "../components/MeaSidebar";

const ProfileLayout = () => {
  const {
    isConnected,
    currentMembership,
    currentTraining,
    switchSchool,
    switchTraining,
  } = useAuthContext();
  const location = useLocation();
  // params
  const params = useParams();
  //
  const schoolId = params.schoolId;
  const trainingId = params.trainingId;
  const currentSchoolId = currentMembership?.school_id;

  useEffect(() => {
    if (
      !currentSchoolId &&
      schoolId !== undefined &&
      schoolId !== currentSchoolId
    ) {
      //console.log("----- switch school to ", schoolId);
      switchSchool(schoolId);
    }
    if (trainingId !== null && trainingId !== undefined) {
      //console.log(trainingId, currentTraining?.id);
      //console.log("----- switch training to ", trainingId);
      if (trainingId !== currentTraining?.id) {
        switchTraining(trainingId, schoolId).then((data) => {
          //console.log("done", data);
        });
      }
    } else {
      switchTraining(null);
    }
  }, [schoolId, trainingId]);

  return isConnected() ? (
    <MeaLayout
      sidebar={
        <MeaSidebar
          showTrainingMenu={false}
          showRegistrationMenu={false}
          showAcademicLifeMenu={false}
          showSchoolMenu={false}
          showUserMenu={true}
          showSuperAdminMenu={false}
        />
      }
      content={<Outlet />}
    />
  ) : (
    <Navigate to="/d/welcome" state={{ from: location }} replace />
  );
};
export default ProfileLayout;
