import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
  filter
) => {
  return useQuery(
    ["schedule", schoolId, trainingId, accessToken, page, pageSize, filter],
    async () => {
      let slotId = filter?.slot_id;
      if (slotId === undefined) {
        slotId = "";
      }
      let levelId = filter?.level_id;
      if (levelId === undefined) {
        levelId = "";
      }
      let classeId = filter?.classe_id;
      if (classeId === undefined) {
        classeId = "";
      }
      let courseId = filter?.course_id;
      if (courseId === undefined) {
        courseId = "";
      }
      let teacherId = filter?.teacher_id;
      if (teacherId === undefined) {
        teacherId = "";
      }
      let classroomId = filter?.classroom_id;
      if (classroomId === undefined) {
        classroomId = "";
      }
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/schedule?page=${page}&pageSize=${pageSize}&slot_id=${slotId}&level_id=${levelId}&class_id=${classeId}&course_id=${courseId}&teacher_id=${teacherId}&classroom_id=${classroomId}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch schedule items!");
        }
        if (response.status >= 299) {
          throw new Error(
            "Erreur de récupération de l'emploi de temps de la semaine!"
          );
        }
        const result = await response.json();
        return result;
      } catch (error) {
        // Handle the error
        console.error("Error fetching schedule items:", error);
        throw error;
      }
    }
  );
};

const GetOne = (schoolId, trainingId, accessToken, scheduleItemId) => {
  return useQuery(
    ["slot", schoolId, trainingId, accessToken, scheduleItemId],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/schedule/${scheduleItemId}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            "Erreur de récupération d'une entrée de Emplois du temps!"
          );
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    }
  );
};

const CreateOne = async (
  schoolId,
  trainingId,
  accessToken,
  courseName,
  coursePresentation,
  levelId,
  showOrder
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/schedule",
      {
        method: "POST",
        body: JSON.stringify({
          name: courseName,
          presentation: coursePresentation,
          level_id: levelId,
          show_order: parseInt(showOrder),
        }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de création d'une entrée de Emplois du temps!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (
  schoolId,
  trainingId,
  accessToken,
  scheduleItemId,
  data
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/schedule/" +
        scheduleItemId,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Erreur de modification d'une entrée de Emplois du temps!`
      );
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, trainingId, accessToken, courseId) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/schedule/" +
        courseId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Erreur de suppression d'une entrée de Emplois du temps!`
      );
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const scheduleAPI = {
  useGetList,
  GetOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
};

export default scheduleAPI;
