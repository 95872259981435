import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
) => {
  return useQuery(
    ["system-accounts", accessToken, page, pageSize],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/system/features?page=${page}&pageSize=${pageSize}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          },
        );

        if (!response.ok) {
          throw new Error(
            "Erreur de récupération de la liste des fonctionalités!",
          );
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    },
  );
};

const UpdateOne = async (accessToken, id, data) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/system/features/" + id,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      },
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification de la fonctionalité!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const systemFeaturesAPI = {
  useGetList,
  UpdateOne,
};

export default systemFeaturesAPI;
