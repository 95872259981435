import MeaPage from "../../../components/MeaPage";
import ClassroomsListComponent from "./components/ClassroomsListComponent";
import { useRef } from "react";

const ClassroomsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des salles"
        subtitle="Gérer l'ensemble des salles utilisés par cette formation"
      >
        <ClassroomsListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default ClassroomsListPage;
