import { useEffect, useState } from "react";
import "./MeaAttendancesProgressBar.css";

const MeaAttendancesProgressBar = ({
  attendances,
  showValues = true,
  showLegends = true,
}) => {
  const [pourcentages, setPourcentages] = useState({
    present: 0,
    absent: 0,
    late: 0,
    notset: 100,
  });
  useEffect(() => {
    if (attendances !== undefined) {
      setPourcentages(calculateAttendancesPourcentage(attendances));
    }
  }, [attendances]);
  const calculateAttendancesPourcentage = (attendances) => {
    let total = 0;
    let present = 0;
    let absent = 0;
    let late = 0;
    let notset = 0;
    //
    attendances?.forEach(function (item, i) {
      total++;
      if (item.status === "PRESENT") {
        present++;
      } else if (item.status === "ABSENT") {
        absent++;
      } else if (item.status === "LATE") {
        late++;
      } else if (item.status === "NOTSET") {
        notset++;
      }
    });
    return {
      present: total > 0 ? (present * 100) / total : 0,
      absent: total > 0 ? (absent * 100) / total : 0,
      late: total > 0 ? (late * 100) / total : 0,
      notset: total > 0 ? (notset * 100) / total : 0,
    };
    //
  };

  return (
    <div className="multicolor-bar">
      {(showValues && (
        <>
          <div className="values">
            {(pourcentages?.present && (
              <div
                className="value"
                style={{
                  color: "var(--green-500)",
                  width: pourcentages?.present + "%",
                }}
                key={"present"}
              >
                <span>{pourcentages?.present}%</span>
              </div>
            )) ||
              ""}
            {(pourcentages?.late && (
              <div
                className="value"
                style={{
                  color: "var(--orange-500)",
                  width: pourcentages?.late + "%",
                }}
                key={"absent"}
              >
                <span>{pourcentages?.late}%</span>
              </div>
            )) ||
              ""}
            {(pourcentages?.absent && (
              <div
                className="value"
                style={{
                  color: "var(--red-500)",
                  width: pourcentages?.absent + "%",
                }}
                key={"late"}
              >
                <span>{pourcentages?.absent}%</span>
              </div>
            )) ||
              ""}
            {(pourcentages?.notset && (
              <div
                className="value"
                style={{
                  color: "var(--gray-500)",
                  width: pourcentages?.notset + "%",
                }}
                key={"notset"}
              >
                <span>{pourcentages?.notset}%</span>
              </div>
            )) ||
              ""}
          </div>
          <div className="scale">
            {(pourcentages?.present && (
              <div
                className="graduation"
                style={{
                  color: "var(--green-500)",
                  width: pourcentages?.present + "%",
                }}
                key={"present"}
              >
                <span>|</span>
              </div>
            )) ||
              ""}
            {(pourcentages?.late && (
              <div
                className="graduation"
                style={{
                  color: "var(--orange-500)",
                  width: pourcentages?.late + "%",
                }}
                key={"late"}
              >
                <span>|</span>
              </div>
            )) ||
              ""}
            {(pourcentages?.absent && (
              <div
                className="graduation"
                style={{
                  color: "var(--red-500)",
                  width: pourcentages?.absent + "%",
                }}
                key={"absent"}
              >
                <span>|</span>
              </div>
            )) ||
              ""}
            {(pourcentages?.notset && (
              <div
                className="graduation"
                style={{
                  color: "var(--gray-500)",
                  width: pourcentages?.notset + "%",
                }}
                key={"notset"}
              >
                <span>|</span>
              </div>
            )) ||
              ""}
          </div>
        </>
      )) ||
        ""}
      <div className="bars">
        {(pourcentages?.present && (
          <div
            className="bar"
            style={{
              backgroundColor: "var(--green-500)",
              width: pourcentages?.present + "%",
            }}
            key={"present"}
          ></div>
        )) ||
          ""}
        {(pourcentages?.late && (
          <div
            className="bar"
            style={{
              backgroundColor: "var(--orange-500)",
              width: pourcentages?.late + "%",
            }}
            key={"late"}
          ></div>
        )) ||
          ""}
        {(pourcentages?.absent && (
          <div
            className="bar"
            style={{
              backgroundColor: "var(--red-500)",
              width: pourcentages?.absent + "%",
            }}
            key={"absent"}
          ></div>
        )) ||
          ""}
        {(pourcentages?.notset && (
          <div
            className="bar"
            style={{
              backgroundColor: "var(--gray-500)",
              width: pourcentages?.notset + "%",
            }}
            key={"notset"}
          ></div>
        )) ||
          ""}
      </div>
      {(showLegends && (
        <div className="legends">
          {(pourcentages?.present && (
            <div className="legend" key={"presence"}>
              <span className="dot" style={{ color: "var(--green-500)" }}>
                ●
              </span>
              <span className="label">Présence</span>
            </div>
          )) ||
            ""}
          {(pourcentages?.late && (
            <div className="legend" key={"late"}>
              <span className="dot" style={{ color: "var(--orange-500)" }}>
                ●
              </span>
              <span className="label">Retards</span>
            </div>
          )) ||
            ""}
          {(pourcentages?.absent && (
            <div className="legend" key={"absence"}>
              <span className="dot" style={{ color: "var(--red-500)" }}>
                ●
              </span>
              <span className="label">Absences</span>
            </div>
          )) ||
            ""}
          {(pourcentages?.notset && (
            <div className="legend" key={"notset"}>
              <span className="dot" style={{ color: "var(--gray-500)" }}>
                ●
              </span>
              <span className="label">Non définis</span>
            </div>
          )) ||
            ""}
        </div>
      )) ||
        ""}
    </div>
  );
};

export default MeaAttendancesProgressBar;
