import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import membersAPI from "../../../../services/members";
import useAuthContext from "../../../../hooks/useAuthContext";
import MeaDataTable from "../../../../components/MeaDataTable";
import MeaMemberAvatar from "../../../../components/MeaMemberAvatar";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Messages } from "primereact/messages";
import studentsAPI from "../../../../services/students";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Message } from "primereact/message";
import MeaStudentStatus2 from "../../../../components/MeaStudentStatus2";
import MeaStudentResult from "../../../../components/MeaStudentResult";
import { useNavigate } from "react-router-dom";

const NewRegistrationComponent = () => {

    const { auth, currentMembership, currentTraining } = useAuthContext();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const schoolId = currentMembership?.school_id;
    const trainingId = currentTraining?.id;
    const accessToken = auth?.access_token;

    const navigate = useNavigate();

    const stepperRef = useRef(null);
    const searchStudentsTableRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const msgs1 = useRef(null);
    const msgs2 = useRef(null);
    const msgs3 = useRef(null);

    //
    const toast = useRef();

    //
    const [searchStudent, setSearchStudent] = useState({
        id: "",
        lastname: "",
        firstname: "",
        birthday: "",
        email: "",
    });

    const [selectedSearchMember, setSelectedSearchMember] = useState(null);
    const [newStudent, setNewStudent] = useState({});

    const updateSearchStudentField = (field, value) => {
        let updated = {};
        updated[field] = value;
        setSearchStudent((s) => ({
            ...s,
            ...updated,
        }));
    };
    const updateNewStudentField = (field, value) => {
        let updated = {};
        updated[field] = value;
        setNewStudent((s) => ({
            ...s,
            ...updated,
        }));
    };

    useEffect(() => {
        if (selectedSearchMember) {
            setNewStudent({
                member_id: selectedSearchMember.id,
                lastname: selectedSearchMember.lastname,
                firstname: selectedSearchMember.firstname,
                gender: selectedSearchMember.gender,
                birthday: selectedSearchMember.birthday,
                email: selectedSearchMember.email,
                phone: selectedSearchMember.phone,
                address: selectedSearchMember.address,
            });
        } else {
            setNewStudent({
                member_id: "",
                lastname: "",
                firstname: "",
                gender: "",
                birthday: "",
                email: "",
                phone: "",
                address: "",
            });
        }
    }, [selectedSearchMember]);

    const [previousRegistration, setPreviousRegistration] = useState([]);

    const verifySelectedStudent = () => {
        setLoading(true)
        const selected = searchStudentsTableRef.current.getSelectedElements();
        if (selected === null || selected === undefined || selected?.length === 0) {
            if (msgs1.current) {
                msgs1.current.clear();
                msgs1.current.show({ id: '1', sticky: true, severity: 'warn', summary: 'Avertissement', detail: 'Aucun élève séléctionné', closable: true });
            }
            setLoading(false)
        } else {
            // verify if he/she is already registered
            studentsAPI.SearchStudent(schoolId, trainingId, accessToken, 1, 1000, { member_id: selected.id }).then((data) => {
                console.log(data);
                if (data?.total > 0) {
                    if (msgs1.current) {
                        msgs1.current.clear();
                        msgs1.current.show({ id: '1', sticky: true, severity: 'error', summary: 'Avertissement', detail: "L'élève " + selected?.lastname + " " + selected?.firstname + " est déjà inscrit à cette formation", closable: true });
                    }
                } else {
                    setSelectedSearchMember(selected);
                    membersAPI.GetMemberTrainingsRegistrations(schoolId, accessToken, selected.id).then((data) => {
                        setPreviousRegistration(data?.data);
                    }).catch((error) => {
                        console.log("error", error);
                    })
                    stepperRef.current.nextCallback();
                }
            }).catch((error) => {
                console.log("error", error);
            }
            ).finally(() => {
                setLoading(false)
            });
        }
    };

    const createStudent = () => {
        setLoading(true);
        if (newStudent.lastname === "" || newStudent.firstname === "") {
            if (msgs2.current) {
                msgs2.current.clear();
                msgs2.current.show({ id: '2', sticky: true, severity: 'error', summary: 'Erreur', detail: 'Nom et prénom sont obligatoires', closable: true });
            }
        } else {
            studentsAPI.CreateOne(schoolId, trainingId, accessToken, newStudent).then((data) => {
                console.log(data);
                setNewStudent(data?.data);
                stepperRef.current.nextCallback();
            }).catch((error) => {
                console.log("error", error);
                if (msgs2.current) {
                    msgs2.current.clear();
                    msgs2.current.show({ id: '2', sticky: true, severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la création du dossier de candidature', closable: true });
                }
            }).finally(() => {
                setLoading(false);
            });

        }
        setLoading(false);
    }


    const querySearchStudentsList = membersAPI.useSearchStudentMembers(
        schoolId,
        accessToken,
        page,
        pageSize,
        {
            id: searchStudent?.id || "",
            lastname: searchStudent?.lastname || "",
            firstname: searchStudent?.firstname || "",
            birthday: searchStudent?.birthday || "",
            email: searchStudent?.email || "",
        }
    );



    return (
        <div className="w-full mt-5">
            <Toast ref={toast} position="bottom-center" />
            <Stepper ref={stepperRef} style={{ flexBasis: '50rem' }} linear activeStep={0}>
                <StepperPanel header="Chercher s'il exist">
                    <div className="flex flex-column mt-3">
                        <div className="mb-4">Utiliser un ou plusieurs des champs suivants pour rechercher si l'élève est déjà présent dans la base de données</div>
                        <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                            <ul className="list-none p-0 m-0">

                                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">Immatriculation</div>
                                    <div className="text-900 w-full md:w-9">
                                        <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={searchStudent?.id} onInput={(e) => {
                                            updateSearchStudentField("id", e.target.value)
                                        }} />
                                    </div>
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-3 font-medium">Nom de famille</div>
                                    <div className="text-900 w-full md:w-9">
                                        <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={searchStudent?.lastname} onInput={(e) => {
                                            updateSearchStudentField("lastname", e.target.value?.toUpperCase())
                                        }} /></div>
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">Prénom</div>
                                    <div className="text-900 w-full md:w-9">
                                        <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={searchStudent?.firstname} onInput={(e) => {
                                            updateSearchStudentField("firstname", e.target.value?.toUpperCase())
                                        }} /></div>
                                </li>

                                <li className="flex align-items-center py-2 px-2 flex-wrap">
                                    <div className="text-500 w-full md:w-3 font-medium">Date de naissance</div>
                                    <div className="text-900 w-full md:w-9">
                                        <InputText type="date" className="p-inputtext-sm w-full md:w-9" value={searchStudent?.birthday} onChange={(e) => {
                                            console.log(e.target.value)
                                            updateSearchStudentField("birthday", e.target.value)
                                        }} /></div>
                                </li>

                                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">E-mail</div>
                                    <div className="text-900 w-full md:w-9">
                                        <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={searchStudent?.email} onInput={(e) => {
                                            updateSearchStudentField("email", e.target.value)
                                        }} /></div>
                                </li>

                            </ul>

                        </div>
                    </div>
                    <div className="flex flex-column ">
                        <div className="w-full pl-3 pr-3">
                            <MeaDataTable
                                ref={searchStudentsTableRef}
                                query={querySearchStudentsList}
                                serviceAPI={membersAPI}
                                showFilterButton={false}
                                names={{ pagination: "student", singular: "élève", plural: "élèves" }}
                                setPage={setPage}
                                setPageSize={setPageSize}>
                                <Column
                                    selectionMode="single"
                                    headerStyle={{ width: "3rem" }}
                                ></Column>
                                <Column
                                    field="id"
                                    header="Immatriculation"
                                ></Column>
                                <Column
                                    field="lastname"
                                    header="Nom de famille"
                                ></Column>
                                <Column
                                    field="firstname"
                                    header="Prénom"
                                ></Column>
                                <Column
                                    field="birthday"
                                    header="Date de naissance"
                                ></Column>
                                <Column
                                    field="email"
                                    header="E-mail"
                                ></Column>

                            </MeaDataTable>
                        </div>

                    </div>
                    <div className="flex ">
                        <Messages ref={msgs1} />
                    </div>
                    <div className="flex py-4">
                        <Button label="Suivant" icon="pi pi-arrow-right" onClick={verifySelectedStudent} loading={loading} />
                        <Button label="Elève non présent dans la base de donnée" loading={loading} outlined icon="pi pi-arrow-right" className="ml-2" onClick={() => {
                            setSelectedSearchMember(null);
                            stepperRef.current.nextCallback();
                        }} />
                    </div>
                </StepperPanel>

                <StepperPanel header="Informations">
                    <div className="flex flex-column mt-3">
                        <div className="mb-4">Veuillez remplire (ou modifier) le formulaire de candidature</div>
                        <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                            <ul className="list-none p-0 m-0">

                                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-3 font-medium"></div>
                                    {selectedSearchMember && <Message severity="success" text="Elève déjà inscrit à l'école - ré-inscription dans cette nouvelle formation" /> || <Message severity="info" text="Elève jamais inscrit à l'école - nouvelle condidature" />}
                                </li>
                                {selectedSearchMember && <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-3 font-medium">Elève</div>
                                    <div className="p-inputtext-sm w-full md:w-9 flex flex-column">
                                        <MeaMemberAvatar member={selectedSearchMember} />
                                    </div>
                                </li>}
                                {selectedSearchMember && <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-3 font-medium">Immatriculation</div>
                                    <div className="p-inputtext-sm w-full md:w-9 flex flex-column">
                                        <InputText type="text" disabled aria-describedby="member-id-help" className="p-inputtext-sm" value={newStudent?.member_id || ""} onInput={(e) => {
                                            updateNewStudentField("member_id", e.target.value)
                                        }} />
                                    </div>
                                </li>}
                                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">Nom de famille <span className="text-red-500">*</span></div>
                                    <InputText type="text" autoFocus className="p-inputtext-sm w-full md:w-9" value={newStudent?.lastname || ""} onInput={(e) => {
                                        updateNewStudentField("lastname", e.target.value?.toUpperCase())
                                    }} />
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap">
                                    <div className="text-500 w-full md:w-3 font-medium">Prénom <span className="text-red-500">*</span></div>
                                    <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={newStudent?.firstname || ""} onInput={(e) => {
                                        updateNewStudentField("firstname", e.target.value?.toUpperCase())
                                    }} />
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">E-mail de contact <span className="text-red-500">*</span></div>
                                    <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={newStudent?.email || ""} onInput={(e) => {
                                        updateNewStudentField("email", e.target.value)
                                    }} />
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap">
                                    <div className="text-500 w-full md:w-3 font-medium">Date de naissance</div>
                                    <InputText type="date" className="p-inputtext-sm w-full md:w-9" value={newStudent?.birthday || ""} onChange={(e) => {
                                        console.log(e.target.value)
                                        updateNewStudentField("birthday", e.target.value)
                                    }} />
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">Genre</div>
                                    <Dropdown
                                        className="p-inputtext-sm w-full md:w-9"
                                        value={newStudent?.gender || ""}
                                        checkmark={true}
                                        onChange={(e) => { updateNewStudentField("gender", e.value) }}
                                        options={[
                                            { label: "", code: "N" },
                                            { label: "Fille", code: "G" },
                                            { label: "Garçon", code: "B" },
                                        ]}
                                        optionLabel="label"
                                        optionValue="code"
                                        placeholder="Genre"
                                    />
                                </li>

                                <li className="flex align-items-center py-2 px-2 flex-wrap">
                                    <div className="text-500 w-full md:w-3 font-medium">Téléphone</div>
                                    <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={newStudent?.phone || ""} onInput={(e) => {
                                        updateNewStudentField("phone", e.target.value)
                                    }} />
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">Adresse</div>
                                    <InputText type="text" className="p-inputtext-sm w-full md:w-9" value={newStudent?.address || ""} onInput={(e) => {
                                        updateNewStudentField("address", e.target.value)
                                    }} />
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap">
                                    <div className="text-500 w-full md:w-3 font-medium">Autorise l'école à m'envoyer des informations</div>
                                    <div className="text-900 w-full md:w-9 line-height-3"><Checkbox onChange={e => updateNewStudentField("accept_contacted", e.checked)} checked={newStudent?.accept_contacted}></Checkbox></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex ">
                        <Messages ref={msgs2} />
                    </div>
                    <div className="flex py-4 gap-2">
                        <Button label="Retour" outlined icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                        <Button label="Suivant" icon="pi pi-arrow-right" iconPos="right" onClick={() => {
                            if (newStudent.lastname === "" || newStudent.firstname === "" || newStudent.email === "") {
                                if (msgs2.current) {
                                    msgs2.current.clear();
                                    msgs2.current.show({ id: '2', sticky: true, severity: 'error', summary: 'Erreur', detail: 'Nom, prénom et e-mail de contact sont obligatoires', closable: true });
                                }
                            } else {
                                stepperRef.current.nextCallback();
                            }
                        }} />
                    </div>
                </StepperPanel>
                <StepperPanel header="Compléments">
                    <div className="flex flex-column mt-3">
                        <div className="mb-4">Veuillez compléter le formulaire de candidature</div>
                        <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                            <ul className="list-none p-0 m-0">
                                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-3 font-medium"></div>
                                    {selectedSearchMember && <Message severity="success" text="Elève déjà inscrit à l'école - ré-inscription dans cette nouvelle formation" /> || <Message severity="info" text="Elève jamais inscrit à l'école - nouvelle condidature" />}
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-3 font-medium">Elève</div>
                                    <div className="p-inputtext-sm w-full md:w-9 flex flex-column">
                                        {selectedSearchMember && <MeaMemberAvatar member={selectedSearchMember} /> || <div className="text-500">{newStudent?.lastname} {newStudent?.firstname} {newStudent?.birthday}</div>}
                                    </div>
                                </li>
                                {selectedSearchMember && <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">Formation précédente</div>

                                    <Dropdown className="w-full md:w-9" value={newStudent?.previous_training_id || ""}
                                        onChange={(e) => { updateNewStudentField("previous_training_id", e.value); console.log("selected:", e.value) }}
                                        options={previousRegistration}
                                        itemTemplate={(option) => {
                                            return (
                                                <div className="flex flex-wrap align-items-center ">
                                                    <div className="font-medium">{option?.training?.name || ""}</div>
                                                    <div className="text-900 ml-2"><MeaStudentStatus2 student={option} /></div>
                                                </div>
                                            )
                                        }}
                                        valueTemplate={(option) => {
                                            return (
                                                <div className="flex flex-wrap align-items-center ">
                                                    <div className="font-medium">{option?.training?.name || ""}</div>
                                                    <div className="text-900 ml-2"><MeaStudentStatus2 student={option} /></div>
                                                    <div className="text-900 ml-2"><MeaStudentResult student={option} /></div>
                                                </div>
                                            )
                                        }}
                                        optionLabel="name"
                                        optionValue="training.id"
                                    />

                                </li>}
                                <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                    <div className="text-500 w-full md:w-3 font-medium">Remarques</div>
                                    <InputTextarea type="text" rows={8} className="p-inputtext-sm w-full md:w-9" value={newStudent?.registration_details || ""} onInput={(e) => {
                                        updateNewStudentField("registration_details", e.target.value)
                                    }} />
                                </li>

                            </ul>

                        </div>
                    </div>
                    <div className="flex py-4 gap-2">
                        <Button label="Retour" outlined icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                        <Button label="Créer la candidature" icon="pi pi-arrow-right" iconPos="right" onClick={createStudent} />
                    </div>
                </StepperPanel>
                <StepperPanel header="Finale">
                    <div className="flex flex-column">
                        <div className="flex flex-column align-items-center my-4">
                            <span className="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style={{ width: '64px', height: '64px' }}>
                                <i className="pi pi-check text-5xl"></i>
                            </span>
                            <div className="font-medium text-2xl text-900">Dossier de candidature créé</div>
                            <div className="font-medium text-xl mt-4 text-900">{newStudent?.lastname} {newStudent?.firstname}</div>
                        </div>
                        <div className="flex flex-column align-items-center my-1">
                            <p className="line-height-3 ">
                                Vous pouvez consulter le dossier ou créer une nouvelle candidature.
                            </p>
                        </div>
                    </div>
                    <div className="flex py-4 flex-column align-items-center">
                        <Button label="Consulter le dossier" severity="secondary" icon="pi pi-book" onClick={() => {
                            navigate("/c/t/" + schoolId + "/" + trainingId + "/registrations/" + newStudent?.id);
                        }} />
                        <Button label="Créer une nouvelle candidature" icon="pi pi-plus" className="mt-2" onClick={() => {
                            stepperRef.current.setActiveStep(0);
                        }} />
                        <Button label="Liste des dossiers" outlined className="mt-2" onClick={() => {
                            navigate("/c/t/" + schoolId + "/" + trainingId + "/registrations");
                        }} />
                    </div>
                </StepperPanel>
            </Stepper>
        </div>

    );
};

export default NewRegistrationComponent;