import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import CreateCourseModal from "./AddStudentModal";
import MeaStudentAvatar from "../../../../components/MeaStudentAvatar";
import studentsAPI from "../../../../services/students";
import MeaLink from "../../../../components/MeaLink";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import MeaStudentStatus from "../../../../components/MeaStudentStatus";
import MeaStudentAge from "../../../../components/MeaStudentAge";
import classesAPI from "../../../../services/classes";
import levelsAPI from "../../../../services/levels";
import { Dropdown } from "primereact/dropdown";
import UpdateStudentAffectationModal from "./UpdateStudentAffectationModal";

const StudentsListComponent = forwardRef((props, ref) => {
  const { selectedFields, filter, showAddButton, showFilterButton } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const navigate = useNavigate();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  let selectedData = {
    name: selectedFields?.name ?? true,
    age: selectedFields?.age ?? true,
    level: selectedFields?.level ?? true,
    classe: selectedFields?.classe ?? true,
    status: selectedFields?.status ?? true,
    edit: selectedFields?.edit ?? true,
  };
  //
  const [state, setState] = useUrlState({
    studentsPage: "1",
    studentsPageSize: "30",
    studentsFilter: JSON.stringify({
      level_id: filter?.level_id || "",
      classe_id: filter?.classe_id || "",
      name: filter?.name || "",
    }),
  });
  //
  const [selectedStudent, setSelectedStudent] = useState(null);
  //
  const queryList = studentsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    JSON.parse(state.studentsFilter)
  );
  const levelsQuery = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const classesQuery = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000,
    {
      level_id: JSON.parse(state.studentsFilter)?.level_id,
    }
  );
  const status = [
    { value: "PREREGISTRED", label: "Pré-inscrit" },
    { value: "ENROLLED", label: "Inscrit" },
    { value: "DROPPEDOUT", label: "Abondon" },
    { value: "EXPELLED", label: "Exclu" },
    { value: "RETAINED", label: "Redoublé" },
    { value: "PASSED", label: "Réussi" },
  ];
  //
  const setFilter = (field, value) => {
    let currentFilter = state?.studentsFilter;
    let currentFilterObj = JSON.parse(currentFilter);
    currentFilterObj[field] = value;
    setState((s) => ({
      studentsPage: 1,
      studentsFilter: JSON.stringify(currentFilterObj),
    }));
  };
  const filterCombobox = (options, query, field) => {
    return (
      <Dropdown
        value={query?.data?.data?.find(
          (obj) => obj.id === JSON.parse(state.studentsFilter)[field]
        )}
        options={query?.data?.data}
        optionLabel="name"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
          setFilter(field, e.value?.id || "");
        }}
        //itemTemplate={statusItemTemplate}
        placeholder="Séléctionner"
        className="p-column-filter"
        showClear
      />
    );
  };
  //
  const setSearchName = (name) => {
    let currentFilter = state?.studentsFilter;
    let currentFilterObj = JSON.parse(currentFilter);
    currentFilterObj.name = name;
    setState((s) => ({
      studentsPage: 1,
      studentsFilter: JSON.stringify(currentFilterObj),
    }));
  };
  //

  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <SplitButton
          label="Pré-inscription d'un élève"
          icon="pi pi-plus"
          size="small"
          model={[
            {
              label: "Importer depuis une autre formation",
              icon: "",
              command: () => {},
            },
            {
              label: "Importer depuis une liste d'attente",
              icon: "",
              command: () => {},
            },
            {
              label: "Importer depuis un fichier",
              icon: "",
              command: () => {},
            },
          ]}
          className="mr-2"
          onClick={() => {
            navigate(
              "/c/t/" + schoolId + "/" + trainingId + "/students-registration"
            );
          }}
          data-pr-tooltip="Nouveau élève(e)"
        />
      )}
      <span className="p-input-icon-left mr-2 ">
        <i className="pi pi-search" />
        <InputText
          className="p-inputtext-sm "
          value={state.studentsFilter?.name}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder="Chercher un(e) élève"
        />
      </span>
    </div>
  );
  //
  return (
    <>
      <CreateCourseModal ref={createModalRef} />
      <UpdateStudentAffectationModal
        ref={updateModalRef}
        student={selectedStudent}
      />
      <MeaDataTable
        query={queryList}
        serviceAPI={studentsAPI}
        names={{
          pagination: "students",
          singular: "élève",
          plural: "élèves",
        }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        {selectedData.name && (
          <Column
            header="Elève"
            body={(student) => <MeaStudentAvatar student={student} />}
          ></Column>
        )}
        {selectedData.age && (
          <Column
            header="Age"
            body={(student) => (
              <MeaStudentAge student={student} showBirthdate={false} />
            )}
          ></Column>
        )}
        {selectedData.level && (
          <Column
            field="level"
            header="Niveau"
            body={(item) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/levels/" +
                  item?.level?.id
                }
              >
                {item?.level?.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, levelsQuery, "level_id")
            }
          ></Column>
        )}
        {selectedData.classe && (
          <Column
            field="classe"
            header="Classe"
            body={(item) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/classes/" +
                  item?.classe?.id
                }
              >
                {item?.classe?.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, classesQuery, "classe_id")
            }
          ></Column>
        )}
        {selectedData.status && (
          <Column
            field="status"
            header="Status"
            body={(item) => {
              return <MeaStudentStatus student={item} />;
            }}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) => {
              return (
                <Dropdown
                  value={JSON.parse(state.studentsFilter)["status"] || ""}
                  options={status}
                  onChange={(e) => {
                    options.filterApplyCallback(e.value);
                    setFilter("status", e.value || "");
                  }}
                  //itemTemplate={statusItemTemplate}
                  placeholder="Séléctionner"
                  className="p-column-filter"
                  showClear
                />
              );
            }}
          ></Column>
        )}
        {selectedData.edit && (
          <Column
            body={(item) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  text
                  aria-label="Filter"
                  onClick={() => {
                    setSelectedStudent(item);
                    if (updateModalRef.current) {
                      updateModalRef.current.show(() => {
                        queryList.refetch();
                      });
                    }
                  }}
                />
              </>
            )}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        )}
      </MeaDataTable>
    </>
  );
});

export default StudentsListComponent;
