import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import SessionsListComponent from "../../sessions/components/SessionsListComponent";

const DayCoursesModal = forwardRef((props, ref) => {
  const {filter} = props
  //
  const [active, setActive] = useState(false);
  const [callbackFn, setCallbackFn] = useState(() => {});
  const [elementQuery, setElementQuery] = useState();
  //
  const [loading, setLoading] = useState(false);
  //
  const toast = useRef(null);
  //

  //
  useImperativeHandle(ref, () => ({
    show: (callback, elementQuery) => {
      setCallbackFn(() => callback);
      setElementQuery(elementQuery);
      setActive(true);
    },
    getElementQuery: () => elementQuery,
  }));
  //

  const footerContent = (
    <div>
      <Button
        label="Fermer"
        icon="pi pi-times"
        disabled={loading}
        onClick={() => setActive(false)}
        className="p-button-text"
      />

    </div>
  );

  return (
    <>
      <Toast ref={toast} position="bottom-center" />
      <Dialog
        header={"Liste des séances de cours - " + filter.start_date}
        visible={active}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setActive(false)}
        footer={footerContent}
      >
        <div className="w-full">
          <SessionsListComponent
            ref={ref}
            showAddButton={false}
            showFilterButton={true}
            filter={{
              start_date: filter?.start_date || "",
              end_date: filter?.end_date || "",
            }}
          />
        </div>
      </Dialog>
    </>
  );
});

export default DayCoursesModal;
