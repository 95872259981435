import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import MeaDataTable from "../../../../components/MeaDataTable";
import systemFeaturesAPI from "../../../../services/system_features";

const SystemFeaturesListComponent = forwardRef((props, ref) => {
  const { showFilterButton } = props;
  const { auth } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = "";
  const trainingId = "";
  const accessToken = auth?.access_token;
  //
  //const createModalRef = useRef();
  //const updateModalRef = useRef();
  //
  //
  const queryList = systemFeaturesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
  );
  //
  const updateFeature = (id, value) => {
    console.log("id", id, "updateFeature", value);
    systemFeaturesAPI
      .UpdateOne(accessToken, id, { activated: value })
      .then((data) => {
        queryList?.refetch();
      });
  };
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //
  return (
    <>
      <MeaDataTable
        query={queryList}
        serviceAPI={systemFeaturesAPI}
        names={{
          pagination: "feature",
          singular: "Fonctionalité",
          plural: "Fonctionalités",
        }}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column field="name" header="Fonctionalité"></Column>
        <Column field="presentation" header="Présentation"></Column>
        <Column
          field="activated"
          header="Active?"
          body={(item) => (
            <InputSwitch
              inputId="chkStatus"
              className="mr-2"
              checked={item?.activated}
              tooltipOptions={{ position: "top" }}
              onChange={(e) => {
                updateFeature(item.id, e.value);
              }}
            />
          )}
        ></Column>
      </MeaDataTable>
    </>
  );
});

export default SystemFeaturesListComponent;
