import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";
import useAuthContext from "../../../../hooks/useAuthContext";
import MeaLink from "../../../../components/MeaLink";
import {
  ToFormatedDateWithWeekday,
  ToFormatedHour,
} from "../../../../utils/dates";
import MeaTeacherAvatar from "../../../../components/MeaTeacherAvatar";

const SessionDetailComponent = ({ session }) => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;

  return (
    <>
      <MeaDetailCard>
        <MeaDetailField
          field="Jour Debut"
          value={
            ToFormatedDateWithWeekday(new Date(session?.event?.start_date)) +
            "  " +
            ToFormatedHour(new Date(session?.event?.start_date))
          }
        />
        <MeaDetailField
          field="Jour Fin"
          value={
            ToFormatedDateWithWeekday(new Date(session?.event?.end_date)) +
            "  " +
            ToFormatedHour(new Date(session?.event?.end_date))
          }
        />
        <MeaDetailField
          field="Niveau"
          value={
            <MeaLink
              to={
                "/c/t/" +
                schoolId +
                "/" +
                trainingId +
                "/levels/" +
                session?.schedule_item?.level?.id
              }
            >
              {" "}
              {session?.schedule_item?.level?.name}{" "}
            </MeaLink>
          }
        />
        <MeaDetailField
          field="Classe"
          value={
            <MeaLink
              to={
                "/c/t/" +
                schoolId +
                "/" +
                trainingId +
                "/classes/" +
                session?.schedule_item?.classe?.id
              }
            >
              {" "}
              {session?.schedule_item?.classe?.name}{" "}
            </MeaLink>
          }
        />
        <MeaDetailField
          field="Cours"
          value={
            <MeaLink
              to={
                "/c/t/" +
                schoolId +
                "/" +
                trainingId +
                "/courses/" +
                session?.schedule_item?.course?.id
              }
            >
              {" "}
              {session?.schedule_item?.course?.name}{" "}
            </MeaLink>
          }
        />
        <MeaDetailField
          field="Salle"
          value={
            <MeaLink
              to={
                "/c/t/" +
                schoolId +
                "/" +
                trainingId +
                "/classrooms/" +
                session?.classroom?.id
              }
            >
              {" "}
              {session?.classroom?.name}{" "}
            </MeaLink>
          }
        />
        <MeaDetailField
          field="Enseignant(e)"
          value={
            <MeaTeacherAvatar
              teacher={session?.teacher}
              substitute={session?.schedule_item?.teacher}
            />
          }
        />
      </MeaDetailCard>
    </>
  );
};

export default SessionDetailComponent;
