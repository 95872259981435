import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
    schoolId,
    trainingId,
    accessToken,
    memberId,
    page = 1,
    pageSize = 30,
    filter
) => {
    return useQuery(
        ["students", schoolId, trainingId, accessToken, memberId, page, pageSize, filter],
        async () => {
            try {
                const response = await fetch(
                    BACKEND_URL +
                    `/api/v1/schools/${schoolId}/members/${memberId}/registrations?page=${page}&pageSize=${pageSize}`,
                    {
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + accessToken,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch registrations!");
                }
                if (response.status >= 299) {
                    throw new Error("Erreur de récupération de la liste des inscriptions!");
                }
                const result = await response.json();
                return result;
            } catch (error) {
                // Handle the error
                console.error("Error fetching registrations:", error);
                throw error;
            }
        }
    );
};




const memberRegistrationsAPI = {
    useGetList,
};

export default memberRegistrationsAPI;
