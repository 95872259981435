
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import useAuthContext from '../hooks/useAuthContext';

const MeaUpdateAvatarModal = forwardRef((props, ref) => {
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [active, setActive] = useState(false);
  const [callbackFn, setCallbackFn] = useState(() => {});
  //
  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  //
  useImperativeHandle(ref, () => ({
    show: (callback) => {
      setCallbackFn(() => callback);
      setActive(true);
    },
  }));
  //
  const onTemplateRemove = (file, callback) => {
      setTotalSize(totalSize - file.size);
      callback();
  };
  //
  const itemTemplate = (file, props) => {
      return (
          <div className="flex align-items-center flex-wrap">
              <div className="flex align-items-center" style={{ width: '40%' }}>
                  <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                  <span className="flex flex-column text-left ml-3">
                      {file.name}
                      <small>{new Date().toLocaleDateString()}</small>
                  </span>
              </div>
              <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
              <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
          </div>
      );
  };
  //
  const emptyTemplate = () => {
      return (
          <div className="flex align-items-center flex-column">
              <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
              <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                Glissez et déposez l'image ici
              </span>
          </div>
      );
  };
  //
  const uploadAvatar = async (event) => {
    const file = event.files[0];
    props.serviceAPI
      .UpdateAvatar(schoolId, accessToken, props.id, file)
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Avatar mis a jour!",
        });
        setActive(false);
        callbackFn();
        //
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur de modification!",
        });
      })

  };
  
  const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Modifier la photo"
        visible={active}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setActive(false)}
      >
        <div>
            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

            <FileUpload
              ref={fileUploadRef}
              accept="image/*"
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
              uploadHandler={uploadAvatar}
              customUpload
          />
        </div>
      </Dialog>
    </>
  )
});

export default MeaUpdateAvatarModal;