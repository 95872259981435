import { Avatar } from "primereact/avatar";
import MeaLink from "./MeaLink";
import useAuthContext from "../hooks/useAuthContext";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const MeaTeacherAvatar = ({ teacher, substitute }) => {
  const { currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  return (
    teacher && (
      <MeaLink
        to={"/c/t/" + schoolId + "/" + trainingId + "/teachers/" + teacher?.id}
      >
        <Avatar
          className="mr-2 p-overlay-badge"
          icon="pi pi-user"
          shape="circle"
          image={
            teacher?.member?.avatar
              ? BACKEND_URL + "/api/v1/medias/" + teacher?.member?.avatar
              : ""
          }
          label={
            teacher?.member?.lastname
              ? Array.from(teacher?.member?.lastname)[0] +
                (teacher?.member?.firstname
                  ? Array.from(teacher?.member?.firstname)[0]
                  : "")
              : teacher?.member?.name
              ? Array.from(teacher?.member?.name)[0]
              : ""
          }
          size="normal"
        >
          {substitute && substitute?.id !== teacher?.id && (
            <>
              <Tooltip target=".custom-target-icon" />
              <Badge
                size="normal"
                className="custom-target-icon"
                data-pr-tooltip={`${
                  teacher?.member?.name ||
                  teacher?.member?.lastname + " " + teacher?.member?.firstname
                } remplace ${
                  substitute?.member?.name ||
                  substitute?.member?.lastname +
                    " " +
                    substitute?.member?.firstname
                }`}
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                severity="warning"
                tooltip="hello"
                value={
                  <i
                    className="pi pi-arrow-right-arrow-left"
                    style={{ fontSize: "0.7rem" }}
                  ></i>
                }
              />
            </>
          )}
        </Avatar>
        <span>
          {teacher?.member?.name ||
            teacher?.member?.lastname + " " + teacher?.member?.firstname}
        </span>
      </MeaLink>
    )
  );
};
export default MeaTeacherAvatar;
