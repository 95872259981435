import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import coursesAPI from "../../../../services/courses";
import CreateCourseModal from "./CreateCourseModal";
import UpdateCourseModal from "./UpdateCourseModal";
import levelsAPI from "../../../../services/levels";
import { Dropdown } from "primereact/dropdown";

const CoursesListComponent = forwardRef((props, ref) => {
  const { selectedFields, filter, showFilterButton, showAddButton, showToolbar } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  let selectedData = {
    name: selectedFields?.name ?? true,
    presentation: selectedFields?.presentation ?? true,
    level: selectedFields?.level ?? true,
    edit: selectedFields?.edit ?? true,
  };
  //
  const [state, setState] = useUrlState({
    coursesPage: "1",
    coursesPageSize: "30",
    coursesFilter: JSON.stringify({
      level_id: filter?.level_id || "",
    }),
  });
  const [course, setCourse] = useState(null);
  //
  const queryList = coursesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    JSON.parse(state.coursesFilter)
  );
  //
  const levelsQuery = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  //
  const setFilter = (field, value) => {
    let currentFilter = state?.coursesFilter;
    let currentFilterObj = JSON.parse(currentFilter);
    currentFilterObj[field] = value;
    setState((s) => ({
      coursePage: 1,
      coursesFilter: JSON.stringify(currentFilterObj),
    }));
  };
  //
  const filterCombobox = (options, query, field) => {
    return (
      <Dropdown
        value={query?.data?.data?.find(
          (obj) => obj.id === JSON.parse(state.coursesFilter)[field]
        )}
        options={query?.data?.data}
        optionLabel="name"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
          setFilter(field, e.value?.id || "");
        }}
        //itemTemplate={statusItemTemplate}
        placeholder="Séléctionner"
        className="p-column-filter"
        showClear
      />
    );
  };

  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Créer un cours"
          icon="pi pi-plus"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouveau cours"
        />
      )}
    </div>
  );
  //
  return (
    <>
      <CreateCourseModal ref={createModalRef} levelId={filter?.level_id} />
      <MeaDataTable
        query={queryList}
        serviceAPI={coursesAPI}
        names={{ pagination: "courses", singular: "cours", plural: "cours" }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        showToolbar={showToolbar}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        {selectedData.name && (
          <Column
            field="name"
            header="Cours"
            body={(item) => (
              <MeaLink
                to={
                  "/c/t/" + schoolId + "/" + trainingId + "/courses/" + item.id
                }
              >
                {item.name}
              </MeaLink>
            )}
          ></Column>
        )}
        {selectedData.presentation && (
          <Column field="presentation" header="Presentation"></Column>
        )}
        {selectedData.level && (
          <Column
            field="level"
            header="Niveau"
            body={(item) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/levels/" +
                  item?.level?.id
                }
              >
                {item?.level?.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, levelsQuery, "level_id")
            }
          ></Column>
        )}
        {selectedData.edit && (
          <Column
            body={(item) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  text
                  aria-label="Filter"
                  onClick={() => {
                    setCourse(item);
                    if (updateModalRef.current) {
                      updateModalRef.current.show(() => {
                        queryList.refetch();
                      });
                    }
                  }}
                />
              </>
            )}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        )}
      </MeaDataTable>
      <UpdateCourseModal ref={updateModalRef} course={course} />
    </>
  );
});

export default CoursesListComponent;
