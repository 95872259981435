import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import trainingsAPI from "../../../../services/trainings";
import CreateTrainingModal from "./CreateTrainingModal";
import MeaLink from "../../../../components/MeaLink";
import UpdateTrainingModal from "../../../training/detail/components/UpdateTrainingModal";

const TrainingsListComponent = forwardRef((props, ref) => {
  const { showAddButton } = props;
  const { auth, currentMembership } = useAuthContext();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const schoolId = currentMembership?.school_id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  const [state, setState] = useUrlState({
    trainingName: "",
    trainingStatus: "",
  });
  const [training, setTraining] = useState(null);
  //
  const queryList = trainingsAPI.useGetList(
    schoolId,
    accessToken,
    page,
    pageSize,
    state.trainingStatus
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Créer une formation"
          icon="pi pi-plus"
          size="normal"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouvelle formation"
        />
      )}
      {/* 
      <span className="p-input-icon-left mr-2 ">
        <i className="pi pi-search" />
        <InputText
          disabled
          className="p-inputtext-sm"
          value={state.memberName}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder="Chercher une formation"
        />
      </span>
      */}
    </div>
  );
  //
  return (
    <>
      <CreateTrainingModal ref={createModalRef} />
      <MeaDataTable
        query={queryList}
        serviceAPI={trainingsAPI}
        names={{
          pagination: "trainings",
          singular: "formation",
          plural: "formations",
        }}
        startToolbarTemplate={startToolbarTemplate}
        //showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column
          field="name"
          header="Formation"
          body={(item) => (
            <MeaLink to={"/c/t/" + schoolId + "/" + item.id}>
              {item.name}
            </MeaLink>
          )}
        ></Column>
        <Column field="presentation" header="Présentation"></Column>
        <Column
          field="start_registration_date"
          header="Date de début des inscriptions"
          body={(item) => {
            return item?.start_registration_date ? new Date(item?.start_registration_date).toLocaleDateString("fr-FR") : "-";
          }}
        ></Column>
        <Column
          field="end_registration_date"
          header="Date de fin des inscriptions"
          body={(item) => {
            return item?.end_registration_date ? new Date(item?.end_registration_date).toLocaleDateString("fr-FR") : "-";
          }}
        ></Column>
        <Column
          field="start_courses_date"
          header="Date de début des cours"
          body={(item) => {
            return item?.start_courses_date ? new Date(item?.start_courses_date).toLocaleDateString("fr-FR") : "-";
          }}
        ></Column>
        <Column
          field="end_courses_date"
          header="Date de fin des cours"
          body={(item) => {
            return item?.end_courses_date ? new Date(item?.end_courses_date).toLocaleDateString("fr-FR") : "-";
          }}
        ></Column>
        <Column
          body={(item) => (
            <>
              <Button
                icon="pi pi-pencil"
                rounded
                text
                aria-label="Filter"
                onClick={() => {
                  setTraining(item);
                  if (updateModalRef.current) {
                    updateModalRef.current.show(() => {
                      queryList.refetch();
                    });
                  }
                }}
              />
            </>
          )}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </MeaDataTable>
      <UpdateTrainingModal ref={updateModalRef} training={training} />
    </>
  );
});

export default TrainingsListComponent;
