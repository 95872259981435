import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
  filter
) => {
  return useQuery(
    ["attendances", schoolId, trainingId, accessToken, page, pageSize, filter],
    async () => {
      //const filter = JSON.parse(filters) || undefined;
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/attendances?course_session_id=${
              filter?.course_session_id || ""
            }&page=${page}&pageSize=${pageSize}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch attendance!");
        }
        if (response.status >= 299) {
          throw new Error("Erreur de récupération de la feuille de présence!");
        }
        const levels = await response.json();
        return levels;
      } catch (error) {
        // Handle the error
        console.error("Error fetching attendance:", error);
        throw error;
      }
    }
  );
};

const GetOne = (schoolId, trainingId, accessToken, attendanceId) => {
  return useQuery(
    ["attendance", schoolId, trainingId, accessToken, attendanceId],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/attendances/${attendanceId}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            "Erreur de récupération d'une entrée de feuille de présence!"
          );
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    }
  );
};

const CreateOne = async (schoolId, trainingId, accessToken, data) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/attendances",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de génération de la feuille de présence!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (
  schoolId,
  trainingId,
  accessToken,
  attendanceId,
  data
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/attendances/" +
        attendanceId,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Erreur de mise à jour d'une entrée de la feuille de présence!`
      );
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, trainingId, accessToken, attendanceId) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/attendances/" +
        attendanceId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression de la séance de cours!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const attendancesAPI = {
  useGetList,
  GetOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
};

export default attendancesAPI;
