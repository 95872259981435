import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";

const TrainingDetailComponent = ({ training }) => {
  return (
    <>
      <MeaDetailCard>
        <MeaDetailField field="Nom" value={training?.name} />
        <MeaDetailField
          field="Présentation"
          value={training?.presentation || "-"}
        />
        <MeaDetailField
          field="Date de début d'inscriptions"
          value={training?.start_registration_date ? new Date(training?.start_registration_date).toLocaleDateString("fr-FR") : "-"}
        />
        <MeaDetailField
          field="Date de fin d'inscriptions"
          value={training?.end_registration_date ? new Date(training?.end_registration_date).toLocaleDateString("fr-FR") : "-"}
        />
        <MeaDetailField
          field="Date de début des cours"
          value={training?.start_courses_date ? new Date(training?.start_courses_date).toLocaleDateString("fr-FR") : "-"}
        />
        <MeaDetailField
          field="Date de fin des cours"
          value={training?.end_courses_date ? new Date(training?.end_courses_date).toLocaleDateString("fr-FR") : "-"}
        />

        {/* <MeaDetailField 
          field="Statut" 
          value={
            (training?.status === "OPEN" && (
              <Tag severity="success">OUVERTE</Tag>
            )) ||
            (training?.status === "DRAFT" && (
              <Tag severity="warning">EN PREPARATION</Tag>
            )) ||
            (training?.status === "SUSPENDED" && (
              <Tag severity="danger">SUSPENDUE</Tag>
            )) ||
            (training?.status === "ARCHIVED" && (
              <Tag severity="secondary">ARCHIVE</Tag>
            ))
          }
        /> */}
      </MeaDetailCard>
    </>
  );
};

export default TrainingDetailComponent;
