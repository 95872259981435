import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import useUrlState from "@ahooksjs/use-url-state";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ToFormatedDateForEvents } from "../../../../utils/dates";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import eventsAPI from "../../../../services/events";
import { Dropdown } from "primereact/dropdown";
import CreateEventModal from "./CreateEventModal";
import tasksAPI from "../../../../services/tasks";
import { Toast } from "primereact/toast";
import UpdateEventModal from "./UpdateEventModal";

const EventsListComponent = forwardRef((props, ref) => {
  const { showFilterButton, showAddButton } = props;
  //
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  const [state, setState] = useUrlState({
    eventType: "MEETING,VACATION,BANK_HOLIDAY,OTHER",
  });
  const [event, setEvent] = useState(null)
  //
  const toast = useRef();
  //
  let filters = {
    isAggregate: props?.filters?.isAggregate || false,
    eventType: state.eventType,
  };
  //
  const queryList = eventsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    filters
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <>
          <Button
            label="Créer un évènement"
            icon="pi pi-plus"
            size="small"
            className="p-button mr-2 action"
            onClick={() => {
              createModalRef.current.show(() => {
                queryList.refetch();
              });
            }}
            data-pr-tooltip="Nouveau évènement"
          />
          <Button
            label="Générer les jours fériés"
            icon="pi pi-calendar-plus"
            size="small"
            outlined
            className="p-button mr-2 action"
            onClick={() => {
              confirmDialog({
                message:
                  "Voulez-vous générer les jours fériés pour cette formation?",
                header: "Confirmation",
                icon: "pi pi-info-circle",
                acceptLabel: "Oui",
                rejectLabel: "Non",
                defaultFocus: "reject",
                accept: () => {
                  tasksAPI
                    .GenerateHolidays(schoolId, trainingId, accessToken)
                    .then((data) => {
                      toast.current.show({
                        severity: "success",
                        summary: "Succès",
                        detail: "Succès",
                        life: 3000,
                      });
                      queryList?.refetch();
                    })
                    .catch((error) => {
                      toast.current.show({
                        severity: "error",
                        summary: "Erreur",
                        detail: "Erreur de génération!",
                      });
                    });
                },
              });
            }}
            data-pr-tooltip="Nouveau évènement"
          />
        </>
      )}
    </div>
  );
  //
  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} position="bottom-center" />
      <CreateEventModal ref={createModalRef} />
      <MeaDataTable
        query={queryList}
        serviceAPI={eventsAPI}
        names={{
          pagination: "event",
          singular: "évènement",
          plural: "évènements",
        }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column
          header="Nom de l'évènement"
          body={(event) => {
            return (
              <MeaLink
                to={
                  "/c/t/" + schoolId + "/" + trainingId + "/events/" + event.id
                }
              >
                {event?.name}
              </MeaLink>
            );
          }}
        ></Column>
        <Column header="Description" field="presentation"></Column>
        <Column
          header="Date de début"
          body={(event) => {
            const startSate = new Date(event?.start_date);
            const formattedStartDate = ToFormatedDateForEvents(
              startSate,
              event?.all_day
            );
            return <span>{formattedStartDate}</span>;
          }}
        ></Column>
        <Column
          header="Date de fin"
          body={(event) => {
            const endSate = new Date(event?.end_date);
            const formattedEndDate = ToFormatedDateForEvents(
              endSate,
              event?.all_day
            );
            return <span>{formattedEndDate}</span>;
          }}
        ></Column>
        <Column
          field="event.type"
          header="Type d'évènement"
          body={(event) => event?.event_type}
          filter
          showFilterMenu={false}
          filterElement={(options) => {
            return (
              <Dropdown
                value={state.eventType}
                options={[
                  {
                    value: "MEETING,VACATION,BANK_HOLIDAY,OTHER",
                    label: "Tous les types d'événements",
                  },
                  { value: "MEETING", label: "Réunions" },
                  { value: "VACATION", label: "Vacances" },
                  { value: "BANK_HOLIDAY", label: "Jours fériés" },
                  { value: "OTHER", label: "Autres" },
                ]}
                onChange={(e) => {
                  options.filterApplyCallback(e.value);
                  setState((s) => ({
                    eventPage: 1,
                    eventType: e.value ? e.value : "",
                  }));
                }}
                //itemTemplate={statusItemTemplate}
                placeholder=""
                className="p-column-filter"
                style={{ minWidth: "6rem" }}
              />
            );
          }}
        ></Column>
        <Column
          body={(item) => (
            <>
              <Button
                icon="pi pi-pencil" 
                rounded text aria-label="Filter"
                onClick={()=>{
                  setEvent(item)
                  if (updateModalRef.current) {
                    updateModalRef.current.show(() => {
                      queryList.refetch();
                    });
                  }
                }}
              />
            </>
          )}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </MeaDataTable>
      <UpdateEventModal ref={updateModalRef} event={event} />
    </>
  );
});

export default EventsListComponent;
