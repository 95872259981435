import React from "react";
import moment from "moment";
import { Tag } from "primereact/tag";

const MeaEvenTypeBadge = ({ event, isBox }) => {
  const badgeInfos = getToneForEvent(event);
  return (
    <Tag  value={event.title} style={{ width: "100%", backgroundColor: badgeInfos.backgroundColor, color: badgeInfos.color }}/>
  );
};

export const getToneForEvent = (event) => {
  const eventType = event?.event_type;
  switch (eventType) {
    case "MEETING":
      return { backgroundColor: "#22c55e", color: "white" };
    case "VACATION":
      return { backgroundColor: "#ef4444" , color: "white" };
    case "BANK_HOLIDAY":
      return { backgroundColor: "#ef4444" , color: "white" };
    case "COURSE_SESSION":
      const currentDate = moment().startOf("day");
      const endDate = moment(event?.endDate).startOf("day");
      if (endDate.isAfter(currentDate)) {
        return { backgroundColor: "#f97316" , color: "white" };
      } else if (endDate.isBefore(currentDate)) {
        return { backgroundColor: "#e8e8e8" , color: "#8c6161" };
      } else {
        return { backgroundColor: "#0ea5e9" , color: "white" };
      }
    default:
      return { backgroundColor: "#0c2c3b" , color: "white" };
  }
};

export default MeaEvenTypeBadge;
