import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import SessionsListComponent from "../../training/sessions/components/SessionsListComponent";

const AttendancesListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Feuilles de présences"
        subtitle="Gérer l'ensemble des feuilles de présences"
      >
        <SessionsListComponent
          ref={listRef}
          showAddButton={false}
          showFilterButton={true}
          selectedFields={{
            presence: true,
            presenceStatistics: true,
          }}
        />
      </MeaPage>
    </>
  );
};
export default AttendancesListPage;
