import { Avatar } from "primereact/avatar";
import MeaLink from "./MeaLink";
import useAuthContext from "../hooks/useAuthContext";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const MeaMemberAvatar = ({ member }) => {
  const { currentMembership } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  return (
    member !== null &&
    member !== undefined &&
    ((
      <MeaLink to={"/c/s/" + schoolId + "/members/" + member?.id}>
        <Avatar
          className="mr-2 p-overlay-badge"
          icon="pi pi-user"
          shape="circle"
          image={
            member?.avatar
              ? BACKEND_URL + "/api/v1/medias/" + member?.avatar
              : ""
          }
          label={
            member?.lastname
              ? Array.from(member?.lastname)[0] +
                (member?.firstname ? Array.from(member?.firstname)[0] : "")
              : member?.name
              ? Array.from(member?.name)[0]
              : ""
          }
          size="normal"
        ></Avatar>
        <span>
          {member?.name || member?.lastname + " " + member?.firstname}
        </span>
      </MeaLink>
    ) || <>-</>)
  );
};
export default MeaMemberAvatar;
