import { Badge } from "primereact/badge";
import { Ripple } from "primereact/ripple";
import { StyleClass } from "primereact/styleclass";
import { useRef, useState } from "react";
import useAuthContext from "../hooks/useAuthContext";
import { Button } from "primereact/button";
import SwitchTrainingModal from "../pages/school/trainings/modals/SwitchTrainingModal";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const MeaLayout = ({ sidebar, content, schoolMode = false }) => {
  const navigate = useNavigate();
  const {
    currentMembership,
    currentTraining,
    currentAccount,
    setCurrentTraining,
    updateCurrentMembership,
    setCurrentAccount,
    logoutCurrentAccount,
  } = useAuthContext();

  const btnRef10 = useRef(null);
  const btnRef11 = useRef(null);

  const avatarMenu = useRef(null);
  const avatarMenuItems = [
    {
      template: (item, options) => {
        return (
          <a
            className="p-ripple flex p-2 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full"
          >
            <img
              src={
                currentAccount?.avatar
                  ? BACKEND_URL + "/api/v1/medias/" + currentAccount?.avatar
                  : "/user.png"
              }
              className="mr-3 border-circle lg:hidden"
              style={{ width: "32px", height: "32px" }}
              alt="User"
            />
            <div className="block">
              <div className="text-900 font-medium">
                {currentAccount?.firstname} {currentAccount?.lastname}
              </div>
              <span className="text-600 font-medium text-sm">
                {currentAccount?.email}
              </span>
            </div>
            <Ripple />
          </a>
        );
      },
    },
    {
      separator: true,
    },
    {
      label: "Mon profil utilisateur",
      icon: "pi pi-user",
      command: () => {
        navigate("/c/user/profile");
      },
    },
    {
      label: "Gestion de l'école",
      visible: currentMembership?.school !== undefined ? true : false,
      icon: "pi pi-home",
      command: () => {
        navigate("/c/s/" + currentMembership?.school_id);
      },
    },
    {
      label: "Mes écoles",
      icon: "pi pi-home",
      command: () => {
        navigate("/c/user/schools");
      },
    },
    {
      label: "Super Admin",
      visible: currentAccount?.superadmin ? true : false,
      icon: "pi pi-cog",
      command: () => {
        navigate("/c/sys/schools");
      },
    },
    {
      separator: true,
    },
    {
      label: "Déconnexion",
      icon: "pi pi-sign-out",
      command: () => {
        logout();
      },
    },
  ];

  const _logo = currentMembership?.school?.avatar
    ? BACKEND_URL + "/api/v1/medias/" + currentMembership?.school.avatar
    : "/mea-logo.png";

  const switchTrainingModal = useRef();

  const logout = async () => {
    setCurrentTraining(null);
    updateCurrentMembership(null);
    setCurrentAccount(null);
    logoutCurrentAccount();
    navigate("/d/login");
  };

  return (
    <>
      <SwitchTrainingModal
        ref={switchTrainingModal}
        schoolId={currentMembership?.school_id}
      />
      <div className="min-h-screen flex relative lg:static surface-ground">
        {sidebar}
        <div className="super-sidebar min-h-screen flex flex-column relative flex-auto">
          <div
            className="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static"
            style={{ height: "60px" }}
          >
            <div className="flex">
              <StyleClass
                nodeRef={btnRef10}
                selector="#app-sidebar-2"
                enterClassName="hidden"
                enterActiveClassName="fadeinleft"
                leaveToClassName="hidden"
                leaveActiveClassName="fadeoutleft"
                hideOnOutsideClick
              >
                <a
                  ref={btnRef10}
                  className="p-ripple cursor-pointer block lg:hidden text-700 mr-3"
                >
                  <i className="pi pi-bars text-4xl"></i>
                  <Ripple />
                </a>
              </StyleClass>

              {schoolMode && (
                <div
                  className="flex align-items-center"
                  style={{ height: "60px" }}
                >
                  <img src={_logo} alt="MEA" height={40} />
                </div>
              ) || (
                  <Button
                    onClick={(e) => switchTrainingModal.current.open()}
                    className="bg-gray-100 text-color-secondary hover:bg-gray-400 border-gray-700"
                  >
                    <i className="pi pi-inbox text-base  mr-2 "></i>
                    <span className="block font-medium">
                      {currentTraining?.name}
                    </span>
                    <Ripple />
                  </Button>
                )}
            </div>
            <StyleClass
              nodeRef={btnRef11}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="fadein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
              hideOnOutsideClick
            >
              <a
                onClick={(event) => avatarMenu.current.toggle(event)}
                className="p-ripple cursor-pointer block lg:hidden text-700"
              >
                <i className="pi pi-ellipsis-v text-2xl"></i>
                <Ripple />
              </a>
            </StyleClass>
            <ul
              className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
    surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static"
            >
              <li className="border-top-1 surface-border lg:border-top-none">
                <Menu
                  model={avatarMenuItems}
                  popup
                  ref={avatarMenu}
                  id="popup_avatar_menu"
                />
                <a
                  onClick={(event) => avatarMenu.current.toggle(event)}
                  className="p-ripple flex p-2 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full"
                >
                  <img
                    src={
                      currentAccount?.avatar
                        ? BACKEND_URL +
                        "/api/v1/medias/" +
                        currentAccount?.avatar
                        : "/user.png"
                    }
                    className="border-circle"
                    style={{ width: "37px", height: "37px" }}
                    alt="User"
                  />
                  <div className="block lg:hidden">
                    <div className="text-900 font-medium">
                      Josephine Lillard
                    </div>
                    <span className="text-600 font-medium text-sm">
                      Marketing Specialist
                    </span>
                  </div>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  className="p-ripple lg:hidden flex p-2 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full"
                >
                  <i className="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                    <Badge severity="danger" />
                  </i>
                  <span className="block lg:hidden font-medium">
                    Mon profil utilisateur
                  </span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  className="p-ripple lg:hidden flex p-2 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full"
                >
                  <i className="pi pi-home text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                    <Badge severity="danger" />
                  </i>
                  <span className="block lg:hidden font-medium">
                    Mes écoles
                  </span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  className="p-ripple lg:hidden flex p-2 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                transition-duration-150 transition-colors w-full"
                >
                  <i className="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                    <Badge severity="danger" />
                  </i>
                  <span className="block lg:hidden font-medium">
                    Super Admin
                  </span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  className="p-ripple lg:hidden flex p-2 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full"
                >
                  <i className="pi pi-sign-out text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                    <Badge severity="danger" />
                  </i>
                  <span className="block lg:hidden font-medium">
                    Déconnexion
                  </span>
                  <Ripple />
                </a>
              </li>
            </ul>
          </div>

          <div className="p-0 m-0 flex flex-column flex-auto">
            <div className="flex-auto">{content}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeaLayout;
