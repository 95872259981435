import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import membersAPI from "../../../../services/members";
import { Dropdown } from "primereact/dropdown";
import {
  ToFormatedISOTime,
  ToFormatedShortDate,
} from "../../../../utils/dates";

const UpdateMemberModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");

  //
  useEffect(() => {
    setName(props?.member?.name);
    setFirstname(props?.member?.firstname);
    setLastname(props?.member?.lastname);
    setEmail(props?.member?.email);
    setPhone(props?.member?.phone);
    setAddress(props?.member?.address);
    setGender(props?.member?.gender);
    setBirthday(props?.member?.birthday);
  }, [props]);
  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer le profil"
        serviceAPI={membersAPI}
        id={props?.member?.id}
        data={{
          name: name,
          firstname: firstname,
          lastname: lastname,
          gender: gender,
          birthday: birthday ? ToFormatedShortDate(new Date(birthday)) : "",
          phone: phone,
          address: address,
        }}
      >
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex-1 flex-column">
            <label
              htmlFor="lastname"
              className="block text-900 font-medium mb-2"
            >
              Nom de famille
            </label>
            <InputText
              id="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="w-full mb-1"
            />
          </div>

          <div className="p-inputgroup flex-1 flex-column">
            <label
              htmlFor="firstname"
              className="block text-900 font-medium mb-2"
            >
              Prénom
            </label>
            <InputText
              id="firstname"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              className="w-full mb-1"
            />
          </div>
        </div>

        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex-1 flex-column">
            <label htmlFor="name" className="block text-900 font-medium mb-2">
              Nom d'usage
            </label>
            <InputText
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full mb-1"
            />
          </div>

          <div className="p-inputgroup flex-1 flex-column">
            <label htmlFor="gender" className="block text-900 font-medium mb-2">
              Genre
            </label>
            <Dropdown
              id="gender"
              value={gender}
              options={[
                { label: "Masculin", value: "B" },
                { label: "Féminin", value: "G" },
              ]}
              onChange={(e) => setGender(e.value)}
              optionLabel="label"
              className="w-full mb-1"
            />
          </div>
        </div>

        <label htmlFor="birthday" className="block text-900 font-medium mb-2">
          Date de naissance
        </label>
        <InputText
          id="birthday"
          type="date"
          value={birthday ? new Date(birthday).toISOString().split("T")[0] : ""}
          onChange={(e) => setBirthday(e.target.value)}
          className="w-full mb-1"
        />

        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex-1 flex-column">
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-1"
              disabled={true}
            />
          </div>

          <div className="p-inputgroup flex-1 flex-column">
            <label htmlFor="phone" className="block text-900 font-medium mb-2">
              Téléphone
            </label>
            <InputText
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full mb-1"
            />
          </div>
        </div>

        <label htmlFor="adress" className="block text-900 font-medium mb-2">
          Adresse
        </label>
        <InputTextarea
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </MeaUpdateModal>
    </>
  );
});

export default UpdateMemberModal;
