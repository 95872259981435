import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";

const EventDetailComponent = ({ event }) => {
  return (
    <MeaDetailCard>
      <MeaDetailField field="Evènement" value={event?.name} />
      <MeaDetailField field="Présentation" value={event?.presentation || " "} />
    </MeaDetailCard>
  );
};

export default EventDetailComponent;
