import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import CoursesListComponent from "./components/CoursesListComponent";

const CoursesListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des cours"
        subtitle="Gérer l'ensemble des cours de cette formation"
      >
        <CoursesListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default CoursesListPage;
