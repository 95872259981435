import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
  email = "",
) => {
  return useQuery(
    ["system-accounts", accessToken, page, pageSize, email],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/system/accounts?page=${page}&pageSize=${pageSize}&email=${email}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          },
        );

        if (!response.ok) {
          throw new Error(
            "Erreur de récupération de la liste des comptes utilisateurs!",
          );
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    },
  );
};

const GetOne = (schoolId, trainingId, accessToken, id) => {
  return useQuery(
    ["systemAccount", schoolId, trainingId, accessToken, id],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL + `/api/v1/system/accounts/${id}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          },
        );

        if (!response.ok) {
          throw new Error("Erreur de récupération du compte utilisateur!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    },
  );
};

const DeleteOne = async (schoolId, trainingId, accessToken, id) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/api/v1/system/accounts/" + id,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      },
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression du compte utilisateur!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const systemAccountsAPI = {
  useGetList,
  GetOne,
  DeleteOne,
};

export default systemAccountsAPI;
