import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import classroomsAPI from "../../../../services/classrooms";
import { InputTextarea } from "primereact/inputtextarea";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";

const UpdateClassroomModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");
  const [capacity, setCapacity] = useState("15");
  //
  useEffect(() => {
    setName(props?.classroom?.name);
    setPresentation(props?.classroom?.presentation);
    setCapacity(props?.classroom?.capacity);
  }, [props]);

  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer la salle"
        serviceAPI={classroomsAPI}
        id={props?.classroom?.id} 
        data={{
          name: name,
          presentation: presentation,
          capacity: parseInt(capacity),
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom de la salle
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
        <label htmlFor="capacity" className="block text-900 font-medium mb-2">
          Capacité
        </label>
        <InputText
          id="capacity"
          type="number"
          value={capacity}
          onChange={(e) => setCapacity(e.target.value)}
          className="w-full mb-3"
        />
      </MeaUpdateModal>
    </>
  );
});

export default UpdateClassroomModal;
