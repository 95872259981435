import React, { useState, forwardRef } from "react";

import { InputText } from "primereact/inputtext";
import MeaCreateModal from "../../../../components/MeaCreateModal";
import { InputTextarea } from "primereact/inputtextarea";
import queuesAPI from "../../../../services/queues";

const CreateQueueModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");
  //
  return (
    <>
      <MeaCreateModal
        ref={ref}
        title="Créer une nouvelle liste d'attente"
        serviceAPI={queuesAPI}
        data={{
          name: name,
          presentation: presentation,
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom de la liste
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />

        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
        {/* <p>
          La formation aura un status{" "}
          <Tag severity="warning" value="EN PREPARATION"></Tag> par défault.
          Vous devriez compléter son contenue avant de l'activer et donc le
          rendre disponible aux différents utilisateurs concernés.
        </p> */}
      </MeaCreateModal>
    </>
  );
});

export default CreateQueueModal;
