import React, { useState, forwardRef, useEffect } from "react";

import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import { Dropdown } from "primereact/dropdown";
import studentsAPI from "../../../../services/students";
import levelsAPI from "../../../../services/levels";
import classesAPI from "../../../../services/classes";
import useAuthContext from "../../../../hooks/useAuthContext";

const UpdateStudentAffectationModal = forwardRef((props, ref) => {
  //
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [classId, setClassId] = useState("");
  const [levelId, setLevelId] = useState("");

  //
  useEffect(() => {
    setLevelId(props?.student?.level_id);
    setClassId(props?.student?.class_id);
  }, [props]);
  //
  const levelsQuery = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const classesQuery = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000,
    {
      level_id: levelId,
    }
  );
  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Changement de niveau / classe"
        serviceAPI={studentsAPI}
        id={props?.student?.id}
        data={{
          level_id: levelId,
          class_id: classId,
        }}
      >
        <div className="w-full">
          <div className="flex flex-column gap-2">
            <label htmlFor="level" className="block text-900 font-medium mb-2">
              Niveau
            </label>
            <Dropdown
              value={levelsQuery?.data?.data?.find((obj) => obj.id === levelId)}
              onChange={(e) => setLevelId(e.value?.id)}
              options={levelsQuery?.data?.data}
              optionLabel="name"
              className="w-full mb-3"
            />
            <label htmlFor="classe" className="block text-900 font-medium mb-2">
              Classe
            </label>
            <Dropdown
              disabled={
                levelId === null || levelId === undefined || levelId === ""
              }
              value={classesQuery?.data?.data?.find(
                (obj) => obj.id === classId
              )}
              onChange={(e) => setClassId(e.value?.id)}
              options={classesQuery?.data?.data}
              optionLabel="name"
              className="w-full mb-3"
            />
          </div>
        </div>
      </MeaUpdateModal>
    </>
  );
});

export default UpdateStudentAffectationModal;
