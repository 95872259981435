import { useNavigate, useParams } from "react-router-dom";
import MeaPage from "../../../components/MeaPage";
import useAuthContext from "../../../hooks/useAuthContext";
import MeaPageTitle from "../../../components/MeaPageTitle";
import { useRef, useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import MeaLoadingContent from "../../../components/MeaLoadingContent";
import { Panel } from "primereact/panel";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import queuesAPI from "../../../services/queues";
import UpdateQueueModal from "./components/UpdateQueueModal";
import QueueDetailComponent from "./components/QueueDetailComponent";
import QueueLevelsListComponent from "./components/QueueLevelsListComponent";
import QueueStudentsListComponent from "./components/QueueStudentsListComponent";

const QueueDetailPage = () => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const params = useParams();
  const updateModalRef = useRef();
  const toast = useRef();
  const navigate = useNavigate();
  //
  const queueId = params.queueId;
  //
  const queueQuery = queuesAPI.GetOne(schoolId, queueId, accessToken);
  const queueObj = queueQuery?.data?.data;
  //
  // states
  const [activeItem, setActiveItem] = useState(0);
  // params
  const items = [
    { label: "Liste d'élèves en attente", icon: "pi pi-users" },
    { label: "Niveaux", icon: "pi pi-sliders-v" },
  ];

  //
  return (
    <>
      <MeaPage
        title={
          <MeaPageTitle prefix={"Liste d'attente:"} title={queueObj?.name} />
        }
        actions={[
          {
            label: "Modifier",
            icon: "pi pi-file-edit",
            command: () => {
              if (updateModalRef.current) {
                updateModalRef.current.show(() => {
                  queueQuery.refetch();
                });
              }
            },
          },
          {
            separator: true,
          },
          {
            label: "Supprimer",
            icon: "pi pi-trash",
            command: () => {
              confirmDialog({
                message:
                  "Voulez-vous vraiment supprimer cette liste d'attente?",
                header: "Confirmation",
                icon: "pi pi-info-circle",
                defaultFocus: "reject",
                acceptClassName: "p-button-danger",
                accept: () => {
                  queuesAPI
                    .DeleteOne(schoolId, queueId, accessToken)
                    .then((data) => {
                      toast.current.show({
                        severity: "success",
                        summary: "Succès",
                        detail: queueObj?.name + " Supprimé!",
                        life: 3000,
                      });
                      navigate("/c/t/" + schoolId + "/queues");
                    })
                    .catch((error) => {
                      toast.current.show({
                        severity: "error",
                        summary: "Erreur",
                        detail: "Erreur de suppression!",
                      });
                    });
                },
              });
            },
          },
        ]}
      >
        <QueueDetailComponent queue={queueObj} />
        <UpdateQueueModal ref={updateModalRef} level={queueObj} />
        <ConfirmDialog />
        <Toast ref={toast} />

        <Panel className="mt-5">
          <TabMenu
            model={items}
            activeIndex={activeItem}
            onTabChange={(e) => {
              setActiveItem(e.index);
            }}
          />
          {activeItem === 0 && (
            <MeaLoadingContent objectQuery={queueQuery}>
              <QueueStudentsListComponent showAddButton={true} />
            </MeaLoadingContent>
          )}
          {activeItem === 1 && (
            <MeaLoadingContent objectQuery={queueQuery}>
              <QueueLevelsListComponent showAddButton={true} />
            </MeaLoadingContent>
          )}
        </Panel>
      </MeaPage>
    </>
  );
};
export default QueueDetailPage;
