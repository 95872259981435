import React, { useState, forwardRef } from "react";

import MeaCreateModal from "../../../../components/MeaCreateModal";
import { Dropdown } from "primereact/dropdown";
import attendancesAPI from "../../../../services/attendances";

const GenerateAttendancesModal = forwardRef((props, ref) => {
  //
  //
  const options = [
    { key: "NOTSET", label: "Non défini", value: "NOTSET" },
    { key: "PRESENT", label: "Présent", value: "PRESENT" },
    { key: "ABSENT", label: "Absent", value: "ABSENT" },
    { key: "LATE", label: "Retard", value: "LATE" },
  ];
  //
  const [defaultStatus, setDefaultStatus] = useState("PRESENT");
  //
  return (
    <>
      <MeaCreateModal
        ref={ref}
        title="Générer la feuille de présence"
        serviceAPI={attendancesAPI}
        data={{
          course_session_id: props?.session?.id,
          default_status: defaultStatus,
        }}
      >
        <span>
          Confirmez-vous la génération de la feuille de présence pour cette
          séance de cours?{" "}
        </span>
        <label htmlFor="status" className="block text-900 font-medium mb-2">
          Présence par défaut à mettre pour tous les élèves
        </label>
        <Dropdown
          id="status"
          options={options}
          value={defaultStatus || "PRESENT"}
          onChange={(e) => setDefaultStatus(e.value)}
          optionLabel="label"
          placeholder="Choisir la valeur par défaut"
          className="w-full mb-3"
        />
      </MeaCreateModal>
    </>
  );
});

export default GenerateAttendancesModal;
