import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import { InputTextarea } from "primereact/inputtextarea";
import trainingsAPI from "../../../../services/trainings";

const UpdateTrainingModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");
  const [trainingStartDate, setTrainingStartDate] = useState("");
  const [trainingEndDate, setTrainingEndDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");

  useEffect(() => {
    setName(props?.training?.name);
    setPresentation(props?.training?.presentation);
    setTrainingStartDate(props?.training?.start_courses_date || "");
    setTrainingEndDate(props?.training?.end_courses_date || "");
    setRegistrationStartDate(props?.training?.start_registration_date || "");
    setRegistrationEndDate(props?.training?.end_registration_date || "");
  }, [props]);
  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer la formation"
        id={props?.training?.id}
        serviceAPI={trainingsAPI}
        data={{
          name: name,
          presentation: presentation,
          start_courses_date:
            trainingStartDate !== "" && trainingStartDate !== undefined
              ? new Date(trainingStartDate).toISOString()
              : null,
          end_courses_date:
            trainingEndDate !== "" && trainingStartDate !== trainingEndDate
              ? new Date(trainingEndDate).toISOString()
              : null,
          start_registration_date:
            registrationStartDate !== "" && registrationStartDate !== undefined
              ? new Date(registrationStartDate).toISOString()
              : null,
          end_registration_date:
            registrationEndDate !== "" && registrationStartDate !== registrationEndDate
              ? new Date(registrationEndDate).toISOString()
              : null,
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom de la formation
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="startregistrationdate"
              className="block text-900 font-medium mb-2"
            >
              Date de début des inscriptions
            </label>
            <InputText
              id="startregistrationdate"
              type="date"
              value={
                registrationStartDate
                  ? new Date(registrationStartDate).toISOString().split("T")[0]
                  : ""
              }
              onChange={(e) => setRegistrationStartDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="endregistrationdate"
              className="block text-900 font-medium mb-2"
            >
              Date de fin des inscriptions
            </label>
            <InputText
              id="endregistrationdate"
              type="date"
              value={
                registrationEndDate
                  ? new Date(registrationEndDate).toISOString().split("T")[0]
                  : ""
              }
              onChange={(e) => setRegistrationEndDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
        </div>
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="startregistrationdate"
              className="block text-900 font-medium mb-2"
            >
              Date de début des cours
            </label>
            <InputText
              id="startdate"
              type="date"
              value={
                trainingStartDate
                  ? new Date(trainingStartDate).toISOString().split("T")[0]
                  : ""
              }
              onChange={(e) => setTrainingStartDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="enddate"
              className="block text-900 font-medium mb-2"
            >
              Date de fin des cours
            </label>
            <InputText
              id="enddate"
              type="date"
              value={
                trainingEndDate
                  ? new Date(trainingEndDate).toISOString().split("T")[0]
                  : ""
              }
              onChange={(e) => setTrainingEndDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
        </div>

      </MeaUpdateModal>
    </>
  );
});

export default UpdateTrainingModal;
