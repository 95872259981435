import { useState /*, useEffect*/, useRef } from "react";
import { useNavigate } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";
import MeaLink from "../../components/MeaLink";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const RESET_PASS_URL = BACKEND_URL + "/api/v1/auth/requestresetpass";

const PasswordReset = () => {
  const navigate = useNavigate();
  //
  const captchaRef = useRef(null);
  //
  const [email, setEmail] = useState("");
  //
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const [errMsg, setErrMsg] = useState("");
  //

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    setLoading(true);
    fetch(RESET_PASS_URL, {
      method: "POST",
      body: JSON.stringify({ email: email, "g-recaptcha-response": token }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status >= 299) {
          throw new Error("");
        }
        return response.json();
      })
      .then((response) => {
        setErrMsg("");
        toast.current.show({
          severity: "success",
          summary: "Félécitations!",
          detail:
            "Un email a été envoyé à " +
            email +
            " pour définir votre mot de passe.",
          sticky: true,
          content: (props) => (
            <div
              className="flex flex-column align-items-left"
              style={{ flex: "1" }}
            >
              <div className="flex align-items-center gap-2">
                <span className="font-bold text-900">
                  {props.message.summary}
                </span>
              </div>
              <div className="font-medium text-lg my-3 text-900">
                {props.message.detail}
              </div>
              <Button
                className="p-button-sm flex"
                label="S'identifier"
                severity="success"
                onClick={clear}
              ></Button>
            </div>
          ),
        });
      })
      .catch(function (error) {
        setEmail("");
        setErrMsg(
          "Problème de réinitialisation de mot de passe! " + error.message
        );
      })
      .finally(() => {
        //
        setLoading(false);
      });
  };

  const clear = () => {
    toast.current.clear();
    navigate("/d/login");
  };

  return (
    <>
      <Toast ref={toast} position="center" onRemove={clear} />
      <div style={{ paddingTop: "30px", paddingBottom: "30px" }} className="flex justify-content-center flex-wrap">
        <div style={{ maxWidth: "400px" }} className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="flex align-items-start">
              <Button
                icon="pi pi-arrow-left"
                text
                style={{ minHeight: "50px", maxWidth: "50px" }}
                onClick={() => window.history.back()}
              ></Button>
              <div>
                <MeaLink to="/d/welcome"><img src="/mea-logo.png" alt="hyper" height={50} className="mb-3 pl-3" /></MeaLink>
              </div>
            </div>
            <div className="text-900 text-3xl font-medium mb-3">Mot de passe oublié?</div>
          </div>

          <div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" className="block text-900 font-medium mb-2">Votre adresse email</label>
              <InputText id="email" type="text" placeholder="" className="w-full mb-3" value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus />

              <div className="flex align-items-center justify-content-between mb-4">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                ></ReCAPTCHA>
              </div>
              {errMsg && (
                <Message
                  severity="error"
                  text={errMsg}
                  className="block w-full"
                />
              )}
              <Button label="Réinitialiser le mot de passe" icon="pi pi-user" className="w-full" loading={loading} />
            </form>
          </div>
        </div>
      </div>

    </>
  );
};
export default PasswordReset;
