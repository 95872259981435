import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";
import MeaLink from "../../../../components/MeaLink";

const CourseDetailComponent = ({ course }) => {
  return (
    <>
      <MeaDetailCard>
        <MeaDetailField field="Nom du cours" value={course?.name} />
        <MeaDetailField
          field="Niveau"
          value={
            <MeaLink
              to={
                "/c/t/" +
                course?.school_id +
                "/" +
                course?.training_id +
                "/levels/" +
                course?.level?.id
              }
            >
              {course?.level?.name}
            </MeaLink>
          }
        />
        <MeaDetailField
          field="Présentation"
          value={course?.presentation || " "}
        />
      </MeaDetailCard>
    </>
  );
};

export default CourseDetailComponent;
