import React, { useState, forwardRef, useEffect } from "react";

import { InputTextarea } from "primereact/inputtextarea";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import studentsAPI from "../../../../services/students";

const UpdateRegistrationDetailsModal = forwardRef((props, ref) => {
  //
  const [registrationDetails, setRegistrationDetails] = useState("");
  //
  useEffect(() => {
    setRegistrationDetails(props?.student?.registration_details);
  }, [props]);

  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Plus d'informations sur la demande"
        serviceAPI={studentsAPI}
        id={props?.student?.id}
        data={{
          registration_details: registrationDetails,
        }}
      >
        <InputTextarea value={registrationDetails || ""} className="w-full" onChange={(e) => {
          setRegistrationDetails(e.target.value);
        }} rows={5} autoResize />
      </MeaUpdateModal>
    </>
  );
});

export default UpdateRegistrationDetailsModal;
