import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  ToFormatedDateWithWeekday,
  ToFormatedHour,
} from "../../../../utils/dates";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import sessionsAPI from "../../../../services/sessions";
import { useNavigate } from "react-router-dom";
import MeaTeacherAvatar from "../../../../components/MeaTeacherAvatar";
import CreateSessionModal from "./CreateSessionModal";
import UpdateSessionModal from "./UpdateSessionModal";
import MeaAttendancesProgressBar from "../../../../components/MeaAttendancesProgressBar";
import classesAPI from "../../../../services/classes";
import coursesAPI from "../../../../services/courses";
import teachersAPI from "../../../../services/teachers";
import classroomsAPI from "../../../../services/classrooms";
import { Dropdown } from "primereact/dropdown";

const SessionsListComponent = forwardRef((props, ref) => {
  const { selectedFields, filter, showFilterButton, showAddButton } = props;
  //
  const navigate = useNavigate();
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  let selectedData = {
    slot: selectedFields?.slot ?? true,
    level: selectedFields?.level ?? true,
    classe: selectedFields?.classe ?? true,
    course: selectedFields?.course ?? true,
    teacher: selectedFields?.teacher ?? true,
    classroom: selectedFields?.classroom ?? true,
    presence: selectedFields?.presence ?? false,
    presenceStatistics: selectedFields?.presenceStatistics ?? false,
    edit: selectedFields?.edit ?? true,
  };
  //
  const [state, setState] = useUrlState({
    sessionsFilter: JSON.stringify({
      slot_id: filter?.slot_id || "",
      level_id: filter?.level_id || "",
      classe_id: filter?.classe_id || "",
      course_id: filter?.course_id || "",
      teacher_id: filter?.teacher_id || "",
      classroom_id: filter?.classroom_id || "",
      when: filter?.when || "FUTURE",
      start_date: filter?.start_date || "",
      end_date: filter?.end_date || "",
    }),
  });
  const [session, setSession] = useState(null);
  //
  //
  const queryList = sessionsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    JSON.parse(state.sessionsFilter)
  );
  //
  const classesQuery = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const coursesQuery = coursesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const teachersQuery = teachersAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const classroomsQuery = classroomsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  //
  const setFilter = (field, value) => {
    let currentFilter = state?.sessionsFilter;
    let currentFilterObj = JSON.parse(currentFilter);
    currentFilterObj[field] = value;
    setState((s) => ({
      schedulePage: 1,
      sessionsFilter: JSON.stringify(currentFilterObj),
    }));
  };
  //
  const filterCombobox = (options, query, field) => {
    return (
      <Dropdown
        value={query?.data?.data?.find(
          (obj) => obj.id === JSON.parse(state.sessionsFilter)[field]
        )}
        options={query?.data?.data}
        optionLabel="name"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
          setFilter(field, e.value?.id || "");
        }}
        //itemTemplate={statusItemTemplate}
        placeholder="Séléctionner"
        className="p-column-filter"
        showClear
      />
    );
  };
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <div>
          <Button
            label="Créer une session"
            icon="pi pi-plus"
            size="small"
            className="p-button mr-2 action"
            onClick={() => {
              createModalRef.current.show(() => {
                queryList.refetch();
              });
            }}
            data-pr-tooltip="Nouvelle session"
          />
          <Button
            label="Générer des séances de cours"
            icon="pi pi-calendar-plus"
            size="small"
            className="p-button mr-2 action"
            outlined
            onClick={() => {
              navigate(`/c/t/${schoolId}/${trainingId}/generate-sessions`);
            }}
            data-pr-tooltip="Generate"
          />
        </div>
      )}
    </div>
  );
  //

  //
  return (
    <>
      <CreateSessionModal ref={createModalRef} />
      {selectedData.presenceStatistics && (
        <div className="grid">
          {/* 
        <div className="col-12 md:col-6 lg:col-3 p-3">
          <div className="p-3 text-center bg-gray-500 border-round">
            <div className="text-2xl font-medium text-white mb-2">12</div>
            <span className="text-gray-100 font-medium">Non définis</span>
          </div>
        </div>
        */}
          <div className="col-12 md:col-6 lg:col-4 p-3">
            <div className="p-3 text-center bg-green-500 border-round">
              <div className="text-2xl font-medium text-white mb-2">
                {queryList?.data?.extraMeta?.present_count || "-"}
              </div>
              <span className="text-green-100 font-medium">Présences</span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 p-3">
            <div className="p-3 text-center bg-orange-500 border-round">
              <div className="text-2xl font-medium text-white mb-2">
                {queryList?.data?.extraMeta?.late_count || "-"}
              </div>
              <span className="text-orange-100 font-medium">Retards</span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 p-3">
            <div className="p-3 text-center bg-red-500 border-round">
              <div className="text-2xl font-medium text-white mb-2">
                {queryList?.data?.extraMeta?.absent_count || "-"}
              </div>
              <span className="text-red-100 font-medium">Absences</span>
            </div>
          </div>
        </div>
      )}
      <MeaDataTable
        query={queryList}
        serviceAPI={sessionsAPI}
        names={{ pagination: "session", singular: "séance", plural: "séances" }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        {selectedData.slot && (
          <Column
            header="Séance"
            body={(session) => {
              const startDate = new Date(session?.event?.start_date);
              const formattedStartDate = ToFormatedDateWithWeekday(startDate);
              const formattedStartHour = ToFormatedHour(startDate);
              const endSate = new Date(session?.event?.end_date);
              const formattedEndHour = ToFormatedHour(endSate);
              return (
                <MeaLink
                  to={
                    "/c/t/" +
                    schoolId +
                    "/" +
                    trainingId +
                    "/sessions/" +
                    session.id
                  }
                >
                  {formattedStartDate} de {formattedStartHour} à{" "}
                  {formattedEndHour}
                </MeaLink>
              );
            }}
          ></Column>
        )}
        {selectedData.classe && (
          <Column
            field="schedule_item.classe.name"
            header="Classe"
            body={(session) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/classes/" +
                  session.schedule_item.classe.id
                }
              >
                {session.schedule_item.classe.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, classesQuery, "classe_id")
            }
          ></Column>
        )}
        {selectedData.course && (
          <Column
            field="schedule_item.course.name"
            header="Cours"
            body={(session) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/courses/" +
                  session.schedule_item.course.id
                }
              >
                {session.schedule_item.course.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, coursesQuery, "course_id")
            }
          ></Column>
        )}
        {selectedData.teacher && (
          <Column
            header="Enseignant(e)"
            body={(session) => (
              <MeaTeacherAvatar
                teacher={session.teacher}
                substitute={session?.schedule_item?.teacher}
              />
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) => {
              return (
                <Dropdown
                  value={JSON.parse(state.sessionsFilter)["teacher_id"] || ""}
                  options={teachersQuery?.data?.data?.map((item) => {
                    return {
                      label:
                        item.member?.name ||
                        item.member?.lastname + " " + item.member?.firstname,
                      value: item.id,
                    };
                  })}
                  onChange={(e) => {
                    options.filterApplyCallback(e.value);
                    setFilter("teacher_id", e.value || "");
                  }}
                  //itemTemplate={statusItemTemplate}
                  placeholder="Séléctionner"
                  className="p-column-filter"
                  showClear
                />
              );
            }}
          ></Column>
        )}
        {selectedData.classroom && (
          <Column
            field="classroom.name"
            header="Salle"
            body={(session) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/classrooms/" +
                  session.classroom.id
                }
              >
                {session.classroom.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, classroomsQuery, "classroom_id")
            }
          ></Column>
        )}
        {selectedData.presence && (
          <Column
            header="Présence"
            body={(item) => (
              <MeaAttendancesProgressBar
                attendances={item?.attendances}
                showValues={false}
                showLegends={false}
              />
            )}
          ></Column>
        )}
        {selectedData.edit && (
          <Column
            body={(item) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  text
                  aria-label="Filter"
                  onClick={() => {
                    setSession(item);
                    if (updateModalRef.current) {
                      updateModalRef.current.show(() => {
                        queryList.refetch();
                      });
                    }
                  }}
                />
              </>
            )}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        )}
      </MeaDataTable>
      <UpdateSessionModal ref={updateModalRef} session={session} />
    </>
  );
});

export default SessionsListComponent;
