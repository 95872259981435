import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import useAuthContext from "../hooks/useAuthContext";

const MeaCreateModal = forwardRef((props, ref) => {
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = props.queueId || authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [active, setActive] = useState(false);
  const [callbackFn, setCallbackFn] = useState(() => { });
  const [elementQuery, setElementQuery] = useState();
  //
  const [loading, setLoading] = useState(false);
  //
  const toast = useRef(null);
  //
  const handleCreateSubmit = async (e) => {
    // Handle create slot submit logic here
    setLoading(true);
    toast.current.show({
      severity: "info",
      summary: "Info",
      sticky: true,
      detail: "Création en cours...",
    });

    props.serviceAPI
      .CreateOne(schoolId, trainingId, accessToken, props.data)
      .then((data) => {
        toast.current.clear();
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: data?.data?.name + " crée!",
        });
        setActive(false);
        callbackFn();
        // 
        if (props.onSubmitted) {
          props.onSubmitted();
        }
        //
      })
      .catch((err) => {
        toast.current.clear();
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur de création!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //
  useImperativeHandle(ref, () => ({
    show: (callback, elementQuery) => {
      setCallbackFn(() => callback);
      setElementQuery(elementQuery);
      setActive(true);
    },
    getElementQuery: () => elementQuery,
  }));
  //

  const footerContent = (
    <div>
      <Button
        label="Annuler"
        icon="pi pi-times"
        disabled={loading}
        onClick={() => setActive(false)}
        className="p-button-text"
      />
      <Button
        type="submit"
        loading={loading}
        label="Créer"
        icon="pi pi-check"
        onClick={() => handleCreateSubmit()}
      />
    </div>
  );

  return (
    <>
      <Toast ref={toast} position="bottom-center" />
      <Dialog
        header={props.title}
        visible={active}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setActive(false)}
        footer={footerContent}
      >
        <div className="w-full">
          <div className="flex flex-column gap-2">{props.children}</div>
        </div>
      </Dialog>
    </>
  );
});

export default MeaCreateModal;
