import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import levelsAPI from "../../../../services/levels";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";

const UpdateLevelModal = forwardRef((props, ref) => {
  //
  const [levelId, setLevelId] = useState("");
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");
  //
  useEffect(() => {
    setLevelId(props?.level?.id)
    setName(props?.level?.name);
    setPresentation(props?.level?.presentation);
  }, [props]);
//
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer le niveau"
        serviceAPI={levelsAPI}
        id={levelId}
        data={{
          name: name,
          presentation: presentation,
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom du niveau
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
      </MeaUpdateModal>
    </>
  );
});

export default UpdateLevelModal;
