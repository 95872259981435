import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import GenerateAttendancesModal from "./GenerateAttendancesModal";
import UpdateAttendanceModal from "./UpdateAttendanceModal";
import attendancesAPI from "../../../../services/attendances";
import MeaStudentAvatar from "../../../../components/MeaStudentAvatar";
import MeaAttendancesProgressBar from "../../../../components/MeaAttendancesProgressBar";

const AttendancesListComponent = forwardRef((props, ref) => {
  const { session, filter, showToolbar, showFilterButton, showAddButton } =
    props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //

  const [state, setState] = useUrlState({
    attendancesPage: "1",
    attendancesPageSize: "30",
    attendancesFilter: JSON.stringify({
      course_session_id: session?.id || "",
    }),
  });
  //
  const queryList = attendancesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    JSON.parse(state.attendancesFilter)
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //
  const [attendance, setAttendance] = useState(null);
  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && queryList?.data?.meta?.total <= 0 && (
        <Button
          label="Générer la feuille de présence"
          icon="pi pi-plus"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Générer la feuille de présence"
        />
      )}
    </div>
  );
  //
  return (
    <>
      <GenerateAttendancesModal ref={createModalRef} session={session} />
      <MeaAttendancesProgressBar
        attendances={queryList?.data?.data}
        showValues={true}
        showLegends={true}
      />
      <MeaDataTable
        query={queryList}
        serviceAPI={attendancesAPI}
        names={{
          pagination: "attendances",
          singular: "présence",
          plural: "présences",
        }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        showToolbar={showToolbar}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column
          header="Elève"
          body={(item) => <MeaStudentAvatar student={item?.student} />}
        ></Column>
        <Column header="Présence" field="status" />
        <Column
          body={(item) => (
            <>
              <Button
                icon="pi pi-pencil"
                rounded
                text
                aria-label="Filter"
                onClick={() => {
                  setAttendance(item);
                  if (updateModalRef.current) {
                    updateModalRef.current.show(() => {
                      queryList.refetch();
                    });
                  }
                }}
              />
            </>
          )}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </MeaDataTable>
      <UpdateAttendanceModal ref={updateModalRef} attendance={attendance} />
    </>
  );
});

export default AttendancesListComponent;
