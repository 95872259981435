import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";
import MeaLink from "../../../../components/MeaLink";

const ClasseDetailComponent = ({ classe }) => {
  return (
    <>
      <MeaDetailCard>
        <MeaDetailField field="Nom de la classe" value={classe?.name} />
        <MeaDetailField
          field="Niveau"
          value={
            <MeaLink
              to={
                "/c/t/" +
                classe?.school_id +
                "/" +
                classe?.training_id +
                "/levels/" +
                classe?.level?.id
              }
            >
              {classe?.level?.name}
            </MeaLink>
          }
        />
        <MeaDetailField
          field="Référent(e)"
          value={classe?.referent_id || "-"}
        />
        <MeaDetailField
          field="Présentation"
          value={classe?.presentation || "-"}
        />
      </MeaDetailCard>
    </>
  );
};

export default ClasseDetailComponent;
