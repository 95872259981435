import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import QueuesListComponent from "./components/QueuesListComponent";

const QueuesListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Listes d'attente"
        subtitle="Gérer l'ensemble des listes d'attente de l'école"
      >
        <QueuesListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default QueuesListPage;
