import { SplitButton } from "primereact/splitbutton";
import studentsAPI from "../../../../services/students";
import useAuthContext from "../../../../hooks/useAuthContext";

const RegistrationStepConfirmationComponent = ({ currentStudent, setCurrentStudent, callback }) => {

    const { auth, currentMembership, currentTraining } = useAuthContext();
    const schoolId = currentMembership?.school_id;
    const trainingId = currentTraining?.id;
    const memberId = currentMembership?.id;
    const accessToken = auth?.access_token;

    const updateStatus = async (newStatus, _callback) => {
        studentsAPI
            .UpdateStatus(schoolId, trainingId, accessToken, currentStudent?.id, newStatus)
            .then((data) => {
                console.log("done.");
                _callback?.();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    return (
        <div className={(currentStudent?.status === "ENROLLED") ? "flex flex-column  justify-content-between flex-wrap col-12 md:col-6 lg:col-4 border-top-2 border-green-500 surface-card shadow-2 border-round" : "col-12 md:col-6 lg:col-4"}>
            {(currentStudent?.status === "NEW" || (currentStudent?.status === "PREREGISTRED")) && <>
                <div className="flex align-items-start p-3 border-bottom-1 surface-border opacity-40">
                    <i className="text-2xl pi pi-credit-card text-900 mr-3"></i>
                    <div className="flex flex-column">
                        <span className="text-900 font-medium text-xl mb-1 line-height-1">Confirmation</span>
                    </div>
                </div>
            </> ||
                <>
                    <div className="flex align-items-start p-3 border-bottom-1 surface-border">
                        <i className="text-2xl pi pi-credit-card text-green-500 mr-3"></i>
                        <div className="flex flex-column">
                            <span className="text-green-500 font-medium text-xl mb-1 line-height-1">Confirmation</span>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <ul className="list-none p-0 m-0">
                            <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                <div className="text-500 w-full md:w-5 font-medium">Immatriculation</div>
                                <div className="text-900 w-full md:w-5">{currentStudent?.member_id || "-"}</div>
                            </li>
                            <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                <div className="text-500 w-full md:w-5 font-medium">Contrat</div>
                                <div className="text-900 w-full md:w-5">-</div>
                            </li>
                            <li className="flex align-items-center py-2 px-2 flex-wrap surface-ground">
                                <div className="text-500 w-full md:w-5 font-medium">Paiement</div>
                                <div className="text-900 w-full md:w-5">-</div>
                            </li>
                        </ul>
                    </div>
                    {(currentStudent?.status === "ENROLLED") && <div className="flex-grow-1">
                        <SplitButton
                            label="Payer les frais d'inscription"
                            onClick={() => {
                                updateStatus("ENROLLED", () => { callback?.() });
                            }}
                            className="w-full mt-3 "
                            severity="success"
                            outlined
                            model={[
                                {
                                    label: "Mettre en liste d'attente",
                                    icon: "pi pi-hourglass",
                                    command: () => {
                                        updateStatus("WAITING_LIST", () => { callback?.() });
                                    },
                                },
                                {
                                    label: "Supprimer la demande",
                                    icon: "pi pi-trash",
                                    disabled: true,
                                },
                            ]}
                        />
                    </div>}
                </>}
        </div>
    );
}

export default RegistrationStepConfirmationComponent;