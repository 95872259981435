import { Button } from "primereact/button";
import MeaStudentActions from "../../../../components/MeaStudentActions";
import { SplitButton } from "primereact/splitbutton";
import useAuthContext from "../../../../hooks/useAuthContext";
import studentsAPI from "../../../../services/students";
import levelsAPI from "../../../../services/levels";
import classesAPI from "../../../../services/classes";
import { Dropdown } from "primereact/dropdown";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";

const RegistrationStepPreRegistrationComponent = ({ currentStudent, setCurrentStudent, callback }) => {

    const { auth, currentMembership, currentTraining } = useAuthContext();
    const schoolId = currentMembership?.school_id;
    const trainingId = currentTraining?.id;
    const memberId = currentMembership?.id;
    const accessToken = auth?.access_token;

    const toast = useRef(null);

    const levelsQuery = levelsAPI.useGetList(
        schoolId,
        trainingId,
        accessToken,
        1,
        1000
    );
    const classesQuery = classesAPI.useGetList(
        schoolId,
        trainingId,
        accessToken,
        1,
        1000,
        {
            level_id: currentStudent?.level_id || "",
        }
    );


    const updateStatus = async (newStatus, _callback) => {
        studentsAPI
            .UpdateStatus(schoolId, trainingId, accessToken, currentStudent?.id, newStatus)
            .then((data) => {
                _callback?.();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const updateLevel = async (newLevelId, _callback) => {
        studentsAPI
            .UpdateOne(schoolId, trainingId, accessToken, currentStudent?.id, {
                level_id: newLevelId,
            })
            .then((data) => {
                _callback?.();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };
    const updateClasse = async (newClasseId, _callback) => {
        studentsAPI
            .UpdateOne(schoolId, trainingId, accessToken, currentStudent?.id, {
                class_id: newClasseId,
            })
            .then((data) => {
                _callback?.();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const confirmRegistration = (memberId, _callback) => {
        studentsAPI
            .UpdateStatus(schoolId, trainingId, accessToken, currentStudent?.id, "ENROLLED", memberId)
            .then((data) => {
                _callback?.();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    return (
        <>
            <Toast ref={toast} position="top-right" />
            <div className={(currentStudent?.status === "PREREGISTRED") ? "flex flex-column  justify-content-between flex-wrap col-12 md:col-6 lg:col-4 border-top-2 border-blue-500 surface-card shadow-2 border-round" : "col-12 md:col-6 lg:col-4"}>
                <>

                    <>
                        {currentStudent?.status === "PREREGISTRED" &&
                            <div className="flex justify-content-between flex-wrap align-items-center  p-3 border-bottom-1  surface-border">
                                <div className="w-11">
                                    <div className="flex align-items-start">
                                        <i className="text-2xl pi pi-th-large text-blue-500 mr-3"></i>
                                        <div className="flex flex-column">
                                            <span className="text-blue-500 font-medium text-xl mb-1 line-height-1">Pré-inscription</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1"><Tag value="Hors-période" style={{ background: 'gray' }} /></div>
                            </div>
                            ||
                            <div className="flex align-items-start p-3 border-bottom-1  surface-border">
                                <i className="text-2xl pi pi-th-large text-900 mr-3"></i>
                                <div className="flex flex-column">
                                    <span className="text-900 font-medium text-xl mb-1 line-height-1">Pré-inscription</span>
                                </div>
                            </div>}
                        <div className="flex-grow-1">
                            <ul className="list-none p-0 m-0">
                                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-5 font-medium">Niveau</div>

                                    <div className="text-900 w-full pl-3 md:w-7">
                                        {(levelsQuery?.data?.total > 0) &&
                                            <><Dropdown
                                                value={levelsQuery?.data?.data?.find(
                                                    (obj) => obj.id === currentStudent?.level_id
                                                ) || {}}
                                                optionLabel="name"
                                                showClear
                                                onChange={(e) => {
                                                    updateLevel(e.value?.id || "", () => { });
                                                    updateClasse("", () => { callback?.() });
                                                    toast.current.clear();
                                                    toast.current.show({
                                                        severity: "success",
                                                        summary: "Succès",
                                                        detail: "Affectation mis à jour!",
                                                        life: 3000,
                                                    });
                                                    //updateStudentField("level_id", e.value?.id);
                                                    //updateStudentField("class_id", "");
                                                }}
                                                options={levelsQuery?.data?.data}
                                                className="w-full"
                                            /></> || currentStudent?.level?.name || "-"}
                                    </div>
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-5 font-medium">Classe</div>
                                    <div className="text-900 w-full pl-3 md:w-7">
                                        {(classesQuery?.data?.total > 0) &&
                                            <><Dropdown
                                                disabled={
                                                    currentStudent?.level_id === null ||
                                                    currentStudent?.level_id === undefined ||
                                                    currentStudent?.level_id === ""
                                                }
                                                value={classesQuery?.data?.data?.find(
                                                    (obj) => obj.id === currentStudent?.class_id
                                                )}
                                                optionLabel="name"
                                                onChange={(e) => {
                                                    updateClasse(e.value?.id || "", () => { callback?.() });
                                                    toast.current.clear();
                                                    toast.current.show({
                                                        severity: "success",
                                                        summary: "Succès",
                                                        detail: "Affectation mis à jour!",
                                                        life: 3000,
                                                    });
                                                }}
                                                options={classesQuery?.data?.data}
                                                showClear
                                                className="w-full"
                                            /></> || currentStudent?.classe?.name || "-"}
                                    </div>
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-5 font-medium">Signature du père</div>
                                    <div className="text-900 w-full pl-3 md:w-5">
                                        {(currentStudent?.status === "PREREGISTRED") &&
                                            <Button label="Signer" size="small" severity="info" disabled /> || "-"}
                                    </div>
                                </li>
                                <li className="flex align-items-center py-2 px-2 flex-wrap ">
                                    <div className="text-500 w-full md:w-5 font-medium">Signature de la mère</div>
                                    <div className="text-900 w-full pl-3 md:w-5">
                                        {(currentStudent?.status === "PREREGISTRED") &&
                                            <Button label="Signer" size="small" severity="info" disabled /> || "-"}
                                    </div>
                                </li>

                            </ul>
                        </div>
                        {(currentStudent?.status === "PREREGISTRED") && <div className="flex-grow-1">
                            <SplitButton
                                label="Confirmer l'inscription"
                                icon="pi pi-check"
                                severity="info"
                                onClick={() => {
                                    confirmRegistration(memberId, () => { callback?.() });
                                }}
                                className="w-full mt-3"


                                model={[
                                    {
                                        label: "Annuler la pré-inscription",
                                        icon: "pi pi-refresh",
                                        command: () => {
                                            updateStatus("NEW", () => { callback?.() });
                                        },
                                    },
                                    {
                                        label: "Mettre en liste d'attente",
                                        icon: "pi pi-hourglass",
                                        command: () => {
                                            updateStatus("WAITING_LIST", () => { callback?.() });
                                        },
                                    },
                                    {
                                        label: "Confirmer la radiation",
                                        disabled: true,
                                        icon: "pi pi-sign-out",
                                        command: () => {
                                            updateStatus("WAITING_LIST", () => { callback?.() });
                                        },
                                    }
                                ]}
                            />
                        </div>}
                    </>
                </>
            </div>
        </>
    );
}

export default RegistrationStepPreRegistrationComponent;