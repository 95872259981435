import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import eventsAPI from "../../../../services/events";
import { Checkbox } from "primereact/checkbox";
import { GetTimeFromDate, ToFormatedISOTime } from "../../../../utils/dates";
import useAuthContext from "../../../../hooks/useAuthContext";

const UpdateEventModal = forwardRef((props, ref) => {
  //
  const { authContext } = useAuthContext();
  const timezone =
    authContext.currentMembership?.school?.timezone || "Europe/Paris"; // Target timezone
  //
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");
  const [eventType, setEventType] = useState();
  const [allDay, setAllDay] = useState(true);
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventStartHour, setEventStartHour] = useState("08:00");
  const [eventEndDate, setEventEndDate] = useState("");
  const [eventEndHour, setEventEndHour] = useState("18:00");
  //
  useEffect(() => {
    setName(props?.event?.name);
    setPresentation(props?.event?.presentation);
    setEventType(props?.event?.event_type);
    setAllDay(props?.event?.all_day);
    setEventStartDate(props?.event?.start_date?.split("T")[0]);
    setEventStartHour(GetTimeFromDate(props?.event?.start_date));
    setEventEndDate(props?.event?.end_date?.split("T")[0]);
    setEventEndHour(GetTimeFromDate(props?.event?.end_date));
  }, [props]);
  //
  const listEvents = [
    { label: "Réunion", value: "MEETING" },
    { label: "Vacance", value: "VACATION" },
    { label: "Jour férié", value: "BANK_HOLIDAY" },
    { label: "Autre", value: "OTHER" },
  ];

  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Créer un évènement"
        serviceAPI={eventsAPI}
        id={props?.event?.id}
        data={{
          name: name,
          presentation: presentation,
          event_type: eventType,
          start_date: ToFormatedISOTime(
            eventStartDate,
            eventStartHour,
            timezone
          ),
          end_date: ToFormatedISOTime(eventEndDate, eventEndHour, timezone),
          all_day: allDay,
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom du évènement
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Type d'événement
        </label>
        <Dropdown
          value={eventType}
          onChange={(e) => setEventType(e.value)}
          options={listEvents}
          optionLabel="label"
          className="w-full mb-3"
        />

        <div className="flex align-items-center">
          <Checkbox
            inputId="allDay"
            onChange={(e) => setAllDay(e.checked)}
            checked={allDay}
          />
          <label htmlFor="allDay" className="ml-2">
            Toute la journée
          </label>
        </div>

        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="startdate"
              className="block text-900 font-medium mb-2"
            >
              Date de début
            </label>
            <InputText
              id="startdate"
              type="date"
              value={eventStartDate}
              onChange={(e) => setEventStartDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          {!allDay && (
            <div className="p-inputgroup flex flex-column gap-2">
              <label
                htmlFor="starthour"
                className="block text-900 font-medium mb-2"
              >
                Heure de début
              </label>
              <InputText
                id="starthour"
                type="time"
                value={eventStartHour}
                onChange={(e) => setEventStartHour(e.target.value)}
                className="w-full mb-3"
              />
            </div>
          )}
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="enddate"
              className="block text-900 font-medium mb-2"
            >
              Date de fin
            </label>
            <InputText
              id="enddate"
              type="date"
              value={eventEndDate}
              onChange={(e) => setEventEndDate(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          {!allDay && (
            <div className="p-inputgroup flex flex-column gap-2">
              <label
                htmlFor="endhour"
                className="block text-900 font-medium mb-2"
              >
                Heure de fin
              </label>
              <InputText
                id="endhour"
                type="time"
                value={eventEndHour}
                onChange={(e) => setEventEndHour(e.target.value)}
                className="w-full mb-3"
              />
            </div>
          )}
        </div>
        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
      </MeaUpdateModal>
    </>
  );
});

export default UpdateEventModal;
