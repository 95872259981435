import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

//import useToastContext from "../../../hooks/useToastContext";
import useAuthContext from "../../../../hooks/useAuthContext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ListBox } from "primereact/listbox";
import { Avatar } from "primereact/avatar";
import membersAPI from "../../../../services/members";
import teachersAPI from "../../../../services/teachers";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AddTeacherModal = forwardRef((props, ref) => {
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [selectedTeachers, setSelectedTeachers] = useState([]);

  //
  const memebersList = membersAPI.useGetList(
    schoolId,
    accessToken,
    1,
    10000,
    "teacher",
    ""
  );
  const uniqueMembers = memebersList.data?.data || [];
  uniqueMembers.sort((a, b) => a.name - b.name);
  const members = uniqueMembers.map((member) => ({
    key: member?.id,
    label:
      member?.name !== ""
        ? member?.name
        : member?.lastname + " " + member.firstname,
    value: member?.id,
    avatar: member?.avatar,
    name: member?.name,
    firstname: member?.firstname,
    lastname: member?.firstname,
  }));
  members.unshift({
    key: "empty-member-key",
    label: "",
    value: "",
  });
  //
  const teachersList = teachersAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueTeachers = teachersList.data?.data || [];
  uniqueTeachers.sort((a, b) => a.member.lastname - b.member.lastname);
  const teachers = uniqueTeachers.map((teacher) => ({
    key: teacher.member.id,
    label: teacher.member.lastname + " " + teacher.member.firstname,
    value: teacher.member.id,
  }));
  teachers.unshift({
    key: "empty-teacher-key",
    label: "",
    value: "",
  });

  //
  const membersNotInTeachers = members.filter((member) => {
    return !teachers.some((teacher) => teacher.value === member.value);
  });

  //
  const [active, setActive] = useState(false);
  const [callbackFn, setCallbackFn] = useState(() => {});
  //
  const [loading, setLoading] = useState(false);
  //
  const toast = useRef(null);
  //
  useEffect(() => {
    teachersList.refetch();
  }, [active]);
  //
  const handleCreateSubmit = async () => {
    // Handle create slot submit logic here
    setLoading(true);
    teachersAPI
      .AddList(schoolId, trainingId, accessToken, {
        members_ids: selectedTeachers,
      })
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Enseignants ajoutés!",
        });
        setActive(false);
        callbackFn();
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Erreur lors de l'ajout!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //
  useImperativeHandle(ref, () => ({
    show: (callback) => {
      setCallbackFn(() => callback);
      setActive(true);
    },
  }));
  //
  const footerContent = (
    <div>
      <Button
        label="Annuler"
        icon="pi pi-times"
        disabled={loading}
        onClick={() => setActive(false)}
        className="p-button-text"
      />
      <Button
        type="submit"
        loading={loading}
        label="Ajouter"
        icon="pi pi-check"
        onClick={() => handleCreateSubmit()}
      />
    </div>
  );
  //
  const teacherTemplate = (teacher) => {
    return (
      <div className="flex align-items-center">
        <Avatar
          className="mr-2 p-overlay-badge"
          icon="pi pi-user"
          shape="circle"
          image={
            teacher?.avatar
              ? BACKEND_URL + "/api/v1/medias/" + teacher?.avatar
              : ""
          }
          label={
            teacher?.name
              ? ""
              : teacher?.lastname
              ? Array.from(teacher?.lastname)[0] +
                (teacher?.firstname ? Array.from(teacher?.firstname)[0] : "")
              : teacher?.name
              ? Array.from(teacher?.name)[0]
              : ""
          }
          size="normal"
        ></Avatar>
        <div>
          {teacher?.name
            ? teacher?.name
            : teacher.firstname + " " + teacher.lastname}
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />

      <Dialog
        header="Nouveaux enseignants"
        visible={active}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setActive(false)}
        footer={footerContent}
      >
        <div className="card flex justify-content-center">
          <ListBox
            multiple
            filter
            value={selectedTeachers}
            onChange={(e) => setSelectedTeachers(e.value)}
            options={membersNotInTeachers}
            optionLabel="name"
            itemTemplate={teacherTemplate}
            className="w-full"
          />
        </div>
      </Dialog>
    </>
  );
});

export default AddTeacherModal;
