import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import SystemFeaturesListComponent from "./components/SystemFeaturesListComponent";
const SystemFeaturesListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des fonctionalités système"
        subtitle="Gérer l'ensemble des fonctionalités système de la plateforme"
      >
        <SystemFeaturesListComponent ref={listRef} showFilterButton={false} />
      </MeaPage>
    </>
  );
};
export default SystemFeaturesListPage;
