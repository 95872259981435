import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import MeaCreateModal from "../../../../components/MeaCreateModal";
import { InputTextarea } from "primereact/inputtextarea";
import levelsAPI from "../../../../services/levels";
import useAuthContext from "../../../../hooks/useAuthContext";
import { Dropdown } from "primereact/dropdown";
import coursesAPI from "../../../../services/courses";

const CreateCourseModal = forwardRef((props, ref) => {
  //
  const { authContext } = useAuthContext();
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");
  const [levelId, setLevelId] = useState(props.levelId || "");
  //
  const levelsList = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueLevels = levelsList.data?.data || [];
  uniqueLevels.sort((a, b) => a.show_order - b.show_order);
  const listLevels = uniqueLevels.map((level, index) => ({
    label: level.name,
    value: level.id,
  }));

  useEffect(() => {
    if (levelId === "" && listLevels.length > 0) {
      setLevelId(listLevels[0].value);
    }
  }, [listLevels, levelId]);

  return (
    <>
      <MeaCreateModal
        ref={ref}
        title="Créer un cours"
        serviceAPI={coursesAPI}
        data={{
          name: name,
          presentation: presentation,
          level_id: levelId,
        }}
        onSubmitted={() => {
          setName("");
          setPresentation("");
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom du cours
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />
        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Niveau du cours
        </label>
        <Dropdown
          value={levelId}
          disabled={props.levelId !== undefined}
          onChange={(e) => setLevelId(e.value)}
          options={listLevels}
          optionLabel="label"
          className="w-full mb-3"
        />
      </MeaCreateModal>
    </>
  );
});

export default CreateCourseModal;
