import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import LevelsListComponent from "./components/LevelsListComponent";

const LevelsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des niveaux"
        subtitle="Gérer l'ensemble des niveaux de cette formation"
      >
        <LevelsListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={false}
        />
      </MeaPage>
    </>
  );
};
export default LevelsListPage;
