import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
  weekday = ""
) => {
  return useQuery(
    ["slots", schoolId, trainingId, accessToken, page, pageSize, weekday],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/slots?page=${page}&pageSize=${pageSize}&weekday=${weekday}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erreur de récupération de la liste des créneaux!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    }
  );
};

const GetOne = (schoolId, trainingId, accessToken, slotId) => {
  return useQuery(
    ["slot", schoolId, trainingId, accessToken, slotId],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/slots/${slotId}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erreur de récupération du créneau!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    }
  );
};

const CreateOne = async (schoolId, trainingId, accessToken, data) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/slots",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de création du créneau!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (schoolId, trainingId, accessToken, id, data) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/slots/" +
        id,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification du créneau!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, trainingId, accessToken, slotId) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/slots/" +
        slotId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression du créneau!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const slotsAPI = {
  useGetList,
  GetOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
};

export default slotsAPI;
