import useAuthContext from "../hooks/useAuthContext";

import { SplitButton } from "primereact/splitbutton";
import studentsAPI from "../services/students";
import { Badge } from "primereact/badge";

const MeaStudentActions = ({ student, callback }) => {
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const memberId = currentMembership?.id;
  const accessToken = auth?.access_token;

  const updateStatus = async (newStatus, _callback) => {
    studentsAPI
      .UpdateStatus(schoolId, trainingId, accessToken, student?.id, newStatus)
      .then((data) => {
        console.log("done.");
        _callback?.();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const updateConfirmation = async (data, _callback) => {
    studentsAPI
      .UpdateConfirmation(schoolId, trainingId, accessToken, student?.id, data)
      .then((data) => {
        console.log("done.");
        _callback?.();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <>
      {student?.status === "NEW" && (
        <SplitButton
          label="Pré-inscrire dans cette formation"
          onClick={() => {
            updateStatus("PREREGISTRED", callback);
          }}
          className="w-full"
          outlined
          model={[
            {
              label: "Mettre en liste d'attente",
              icon: "pi pi-hourglass",
              command: () => {
                updateStatus("WAITING_LIST", callback);
              },
            },
            {
              label: "Supprimer la demande de pré-inscription",
              icon: "pi pi-trash",
              disabled: true,
            },
          ]}
        />
      )}
      {student?.status === "PREREGISTRED" &&
        student?.registration_confirmation === false && (
          <>
            <SplitButton
              label="Confirmer la pré-inscription"
              icon="pi pi-check"

              onClick={() => {
                updateConfirmation(
                  {
                    registration_confirmation: true,
                    registration_confirmation_by_id: memberId,
                  },
                  callback
                );
              }}
              className="w-full"
              outlined
              model={[
                {
                  label: "Re-mettre comme non traité",
                  icon: "pi pi-refresh",
                  command: () => {
                    updateStatus("NEW", callback);
                  },
                },
                {
                  label: "Mettre en liste d'attente",
                  icon: "pi pi-hourglass",
                  command: () => {
                    updateStatus("WAITING_LIST", callback);
                  },
                },

                {
                  label: "Supprimer la demande de pré-inscription",
                  icon: "pi pi-trash",
                },
              ]}
            />

          </>
        )}
      {student?.status === "PREREGISTRED" &&
        student?.registration_confirmation === true && (
          <SplitButton
            label="Validation définitive de l'inscription"
            icon="pi pi-check-square"
            onClick={() => {
              updateStatus("ENROLLED", callback);
            }}
            text
            raised
            rounded
            model={[
              {
                label: "Annuler la confirmation de pré-inscription",
                icon: "pi pi-sparkles",
                command: () => {
                  updateConfirmation(
                    {
                      registration_confirmation: false,
                      registration_confirmation_by_id: memberId,
                    },
                    callback
                  );
                },
              },
              {
                label: "Re-mettre comme non traité",
                icon: "pi pi-refresh",
                command: () => {
                  updateStatus("NEW", callback);
                },
              },
              {
                label: "Mettre en liste d'attente",
                icon: "pi pi-hourglass",
                command: () => {
                  updateStatus("WAITING_LIST", callback);
                },
              },
              {
                label: "Supprimer la demande",
                icon: "pi pi-trash",
                disabled: true,
              },
            ]}
          />
        )}
      {student?.status === "ENROLLED" && (
        <SplitButton
          label="Marquer comme réussi"
          icon="pi pi-arrow-up"
          onClick={() => {
            updateStatus("ENROLLED", callback);
          }}
          text
          raised
          rounded
          model={[
            {
              label: "Redoubler",
              icon: "pi pi-refresh",
              disabled: true,
            },
            {
              label: "Exclure de la formation",
              icon: "pi pi-trash",
              command: () => {
                updateStatus("EXPELLED", callback);
              },
            },
          ]}
        />
      )}
      {student?.status === "WAITING_LIST" && (
        <Badge value="En liste d'attente" severity="warning"></Badge>
      )}
      {student?.status === "DROPPEDOUT" && (
        <Badge value="Abandonné" severity="danger"></Badge>
      )}

      {student?.status === "EXPELLED" && (
        <Badge value="Exclu" severity="danger"></Badge>
      )}
      {student?.status === "RETAINED" && (
        <Badge value="Redoublé" severity="warning"></Badge>
      )}
      {student?.status === "PASSED" && (
        <Badge value="Réussi" severity="success"></Badge>
      )}
    </>
  );
};
export default MeaStudentActions;
