import { useEffect, useState } from "react";
import RegistrationStepConfirmationComponent from "./RegistrationStepConfirmationComponent";
import RegistrationStepNewComponent from "./RegistrationStepNewComponent";
import RegistrationStepPreRegistrationComponent from "./RegistrationStepPreRegistrationComponent";
import RegistrationStepWaitingListComponent from "./RegistrationStepWaitingListComponent";

const RegistrationStepsComponent = ({ student, callback }) => {

    const [currentStudent, setCurrentStudent] = useState();
    useEffect(() => {
        if (student) {
            setCurrentStudent(student);
        }
    }, [student]);

    return (
        <>
            <div className="grid p-0 m-0 ">
                <RegistrationStepNewComponent currentStudent={currentStudent} setCurrentStudent={setCurrentStudent} callback={callback} />
                {currentStudent?.status !== "WAITING_LIST" && <RegistrationStepPreRegistrationComponent currentStudent={currentStudent} setCurrentStudent={setCurrentStudent} callback={callback} />}
                {currentStudent?.status === "WAITING_LIST" && <RegistrationStepWaitingListComponent currentStudent={currentStudent} setCurrentStudent={setCurrentStudent} callback={callback} />}
                {currentStudent?.status !== "WAITING_LIST" && <RegistrationStepConfirmationComponent currentStudent={currentStudent} setCurrentStudent={setCurrentStudent} callback={callback} />}

            </div>
        </>
    );
}

export default RegistrationStepsComponent;