import React, { useState, forwardRef } from "react";

import { InputText } from "primereact/inputtext";
import MeaCreateModal from "../../../../components/MeaCreateModal";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { ToFormatedShortDate } from "../../../../utils/dates";
import membersAPI from "../../../../services/members";
import { Divider } from "primereact/divider";

const AddMemberModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("B");
  const [birthday, setBirthday] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  //

  return (
    <>
      <MeaCreateModal
        ref={ref}
        title="Ajouter un nouveau membre"
        serviceAPI={membersAPI}
        data={{
          firstname: firstname,
          lastname: lastname,
          name: name,
          gender: gender,
          birthday: birthday ? ToFormatedShortDate(new Date(birthday)) : "",
          email: email,
          phone: phone,
          address: address,
          is_admin: isAdmin,
          is_staff: isStaff,
          is_teacher: isTeacher,
          is_student: isStudent,
        }}
        onSubmitted={() => {
          setName("");
          setFirstname("");
          setLastname("");
          setPhone("");
          setAddress("");
          setEmail("");
          setGender("B");
          setBirthday("");
          setIsAdmin(false);
          setIsStaff(false);
          setIsTeacher(false);
          setIsStudent(false);
        }}
      >
        {/* Member info */}
        <h3>Les informations de base</h3>
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex-1 flex-column">
            <label
              htmlFor="lastname"
              className="block text-900 font-medium mb-2"
            >
              Nom de famille
            </label>
            <InputText
              id="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="w-full mb-1"
            />
          </div>

          <div className="p-inputgroup flex-1 flex-column">
            <label
              htmlFor="firstname"
              className="block text-900 font-medium mb-2"
            >
              Prénom
            </label>
            <InputText
              id="firstname"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              className="w-full mb-1"
            />
          </div>
        </div>

        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex-1 flex-column">
            <label htmlFor="name" className="block text-900 font-medium mb-2">
              Nom d'usage
            </label>
            <InputText
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full mb-1"
            />
          </div>

          <div className="p-inputgroup flex-1 flex-column">
            <label htmlFor="gender" className="block text-900 font-medium mb-2">
              Genre
            </label>
            <Dropdown
              id="gender"
              value={gender}
              options={[
                { label: "Masculin", value: "B" },
                { label: "Féminin", value: "G" },
              ]}
              onChange={(e) => setGender(e.value)}
              optionLabel="label"
              className="w-full mb-1"
            />
          </div>
        </div>

        <label htmlFor="birthday" className="block text-900 font-medium mb-2">
          Date de naissance
        </label>
        <InputText
          id="birthday"
          type="date"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
          className="w-full mb-1"
        />

        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex-1 flex-column">
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-1"
            />
          </div>

          <div className="p-inputgroup flex-1 flex-column">
            <label htmlFor="phone" className="block text-900 font-medium mb-2">
              Téléphone
            </label>
            <InputText
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full mb-1"
            />
          </div>
        </div>

        <label htmlFor="adress" className="block text-900 font-medium mb-2">
          Adresse
        </label>
        <InputTextarea
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />

        <Divider className="mb-1" />
        {/* Roles */}
        <h3>Les rôles</h3>

        <div className="flex align-items-center">
          <Checkbox
            inputId="isAdmin"
            onChange={(e) => setIsAdmin(e.checked)}
            checked={isAdmin}
          />
          <label htmlFor="isAdmin" className="ml-2">
            Administration
          </label>
        </div>
        <div className="flex align-items-center">
          <Checkbox
            inputId="isStaff"
            onChange={(e) => setIsStaff(e.checked)}
            checked={isStaff}
          />
          <label htmlFor="isStaff" className="ml-2">
            Personnel de l'école
          </label>
        </div>
        <div className="flex align-items-center">
          <Checkbox
            inputId="isTeacher"
            onChange={(e) => setIsTeacher(e.checked)}
            checked={isTeacher}
          />
          <label htmlFor="isTeacher" className="ml-2">
            Enseignant(e)
          </label>
        </div>
        <div className="flex align-items-center">
          <Checkbox
            inputId="isStudent"
            onChange={(e) => setIsStudent(e.checked)}
            checked={isStudent}
          />
          <label htmlFor="isStudent" className="ml-2">
            Élève
          </label>
        </div>

        <Divider className="mb-1" />
      </MeaCreateModal>
    </>
  );
});

export default AddMemberModal;
