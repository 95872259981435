const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const GenerateHolidays = async (schoolId, trainingId, accessToken) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/tasks/import-calendar-bank-holidays",
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de génération des jours fériés!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const GenerateTrainingSessions = async (
  schoolId,
  trainingId,
  accessToken,
  data
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/tasks/generate-schedule-sessions",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de génération des sessions de cours!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const GetTask = async (
  schoolId, trainingId, jobId, accessToken
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" + trainingId + "/tasks/" +
      jobId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de récupération du détail de la tâche!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const tasksAPI = {
  GenerateHolidays,
  GenerateTrainingSessions,
  GetTask,
};

export default tasksAPI;
