import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";

const MainLayout = () => {
  const { currentMembership, currentTraining, isConnected } = useAuthContext();
  const location = useLocation();
  return !location.pathname || location.pathname === "/" ? (
    isConnected() ? (
      currentMembership?.school_id ? (
        currentTraining?.id ? (
          <Navigate
            to={
              "/c/t/" + currentMembership?.school_id + "/" + currentTraining?.id
            }
          />
        ) : (
          <Navigate to={"/c/s/" + currentMembership?.school_id} replace />
        )
      ) : (
        <Navigate to="/c/user/schools" state={{ from: location }} replace />
      )
    ) : (
      <Navigate to="/d/welcome" state={{ from: location }} replace />
    )
  ) : (
    <Outlet />
  );
};
export default MainLayout;
