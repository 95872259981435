
import { Tag } from "primereact/tag";

const MeaStudentStatus2 = ({ student, previousStatus = false, old = false }) => {
  let sStatus = previousStatus ? student?.previous_training_status : student?.status;
  return (
    <>
      {old && (<>
        {sStatus === "NEW" && (
          <Tag rounded value="Candidature" severity="contrast" style={{ background: 'gray' }}></Tag>
        )}
        {sStatus === "PREREGISTRED" && (
          <Tag rounded value="Pré-inscrition" severity="info" style={{ background: 'gray' }}></Tag>
        )}
        {sStatus === "ENROLLED" && (
          <Tag rounded value="Inscription confirmée" severity="success" style={{ background: 'gray' }} ></Tag>
        )}
        {sStatus === "WAITING_LIST" && (
          <Tag rounded value="En liste d'attente" severity="warning" style={{ background: 'gray' }}></Tag>
        )}
        {sStatus === "DROPPEDOUT" && (
          <Tag rounded value="Abandonné" severity="danger" style={{ background: 'gray' }}></Tag>
        )}

        {sStatus === "EXPELLED" && (
          <Tag rounded value="Exclu" severity="danger" style={{ background: 'gray' }}></Tag>
        )}
        {sStatus === "RETAINED" && (
          <Tag rounded value="Redoublé" severity="warning" style={{ background: 'gray' }}></Tag>
        )}
        {sStatus === "PASSED" && (
          <Tag rounded value="Réussi" severity="success" style={{ background: 'gray' }}></Tag>
        )}
      </>) || (<>
        {sStatus === "NEW" && (
          <Tag rounded value="Candidature" severity="contrast"></Tag>
        )}
        {sStatus === "PREREGISTRED" && (
          <Tag rounded value="Pré-inscrition" severity="info"></Tag>
        )}
        {sStatus === "ENROLLED" && (
          <Tag rounded value="Inscription confirmée" severity="success" ></Tag>
        )}
        {sStatus === "WAITING_LIST" && (
          <Tag rounded value="En liste d'attente" severity="warning"></Tag>
        )}
        {sStatus === "DROPPEDOUT" && (
          <Tag rounded value="Abandonné" severity="danger"></Tag>
        )}

        {sStatus === "EXPELLED" && (
          <Tag rounded value="Exclu" severity="danger"></Tag>
        )}
        {sStatus === "RETAINED" && (
          <Tag rounded value="Redoublé" severity="warning"></Tag>
        )}
        {sStatus === "PASSED" && (
          <Tag rounded value="Réussi" severity="success"></Tag>
        )}
      </>)}

    </>
  );
};
export default MeaStudentStatus2;
