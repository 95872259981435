import { GetWeekDay } from "../../../../utils/dates";

import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";

const SlotDetailComponent = ({ slot }) => {
  return (
    <MeaDetailCard>
      <MeaDetailField field="Nom du créneau" value={slot?.name} />
      <MeaDetailField
        field="Jour de la semaine"
        value={GetWeekDay(slot?.weekday)}
      />
      <MeaDetailField field="Heure de début" value={slot?.start_hour} />
      <MeaDetailField field="Heure de fin" value={slot?.end_hour} />
    </MeaDetailCard>
  );
};

export default SlotDetailComponent;
