import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from "@fullcalendar/react";
import useAuthContext from "../../../../hooks/useAuthContext";
import useUrlState from "@ahooksjs/use-url-state";
import React, { forwardRef, useImperativeHandle } from "react";
import eventsAPI from "../../../../services/events";

const MonthlyCalendar = forwardRef((props, ref) => {
  const { filter } = props;
  //
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const [state, setState] = useUrlState({
    eventsFilter: {
      eventType: filter?.eventType || "",
      isAggretate: false,
    },
  });

  const queryList = eventsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000000, // Fetch all events at once
    state.eventsFilter
  );

  const formattedEvents = queryList?.data?.data.map((item) => {
    let endDate = item.end_date;
    // Check if start date is different from end date
    if (
      item.start_date !== item.end_date &&
      item.event_type !== "COURSE_SESSION"
    ) {
      // Parse the end date string into a Date object
      const endDateObj = new Date(endDate);
      // Add one day to the end date
      endDateObj.setDate(endDateObj.getDate() + 1);
      // Format the end date back to string
      endDate = endDateObj.toISOString();
    }

    return {
      id: item.id,
      title: item.name,
      start: item.start_date,
      end: endDate, // Use the modified end date
      allDay: item.all_day,
    };
  });
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));

  //
  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin]}
      initialView="dayGridMonth"
      events={formattedEvents}
      headerToolbar={{
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      locale="FR"
      eventClick={function (info) {
        window.open(
          "/c/t/" + schoolId + "/" + trainingId + "/events/" + info.event.id,
          "_blank"
        );
      }}
    />
  );
});

export default MonthlyCalendar;
