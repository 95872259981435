
import { Chip } from "primereact/chip";
import { Tag } from "primereact/tag";

const MeaStudentRegistrationOrigin = ({ student }) => {
  let sRegOrigin = student?.registration_origin;
  return (
    <>
      {sRegOrigin === "NEW" && (
        <Tag icon="pi pi-user" value="NOUVEAU" rounded style={{ background: 'var(--surface-500)' }}></Tag>
      )}
      {sRegOrigin === "PASSED" && (
        <Tag icon="pi pi-check" value="PASSAGE" rounded style={{ background: 'var(--green-200)' }}></Tag>
      )}
      {sRegOrigin === "FAILED" && (
        <Tag icon="pi pi-exclamation-triangle" value="REDOUBLEMENT" rounded style={{ background: 'var(--orange-200)' }}></Tag>
      )}
      {sRegOrigin !== "FAILED" && sRegOrigin !== "PASSED" && sRegOrigin !== "NEW" && (
        <div>-</div>
      )}
    </>
  );
};
export default MeaStudentRegistrationOrigin;
