import { Link } from "react-router-dom";

const MeaLink = ({ to, children }) => {
  return (
    <>
      <Link to={to} className="no-underline text-color hover:underline flex align-items-center flex-wrap">
        {children}
      </Link>
    </>
  );
};

export default MeaLink;
