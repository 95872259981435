import MeaDetailCard from "../../../../components/MeaDetailCard";
import { Image } from "primereact/image";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const TeacherDetailAvatarComponent = ({ teacher }) => {
  return (
    <MeaDetailCard>
      <Image
        src={
          teacher?.member?.avatar
            ? BACKEND_URL + "/api/v1/medias/" + teacher?.member?.avatar
            : "/user.png"
        }
        zoomSrc={
          teacher?.member?.avatar
            ? BACKEND_URL + "/api/v1/medias/" + teacher?.member?.avatar
            : "/user.png"
        }
        alt="Image"
        preview
      />
    </MeaDetailCard>
  );
};

export default TeacherDetailAvatarComponent;
