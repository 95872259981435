import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import MembersListComponent from "./components/MembersListComponent";

const MembersListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
      //breadcrumb={<SlotsBreadCrumb />}
      //title="Liste des comptes utilisateurs de l'école"
      //subtitle="Gérer l'ensemble des comptes utilisateurs de l'école"
      >
        <MembersListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default MembersListPage;
