import React, { useState, forwardRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import { InputTextarea } from "primereact/inputtextarea";
import queuesAPI from "../../../../services/queues";

const UpdateQueueModal = forwardRef((props, ref) => {
  //
  const [name, setName] = useState("");
  const [presentation, setPresentation] = useState("");

  useEffect(() => {
    setName(props?.queue?.name);
    setPresentation(props?.queue?.presentation);
  }, [props]);
  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer la liste d'attente"
        id={props?.queue?.id}
        serviceAPI={queuesAPI}
        data={{
          name: name,
          presentation: presentation,
        }}
      >
        <label htmlFor="name" className="block text-900 font-medium mb-2">
          Nom de la formation
        </label>
        <InputText
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mb-3"
          autoFocus
        />

        <label
          htmlFor="presentation"
          className="block text-900 font-medium mb-2"
        >
          Présentation
        </label>
        <InputTextarea
          id="presentation"
          type="text"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
          rows={3}
          className="w-full mb-3"
        />
      </MeaUpdateModal>
    </>
  );
});

export default UpdateQueueModal;
