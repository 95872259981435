import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import CreateClasseModal from "./CreateClasseModal";
import classesAPI from "../../../../services/classes";
import UpdateClasseModal from "./UpdateClasseModal";
import { Dropdown } from "primereact/dropdown";
import levelsAPI from "../../../../services/levels";

const ClassesListComponent = forwardRef((props, ref) => {
  const { selectedFields, filter, showFilterButton, showAddButton, showToolbar } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  let selectedData = {
    name: selectedFields?.name ?? true,
    presentation: selectedFields?.presentation ?? true,
    totalStudent: selectedFields?.totalStudent ?? true,
    level: selectedFields?.level ?? true,
    maxCapacity: selectedFields?.maxCapacity ?? false,
    edit: selectedFields?.edit ?? true
  };
  //
  const [state, setState] = useUrlState({
    classesPage: "1",
    classesPageSize: "30",
    classesFilter: JSON.stringify({ level_id: filter?.level_id || "" }),
  });
  const [classe, setClasse] = useState(null);
  //
  //
  const queryList = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    JSON.parse(state.classesFilter)
  );
  //
  const levelsQuery = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //
  const setFilter = (field, value) => {
    let currentFilter = state?.classesFilter;
    let currentFilterObj = JSON.parse(currentFilter);
    currentFilterObj[field] = value;
    setState((s) => ({
      classesPage: 1,
      classesFilter: JSON.stringify(currentFilterObj),
    }));
  };
  //
  const filterCombobox = (options, query, field) => {
    return (
      <Dropdown
        value={query?.data?.data?.find(
          (obj) => obj.id === JSON.parse(state.classesFilter)[field]
        )}
        options={query?.data?.data}
        optionLabel="name"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
          setFilter(field, e.value?.id || "");
        }}
        //itemTemplate={statusItemTemplate}
        placeholder="Séléctionner"
        className="p-column-filter"
        showClear
      />
    );
  };

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Créer une classe"
          icon="pi pi-plus"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouvelle classe"
        />
      )}
    </div>
  );
  //
  return (
    <>
      <CreateClasseModal ref={createModalRef} levelId={filter?.level_id} />
      <MeaDataTable
        query={queryList}
        serviceAPI={classesAPI}
        names={{ pagination: "classes", singular: "classe", plural: "classes" }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        showToolbar={showToolbar}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        {selectedData.name &&
          <Column
            field="name"
            header="Classe"
            body={(item) => (
              <MeaLink
                to={"/c/t/" + schoolId + "/" + trainingId + "/classes/" + item.id}
              >
                {item.name}
              </MeaLink>
            )}
          ></Column>
        }
        {selectedData.level &&
          <Column
            field="level"
            header="Niveau"
            body={(item) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/levels/" +
                  item?.level?.id
                }
              >
                {item?.level?.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, levelsQuery, "level_id")
            }
          ></Column>
        }
        {selectedData.presentation &&
          <Column field="presentation" header="Presentation"></Column>
        }
        {selectedData.totalStudent &&
          <Column field="total_students" header="Nbr d'élèves"></Column>
        }
        {selectedData.maxCapacity &&
          <Column field="max_capacity" header="Capacité maximale"></Column>
        }
        {selectedData.edit &&
          <Column
            body={(item) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  text
                  aria-label="Filter"
                  onClick={() => {
                    setClasse(item);
                    if (updateModalRef.current) {
                      updateModalRef.current.show(() => {
                        queryList.refetch();
                      });
                    }
                  }}
                />
              </>
            )}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        }
      </MeaDataTable>
      <UpdateClasseModal ref={updateModalRef} classe={classe} />
    </>
  );
});

export default ClassesListComponent;
