import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import useUrlState from "@ahooksjs/use-url-state";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import queuesAPI from "../../../../services/queues";
import CreateQueueModal from "./CreateQueueModal";
import MeaLink from "../../../../components/MeaLink";
import UpdateQueueModal from "./UpdateQueueModal";

const QueuesListComponent = forwardRef((props, ref) => {
  const { showAddButton } = props;
  const { auth, currentMembership } = useAuthContext();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const schoolId = currentMembership?.school_id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  const [state, setState] = useUrlState({
    queueName: "",
    queueStatus: "",
  });
  const [queue, setQueue] = useState(null);
  //
  const queryList = queuesAPI.useGetList(
    schoolId,
    accessToken,
    page,
    pageSize,
    state.queueStatus
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //

  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Créer une liste d'attente"
          icon="pi pi-plus"
          size="normal"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Nouvelle liste d'attente"
        />
      )}
      {/* 
      <span className="p-input-icon-left mr-2 ">
        <i className="pi pi-search" />
        <InputText
          disabled
          className="p-inputtext-sm"
          value={state.memberName}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder="Chercher une liste d'attente"
        />
      </span>
      */}
    </div>
  );
  //
  return (
    <>
      <CreateQueueModal ref={createModalRef} />
      <MeaDataTable
        query={queryList}
        serviceAPI={queuesAPI}
        names={{
          pagination: "queues",
          singular: "liste d'attente",
          plural: "liste d'attentes",
        }}
        startToolbarTemplate={startToolbarTemplate}
        //showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column
          field="name"
          header="Liste d'attente"
          body={(item) => (
            <MeaLink to={"/c/s/" + schoolId + "/queues/" + item.id}>
              {item.name}
            </MeaLink>
          )}
        ></Column>
        <Column field="presentation" header="Présentation"></Column>

        <Column field="status" header="Status"></Column>
        <Column
          body={(item) => (
            <>
              <Button
                icon="pi pi-pencil"
                rounded
                text
                aria-label="Filter"
                onClick={() => {
                  setQueue(item);
                  if (updateModalRef.current) {
                    updateModalRef.current.show(() => {
                      queryList.refetch();
                    });
                  }
                }}
              />
            </>
          )}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </MeaDataTable>
      <UpdateQueueModal ref={updateModalRef} queue={queue} />
    </>
  );
});

export default QueuesListComponent;
