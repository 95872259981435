import useAuthContext from "../../../../hooks/useAuthContext";
import trainingsAPI from "../../../../services/trainings";

const TrainingHeader = ({ training }) => {
  //
  const { auth } = useAuthContext();
  const accessToken = auth?.access_token;
  //
  const statisticsQuery = trainingsAPI.GetStatistics(
    training?.school_id,
    training?.id,
    accessToken
  );
  const statisticsObj = statisticsQuery?.data?.data;

  return (
    <div>
      <div className="surface-section px-4 py-5 md:px-6 lg:px-5 flex flex-wrap">
        <div className="w-full md:w-6">
          <span className="inline-flex align-items-center text-900 text-3xl font-medium mb-3 cursor-pointer">
            {training?.name}
          </span>
          <div className="flex align-items-center text-600">
            <span className="mr-5">
              <i className="pi pi-chevron-circle-right mr-2"></i>Tableau de bord
            </span>
            {/* <span>
              <i className="pi pi-book mr-2"></i>60 Cases
            </span> */}
          </div>
        </div>
        <div className="w-full md:w-6 flex flex-column md:flex-row md:align-items-center md:justify-content-end mt-4 md:mt-0">
          <div className="flex align-items-center mr-0 md:mr-5">
            <span
              className="block border-circle flex align-items-center justify-content-center bg-cyan-300 text-cyan-900 mr-3"
              style={{ width: "56px", height: "56px" }}
            >
              <i className="pi pi-users text-4xl"></i>
            </span>
            <div>
              <span className="font-medium text-600">Elèves</span>
              <p className="mt-1 mb-0 text-900 text-2xl font-medium">
                {statisticsObj?.total_students}
              </p>
            </div>
          </div>
          <div className="flex align-items-center mt-3 md:mt-0">
            <span
              className="block border-circle flex align-items-center justify-content-center bg-yellow-300 text-yellow-900 mr-3"
              style={{ width: "56px", height: "56px" }}
            >
              <i className="pi pi-wallet text-4xl"></i>
            </span>
            <div>
              <span className="font-medium text-600">Classes</span>
              <p className="mt-1 mb-0 text-900 text-2xl font-medium">
                {statisticsObj?.total_classes}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingHeader;
