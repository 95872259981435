import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
  _filters
) => {
  return useQuery(
    ["events", schoolId, trainingId, accessToken, page, pageSize, _filters],
    async () => {
      const eventType = _filters?.eventType || "";
      const isAggretate = _filters?.isAggretate || false;
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/events?page=${page}&pageSize=${pageSize}&event_type=${eventType}&aggregate=${isAggretate}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch events!");
        }
        if (response.status >= 299) {
          throw new Error("Erreur de récupération de la liste des événmenets!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        // Handle the error
        console.error("Error fetching events:", error);
        throw error;
      }
    }
  );
};

const GetOne = (schoolId, trainingId, accessToken, eventId) => {
  return useQuery(
    ["slot", schoolId, trainingId, accessToken, eventId],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/events/${eventId}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erreur de récupération de l'événement!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    }
  );
};

const CreateOne = async (schoolId, trainingId, accessToken, data) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/events",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de création de l'événement!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (schoolId, trainingId, accessToken, eventId, data) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/events/" +
        eventId,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification de l'événement!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, trainingId, accessToken, eventId) => {
  try {
    const response = await fetch(
      BACKEND_URL +
        "/api/v1/schools/" +
        schoolId +
        "/trainings/" +
        trainingId +
        "/events/" +
        eventId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression de l'événement!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const eventsAPI = {
  useGetList,
  GetOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
};

export default eventsAPI;
