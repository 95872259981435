import MeaPage from "../../../components/MeaPage";
import SlotsListComponent from "./components/SlotsListComponent";
import { useRef } from "react";

const SlotsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des créneaux"
        subtitle="Gérer l'ensemble des créneaux dans cette formation"
      >
        <SlotsListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default SlotsListPage;
