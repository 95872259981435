import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import useUrlState from "@ahooksjs/use-url-state";
import slotsAPI from "../../../../services/slots";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { GetWeekDay } from "../../../../utils/dates";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import CreateSlotModal from "../components/CreateSlotModal";
import MeaDataTable from "../../../../components/MeaDataTable";
import { InputText } from "primereact/inputtext";
import UpdateSlotModal from "./UpdateSlotModal";

const SlotsListComponent = forwardRef((props, ref) => {
  const { showFilterButton, showAddButton } = props;
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const createModalRef = useRef();
  const updateModalRef = useRef();
  //
  const [state, setState] = useUrlState({
    slotsPage: "1",
    slotsPageSize: "30",
    slotsWeekday: "",
  });
  const [slot, setSlot] = useState(null)
  //
  //
  const queryList = slotsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    state.slotsWeekday
  );
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));
  //
  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={state.slotsWeekday}
        options={[
          { label: "Lundi", value: "1" },
          { label: "Mardi", value: "2" },
          { label: "Mercredi", value: "3" },
          { label: "Jeudi", value: "4" },
          { label: "Vendredi", value: "5" },
          { label: "Samedi", value: "6" },
          { label: "Dimanche", value: "0" },
        ]}
        onChange={(e) => {
          options.filterApplyCallback(e.value);
          setState((s) => ({
            slotsPage: 1,
            slotsWeekday: e.value ? Number(e.value) : "",
          }));
        }}
        //itemTemplate={statusItemTemplate}
        placeholder=""
        className="p-column-filter"
        showClear
        style={{ minWidth: "6rem" }}
      />
    );
  };
  //
  const startToolbarTemplate = (
    <div>
      {showAddButton && (
        <Button
          label="Créer un créneau"
          icon="pi pi-plus"
          size="small"
          className="p-button mr-2 action"
          onClick={() => {
            createModalRef.current.show(() => {
              queryList.refetch();
            });
          }}
          data-pr-tooltip="Create new slot"
        />
      )}
    </div>
  );
  //
  return (
    <>
      <CreateSlotModal ref={createModalRef} />
      <MeaDataTable
        query={queryList}
        serviceAPI={slotsAPI}
        names={{ pagination: "slot", singular: "créneau", plural: "créneaux" }}
        startToolbarTemplate={startToolbarTemplate}
        showFilterButton={showFilterButton}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column
          field="name"
          header="Créneau"
          editor={(options) => (
            <InputText
              type="text"
              value={options.value}
              onChange={(e) => options.editorCallback(e.target.value)}
            />
          )}
          body={(item) => (
            <MeaLink
              to={"/c/t/" + schoolId + "/" + trainingId + "/slots/" + item.id}
            >
              {item.name}
            </MeaLink>
          )}
        ></Column>
        <Column
          field="weekday"
          header="Jour de la semaine"
          filter
          showFilterMenu={false}
          filterElement={statusRowFilterTemplate}
          body={(item) => GetWeekDay(item.weekday)}
          style={{ width: "20%" }}
          editor={(options) => (
            <Dropdown
              value={options.value}
              options={[
                { label: "Lundi", value: 1 },
                { label: "Mardi", value: 2 },
                { label: "Mercredi", value: 3 },
                { label: "Jeudi", value: 4 },
                { label: "Vendredi", value: 5 },
                { label: "Samedi", value: 6 },
                { label: "Dimanche", value: 0 },
              ]}
              onChange={(e) => options.editorCallback(e.value)}
              placeholder="Select a Status"
            />
          )}
        ></Column>
        <Column
          field="start_hour"
          header="Heure de début"
          style={{ width: "20%" }}
          editor={(options) => (
            <InputText
              type="time"
              value={options.value}
              onChange={(e) => options.editorCallback(e.target.value)}
            />
          )}
        ></Column>
        <Column
          field="end_hour"
          header="Heure de fin"
          style={{ width: "20%" }}
          editor={(options) => (
            <InputText
              type="time"
              value={options.value}
              onChange={(e) => options.editorCallback(e.target.value)}
            />
          )}
        ></Column>
        <Column
          body={(item) => (
            <>
              <Button
                icon="pi pi-pencil" 
                rounded text aria-label="Filter"
                onClick={()=>{
                  setSlot(item)
                  if (updateModalRef.current) {
                    updateModalRef.current.show(() => {
                      queryList.refetch();
                    });
                  }
                }}
              />
            </>
          )}
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </MeaDataTable>
      <UpdateSlotModal ref={updateModalRef} slot={slot} />
    </>
  );
});

export default SlotsListComponent;
