import MeaPage from "../../../components/MeaPage";
import { useRef } from "react";
import SessionsListComponent from "./components/SessionsListComponent";

const SessionsListPage = () => {
  //
  const listRef = useRef();
  //
  return (
    <>
      <MeaPage
        //breadcrumb={<SlotsBreadCrumb />}
        title="Liste des séances de cours"
        subtitle="Gérer l'ensemble des séances de cours dans cette formation"
      >
        <SessionsListComponent
          ref={listRef}
          showAddButton={true}
          showFilterButton={true}
        />
      </MeaPage>
    </>
  );
};
export default SessionsListPage;
