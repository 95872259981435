import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useEffect, useRef, useState } from "react";
import levelsAPI from "../../../../services/levels";
import classesAPI from "../../../../services/classes";
import useAuthContext from "../../../../hooks/useAuthContext";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import studentsAPI from "../../../../services/students";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

const StudentRegistrationComponent = ({ student, callback }) => {
  const navigate = useNavigate();
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const toast = useRef();
  //
  const [currentStudent, setCurrentStudent] = useState();
  //
  useEffect(() => {
    if (student) {
      console.log(student);
      setCurrentStudent(student);
    }
  }, [student]);

  //
  const levelsQuery = levelsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const classesQuery = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000,
    {
      level_id: currentStudent?.level_id,
    }
  );
  //
  const updateStudentField = (field, value) => {
    let updated = {};
    updated[field] = value;
    setCurrentStudent((student) => ({
      ...student,
      ...updated,
    }));
  };
  const formField = (label, field) => {
    return (
      <div className="p-inputgroup flex-1">
        <span className="p-float-label">
          <InputText
            className="bg-indigo-50"
            value={currentStudent ? currentStudent[field] : ""}
            onChange={(e) => updateStudentField(field, e.target.value)}
          />
          <label>{label}</label>
        </span>
      </div>
    );
  };
  //
  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} position="bottom-center" />
      <Accordion multiple activeIndex={[0, 1, 2]}>
        <AccordionTab
          header={
            <div className="mr-4 p-2 bg-indigo-100">
              <b>Pré-inscription dans : </b>
            </div>
          }
        >
          <p className="mt-0">
            Sélectionnez le niveau et/ou la classe dans lesquels vous souhaitez
            pré-inscrire les élèves.
            <br />
          </p>
          <div className="card flex justify-content-start">
            <Dropdown
              value={levelsQuery?.data?.data?.find(
                (obj) => obj.id === currentStudent?.level_id
              )}
              optionLabel="name"
              onChange={(e) => {
                updateStudentField("level_id", e.value?.id);
                updateStudentField("class_id", "");
              }}
              options={levelsQuery?.data?.data}
              showClear
              placeholder="Choisir un niveau"
              className="w-full md:w-14rem"
            />
            <Dropdown
              disabled={
                currentStudent?.level_id === null ||
                currentStudent?.level_id === undefined ||
                currentStudent?.level_id === ""
              }
              value={classesQuery?.data?.data?.find(
                (obj) => obj.id === currentStudent?.class_id
              )}
              optionLabel="name"
              onChange={(e) => {
                updateStudentField("class_id", e.value?.id);
              }}
              options={classesQuery?.data?.data}
              showClear
              placeholder="Choisir une classe"
              className="w-full md:w-14rem ml-2"
            />
          </div>
        </AccordionTab>
        <AccordionTab
          header={
            <div className="mr-4 p-2 bg-indigo-100">
              <b>Dossier de pré-inscription :</b>
            </div>
          }
        >
          <p className="m-0">
            Veuillez remplir le dossier de pré-inscription suivant.
          </p>
          <Fieldset legend="Elève">
            <div className="card flex flex-column md:flex-row gap-3">
              {formField("Nom de l'élève", "lastname")}
              {formField("Prénom de l'élève", "firstname")}
              <div className="p-inputgroup flex-1">
                <span className="p-float-label">
                  <InputMask
                    id="birthday"
                    className="bg-indigo-50"
                    mask="99-99-9999"
                    value={currentStudent ? currentStudent["birthday"] : ""}
                    onChange={(e) =>
                      updateStudentField("birthday", e.target.value)
                    }
                  />
                  <label>Date de naissance</label>
                </span>
              </div>
              <div className="p-inputgroup flex-1">
                <span className="p-float-label">
                  <Dropdown
                    value={currentStudent?.gender}
                    onChange={(e) => { updateStudentField("gender", e.value) }}
                    options={[
                      { name: "Fille", value: 0 },
                      { name: "Garçon", value: 1 },
                    ]}
                    optionLabel="name"
                    placeholder="Genre"
                  />
                </span>
              </div>
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              {formField("Téléphone", "phone")}
              {formField("E-mail", "email")}
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              {formField("Adresse postale", "address")}
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="ingredient1"
                  name="pizza"
                  checked={
                    currentStudent ? currentStudent["accept_contacted"] : ""
                  }
                  onChange={(e) =>
                    updateStudentField("accept_contacted", e.checked)
                  }
                />
                <label className="ml-2">
                  J'autorise l'école Omar à m'envoyer des informations
                </label>
              </div>
            </div>
            <div className="flex overflow-hidden mt-3">
              <Button
                className="flex-none"
                label="Vérifier si'il existe déjà"
                outlined
                onClick={() => {
                  //console.log(student);
                }}
              />
              <div className="flex-grow-1 flex align-items-center justify-content-center"></div>
              <Button
                className="flex-none"
                label="Enregistrer"
                onClick={() => {
                  studentsAPI.UpdateOne(
                    schoolId,
                    trainingId,
                    accessToken,
                    currentStudent?.id,
                    {
                      firstname: currentStudent?.firstname,
                      lastname: currentStudent?.lastname,
                      email: currentStudent?.email,
                      phone: currentStudent?.phone,
                      address: currentStudent?.address,
                      accept_contacted: currentStudent?.accept_contacted,
                      birthday: currentStudent?.birthday,
                      gender: currentStudent?.gender?.value,
                    }
                  );
                }}
              />
            </div>
          </Fieldset>

          <Fieldset legend="Responsables légaux">
            <div className="mb-3">Informations du père:</div>
            <div className="card flex flex-column md:flex-row gap-3">
              {formField("Nom du père", "father_lastname")}
              {formField("Prénom du père", "father_firstname")}
              {formField("Téléphone du père", "father_phone")}
              {formField("E-mail du père", "father_email")}
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              {formField("Adresse postale du père", "father_address")}
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <div className="flex align-items-center">
                <Checkbox
                  checked={
                    currentStudent
                      ? currentStudent["father_accept_contacted"]
                      : ""
                  }
                  onChange={(e) =>
                    updateStudentField("father_accept_contacted", e.checked)
                  }
                />
                <label className="ml-2">
                  J'autorise l'école Omar à m'envoyer des informations
                </label>
              </div>
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <Button label="Vérifier s'il existe déjà" outlined />
            </div>

            <Divider />
            <div className="mb-3">Informations de la mère:</div>
            <div className="card flex flex-column md:flex-row gap-3">
              {formField("Nom de la mère", "mother_lastname")}
              {formField("Prénom de la mère", "mother_firstname")}
              {formField("Téléphone de la mère", "mother_phone")}
              {formField("E-mail de la mère", "mother_email")}
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              {formField("Adresse postale de la mère", "mother_address")}
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <div className="flex align-items-center">
                <Checkbox
                  checked={
                    currentStudent
                      ? currentStudent["mother_accept_contacted"]
                      : ""
                  }
                  onChange={(e) =>
                    updateStudentField("mother_accept_contacted", e.checked)
                  }
                />
                <label className="ml-2">
                  J'autorise l'école Omar à m'envoyer des informations
                </label>
              </div>
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <Button label="Vérifier si elle existe déjà" outlined />
            </div>
            <Divider />
            <div className="mb-3">Situation familiale:</div>
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.parents_divorced === false}
                  onChange={(e) =>
                    updateStudentField("parents_divorced", false)
                  }
                />
                <label className="ml-2">Marié(e)</label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.parents_divorced === true}
                  onChange={(e) => updateStudentField("parents_divorced", true)}
                />
                <label className="ml-2">Divorcé(e)</label>
              </div>
            </div>
          </Fieldset>

          <Fieldset legend="Personnes à contacter en cas d'urgence">
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              {formField("Nom & Prénom - Personne 1", "contact_person_1")}
              {formField("Téléphone personne 1", "contact_person_1_phone")}
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              {formField("Nom & Prénom - Personne 2", "contact_person_2")}
              {formField("Téléphone personne 2", "contact_person_2_phone")}
            </div>
          </Fieldset>
          <Fieldset legend="Autorisations">
            <div className="mb-3">
              J'autorise mon enfant à partir seul après l'école ?
            </div>
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.authorized_back_alone === true}
                  onChange={(e) =>
                    updateStudentField("authorized_back_alone", true)
                  }
                />
                <label className="ml-2">Oui</label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.authorized_back_alone === false}
                  onChange={(e) =>
                    updateStudentField("authorized_back_alone", false)
                  }
                />
                <label className="ml-2">Non</label>
              </div>
            </div>
            <div className="mb-3 mt-5">
              Personnes autorisées à récupérer l'enfant après les cours ?
            </div>
            <div className="card flex flex-wrap justify-content-start gap-3">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="ingredient1"
                  name="pizza"
                  value="Cheese"
                //onChange={onIngredientsChange}
                //checked={ingredients.includes("Cheese")}
                />
                <label className="ml-2">Père</label>
              </div>
              <div className="flex align-items-center">
                <Checkbox
                  inputId="ingredient2"
                  name="pizza"
                  value="Mushroom"
                //onChange={onIngredientsChange}
                //checked={ingredients.includes("Mushroom")}
                />
                <label className="ml-2">Mère</label>
              </div>
              <div className="flex align-items-center">
                <Checkbox
                  inputId="ingredient3"
                  name="pizza"
                  value="Pepper"
                //onChange={onIngredientsChange}
                //checked={ingredients.includes("Pepper")}
                />
                <label className="ml-2">Autre :</label>
              </div>
              <div className="flex align-items-center">
                <InputText />
              </div>
            </div>
            <div className="mb-3 mt-5">
              <b>Droit à l'image :</b> J’autorise l’école à utiliser sur ses
              différents supports et outils de communication, les éventuelles
              photographies ou vidéos de mon enfant réalisées dans le cadre de
              l’école ?
            </div>
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.authorized_take_photo === true}
                  onChange={(e) =>
                    updateStudentField("authorized_take_photo", true)
                  }
                />
                <label className="ml-2">Oui</label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.authorized_take_photo === false}
                  onChange={(e) =>
                    updateStudentField("authorized_take_photo", false)
                  }
                />
                <label className="ml-2">Non</label>
              </div>
            </div>
          </Fieldset>
          <Fieldset legend="Renseignements médicaux">
            <div className="mb-3">L'enfant a-il des problèmes de santé ?</div>
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.health_issues === true}
                  onChange={(e) => updateStudentField("health_issues", true)}
                />
                <label className="ml-2">Oui</label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.health_issues === false}
                  onChange={(e) => updateStudentField("health_issues", false)}
                />
                <label className="ml-2">Non</label>
              </div>
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <div className="p-inputgroup flex-1">
                <span className="p-float-label">
                  <InputText
                    id="firstname"
                    className="bg-indigo-50"
                  //value={value}
                  //onChange={(e) => setValue(e.target.value)}
                  />
                  <label>
                    Préciser les précautions à prendre et la conduite à tenir
                  </label>
                </span>
              </div>
            </div>
            <div className="mb-3 mt-5">
              L'enfant suit-il un traitement médical ? (ATTENTION : aucun
              traitement médical ne pourra être administré sans ordonnance)
            </div>
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.medical_traitement === true}
                  onChange={(e) =>
                    updateStudentField("medical_traitement", true)
                  }
                />
                <label className="ml-2">Oui</label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.medical_traitement === false}
                  onChange={(e) =>
                    updateStudentField("medical_traitement", false)
                  }
                />
                <label className="ml-2">Non</label>
              </div>
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <div className="p-inputgroup flex-1">
                <span className="p-float-label">
                  <InputText
                    id="firstname"
                    className="bg-indigo-50"
                  //value={value}
                  //onChange={(e) => setValue(e.target.value)}
                  />
                  <label>Préciser la conduite à tenir</label>
                </span>
              </div>
            </div>
            <div className="mb-3 mt-5">L'enfant a-il des allergies ?</div>
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.has_allergies === true}
                  onChange={(e) => updateStudentField("has_allergies", true)}
                />
                <label className="ml-2">Oui</label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.has_allergies === false}
                  onChange={(e) => updateStudentField("has_allergies", false)}
                />
                <label className="ml-2">Non</label>
              </div>
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <div className="p-inputgroup flex-1">
                <span className="p-float-label">
                  <InputText
                    id="firstname"
                    className="bg-indigo-50"
                  //value={value}
                  //onChange={(e) => setValue(e.target.value)}
                  />
                  <label>Préciser lesquelles</label>
                </span>
              </div>
            </div>
            <div className="mb-3 mt-5">
              L'enfant porte-il des équipements médicales (des lunettes, des
              prothèses auditives, des prothèses dentaires, etc)?
            </div>
            <div className="card flex flex-column md:flex-row gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.wears_medical_devices === true}
                  onChange={(e) =>
                    updateStudentField("wears_medical_devices", true)
                  }
                />
                <label className="ml-2">Oui</label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  checked={currentStudent?.wears_medical_devices === false}
                  onChange={(e) =>
                    updateStudentField("wears_medical_devices", false)
                  }
                />
                <label className="ml-2">Non</label>
              </div>
            </div>
            <div className="card flex flex-column md:flex-row gap-3 mt-3">
              <div className="p-inputgroup flex-1">
                <span className="p-float-label">
                  <InputText
                    id="firstname"
                    className="bg-indigo-50"
                  //value={value}
                  //onChange={(e) => setValue(e.target.value)}
                  />
                  <label>Préciser lesquelles</label>
                </span>
              </div>
            </div>
          </Fieldset>
        </AccordionTab>
        <AccordionTab
          header={
            <div className="mr-4 p-2 bg-indigo-100">
              <b>Confirmation : </b>
            </div>
          }
        >
          <div className="mb-3">
            Je m’engage à informer l’école de tous changements de situation
            familiale, coordonnées…
          </div>
          <div className="card flex flex-wrap justify-content-start gap-3">
            <div className="flex align-items-center">
              <Checkbox
                checked={
                  currentStudent
                    ? currentStudent["inform_change_of_situation"]
                    : ""
                }
                onChange={(e) =>
                  updateStudentField("inform_change_of_situation", e.checked)
                }
              />
              <label className="ml-2">Oui</label>
            </div>
          </div>
          <Button
            className="mt-5 mb-3"
            label="Créer le dossier de pré-inscription"
            disabled={!currentStudent?.inform_change_of_situation}
            onClick={() => {
              confirmDialog({
                message: "Voulez-vous enregistrer cette pré-inscription?",
                header: "Confirmation",
                icon: "pi pi-info-circle",
                acceptLabel: "Oui",
                rejectLabel: "Non",
                defaultFocus: "reject",
                accept: () => {
                  studentsAPI
                    .CreateOne(schoolId, trainingId, accessToken, student)
                    .then((data) => {
                      toast.current.show({
                        severity: "success",
                        summary: "Succès",
                        detail: "Succès",
                        life: 3000,
                      });
                      //console.log(data);
                      navigate(
                        "/c/t/" +
                        schoolId +
                        "/" +
                        trainingId +
                        "/students/" +
                        data?.data?.id
                      );
                    })
                    .catch((error) => {
                      toast.current.show({
                        severity: "error",
                        summary: "Erreur",
                        detail:
                          "Erreur d'enregistrement de la pré-inscription!",
                      });
                    });
                },
              });
            }}
          />
        </AccordionTab>
      </Accordion>
    </>
  );
};

export default StudentRegistrationComponent;
