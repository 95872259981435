import MeaDetailField from "../../../../components/MeaDetailField";
import MeaDetailCard from "../../../../components/MeaDetailCard";

const TeacherDetailComponent = ({ teacher }) => {
  return (
    <MeaDetailCard>
      <MeaDetailField field="Identifiant" value={teacher?.member?.id || "-"} />
      <MeaDetailField field="Nom" value={teacher?.member?.lastname || "-"} />
      <MeaDetailField
        field="Prénom"
        value={teacher?.member?.firstname || "-"}
      />
      <MeaDetailField
        field="Nom d'usage"
        value={teacher?.member?.name || "-"}
      />
      <MeaDetailField field="Genre" value={
        teacher?.member?.gender !== undefined &&
          teacher?.member?.gender === "G"
          ? "Féminin"
          : teacher?.member?.gender === "B"
            ? "Masulin"
            : "-"
      }
      />
      <MeaDetailField field="Email" value={teacher?.member?.email || "-"} />
      <MeaDetailField field="Téléphone" value={teacher?.member?.phone || "-"} />
    </MeaDetailCard>
  );
};

export default TeacherDetailComponent;
