import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetStudentsRegistrationsStats = (
  schoolId,
  trainingId,
  accessToken
) => {
  return useQuery(
    ["stats-students-subscriptions", schoolId, trainingId, accessToken],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
            `/api/v1/schools/${schoolId}/trainings/${trainingId}/statistics/students-registrations`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch subscription statistcs!");
        }
        if (response.status >= 299) {
          throw new Error(
            "Erreur de récupération de la liste des statistiques des inscriptions d'élèves!"
          );
        }
        const result = await response.json();
        return result;
      } catch (error) {
        // Handle the error
        console.error("Error fetching statistics:", error);
        throw error;
      }
    }
  );
};

const statisticsAPI = {
  useGetStudentsRegistrationsStats,
};

export default statisticsAPI;
