import React, { useState, useEffect, forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import teachersAPI from "../../../../services/teachers";
import useAuthContext from "../../../../hooks/useAuthContext";
import classroomsAPI from "../../../../services/classrooms";
import MeaUpdateModal from "../../../../components/MeaUpdateModal";
import slotsAPI from "../../../../services/slots";
import scheduleAPI from "../../../../services/schedule";

const UpdateScheduleModal = forwardRef((props, ref) => {
  const { authContext } = useAuthContext();
  //
  const schoolId = authContext.currentMembership?.school?.id;
  const trainingId = authContext?.currentTraining?.id;
  const accessToken = authContext?.auth?.access_token;
  //
  const [teacher, setTeacher] = useState("-1");
  const [classroom, setClassroom] = useState("-1");
  const [slot, setSlot] = useState({
    id: "",
    startHour: "",
    endHour: "",
  });
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");
  //
  useEffect(() => {
    setTeacher(props?.schedule?.teacher_id || "-1");
    setClassroom(props?.schedule?.classroom_id || "-1");
    setSlot(props?.schedule?.slot?.id || "-1");
    setStartHour(props?.schedule?.slot?.start_hour);
    setEndHour(props?.schedule?.slot?.end_hour);
  }, [props]);

  //
  const slotsList = slotsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueSlots = slotsList.data?.data || [];
  uniqueSlots.sort((a, b) => a.show_order - b.show_order);
  const slots = uniqueSlots.map((slot, index) => ({
    key: slot.id,
    label: slot.name,
    value: slot.id,
    start_hour: slot.start_hour,
    end_hour: slot.end_hour,
    show_order: slot.show_order,
  }));
  slots.unshift({
    key: "empty-slot-key",
    label: "",
    value: "",
    start_hour: "",
    end_hour: "",
    show_order: 0,
  });
  //
  const teachersList = teachersAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueTeachers = teachersList.data?.data || [];
  uniqueTeachers.sort((a, b) => a.member.lastname - b.member.lastname);
  const teachers = uniqueTeachers.map((teacher, index) => ({
    key: teacher.id,
    label: teacher.member.name ? teacher.member.name : teacher.member.lastname + " " + teacher.member.firstname,
    value: teacher.id,
  }));
  teachers.unshift({
    key: "empty-teacher-key",
    label: "",
    value: "",
  });
  //
  const classroomsList = classroomsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    100000
  );
  const uniqueClassrooms = classroomsList.data?.data || [];
  uniqueClassrooms.sort((a, b) => a.name - b.name);
  const classrooms = uniqueClassrooms.map((classroom, index) => ({
    key: classroom.id,
    label: classroom.name,
    value: classroom.id,
  }));
  classrooms.unshift({
    key: "empty-classroom-key",
    label: "",
    value: "",
  });

  //
  return (
    <>
      <MeaUpdateModal
        ref={ref}
        title="Editer une programmation"
        serviceAPI={scheduleAPI}
        id={props?.schedule?.id}
        data={{
          teacher_id: teacher === "-1" ? "" : teacher,
          classroom_id: classroom === "-1" ? "" : classroom,
          slot_id: slot === "-1" ? "" : slot,
          start_hour: startHour,
          end_hour: endHour,
        }}
      >
        <label htmlFor="Créneau" className="block text-900 font-medium mb-2">
          Créneau
        </label>
        <Dropdown
          id="Créneau"
          options={slots}
          value={slot || "-1"}
          onChange={(e) => {
            console.log(e);
            setSlot(e.value);
            setStartHour(e.start_hour);
            setEndHour(e.end_hour);
          }}
          optionLabel="label"
          placeholder="Select a slot"
          className="w-full mb-3"
        />
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="heureDebut"
              className="block text-900 font-medium mb-2"
            >
              Heure de début
            </label>
            <InputText
              id="heureDebut"
              type="time"
              value={startHour}
              onChange={(e) => setStartHour(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="heureFin"
              className="block text-900 font-medium mb-2"
            >
              Heure de fin
            </label>
            <InputText
              id="heureFin"
              type="time"
              value={endHour}
              onChange={(e) => setEndHour(e.target.value)}
              className="w-full mb-3"
            />
          </div>
        </div>
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label htmlFor="classe" className="block text-900 font-medium mb-2">
              Classe
            </label>
            <InputText
              id="classe"
              value={props?.schedule?.classe?.name}
              className="w-full mb-3"
              disabled={true}
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label htmlFor="cours" className="block text-900 font-medium mb-2">
              Cours
            </label>
            <InputText
              id="cours"
              value={props?.schedule?.course?.name}
              className="w-full mb-3"
              disabled={true}
            />
          </div>
        </div>
        <div className="card flex flex-column md:flex-row gap-3">
          <div className="p-inputgroup flex flex-column gap-2">
            <label
              htmlFor="enseignant"
              className="block text-900 font-medium mb-2"
            >
              Enseignant(e)
            </label>
            <Dropdown
              id="enseignant"
              options={teachers}
              value={teacher || "-1"}
              onChange={(e) => setTeacher(e.value)}
              optionLabel="label"
              placeholder="Select a Teacher"
              className="w-full mb-3"
            />
          </div>
          <div className="p-inputgroup flex flex-column gap-2">
            <label htmlFor="salle" className="block text-900 font-medium mb-2">
              Salle
            </label>
            <Dropdown
              id="salle"
              options={classrooms}
              value={classroom || "-1"}
              onChange={(e) => setClassroom(e.value)}
              optionLabel="label"
              placeholder="Select a Classroom"
              className="w-full mb-3"
            />
          </div>
        </div>
      </MeaUpdateModal>
    </>
  );
});

export default UpdateScheduleModal;
