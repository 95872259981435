import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const useMemberships = (accessToken, page, pageSize) => {
  return useQuery(["memberships", accessToken, page, pageSize], async () => {
    try {
      const response = await fetch(
        BACKEND_URL +
        `/api/v1/account/memberships?page=${page}&pageSize=${pageSize}`,
        {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch memberships!");
      }
      if (response.status >= 299) {
        throw new Error("Erreur de récupération de la liste de mes écoles!");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      // Handle the error
      console.error("Error fetching memberships:", error);
      throw error;
    }
  });
};

const GetMemberships = async (accessToken, page, pageSize) => {
  try {
    const response = await fetch(BACKEND_URL +
      `/api/v1/account/memberships?page=${page}&pageSize=${pageSize}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur de récupération de la liste de mes écoles!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateCurrentAccountPreferences = async (
  accessToken,
  lastVisitedSchool
) => {
  try {
    if (!lastVisitedSchool) {
      return null;
    }
    const response = await fetch(BACKEND_URL + `/api/v1/account/preferences`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "PATCH",
      credentials: "include",
      body: JSON.stringify({
        last_visited_school: lastVisitedSchool ? lastVisitedSchool : null,
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to update account preferences!");
    }
    if (response.status >= 299) {
      throw new Error(
        "Erreur de mise à jours des préférences de l'utilisateur courant!"
      );
    }
    const trainingObj = await response.json();
    return trainingObj?.data;
  } catch (error) {
    console.error(
      `There was an error in updating current account preferences: ${error.message}`
    );
    return null;
  }
};

const accountsAPI = {
  useMemberships,
  GetMemberships,
  UpdateCurrentAccountPreferences,
};

export default accountsAPI;
