import { useState, useRef } from "react";
import useAuthContext from "../../hooks/useAuthContext";
import { useNavigate, useLocation } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import MeaLink from "../../components/MeaLink";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const LOGIN_URL = BACKEND_URL + "/api/v1/auth/login";

const Login = () => {
  const { setCurrentAccount, switchSchool, switchTraining } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  //
  const captchaRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    setLoading(true);
    fetch(LOGIN_URL, {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
        "g-recaptcha-response": token,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status >= 299) {
          throw new Error("Votre mot de passe ou votre email semble erroné.");
        }
        return response.json();
      })
      .then((response) => {
        if (response) {
          //const auth = response?.auth;
          const auth = undefined;
          const account = response?.account;
          setCurrentAccount(account);
          console.log("login success");
          //setAuth(auth);
          //
          if ((from && from === "") || from === "/") {
            let schoolId = null;
            let trainingId = null;
            let member = null;
            const accountPreferences = response?.account?.preferences;
            if (accountPreferences && accountPreferences?.last_visited_school) {
              // check already visited a school before
              schoolId = accountPreferences?.last_visited_school;
              //const lastVisitedSchool = accountPreferences?.last_visited_school;
              const memberships = account?.memberships;
              if (memberships) {
                member = memberships.find((element, index, array) => {
                  return element.school_id === schoolId;
                });
              }
              // load last visited training
              if (
                member &&
                member?.preferences &&
                member?.preferences?.last_visited_training
              ) {
                trainingId = member?.preferences?.last_visited_training;
              }
              //
              //
              if (trainingId !== null) {
                //
                switchSchool(schoolId, account);
                //let training = trainingsAPI.GetTrainingObj(schoolId, trainingId, accessToken);
                switchTraining(trainingId, schoolId, auth?.access_token)
                  .then((result) => {
                    navigate("/", { replace: true });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                if (schoolId !== null) {
                  switchSchool(schoolId, account);
                  navigate("/", { replace: true });
                } else {
                }
              }
            }
          }
          navigate(from, { replace: true });
          //
          setUsername("");
          setPassword("");
        } else {
          setPassword("");
        }
      })
      .catch(function (error) {
        setPassword("");
        setErrMsg("Problème d'authentification: " + error.message);
        try {
          captchaRef.current.reset();
        } catch (error) {
          console.log("captcha warning: ", error);
        }
      })
      .finally(() => {
        //
        setLoading(false);
      });
  };

  return (
    <>
      <div style={{ paddingTop: "30px" }} className="flex justify-content-center flex-wrap">
        <div style={{ maxWidth: "400px" }} className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="flex align-items-start">
              <Button
                icon="pi pi-arrow-left"
                text
                style={{ minHeight: "50px", maxWidth: "50px" }}
                onClick={() => navigate("/")}
              ></Button>
              <div>
                <MeaLink to="/d/welcome"><img src="/mea-logo.png" alt="hyper" height={50} className="mb-3 pl-3" /></MeaLink>
              </div>
            </div>
            <div className="text-900 text-3xl font-medium mb-3">Connexion</div>
            <span className="text-600 font-medium line-height-3">Vous n'avez pas de compte?</span>
            <MeaLink to="/d/signup"><span className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Inscrivez-vous!</span></MeaLink>
          </div>

          <div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" className="block text-900 font-medium mb-2">Adresse email</label>
              <InputText id="email" type="text" placeholder="" className="w-full mb-3" value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoFocus />

              <label htmlFor="password" className="block text-900 font-medium mb-2">Mot de passe</label>
              <InputText type="password" placeholder="" className="w-full mb-3" value={password}
                onChange={(e) => setPassword(e.target.value)} />

              <div className="flex align-items-center justify-content-between mb-6">
                <div className="flex align-items-center">
                  {/*<Checkbox id="rememberme" className="mr-2" checked={checked1} onChange={(e) => setChecked1(e.checked)} />
                <label htmlFor="rememberme">Remember me</label>*/}
                </div>
                <MeaLink to="/d/resetpass"><span className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Mot de passe oublié?</span></MeaLink>
              </div>
              <div className="flex align-items-center justify-content-between mb-4">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                ></ReCAPTCHA>
              </div>
              {errMsg && (
                <Message
                  severity="error"
                  text={errMsg}
                  className="block w-full mb-3"
                />
              )}
              <Button label="S'identifier" icon="pi pi-user" className="w-full" loading={loading} />

            </form>
          </div>
        </div>
      </div>

    </>
  );
};
export default Login;
