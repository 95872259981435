import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import DisconnectedUserLayout from "./layouts/DisconnectedUserLayout";
import ConnectedUserLayout from "./layouts/ConnectedUserLayout";
import SchoolHome from "./pages/school/SchoolHome";
import UserProfileDetailPage from "./pages/user/profile/UserProfileDetailPage";
import TrainingInfo from "./pages/training/detail/TrainingInfo";
import SlotsListPage from "./pages/training/slots/SlotsListPage";
import SlotDetailPage from "./pages/training/slots/SlotDetailPage";
import Login from "./pages/auth/Login";
import UserSchoolsDetailPage from "./pages/user/schools/UserSchoolsDetailPage";
import PasswordReset from "./pages/auth/PasswordReset";
import SessionsListPage from "./pages/training/sessions/SessionsListPage";
import ScheduleListPage from "./pages/training/schedule/ScheduleListPage";
import EventsListPage from "./pages/training/events/EventsListPage";
import ClassroomsListPage from "./pages/training/classrooms/ClassroomsListPage";
import LevelsListPagee from "./pages/training/levels/LevelsListPage";
import CoursesListPage from "./pages/training/courses/CoursesListPage";
import TeachersListPage from "./pages/training/teachers/TeachersListPage";
import ClassesListPage from "./pages/training/classes/ClassesListPage";
import StudentsListPage from "./pages/training/students/StudentsListPage";
import MembersListPage from "./pages/school/members/MembersListPage";
import TrainingsListPage from "./pages/school/trainings/TrainingsListPage";
import ClassroomDetailPage from "./pages/training/classrooms/ClassroomDetailPage";
import LevelDetailPage from "./pages/training/levels/LevelDetailPage";
import ClasseDetailPage from "./pages/training/classes/ClasseDetailPage";
import CourseDetailPage from "./pages/training/courses/CourseDetailPage";
import TeacherDetailPage from "./pages/training/teachers/TeacherDetailPage";
import StudentDetailPage from "./pages/training/students/StudentDetailPage";
import EventDetailPage from "./pages/training/events/EventDetailPage";
import MemberDetailPage from "./pages/school/members/MemberDetailPage";
import GenerateSessionsPage from "./pages/training/sessions/GenerateSessionsPage";
import StudentsRegistrationPage from "./pages/training/students/StudentsRegistrationPage";
import SessionDetailPage from "./pages/training/sessions/SessionDetailPage";
import QueuesListPage from "./pages/school/queues/QueuesListPage";
import QueueDetailPage from "./pages/school/queues/QueueDetailPage";
import PlanningDetailPage from "./pages/training/planning/PlanningDetailPage";
import AttendancesListPage from "./pages/acedemiclife/attendances/AttendancesListPage";
import RegistrationsListPage from "./pages/training/registrations/RegistrationsListPage";
import LandingPage from "./pages/landing/LandingPage";
import Signup from "./pages/auth/Signup";
import DoPasswordReset from "./pages/auth/DoPasswordReset";
import RegistrationDetailPage from "./pages/training/registrations/RegistrationDetailPage";
import NewRegistrationPage from "./pages/training/registrations/NewRegistrationPage";
import SystemSchoolsListPage from "./pages/system/schools/SystemSchoolsListPage";
import ProfileLayout from "./layouts/ProfileLayout";
import SchoolLayout from "./layouts/SchoolLayout";
import SuperAdminLayout from "./layouts/SuperAdminLayout";
import SystemAccountsListPage from "./pages/system/accounts/SystemAccountsListPage";
import SystemFeaturesListPage from "./pages/system/features/SystemFeaturesListPage";
import TrainingLayout from "./layouts/TrainingLayout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {/* public routes */}
        <Route path="d" element={<DisconnectedUserLayout />}>
          <Route path="welcome" element={<LandingPage />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="resetpass" element={<PasswordReset />} />
          <Route path="resetpass/:token" element={<DoPasswordReset />} />
          <Route path="unauthorized" element={<Signup />} />
        </Route>
      </Route>
      {/* connected user routes */}
      <Route path="c" element={<ConnectedUserLayout />}>
        {/* user account layout */}
        <Route path="user" element={<ProfileLayout />}>
          <Route index element={<UserProfileDetailPage />} />
          <Route path="profile" element={<UserProfileDetailPage />} />
          <Route path="schools" element={<UserSchoolsDetailPage />} />
        </Route>
        {/* school layout */}
        <Route path="s/:schoolId/" element={<SchoolLayout />}>
          <Route index element={<SchoolHome />} />
          <Route path="members" element={<MembersListPage />} loading />
          <Route
            path="members/:memberId"
            element={<MemberDetailPage />}
            loading
          />
          <Route path="trainings" element={<TrainingsListPage />} loading />
          <Route path="queues" element={<QueuesListPage />} loading />
          <Route path="queues/:queueId" element={<QueueDetailPage />} loading />
        </Route>
        {/* training layout */}
        <Route path="t/:schoolId/:trainingId/" element={<TrainingLayout />}>
          <Route index element={<TrainingInfo />} />
          <Route path="details" element={<TrainingInfo />} loading />
          <Route path="slots" element={<SlotsListPage />} loading />
          <Route path="slots/:slotId" element={<SlotDetailPage />} loading />
          <Route path="classrooms" element={<ClassroomsListPage />} loading />
          <Route
            path="classrooms/:classroomId"
            element={<ClassroomDetailPage />}
            loading
          />
          <Route path="levels" element={<LevelsListPagee />} loading />
          <Route path="levels/:levelId" element={<LevelDetailPage />} loading />
          <Route path="courses" element={<CoursesListPage />} loading />
          <Route
            path="courses/:courseId"
            element={<CourseDetailPage />}
            loading
          />
          <Route path="teachers" element={<TeachersListPage />} loading />
          <Route
            path="teachers/:teacherId"
            element={<TeacherDetailPage />}
            loading
          />
          <Route path="classes" element={<ClassesListPage />} loading />
          <Route
            path="classes/:classeId"
            element={<ClasseDetailPage />}
            loading
          />
          <Route
            path="students/:studentId"
            element={<StudentDetailPage />}
            loading
          />
          <Route
            path="registrations"
            element={<RegistrationsListPage />}
            loading
          />
          <Route
            path="registrations/:studentId"
            element={<RegistrationDetailPage />}
            loading
          />
          <Route
            path="new-registration"
            element={<NewRegistrationPage />}
            loading
          />

          <Route
            path="students-registration"
            element={<StudentsRegistrationPage />}
            loading
          />
          <Route path="sessions" element={<SessionsListPage />} loading />
          <Route
            path="sessions/:sessionId"
            element={<SessionDetailPage />}
            loading
          />
          <Route
            path="generate-sessions"
            element={<GenerateSessionsPage />}
            loading
          />
          <Route path="schedule" element={<ScheduleListPage />} loading />
          <Route path="events" element={<EventsListPage />} loading />
          <Route path="events/:eventId" element={<EventDetailPage />} loading />
          <Route path="planning" element={<PlanningDetailPage />} loading />
        </Route>
        {/* training layout */}
        <Route path="a/:schoolId/:trainingId/">
          <Route index element={<TrainingInfo />} />
          <Route path="students" element={<StudentsListPage />} loading />
          <Route path="attendances" element={<AttendancesListPage />} loading />
          <Route path="sessions" element={<SessionsListPage />} loading />
        </Route>
        {/* superadmin layout */}
        <Route path="sys/" element={<SuperAdminLayout />}>
          <Route index element={<SystemSchoolsListPage />} />
          <Route path="schools" element={<SystemSchoolsListPage />} loading />
          <Route path="accounts" element={<SystemAccountsListPage />} loading />
          <Route path="features" element={<SystemFeaturesListPage />} loading />
          <Route
            path="superadmins"
            element={<SystemSchoolsListPage />}
            loading
          />
          <Route path="features" element={<SystemSchoolsListPage />} loading />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
