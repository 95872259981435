import { useParams } from "react-router-dom";
import useAuthContext from "../../../hooks/useAuthContext";
import studentsAPI from "../../../services/students";
import NewRegistrationComponent from "./components/NewRegistrationComponent";
import MeaPage from "../../../components/MeaPage";
import { BreadCrumb } from "primereact/breadcrumb";

const NewRegistrationPage = () => {

    //
    //

    return (
        <MeaPage
            //breadcrumb={<BreadCrumb model={[{ label: 'Les inscriptions' }]} home={{ icon: 'pi pi-home', url: '/' }} />}
            title="Nouveau dossier d'inscription"
            subtitle="Créer un nouveau dossier d'inscription pour cette formation"
        >
            <NewRegistrationComponent />
        </MeaPage>
    );
}

export default NewRegistrationPage;
