import useAuthContext from "../../../../hooks/useAuthContext";
import { Column } from "primereact/column";
import MeaLink from "../../../../components/MeaLink";
import useUrlState from "@ahooksjs/use-url-state";
import scheduleAPI from "../../../../services/schedule";
import classesAPI from "../../../../services/classes";
import coursesAPI from "../../../../services/courses";
import slotsAPI from "../../../../services/slots";
import teachersAPI from "../../../../services/teachers";
import classroomsAPI from "../../../../services/classrooms";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import MeaDataTable from "../../../../components/MeaDataTable";
import MeaTeacherAvatar from "../../../../components/MeaTeacherAvatar";
import { Dropdown } from "primereact/dropdown";
import UpdateScheduleModal from "./UpdateScheduleModal";

const ScheduleListComponent = forwardRef((props, ref) => {
  const {
    selectedFields,
    filter,
    showFilterButton,
    showToolbar,
    showTotalSelectedElements,
  } = props;
  //
  const { auth, currentMembership, currentTraining } = useAuthContext();
  const schoolId = currentMembership?.school_id;
  const trainingId = currentTraining?.id;
  const accessToken = auth?.access_token;
  //
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();
  //
  const tableRef = useRef();
  const updateModalRef = useRef();
  //
  let selectedData = {
    slot: selectedFields?.slot ?? true,
    startHour: selectedFields?.startHour ?? true,
    endHour: selectedFields?.endHour ?? true,
    classe: selectedFields?.classe ?? true,
    course: selectedFields?.course ?? true,
    teacher: selectedFields?.teacher ?? true,
    classroom: selectedFields?.classroom ?? true,
    edit: selectedFields?.edit ?? true,
  };
  //
  const [state, setState] = useUrlState({
    schedulePage: "1",
    scheduleFilter: JSON.stringify({
      slot_id: filter?.slot_id || "",
      level_id: filter?.level_id || "",
      classe_id: filter?.classe_id || "",
      course_id: filter?.course_id || "",
      teacher_id: filter?.teacher_id || "",
      classroom_id: filter?.classroom_id || "",
    }),
  });
  //
  //
  const queryList = scheduleAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    page,
    pageSize,
    JSON.parse(state.scheduleFilter)
  );
  const [schedule, setschedule] = useState(null);
  //
  const classesQuery = classesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const coursesQuery = coursesAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const slotsQuery = slotsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const teachersQuery = teachersAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  const classroomsQuery = classroomsAPI.useGetList(
    schoolId,
    trainingId,
    accessToken,
    1,
    1000
  );
  //
  const setFilter = (field, value) => {
    let currentFilter = state?.scheduleFilter;
    let currentFilterObj = JSON.parse(currentFilter);
    currentFilterObj[field] = value;
    setState((s) => ({
      schedulePage: 1,
      scheduleFilter: JSON.stringify(currentFilterObj),
    }));
  };
  //
  const filterCombobox = (options, query, field) => {
    return (
      <Dropdown
        value={query?.data?.data?.find(
          (obj) => obj.id === JSON.parse(state.scheduleFilter)[field]
        )}
        options={query?.data?.data}
        optionLabel="name"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
          setFilter(field, e.value?.id || "");
        }}
        //itemTemplate={statusItemTemplate}
        placeholder="Séléctionner"
        className="p-column-filter"
        showClear
      />
    );
  };
  //
  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryList?.refetch();
    },
  }));

  //
  useImperativeHandle(ref, () => ({
    getSelectedElements: () => {
      return tableRef.current.getSelectedElements();
    },
  }));
  //
  return (
    <>
      <MeaDataTable
        ref={tableRef}
        query={queryList}
        serviceAPI={scheduleAPI}
        names={{
          pagination: "schedule",
          singular: "séance",
          plural: "séances par semaine",
        }}
        //startToolbarTemplate={startToolbarTemplate}
        showToolbar={showToolbar}
        showFilter={false}
        showFilterButton={showFilterButton}
        showTotalSelectedElements={showTotalSelectedElements}
        setPage={setPage}
        setPageSize={setPageSize}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        {selectedData.classe && (
          <Column
            field="classe.name"
            header="Classe"
            body={(schedule) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/classes/" +
                  schedule?.classe.id
                }
              >
                {schedule?.classe?.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, classesQuery, "classe_id")
            }
          ></Column>
        )}
        {selectedData.course && (
          <Column
            field="schedule_item.course.name"
            header="Cours"
            body={(schedule) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/courses/" +
                  schedule?.course?.id
                }
              >
                {schedule?.course?.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, coursesQuery, "course_id")
            }
          ></Column>
        )}
        {selectedData.slot && (
          <Column
            header="Créneau"
            body={(schedule) => {
              return (
                <MeaLink
                  to={
                    "/c/t/" +
                    schoolId +
                    "/" +
                    trainingId +
                    "/slots/" +
                    schedule?.slot_id
                  }
                >
                  {schedule?.slot?.name}
                </MeaLink>
              );
            }}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, slotsQuery, "slot_id")
            }
          ></Column>
        )}
        {selectedData.startHour && (
          <Column header="Heure de début" field="start_hour"></Column>
        )}
        {selectedData.endHour && (
          <Column header="Heure de début" field="end_hour"></Column>
        )}
        {selectedData.teacher && (
          <Column
            header="Enseignant(e)"
            body={(schedule) => (
              <MeaTeacherAvatar teacher={schedule?.teacher} />
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) => {
              return (
                <Dropdown
                  value={JSON.parse(state.scheduleFilter)["teacher_id"] || ""}
                  options={teachersQuery?.data?.data?.map((item) => {
                    return {
                      label:
                        item.member?.name ||
                        item.member?.lastname + " " + item.member?.firstname,
                      value: item.id,
                    };
                  })}
                  onChange={(e) => {
                    options.filterApplyCallback(e.value);
                    setFilter("teacher_id", e.value || "");
                  }}
                  //itemTemplate={statusItemTemplate}
                  placeholder="Séléctionner"
                  className="p-column-filter"
                  showClear
                />
              );
            }}
          ></Column>
        )}
        {selectedData.classroom && (
          <Column
            field="classroom.name"
            header="Salle"
            body={(schedule) => (
              <MeaLink
                to={
                  "/c/t/" +
                  schoolId +
                  "/" +
                  trainingId +
                  "/classrooms/" +
                  schedule?.classroom?.id
                }
              >
                {schedule?.classroom?.name}
              </MeaLink>
            )}
            filter
            showFilterMenu={false}
            showClearButton={false}
            filterElement={(options) =>
              filterCombobox(options, classroomsQuery, "classroom_id")
            }
          ></Column>
        )}
        {selectedData.edit && (
          <Column
            body={(schedule) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  text
                  aria-label="Filter"
                  onClick={() => {
                    setschedule(schedule);
                    if (updateModalRef.current) {
                      updateModalRef.current.show(() => {
                        queryList.refetch();
                      });
                    }
                  }}
                />
              </>
            )}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        )}
      </MeaDataTable>
      <UpdateScheduleModal ref={updateModalRef} schedule={schedule} />
    </>
  );
});

export default ScheduleListComponent;
