import { useQuery } from "react-query";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useGetList = (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
  filter
) => {
  return useQuery(
    ["students", schoolId, trainingId, accessToken, page, pageSize, filter],
    async () => {
      let levelId = filter?.level_id;
      if (levelId === undefined) {
        levelId = "";
      }
      let classeId = filter?.classe_id;
      if (classeId === undefined) {
        classeId = "";
      }
      let searchName = filter?.name;
      if (searchName === undefined) {
        searchName = "";
      }
      let memberId = filter?.member_id;
      if (memberId === undefined) {
        memberId = "";
      }
      let status = filter?.status;
      if (status === undefined) {
        status = "";
      }
      try {
        const response = await fetch(
          BACKEND_URL +
          `/api/v1/schools/${schoolId}/trainings/${trainingId}/students?page=${page}&pageSize=${pageSize}&level_id=${levelId}&class_id=${classeId}&name=${searchName}&member_id=${memberId}&status=${status}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch students!");
        }
        if (response.status >= 299) {
          throw new Error("Erreur de récupération de la liste des élèves!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        // Handle the error
        console.error("Error fetching students:", error);
        throw error;
      }
    }
  );
};

const SearchStudent = async (
  schoolId,
  trainingId,
  accessToken,
  page = 1,
  pageSize = 30,
  filter
) => {
  let levelId = filter?.level_id;
  if (levelId === undefined) {
    levelId = "";
  }
  let classeId = filter?.classe_id;
  if (classeId === undefined) {
    classeId = "";
  }
  let searchName = filter?.name;
  if (searchName === undefined) {
    searchName = "";
  }
  let memberId = filter?.member_id;
  if (memberId === undefined) {
    memberId = "";
  }
  let status = filter?.status;
  if (status === undefined) {
    status = "";
  }
  try {
    const response = await fetch(
      BACKEND_URL +
      `/api/v1/schools/${schoolId}/trainings/${trainingId}/students?page=${page}&pageSize=${pageSize}&level_id=${levelId}&class_id=${classeId}&name=${searchName}&member_id=${memberId}&status=${status}`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch students!");
    }
    if (response.status >= 299) {
      throw new Error("Erreur de récupération de la liste des élèves!");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    // Handle the error
    console.error("Error fetching students:", error);
    throw error;
  }
};

const GetOne = (schoolId, trainingId, accessToken, studentId) => {
  return useQuery(
    ["student", schoolId, trainingId, accessToken, studentId],
    async () => {
      try {
        const response = await fetch(
          BACKEND_URL +
          `/api/v1/schools/${schoolId}/trainings/${trainingId}/students/${studentId}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erreur de récupération de l'élève!");
        }
        const result = await response.json();
        return result;
      } catch (error) {
        throw error;
      }
    }
  );
};

const CreateOne = async (schoolId, trainingId, accessToken, data) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/students",
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de création de la demande de pré-inscription!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateOne = async (
  schoolId,
  trainingId,
  accessToken,
  studentId,
  data
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/students/" +
      studentId,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification des informations de l'élève!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateStatus = async (
  schoolId,
  trainingId,
  accessToken,
  studentId,
  newStatus,
  registrationConfirmationById
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/students/" +
      studentId +
      "/status",
      {
        method: "PATCH",
        body: JSON.stringify({ status: newStatus, registration_confirmation_by_id: registrationConfirmationById }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification du status de l'élève!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdateConfirmation = async (
  schoolId,
  trainingId,
  accessToken,
  studentId,
  data
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/students/" +
      studentId +
      "/confirmation",
      {
        method: "PATCH",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de modification du status de l'élève!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const UpdatePreviousTrainingInfos = async (
  schoolId,
  trainingId,
  accessToken,
  studentId
) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/students/" +
      studentId +
      "/update-previous-training-infos",
      {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de mise à jours de informations la formation précédente`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const DeleteOne = async (schoolId, trainingId, accessToken, studentId) => {
  try {
    const response = await fetch(
      BACKEND_URL +
      "/api/v1/schools/" +
      schoolId +
      "/trainings/" +
      trainingId +
      "/students/" +
      studentId,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Erreur de suppression de l'élève!`);
    }
    let actualData = await response.json();
    return actualData;
  } catch (err) {
    throw err;
  }
};

const studentsAPI = {
  useGetList,
  SearchStudent,
  GetOne,
  CreateOne,
  UpdateOne,
  UpdateStatus,
  UpdateConfirmation,
  UpdatePreviousTrainingInfos,
  DeleteOne,
};

export default studentsAPI;
